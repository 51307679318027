import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DialogConfig, DialogRef } from '@og_soft/dialog';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { UtilPrintService } from '@isp-sc/shared/data-access';

@Component({
  selector: 'sc-document-agreement-dialog',
  templateUrl: './document-agreement-dialog.component.html',
  styleUrls: ['./document-agreement-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DocumentAgreementDialogComponent {
  isConfirmed = false;
  isDocumentLoaded = false;

  @ViewChild(PdfViewerComponent)
  readonly pdfViewerComponent?: PdfViewerComponent;

  readonly documentId: number = this.dialogConfig?.data?.documentId;
  readonly documentNameTpl?: TemplateRef<void> =
    this.dialogConfig?.data?.documentNameTpl;
  readonly agreementDocumentUrl: string = this.utilPrintService.docLinkUrl(
    'cd',
    this.documentId
  );

  constructor(
    private readonly dialogConfig: DialogConfig,
    private readonly utilPrintService: UtilPrintService,
    private readonly cdr: ChangeDetectorRef,
    private readonly renderer: Renderer2,
    private readonly dialogRef: DialogRef
  ) {}

  handleLoadComplete(): void {
    this.isDocumentLoaded = true;
    this.renderer.setStyle(
      // Position of internal pdfViewerContainer must be reset to relative, so agreement checkbox will be shown below the file preview
      (
        this.pdfViewerComponent
          ?.pdfViewerContainer as ElementRef<HTMLDivElement>
      )?.nativeElement,
      'position',
      'relative'
    );
    this.cdr.detectChanges();
  }

  handleSubmit(): void {
    this.dialogRef.close({
      agreementConfirmed: true,
    });
  }
}
