<dialog-title>
  <h3 i18n="@@UserServiceEdit">Změna nastavení</h3>
</dialog-title>

<dialog-content>
  <p>
    <b>{{ caption }}</b>
  </p>

  <app-param-form
    [paramsService]="saParamsService"
    [form]="form"
    [paramFormName]="'params'"
    [objectId]="{ id: saId! }"
    [dependencyService]="dependencyService"
    (formFinished)="onParamsReady()"
  >
    <!--
  <p>
    <mat-form-field class="mango-form-field">
      <input matInput formControlName="saName" id="saName" name="saName" i18n-placeholder="@@SA_EDIT_LABEL_saName" placeholder="Název služby" i18n-title="@@SA_EDIT_HINT_saName" title="Uživatelem zvolený název služby">
    </mat-form-field>
  </p>
-->
  </app-param-form>
</dialog-content>

<dialog-actions>
  <button
    mat-flat-button
    dialog-close
    i18n="
      Zavřít|Tlačítko na zavření dialogu v dialogu při uzavření
      ticketu@@ProcessFinish.button.Close"
  >
    Zavřít
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="save()"
    i18n="
      Odeslat|Tlačítko na uložení dialogu v dialogu při uzavření
      ticketu@@ProcessFinish.button.Send"
  >
    Uložit
  </button>
</dialog-actions>
<app-session-processing></app-session-processing>
