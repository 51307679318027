import { Component, HostBinding, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'mgt-cell-group',

  template: `<ng-content></ng-content>`,

  styles: [
    `
      :host {
        flex: 0 1 20%;
      }
      :host[master] {
        flex: 100 2 auto;
      }

      :host[desktop-only].mobile {
        display: none;
      }

      :host[mobile-only]:not(.mobile) {
        display: none;
      }
    `,
  ],
})
export class MgtCellGroupComponent {
  /* Informace od deviceDetectorService, že jsme na mobilu. */
  @HostBinding('class.mobile')
  public mobile = false;

  constructor(private deviceDetector: DeviceDetectorService) {
    this.mobile = deviceDetector.isMobile();
  }
}
