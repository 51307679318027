import { Injectable } from '@angular/core';
import { DataBase, DataBaseServiceConfig } from '@og_soft/data-base';
import { HttpClient } from '@angular/common/http';
import { CachingInterceptor } from '@og_soft/cache';
import { PhoneNumberPipe } from '@og_soft/phone-number';
import { RecPhonetCreditGetData } from '@isp-sc/shared/segments/phonet/common';

@Injectable({
  providedIn: 'root',
})
export class PhonetCreditGetService extends DataBase<RecPhonetCreditGetData> {
  protected getAllUrl(): string {
    return 'phonet-credit-get' as const;
  }

  constructor(
    http: HttpClient,
    configService: DataBaseServiceConfig,
    cache: CachingInterceptor,
    protected phoneNumber: PhoneNumberPipe
  ) {
    super(http, configService, cache);
  }

  protected override recordPostprocess(
    record: RecPhonetCreditGetData
  ): RecPhonetCreditGetData {
    if (record.saPhonetNum) {
      record.saPhonetName +=
        ' (' + this.phoneNumber.transform('+' + record.saPhonetNum) + ')';
    }

    return super.recordPostprocess(record);
  }
}
