//Nastavení nutné proto, aby při použití hammerjs fungovalo scrolování
import { HammerGestureConfig } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MangoHammerConfig extends HammerGestureConfig {
  override overrides = <any>{
    pan: { direction: 6 },
    pinch: { enable: false },
    rotate: { enable: false },
  };
}
