import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ScConfiguration, SC_CONFIGURATION } from '@isp-sc/shared/common';

@Injectable({
  providedIn: 'root',
})
export class DataRmRedirectService {
  constructor(
    private http: HttpClient,
    @Inject(SC_CONFIGURATION) private scConfiguration: ScConfiguration
  ) {}

  private url = this.scConfiguration.baseUrl + `/rm-redir`;

  getSetup(encdata: any) {
    return this.http.get<any>(this.url + '/' + encdata);
  }

  doMacChange(data: any) {
    return this.http.post<any>(this.url, data);
  }
}
