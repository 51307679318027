import { Inject, Injectable } from '@angular/core';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { ScConfiguration, SC_CONFIGURATION } from '@isp-sc/shared/common';

@Injectable({
  providedIn: 'root',
})
export class UtilPrintService {
  constructor(
    public session: SessionService,
    @Inject(SC_CONFIGURATION) private scConfiguration: ScConfiguration
  ) {}

  public docLinkUrl(docType: any, docId: any) {
    let baseUrl = this.scConfiguration.baseUrl;
    if (
      this.scConfiguration.production &&
      this.session.user?.locDefaults.selfcareUrl
    ) {
      baseUrl = `https://${this.session.locDefaults.selfcareUrl}/api`;
    }

    return this.session.user
      ? `${baseUrl}/print-document/${docId}?dt=${docType}&atb=${this.session.user.atb}`
      : '';
  }
}
