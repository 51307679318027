import { Inject, Injectable } from '@angular/core';
import { DataBase, DataBaseServiceConfig } from '@og_soft/data-base';
import { Observable } from 'rxjs';
import { SC_CONFIGURATION, ScConfiguration } from '@isp-sc/shared/common';
import { HttpClient } from '@angular/common/http';
import { CachingInterceptor } from '@og_soft/cache';

@Injectable({
  providedIn: 'root',
})
export class ProtectedDataItemsCategoriesRegistrationService extends DataBase<{}> {
  constructor(
    public override http: HttpClient,
    serviceConfig: DataBaseServiceConfig,
    private cachingInterceptor: CachingInterceptor,
    @Inject(SC_CONFIGURATION) private scConfiguration: ScConfiguration
  ) {
    super(http, serviceConfig, cachingInterceptor);
  }
  protected getAllUrl(): string {
    return 'protected-data-items-categories-registration' as const;
  }

  override getSingleton(
    params?: any,
    cacheTimeout?: number,
    urlExpandParams?: any
  ): Observable<{}> {
    if (!params) {
      params = {};
    }
    params.ct = this.scConfiguration.ct;
    return super.getOneBy(params, cacheTimeout, urlExpandParams);
  }
}
