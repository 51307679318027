import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter, first, map, mergeMap, tap } from 'rxjs/operators';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UploadProgress } from '@og_soft/data-file';
import {
  ProcessGuideDialogComponent,
  ProcessMessageDialogComponent,
} from '@isp-sc/isp-sc/segments/selfcare/features';
import { ConfirmDialogComponent } from '@isp-sc/shared/ui';
import { DialogConfig, DialogService } from '@og_soft/dialog';
import { ParamFormComponent } from '@isp-sc/shared/segments/params/features';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { FormHelperService } from '@isp-sc/shared/data-access';
import { ProcessRedirectService } from '@isp-sc/shared/segments/process/data-access';
import {
  DataNewProcessService,
  DataParamsNewProcessService,
  DataParamsProcessService,
  DataParamsProcessGeneralService,
  DataProcessService,
  DataProcessGeneralService,
  MangoParamFormControl,
} from '@isp-sc/shared/segments/params/data-access';
import { Process } from '@isp-sc/shared/segments/params/common';
import {
  DataProcessAttachmentService,
  DataProcessNotesService,
} from '@isp-sc/shared/segments/mango-processes/data-access';
import {
  MangoFormControl,
  ProcessNote,
} from '@isp-sc/shared/segments/mango-processes/common';
import { DependencyProcessService } from '@isp-sc/shared/segments/dependency/data-access';
import { castToAny } from '@isp-sc/shared/common';
import {
  IProcessButton,
  IProcessTransition,
} from '@isp-sc/shared/segments/params/common';

@Component({
  selector: 'app-process-edit',
  templateUrl: './process-edit.component.html',
  styleUrls: ['./process-edit.component.scss'],
  providers: [DataProcessAttachmentService, DialogService],
})
export class ProcessEditComponent implements OnInit, OnDestroy {
  protected readonly castToAny = castToAny;
  form!: FormGroup;
  data!: Process;
  type?: number;
  guide: SafeHtml | null = null; // Informace pro uživatele
  processChanged = false; // Pomocná proměnná, která určí že se změnilo core procesu a vynutí reload při přepnutí
  // na odpovídající taby
  notEditable = false; // Určuje jestli je možné proces editovat (v závislosti na právech)
  paramsService: DataParamsProcessGeneralService | null = null; // Servisa pro parametry - přiřadím ji až na základě toho, jestli jde o nový proces
  dataService: DataProcessGeneralService | null = null; // Servisa pro atributy a přechody - přiřadím ji až na základě toho, jestli jde o nový proces

  @ViewChild(ParamFormComponent, { static: false })
  paramForm?: ParamFormComponent;

  attachmentFiles: UploadProgress[] = [];
  processId = 0;
  transitions?: IProcessTransition[];
  visibleTransitions?: IProcessTransition[];
  buttons?: IProcessButton[];
  visibleButtons?: IProcessButton[];
  processNotes?: ProcessNote[];

  public leavingWithBackButton = true;
  private backButtonSubscription?: Subscription;

  constructor(
    public dataServiceAct: DataProcessService,
    public dataServiceNew: DataNewProcessService,
    public paramsServiceAct: DataParamsProcessService,
    public paramsServiceNew: DataParamsNewProcessService,
    public attachmentService: DataProcessAttachmentService,
    private session: SessionService,
    private formHelper: FormHelperService,
    private route: ActivatedRoute,
    public deviceDetector: DeviceDetectorService,
    private historyService: DataProcessNotesService,
    public dependencyService: DependencyProcessService,
    private sanitizer: DomSanitizer,
    private dialog: DialogService,
    private processRedirectService: ProcessRedirectService,
    private router: Router
  ) {
    // při přesměrování nastavuji v redirect service, ale při reloadu se to ztratí
    // takže to tady nastavím ještě jednou.
    this.session.hideNav = true;
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      transition: new MangoFormControl(''),
      state: new MangoFormControl(''),
    });

    this.form.get('transition')?.valueChanges.subscribe(() => {
      this.updateGuide();
    });

    this.fetchProcessData();
    this.fetchAttachments();
    this.fetchProcessNotes();
    this.handleBackButtonClick();
  }

  ngOnDestroy() {
    this.backButtonSubscription?.unsubscribe();
  }

  handleBackButtonClick(): void {
    // subscription detekující event routeru
    this.backButtonSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        tap((event) => {
          // pokud je event vyvolán prohlížecovým tlačítkem zpět, nastavím proměnnou "leavingWithBackButton" na false
          this.leavingWithBackButton =
            event instanceof NavigationStart && !event.restoredState;
        })
      )
      .subscribe();
  }

  /**
   * Načte základní data k procesu - prakticky jsou využitelné především stavy a přechody
   * atributy se potom tahají společně s parametry do parametrového formuláře.
   */
  fetchProcessData(): void {
    this.session.processingSet(
      true,
      $localize`:@@ProcessEdit.loading.data.message:Načítám data požadavku`
    );
    this.route.params.subscribe((params) => {
      this.type = params['type'];
      if (params['id'] === '0') {
        this.paramsService = this.paramsServiceNew;
        this.dataService = this.dataServiceNew;
        this.dataServiceNew.getOneBy({ defId: params['type'] }).subscribe(
          (processData) => {
            this.setProcessData(processData);
          },
          (err) => {
            console.log('Chyba při získání nastavení ' + err);
          }
        );
      } else {
        this.processId = params['id'];
        this.paramsService = this.paramsServiceAct;
        this.dataService = this.dataServiceAct;
        // Zde získám data pro core procesu
        this.dataServiceAct
          .getone(params['id'], { defId: params['type'] })
          .subscribe(
            (processData) => {
              this.setProcessData(processData);
            },
            (err) => {
              console.log('Chyba při získání nastavení ' + err);
            }
          );
      }
    });
  }

  setProcessData(processData: Process): void {
    this.data = processData;
    this.transitions = processData.transitions;
    this.buttons = processData.buttons;
    this.updateTransitions();
    this.updateButtons();

    // V popisu pro uživatele nesmí být žádný nebezpečný obsah, můžu ho tedy označit za bezpečný.
    if (this.data.stateDescription) {
      this.guide = this.sanitizer.bypassSecurityTrustHtml(
        this.data.stateDescription
      );
    }
    this.form.controls['state'].setValue(this.data.stateId);
    this.notEditable = !this.data.editable;
    if (this.notEditable) {
      this.form.controls['transition'].disable();
    }
  }

  /**
   * Načte poznámky
   */
  fetchAttachments(): void {
    this.route.params.subscribe((params) => {
      if (Number(params['id']) > 0) {
        // Zde získám data pro historii procesu
        this.attachmentService.getall({ processId: params['id'] }).subscribe(
          (attachmentData) => {
            this.attachmentFiles = attachmentData.data;
          },
          (err) => {
            console.log('Nepodařilo se získat přílohy ' + err);
          }
        );
      }
    });
  }

  /**
   * Metodu volám když chci odejít z modulu tlačítkem zavřít. Pokud detekuje změny, zobrazí dialog na potvrzení.
   * Detekce není tak komplikovaná jako v OC, nepokrývá všechny možnosti,
   * spíš umožním zavřít něco rozeditovaného,
   * než bych obtěžoval dialogem když uživatel nic nezadal (viz popis checkDataChange).
   */
  private checkClose(): Observable<boolean> {
    return this.checkAttachmentsChange().pipe(
      mergeMap((change) => {
        if (this.checkFormChange() || change) {
          const dialogConfig = new DialogConfig();
          dialogConfig.data = {
            heading: $localize`:@@ProcessEdit.Close.ConfirmDialog.Heading:Chystáte se opustit stránku s neuloženými změnami.`,
            message: $localize`:@@ProcessEdit.Close.ConfirmDialog.Message:Opravdu si přejete opustit tuto stránku?`,
            messageDetail: $localize`:@@ProcessEdit.Close.ConfirmDialog.MessageDetail:Provedené změny nebudou uloženy.`,
          };
          const dialogRef = this.dialog.open(
            ConfirmDialogComponent,
            dialogConfig
          );

          return dialogRef.afterClosed.pipe(
            map((data) => {
              this.session.processingSet(false);
              return !!data;
            })
          );
        } else {
          this.session.processingSet(false);
          return of(true);
        }
      })
    );
  }

  /**
   * Uložení processcore a případné provedení přechodu.
   * @param buttonId - určuje potřebu reloadu core procesu - děje se jen v případě, že jsem na záložkách s
   * parametry nebo s přechody
   */
  saveProcess(buttonId?: number): boolean {
    if (this.form.valid) {
      this.getFormData()?.subscribe((formData) => {
        if (buttonId) {
          formData.attributes.buttonId = buttonId;
        }
        console.log('XXXXXXXXXXXXXXXXXXXXXX formData: ', formData);

        if (this.checkFormChange()) {
          this.session.processingSet(
            true,
            $localize`:@@ProcessEdit.processEdit.message.processing:Ukládám`
          );
          if (this.data.id > 0) {
            // Uložení procesu
            formData.attributes.buttonOnly = false; // Dám endpointu vědět že kromě zpracování tlačítka musí uložit proces
            this.dataServiceAct.put(String(this.data.id), formData).subscribe(
              () => {
                this.processChanged = true;
                this.saveAttachments();
                this.session.processingSet(false);
                this.session.message(
                  $localize`:@@ProcessEdit.processEdit.message.ok:Požadavek uložen`
                );
                this.processRedirectService.returnFromProcess();
              },
              (err) => {
                // Chybovou hlášku zobrazí error interceptor, není potřeba se tady o to starat
                this.session.processingSet(false);
                console.error('Tak to tak úplně nevyšlo.', err);
              }
            );
          } else {
            // Založení procesu
            this.dataServiceAct.post(formData).subscribe(
              (newProcess) => {
                this.processId = newProcess.id; // Tohle je zásadní udělat před pokusem uložit přílohy :)
                this.processChanged = true;
                this.saveAttachments();
                this.session.processingSet(false);
                this.session.message(
                  $localize`:@@ProcessEdit.processCreate.message:Požadavek uložen`
                );
                this.processRedirectService.returnFromProcess();
              },
              (err) => {
                // Chybovou hlášku zobrazí error interceptor, není potřeba se tady o to starat
                this.session.processingSet(false);
                console.error('Tak to tak úplně nevyšlo.', err);
              }
            );
          }
        } else {
          if (buttonId) {
            formData.attributes.buttonOnly = true; // Dám endpointu vědět, že nepotřebuji ukládat proces
            this.session.processingSet(
              true,
              $localize`:@@ProcessEdit.processEdit.button.message.processing:Ukládám`
            );
            if (this.data.id > 0) {
              // Uložení procesu
              this.dataServiceAct.put(String(this.data.id), formData).subscribe(
                () => {
                  this.processChanged = true;
                  this.saveAttachments();
                  this.session.processingSet(false);
                  this.session.message(
                    $localize`:@@ProcessEdit.processEdit.button.message.ok:Požadavek uložen`
                  );
                  this.reloadProces();
                },
                (err) => {
                  // Chybovou hlášku zobrazí error interceptor, není potřeba se tady o to starat
                  this.session.processingSet(false);
                  console.error('Tak to tak úplně nevyšlo.', err);
                }
              );
            }
          }
          console.log('XXXXXXXXXXXXXXXXXXXXXX žádné neuložené změny');
        }

        // Můžu odejít z modulu
      });
      return true;
    } else {
      console.log('XXXXXXXXXXXXXXXXXXXXXX formulář není validní');
      this.session.message(
        $localize`:@@ProcessEdit.processSave.validate.message:Některé položky nejsou vyplněné nebo nemají správnou hodnotu.`
      );
      this.formHelper.markDirty(this.form);
      // Nemůžu odejít  modulu
      return false;
    }
  }

  /**
   * Metoda posbírá data z formuláře a rozdělí je na atributy a parametry
   */
  getFormData(): Observable<{ attributes: any; params: any }> | undefined {
    return this.paramsService
      ?.getall({
        id: this.data.id,
        defId: this.data.typeId,
      })
      .pipe(
        map((paramDefs) => {
          const attributes: any = {};
          const params: any = {};

          Object.keys(
            (this.form.controls['processCoreForm'] as FormGroup).controls
          ).forEach((key) => {
            const paramControl = (
              this.form.controls['processCoreForm'] as FormGroup
            ).get(key) as MangoParamFormControl;
            if (paramControl.param?.attribute) {
              attributes[key] = paramControl.value;
            } else {
              if (paramControl.param?.isMultiSelectParam(true)) {
                if (paramControl.value) {
                  let i = 0;

                  paramControl.value.forEach((value: any) => {
                    params[paramControl.param?.name + '[' + i + ']'] = value;
                    i++;
                  });
                } else {
                  params[paramControl.param.name + '[0]'] = '';
                }
              }
              params[key] = paramControl.value;
            }
          });

          attributes.transition = this.form.get('transition')?.value;
          // Je požadavek na to, aby pokud je jen jeden přechod, byl vždy zaškrtlý.
          // Protože přechody se načítají asynchronně na základě stavu formulář
          // (nejsem tedy jednudše schopný identifikovat okamžik, kdy přesně mám vyhodnotit jestli je jen jeden)
          // vyhodnocuji to nakonec pouze v šabloně, pomocí atributu checked. Ten bohužel neaktualizuej form kontrol
          // takže ten i se zaškrtlým buttonem nemá hodnotu. Tak ji sem nacpu natvrdo...
          if (this.visibleTransitions?.length === 1) {
            attributes.transition = this.visibleTransitions[0].id;
          }
          attributes.type = this.type;

          return {
            attributes,
            params,
          };
        })
      );
  }

  /**
   * Metoda pro ověření, že se ve formuláři změnili hodnoty (vyhodnocuje potřebu ukládání).
   * Kontrola je spíš jednodušší. Vzhledem k tomu jak jsou procesy složité a kolik mají závislostí,
   * není úplně jednoduché vyhodnotit co se změnilo (odlišit nějakou závislost při vykreslování od
   * ručně zadané změny. Chytám se tedy pouze editace formuláře, snad to pokryje většinu případů.
   * Ověření nepokrývá přiložené přílohy (pokud ukládám proces, zajímá mě jen formulář -
   * je to kvůli tlačítkům, tam potřebuju vědět jestli se má spouštět maintenance list edit).
   */
  checkFormChange(): boolean {
    // Pokud není proces editovatelný, tak nebudu nic kontrolovat
    if (this.notEditable) {
      return false;
    }
    return this.form.dirty;
  }

  /**
   * Kontrola na neuložené přílohy. Používám ji jen když chci zavřít proces, abych neopustil
   * neuložené věci.
   */
  private checkAttachmentsChange(): Observable<boolean> {
    return this.attachmentService.files.pipe(
      first(),
      map((files: any) => {
        return files.length > 0;
      })
    );
  }

  /**
   * Vynuluje výběr přechodu
   */
  clearTransition(event: Event): void {
    event.stopPropagation();
    this.form.controls['transition'].reset();
  }

  /**
   * Znovu načte veškerá data procesu
   */
  reloadProces(): void {
    this.fetchProcessData();
    this.paramForm?.fetchParams();
    this.form.controls['transition'].reset();
  }

  leaveProcess(): void {
    this.checkClose().subscribe((canClose) => {
      if (canClose) {
        this.processRedirectService.returnFromProcess();
      }
    });
  }

  addFiles(files: FileList): void {
    const fils = Array.from(files);
    this.attachmentService.addFiles(fils);
  }

  removeFile(file: UploadProgress): void {
    this.attachmentService.removeFile(file);
  }

  removeFiles(): void {
    this.attachmentService.files.subscribe((attachments) => {
      attachments.forEach((file) => {
        this.attachmentService.removeFile(file);
      });
    });
  }

  saveAttachments(): void {
    const uploadFinished = this.attachmentService.uploadFinished();
    if (uploadFinished) {
      this.attachmentService.files.pipe(first()).subscribe((attachments) => {
        const files = [];
        for (const file of attachments) {
          files.push(file.uploadFileName);
        }
        this.attachmentService
          .put(this.processId.toString(), { id: this.processId, files })
          .subscribe(
            (next) => {
              this.removeFiles();
              this.fetchAttachments();
            },
            (error) => {
              console.log('Něco se nepovedlo při uploadu souborů: ', error);
            }
          );
      });
    }
  }

  public updateTransitions(): void {
    this.visibleTransitions = [];
    this.transitions?.forEach((transition) => {
      if (this.form.get('processCoreForm')) {
        // Pokud ještě není zkonstruovaný process core form, tak přechody nepředávám
        this.dependencyService
          .resolveDependency(
            transition.visible,
            this.data.id,
            this.form.getRawValue()
          )
          .subscribe((next) => {
            if (next) {
              if (
                !this.visibleTransitions?.some((tr) => tr.id === transition.id)
              ) {
                this.visibleTransitions?.push(transition);
                setTimeout(() => this.updateGuide());
              }
            }
          });
      }
    });
  }

  public updateButtons(): void {
    this.visibleButtons = [];
    this.buttons?.forEach((button) => {
      if (this.form.get('processCoreForm')) {
        // Pokud ještě není zkonstruovaný process core form, tak přechody nepředávám
        forkJoin([
          this.dependencyService.resolveDependency(
            button.visible,
            this.data.id,
            this.form.getRawValue()
          ),
          this.dependencyService.resolveDependency(
            button.editable,
            this.data.id,
            this.form.getRawValue()
          ),
        ]).subscribe((result) => {
          if (this.visibleButtons)
            if (result[0] || result[1]) {
              if (!this.visibleButtons.includes(button)) {
                button.disabled = !result[1];
                this.visibleButtons.push(button);
                this.visibleButtons.sort((a, b) => a.order - b.order);
              }
            }
        });
      }
    });
  }

  displayGuide(): void {
    const dialogConfig = new DialogConfig();
    dialogConfig.data = {
      guide: this.guide,
    };
    const dialogRef = this.dialog.open(
      ProcessGuideDialogComponent,
      dialogConfig
    );
  }

  paramFormFinished(): void {
    this.updateTransitions();
    this.updateButtons();
    this.session.processingSet(false);
    setTimeout(() => this.dependencyService.setFormChanged(false));
  }

  updateGuide(): void {
    let guideText = '';
    if (this.form.get('transition')?.value) {
      const transition = this.transitions?.find(
        (t) => t.id === this.form.get('transition')?.value
      );
      if (transition) {
        guideText = transition.description;
      }
    }

    // problém s identifikací toho, jestli mám jen jeden přechod (viz komentář v getFormData)
    // musím zde ošetřit explicitně, protože se nepropisuje do formu
    if (this.visibleTransitions?.length === 1) {
      guideText = this.visibleTransitions[0].description;
    }

    if (guideText === '') {
      guideText = this.data.stateDescription;
    }
    if (guideText) {
      this.guide = this.sanitizer.bypassSecurityTrustHtml(guideText);
    } else {
      this.guide = null;
    }
  }

  fetchProcessNotes(): void {
    this.historyService.fetchProcessNotes(this.processId).subscribe((next) => {
      this.processNotes = next.data
        .sort((a, b) => (a.time < b.time ? 1 : -1))
        .map((note) => {
          note.userName =
            note.mangoUserId == this.session.user?.backendLoginId
              ? this.session.user?.fullname
              : $localize`:@@ProcessList.notes.mangoUser:Operátor`;
          return note;
        });
    });
  }

  addComment(): void {
    const dialogConfig = new DialogConfig();
    dialogConfig.data = {};
    const dialogRef = this.dialog.open(
      ProcessMessageDialogComponent,
      dialogConfig
    );

    dialogRef.afterClosed.subscribe((result) => {
      if (result) {
        console.log(
          'XXXXXXXXXXXXXXXXXXXXXX data z editačního formuláře ',
          result
        );
        this.session.processingSet(true);
        this.sendNote(result.note);
      }
    });
  }

  sendNote(noteText: string): void {
    this.historyService.sendNote(this.processId, noteText).subscribe(
      (next) => {
        console.log('Zpráva odeslána');
        this.session.processingSet(false);
        this.fetchProcessNotes();
        // toto dělám na pozadí, nepotřebuju to nijak hlásit
      },
      (err) => {
        console.log('Chyba při ukládání zprávy ' + err);
      }
    );
  }

  settingVisible(): boolean {
    const opt = this.session.getOption('SELFCARE.process-list.note.button');
    return !(opt && opt.includes('hidden'));
  }
}
