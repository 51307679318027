import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ScConfiguration, SC_CONFIGURATION } from '@isp-sc/shared/common';

@Injectable({
  providedIn: 'root',
})
/**
 * Třída slouží pro obsluhu metod spojených se změnou hesla.
 */
export class PasswordService {
  constructor(
    private http: HttpClient,
    @Inject(SC_CONFIGURATION) private scConfiguration: ScConfiguration
  ) {}

  public resetPassword(params: any): Observable<any> {
    const data = { ...params, ...{ ct: this.scConfiguration.ct } };
    return this.http.post(
      `${this.scConfiguration.baseUrl}/reset-password`,
      data
    );
  }

  public checkEmail(params: any): Observable<any> {
    const queryParams = { ...params, ...{ ct: this.scConfiguration.ct } };

    return this.http.get(`${this.scConfiguration.baseUrl}/check-unique-email`, {
      params: queryParams,
    });
  }

  public changePassword(params: any): Observable<any> {
    const data = { ...params, ...{ ct: this.scConfiguration.ct } };
    return this.http.post(
      `${this.scConfiguration.baseUrl}/change-password`,
      data
    );
  }

  public checkCodeValidity(params: any): Observable<any> {
    const queryParams = { ...params, ...{ ct: this.scConfiguration.ct } };
    return this.http.get(
      `${this.scConfiguration.baseUrl}/check-code-validity`,
      { params: queryParams }
    );
  }
}
