import { FormGroup, ValidationErrors } from '@angular/forms';

export const phoneCommunicationValidator: (
  control: FormGroup
) => ValidationErrors | null = (
  control: FormGroup
): ValidationErrors | null => {
  const infoSms = control.get('infoSms');
  const phones = control.get('phones');
  return infoSms?.value && !phones?.value ? { phoneCommunication: true } : null;
};
