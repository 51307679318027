<div class="external-page-layout">
  <div
    class="login-container"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutGap="20px"
    fxLayoutAlign="start stretch"
  >
    <mat-card appearance="outlined" class="login-form-card" fxFlex>
      <mat-card-header>
        <mat-card-title
          ><span i18n="@@ScLogin.login.title">Přihlášení</span></mat-card-title
        >
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="loginForm" (submit)="onSubmit()">
          <p>
            <mat-form-field
              class="mango-form-field"
              appearance="fill"
              subscriptSizing="dynamic"
            >
              <mat-label i18n="@@ScLogin.login.placeholder"
                >Přihlašovací jméno</mat-label
              >
              <input
                matInput
                name="login"
                formControlName="login"
                type="text"
                i18n-placeholder="@@ScLogin.login.placeholder"
                placeholder="Přihlašovací jméno"
              />
            </mat-form-field>
          </p>
          <p>
            <mat-form-field class="mango-form-field" subscriptSizing="dynamic">
              <mat-label i18n="@@ScLogin.password.placeholder">Heslo</mat-label>
              <input
                matInput
                name="password"
                formControlName="password"
                i18n-placeholder="@@ScLogin.password.placeholder"
                placeholder="Heslo"
                [type]="hidePassword ? 'password' : 'text'"
              />
              <!-- Odkaz je tu místo tlačítka kvůli -->
              <a
                href="javascript:void(0)"
                mat-icon-button
                matSuffix
                (click)="hidePassword = !hidePassword"
                i18n-title="@@ScLogin.hidePassword.label"
                [attr.aria-label]="'Skrýt heslo'"
                [attr.aria-pressed]="hidePassword"
              >
                <mat-icon>{{
                  hidePassword ? 'visibility_off' : 'visibility'
                }}</mat-icon>
              </a>
            </mat-form-field>
          </p>
          <button mat-flat-button color="primary" type="submit">
            <span i18n="@@ScLogin.signIn.button">Přihlásit</span>
          </button>
        </form>
        <p class="ref-container">
          <a [routerLink]="['/password']" [queryParams]="{ action: 'reset' }"
            ><span i18n="@@ScLogin.forgotPassword.text">Neznáte heslo?</span></a
          >
          <span class="fill-horiz-space"></span>
          <a [routerLink]="['/registration']" *ngIf="displayRegistration"
            ><span i18n="@@ScLogin.registration.text"
              >Chci se registrovat</span
            ></a
          >
        </p>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<span class="app-version"> {{ appVersion }} </span>
