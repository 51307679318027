import { Injectable } from '@angular/core';
import { FileService as FileServiceOG } from '@og_soft/file';

@Injectable({
  providedIn: 'root',
})
export abstract class FileService extends FileServiceOG {
  public mimeCheck(type: string | null): boolean {
    return Array.prototype.reduce.call(
      navigator.plugins,
      function (supported, plugin) {
        return (
          supported ||
          Array.prototype.reduce.call(
            plugin,
            function (supported, mime) {
              return supported || mime.type == type;
            },
            supported
          )
        );
      },
      false
    ) as boolean;
  }
}
