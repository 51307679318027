import { DataBase } from '@og_soft/data-base';
import { Injectable } from '@angular/core';
import { RecUnicredit } from '@isp-sc/shared/common';

@Injectable({
  providedIn: 'root',
})
export class DataUnicreditService extends DataBase<RecUnicredit> {
  protected getAllUrl(): string {
    return 'eu-unicredit' as const;
  }
}
