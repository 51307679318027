<sc-main-toolbar> </sc-main-toolbar>

<div class="mango-table-page-contents">
  <h1 i18n="@@UserServices.title">Mé služby</h1>

  <sc-page-caption-block></sc-page-caption-block>

  <mgt-header [filterValues]="filterValues" [tab]="tab!">
    <mgt-filters [formGroup]="filters" (applyFilters)="filterValues = $event">
      <app-popup-list
        placeholder="Zařízení"
        i18n-placeholder="@@UserServices.nodeId.placeholder"
        title="Zařízení"
        i18n-title="@@UserServices.nodeId.title"
        formControlName="nodeId"
        [formGroup]="filters"
        [dataSource]="dataNodes"
        [filterDb]="false"
        [defaultFilters]="{ _nodesInfo: true }"
        [appearance]="'fill'"
        [fixOptionsToInputWidth]="true"
      >
      </app-popup-list>
    </mgt-filters>
  </mgt-header>

  <mgt-table #tbl [service]="dataService" [filters]="filterValues">
    <mgt-row
      [replacing-detail]="false"
      *ngFor="let r of tbl.scrollItems; let i = index"
      [record]="r"
    >
      <mgt-ico>
        <mat-icon *ngFor="let icon of scFlagsToIcons(r.scFlags)">{{
          icon
        }}</mat-icon>
      </mgt-ico>

      <mgt-cell-group master>
        <mgt-cell role="title">
          <span>{{ r.saName }}</span>
          <mat-icon *ngIf="r.snType === 'M'" class="srv-state">{{
            saStateToIcon(r.saStateId, true)
          }}</mat-icon>
        </mgt-cell>
        <mgt-cell *ngIf="saIdentGet(r, false)" title="">
          <span class="mat-caption">{{ saIdentGet(r) }}</span>
        </mgt-cell>
        <mgt-cell *ngIf="r.units && r.units.length > 0" title="">
          <span *ngFor="let unit of r.units" class="mat-caption">{{
            [unit.address, unit.socket] | filter | join : ' - '
          }}</span>
        </mgt-cell>
      </mgt-cell-group>

      <mgt-cell-group>
        <mgt-cell
          title=""
          role="result"
          [ngClass]="{
            'cell-monetary': true,
            'mango-text-warn': r.ttdDebt > 0,
            'mango-text-success': r.ttdDebt < 0,
            'mango-text-info': r.ttdDebt === 0
          }"
        >
          <span *ngIf="r.ttdDebt > 0"
            ><span i18n="@@UserServices.ttdDebt.text">Dluh</span>
            {{ r.ttdDebt | currency : getCurrencySymbol() }}</span
          >
        </mgt-cell>
      </mgt-cell-group>

      <div detail>
        <div class="detail-row">
          <div class="detail-field">
            <div class="value">
              <!--
              <div class="detail-field" *ngIf="r.snType == 'M'">
                <mat-icon>{{saStateToIcon(r.saStateId, true)}}</mat-icon>
                {{r.saEuStateName}}
              </div>
              -->
              <ng-container *ngIf="r.snType === 'M'">
                <div class="detail-field">
                  <span
                    class="value mango-text-secondary"
                    i18n="@@UserServices.saStateInfo.service.text"
                    >Služba je</span
                  >
                  <span class="mango-text-normal"> {{ r.saEuStateName }}</span>
                  <span class="value mango-text-secondary">
                    <ng-container
                      *ngIf="
                        r.saDateStart !== r.saAccountFrom &&
                        r.saDateEnd === null
                      "
                      i18n="@@UserServices.saStateInfo1.service.text"
                      >, poskytovaná od
                      {{ r.saDateStart | date : 'd. M. yyyy' }} a účtovaná od
                      {{ r.saAccountFrom | date : 'd. M. yyyy' }}</ng-container
                    >

                    <ng-container
                      *ngIf="
                        r.saDateStart !== r.saAccountFrom &&
                        r.saDateEnd !== null
                      "
                      i18n="@@UserServices.saStateInfo2.text"
                      >, poskytovaná od
                      {{ r.saDateStart | date : 'd. M. yyyy' }} do
                      {{ r.saDateEnd | date : 'd. M. yyyy' }} a účtovaná od
                      {{ r.saAccountFrom | date : 'd. M. yyyy' }} do
                      {{ r.saAccountTo | date : 'd. M. yyyy' }}</ng-container
                    >

                    <ng-container
                      *ngIf="
                        r.saDateStart === r.saAccountFrom &&
                        r.saDateEnd === null
                      "
                      i18n="@@UserServices.saStateInfo3.text"
                      >, poskytovaná a účtovaná od
                      {{ r.saDateStart | date : 'd. M. yyyy' }}</ng-container
                    >

                    <ng-container
                      *ngIf="
                        r.saDateStart === r.saAccountFrom &&
                        r.saDateEnd !== null &&
                        r.saDateEnd === r.saAccountTo
                      "
                      i18n="@@UserServices.saStateInfo4.text"
                      >, poskytovaná a účtovaná od
                      {{ r.saDateStart | date : 'd. M. yyyy' }} do
                      {{ r.saDateEnd | date : 'd. M. yyyy' }}</ng-container
                    >

                    <ng-container
                      *ngIf="
                        r.saDateStart === r.saAccountFrom &&
                        r.saDateEnd !== null &&
                        r.saDateEnd !== r.saAccountTo
                      "
                      i18n="@@UserServices.saStateInfo5.text"
                      >, poskytovaná od
                      {{ r.saDateStart | date : 'd. M. yyyy' }} do
                      {{ r.saDateEnd | date : 'd. M. yyyy' }} a účtovaná od
                      {{ r.saDateStart | date : 'd. M. yyyy' }} do
                      {{ r.saAccountTo | date : 'd. M. yyyy' }}</ng-container
                    >.
                  </span>
                </div>
              </ng-container>
              <div class="detail-field" *ngIf="r.snType === 'S'">
                <div class="value mango-text-secondary">
                  <span i18n="@@UserServices.saStateInfo61.text"
                    >Poskytnuta dne </span
                  ><span class="mango-text-normal"
                    >{{ r.saDateStart | date : 'd. M. yyyy' }}.</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="detail-row">
          <div class="detail-field major" *ngIf="r.saPackagePrice">
            <div
              class="mat-caption"
              *ngIf="r.snVatBottomUp !== 1 && r.dep.length === 0"
              i18n="@@UserServices.Price.snVatBottomUp1.caption"
            >
              Cena služby včetně DPH
            </div>
            <div
              class="mat-caption"
              *ngIf="r.snVatBottomUp === 1 && r.dep.length === 0"
              i18n="@@UserServices.Price.snVatBottomUp2.caption"
            >
              Cena služby bez DPH
            </div>
            <div
              class="mat-caption"
              *ngIf="r.snVatBottomUp !== 1 && r.dep.length > 0"
              i18n="@@UserServices.Price.snVatBottomUp3.caption"
            >
              Cena celého balíčku včetně DPH
            </div>
            <div
              class="mat-caption"
              *ngIf="r.snVatBottomUp === 1 && r.dep.length > 0"
              i18n="@@UserServices.Price.snVatBottomUp4.caption"
            >
              Cena celého balíčku bez DPH
            </div>
            <div class="value cell-monetary">
              {{ r.saPackagePrice | currency : getCurrencySymbol() }}
            </div>
            <div
              class="mat-caption"
              *ngIf="r.reverseCharge === 1"
              i18n="@@UserServices.Price.ReverseCharge.caption"
            >
              U služby je uplatněn přenos daňové povinnosti podle §92a zákona o
              dph.
            </div>
            <!--   <div class="mat-caption" *ngIf="r.saPackagePrice != 0" i18n="@@UserServices.Price.DiscountsNote.caption">Případné slevy nejsou zohledněny, ceny za jednotlivá období najdete ve vyúčtování.</div>>-->
          </div>
        </div>

        <div class="detail-row">
          <div class="detail-field" *ngIf="r.bpEuName && bpEuNameVisible()">
            <div class="mat-caption" i18n="@@UserServices.TypeBilling.caption">
              Typ účtování
            </div>
            <div class="value">{{ r.bpEuName }}</div>
          </div>
          <div class="detail-field" *ngIf="r.saTradingLimit">
            <div class="mat-caption" i18n="@@UserServices.TradingLimit.caption">
              Smluvní závazek
            </div>
            <div class="value">
              {{ r.saTradingLimit | date : 'd. M. yyyy' }}
            </div>
          </div>
          <div class="detail-field" *ngIf="r.saPackagePrice && period(r)">
            <div class="mat-caption" i18n="@@UserServices.Period.caption">
              Perioda
            </div>
            <div class="value">{{ r.saPeriodName }}</div>
          </div>
          <!--<div class="detail-field" *ngIf="r.expire">-->
          <!--<div class="mat-caption">Expirace</div>-->
          <!--<div class="value">{{r.expire | date: 'd. M. yyyy'}}</div>-->
          <!--</div>-->
        </div>

        <!-- Tady budou subdetailní tabulky - rozpis balíčků, rozpis předpisů a pod.
             Pokud je místo, budou vedle sebe. -->
        <div class="detail-tables-container-1 detail-row">
          <!-- Tabulka "Bázová služba a závislé služby" (nadepsaná tak není, je to pracovní název) -->
          <div class="base-and-dependent-services" *ngIf="r.dep.length > 0">
            <div
              class="mat-caption"
              *ngIf="r.dep.length > 0"
              i18n="@@UserServices.UserService.caption"
            >
              Služba
            </div>

            <mgt-row *ngIf="r.dep.length > 0" [expandable]="false">
              <!--class="single-service"-->

              <mgt-ico
                ><mat-icon>{{
                  saStateToIcon(r.saStateId, true)
                }}</mat-icon></mgt-ico
              >
              <mgt-cell-group master>
                <mgt-cell role="title">{{ r.saName }}</mgt-cell>
                <mgt-cell mobile-only
                  >{{ r.saDateStart | date : 'd. M. yyyy - '
                  }}{{ r.saDateEnd | date : 'd. M. yyyy' }}</mgt-cell
                >
              </mgt-cell-group>
              <mgt-cell-group desktop-only>
                <mgt-cell
                  ><span i18n="@@UserServices.saDateStart.text"></span
                  >{{ r.saDateStart | date : 'dd. MM. yyyy - '
                  }}{{ r.saDateEnd | date : 'dd. MM. yyyy' }}</mgt-cell
                >
              </mgt-cell-group>
              <mgt-cell-group>
                <mgt-cell role="result" class="cell-monetary">{{
                  r.saPrice | currency : getCurrencySymbol()
                }}</mgt-cell>
              </mgt-cell-group>
            </mgt-row>

            <!--<br *ngIf="r.dep && r.dep.length > 0">-->
            <div
              class="mat-caption"
              *ngIf="r.dep.length > 0"
              i18n="@@UserServices.depServices.caption"
            >
              Doplňkové služby
            </div>

            <mgt-row
              *ngFor="let dep of r.dep"
              [expandable]="false"
              [record]="dep"
            >
              <mgt-ico
                ><mat-icon>{{
                  saStateToIcon(dep.state, true)
                }}</mat-icon></mgt-ico
              >
              <mgt-cell-group master>
                <mgt-cell role="title">{{ dep.name }}</mgt-cell>
                <mgt-cell mobile-only
                  >{{ dep.dateStart | date : 'd. M. yyyy - '
                  }}{{ dep.dateEnd | date : 'd. M. yyyy' }}</mgt-cell
                >
              </mgt-cell-group>
              <mgt-cell-group desktop-only>
                <mgt-cell
                  ><span i18n="@@UserServices.depDateStart.text"></span>
                  {{ dep.dateStart | date : 'dd. MM. yyyy - '
                  }}{{ dep.dateEnd | date : 'dd. MM. yyyy' }}</mgt-cell
                >
              </mgt-cell-group>
              <mgt-cell-group>
                <mgt-cell role="result" class="cell-monetary">{{
                  dep.price | currency : getCurrencySymbol()
                }}</mgt-cell>
              </mgt-cell-group>
            </mgt-row>
          </div>
          <!-- Konec "Hlavní služba a závislé služby" -->

          <!-- Předpisy -->
          <div
            class="prescriptions"
            *ngIf="
              r.prescription &&
              r.prescription.length > 0 &&
              prescriptionVisible()
            "
          >
            <div class="mat-caption" i18n="@@UserServices.prescription.caption">
              Očekávané budoucí platby (vč. DPH)
            </div>
            <!-- <p i18n="@@UserServices.prescription.text" class="mango-text-secondary"> uvedené ceny jsou včetně dph</p> -->
            <mgt-row
              *ngFor="let prescription of r.prescription"
              [expandable]="false"
              [record]="prescription"
            >
              <mgt-cell-group master>
                <mgt-cell
                  >{{ prescription.periodStart | date : 'dd. MM. yyyy'
                  }}{{
                    prescription.periodEnd | date : ' - dd. MM. yyyy'
                  }}</mgt-cell
                >
                <mgt-cell class="prescription-note mango-text-secondary">{{
                  prescription.note
                }}</mgt-cell>
              </mgt-cell-group>
              <mgt-cell-group>
                <mgt-cell role="result" class="cell-monetary">{{
                  prescription.amount | currency : getCurrencySymbol()
                }}</mgt-cell>
              </mgt-cell-group>
            </mgt-row>
          </div>
          <!-- Konec class="prescriptions" -->
        </div>
        <!-- Konec detail-tables-container-1 -->

        <div class="detail-row" *ngIf="r.paramsRowVisible">
          <div class="detail-field">
            <div class="mat-caption" i18n="@@UserServices.Params.caption">
              Parametry služby
            </div>
            <table class="value params">
              <ng-container *ngFor="let param of r.params">
                <tr *ngIf="param.visibleInTable">
                  <td class="label">{{ param.title }}</td>
                  <td class="value">{{ param.hrValue }} {{ param.units }}</td>
                </tr>
              </ng-container>
            </table>
          </div>
        </div>

        <ng-container *ngIf="getGsmFreeUnitsVisible(r.saId)">
          <div class="detail-row">
            <div class="detail-field">
              <div class="mat-caption" i18n="@@UserServices.FreeCalls.caption">
                Volné minuty
              </div>
              <div class="value">
                {{ getGsmFreeMinutesAvailable(r.saId) }} /
                {{ getGsmFreeMinutesTotal(r.saId) }}
              </div>
            </div>
          </div>
          <div class="detail-row">
            <div class="detail-field">
              <div class="mat-caption" i18n="@@UserServices.FreeSms.caption">
                Volné SMS
              </div>
              <div class="value">
                {{ getGsmFreeSmsAvailable(r.saId) }} /
                {{ getGsmFreeSmsTotal(r.saId) }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <mgt-actions
        [scrollable]="
          session.isMobile &&
          (saIsTelephony(r.scFlags) ? 1 : 0) + (saHasNodes(r) ? 1 : 0) + 2 > 3
        "
      >
        <button
          *ngIf="saIsTelephony(r.scFlags)"
          mat-button
          [routerLink]="['/telephony', r.saId]"
        >
          <mat-icon>call</mat-icon>
          <span i18n="@@UserServices.call.button">Výpis hovorů</span>
        </button>
        <button
          *ngIf="saHasNodes(r) && session.canView('nodes')"
          mat-button
          [routerLink]="['/nodes', r.saId]"
        >
          <mat-icon>devices</mat-icon>
          <span i18n="@@UserServices.device.button">Zařízení</span>
        </button>
        <button mat-button [routerLink]="['/billing', r.saId]">
          <mat-icon>description</mat-icon>
          <span i18n="@@UserServices.description.button">Vyúčtování</span>
        </button>
        <button *ngIf="saHasParams(r)" mat-button (click)="saEdit(r.saId, r)">
          <mat-icon>settings</mat-icon
          ><span i18n="@@UserServices.settings.button">Nastavení</span>
        </button>
        <button
          mat-button
          *ngIf="
            r.ttdDebt > 0 &&
            this.session.getOption('SELFCARE.user-services.creditCard.button')
          "
          (click)="payment(r)"
          target="_blank"
        >
          <mat-icon>credit_card</mat-icon>
          <span i18n="@@UserServices.creditCard.button">Zaplatit</span>
        </button>
      </mgt-actions>
    </mgt-row>

    <div no-data-alternate-content>
      <page-empty
        i18n-message="@@UserServices.PageEmpty.message"
        message="Není co zobrazit."
      ></page-empty>
    </div>

    <!-- Implicitní text "Víc už toho není" na konci je myslím u služeb nesmysl -->
    <div plug></div>
  </mgt-table>
</div>
