import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UserEditFormService } from '@isp-sc/shared/segments/user/data-access';
import { FormHelperService } from '@isp-sc/shared/data-access';
import { DependencyUserService } from '@isp-sc/shared/segments/dependency/data-access';
import { ProtectedDataCategoryConsentAddService } from '@isp-sc/shared/segments/protected-data/data-access';
import { DataParamsUserService } from '@isp-sc/shared/segments/params/data-access';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { UserEditFormType } from '@isp-sc/shared/common';

@Component({
  selector: 'app-user-edit-params',
  templateUrl: './user-edit-params.component.html',
  styleUrls: ['./user-edit-params.component.scss'],
})
export class UserEditParamsComponent {
  public form: FormGroup;

  constructor(
    public formHelper: FormHelperService,
    public userParamsDependencyService: DependencyUserService,
    public protectedDataCategoryConsentAdd: ProtectedDataCategoryConsentAddService,
    private userEditFormService: UserEditFormService,
    public userParamsService: DataParamsUserService,
    public session: SessionService
  ) {
    this.form = this.userEditFormService.getForm(UserEditFormType.params);
  }
}
