export enum ParamControlType {
  check,
  string,
  textBox,
  number,
  date,
  dateTime,
  popupList,
  multiSelect,
  time,
}
