import { Injectable } from '@angular/core';
import { DataParamsService } from './data-params.service';
import { DataValueDomainPatternService } from './data-value-domain-pattern.service';
import { DataCodeListService } from './data-code-list.service';
import { DataValueDomainService } from './data-value-domain.service';
import {
  ProcessServicesService,
  ProcessUnitsService,
} from '@isp-sc/shared/segments/process/data-access';
import {
  Options,
  ParamObjectType,
} from '@isp-sc/shared/segments/params/common';
import { ProcessPremisesService } from '@isp-sc/shared/segments/process/data-access';
import { ParamBase } from './param-base';

@Injectable({
  providedIn: 'root',
})
export class DataParamsServiceActiveService extends DataParamsService {
  protected getAllUrl(): string {
    return 'service-active-params' as const;
  }

  public transformParams(
    paramData: any,
    services: {
      dataValuePatterns: DataValueDomainPatternService;
      dataCodeList: DataCodeListService;
      dataValueDomain: DataValueDomainService;
      dataProcessPremises: ProcessPremisesService;
      dataProcessServices: ProcessServicesService;
      dataProcessUnits: ProcessUnitsService;
    }
  ) {
    const options: Options = {
      id: paramData.id,
      defId: paramData.defId,
      value: paramData.value,
      defaultValue: paramData.defaultValue,
      objectType: ParamObjectType.Service,
      name: paramData.name + (paramData.indexed ? '_' + paramData.index : ''),
      caption: paramData.caption,
      description: paramData.description,
      order: paramData.order,
      type: paramData.type,
      disabled: paramData.disabled,
      indexed: paramData.indexed,
      index: paramData.index,
      domain: paramData.domain,
      hrValue: paramData.hrValue,
      visibilityDependency: paramData.visibilityDependency,
      editDependency: paramData.editDependency,
      requireDependency: paramData.requireDependency,
      unique: paramData.unique,
      visible: paramData.visible,
      editable: paramData.editable,
      group: paramData.group,
      attribute: paramData.attribute,
      objId: paramData.objId,
    };

    return new ParamBase(options, services);
  }
}
