import { Injectable } from '@angular/core';
import { DataBase } from '@og_soft/data-base';

@Injectable({
  providedIn: 'root',
})
export class DataSaFreeUnitsService extends DataBase<{}> {
  protected getAllUrl(): string {
    return 'telephony-free-units' as const;
  }
}
