import { Component } from '@angular/core';
import { DialogConfig } from '@og_soft/dialog';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'sc-process-guide-dialog',
  templateUrl: './process-guide-dialog.component.html',
  styleUrls: ['./process-guide-dialog.component.scss'],
})
export class ProcessGuideDialogComponent {
  guide: SafeHtml;

  constructor(public config: DialogConfig) {
    this.guide = config.data.guide;
  }
}
