import { Injectable } from '@angular/core';
import { DataBase } from '@og_soft/data-base';

@Injectable({
  providedIn: 'root',
})
export class UnconfirmedAgreementsService extends DataBase<any> {
  // getallUrl = "document-agreement-unconfirmed";

  protected getAllUrl(): string {
    return 'document-agreement-unconfirmed' as const;
  }
}
