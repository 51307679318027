import { Injectable } from '@angular/core';
import { DataBase } from '@og_soft/data-base';
import { PopupOption } from '@isp-sc/shared/ui';
import { ValueDomainPattern } from '@isp-sc/shared/segments/params/common';

@Injectable({
  providedIn: 'root',
})
/**
 * Třída vrací hodnoty z tabulky value_domain_patterns
 */
export class DataValueDomainPatternService extends DataBase<ValueDomainPattern> {
  protected getAllUrl(): string {
    return 'domain-pattern' as const;
  }

  override tranformOptions(row: ValueDomainPattern): PopupOption {
    return { id: row.ID, name: row.NAME };
  }
}
