import { Component, Input, OnInit } from '@angular/core';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import {
  UserDataEditService,
  UserEditFormService,
  UserEditProtectedService,
} from '@isp-sc/shared/segments/user/data-access';
import { FormGroup } from '@angular/forms';
import { DialogConfig, DialogService } from '@og_soft/dialog';
import { ProtectedDataLevel3DialogComponent } from '@isp-sc/isp-sc/segments/selfcare/features';
import { ProtectedDataCategory } from '@isp-sc/shared/segments/protected-data/common';
import { UserEditFormType } from '@isp-sc/shared/common';
import { UserType } from '@isp-sc/shared/segments/user/common';
import { ProtectedDataCategoryConsentAddService } from '@isp-sc/shared/segments/protected-data/data-access';

@Component({
  selector: 'app-user-edit-protected',
  templateUrl: './user-edit-protected.component.html',
  styleUrls: ['./user-edit-protected.component.scss'],
})
export class UserEditProtectedComponent implements OnInit {
  @Input() formType?: UserEditFormType | 'all';

  public protectedDataCategories?: ProtectedDataCategory[];
  private editForms?: FormGroup[];
  public userType?: UserType;

  constructor(
    public session: SessionService,
    public protectedDataCategoryConsentAdd: ProtectedDataCategoryConsentAddService,
    private dialogService: DialogService,
    private userEditFormService: UserEditFormService,
    private userEditProtectedService: UserEditProtectedService,
    private userEditService: UserDataEditService
  ) {}

  ngOnInit(): void {
    if (this.formType === 'all') {
      this.editForms = this.userEditFormService.getAllForms();
    } else if (this.formType) {
      this.editForms = [this.userEditFormService.getForm(this.formType)];
    }
    this.userEditProtectedService.resetSavedConsent();
    this.protectedDataInit();

    this.userEditFormService.userTypeChanged().subscribe(() => {
      this.updateUserType();
    });

    this.updateUserType();
  }

  private updateUserType(): void {
    if (
      this.formType === UserEditFormType.personal ||
      this.formType === 'all'
    ) {
      // Pokud edituji osobní údaje nebo jsme  v registrace, aktuální typ mám ve formuláři.
      this.userType = this.userEditFormService
        .getForm(UserEditFormType.personal)
        .get('type')?.value;
    } else {
      // Jinak si ho získám z db
      this.userEditService.getSingleton().subscribe((user) => {
        this.userType = user.type;
      });
    }
  }

  public protectedDataInit(): void {
    if (this.editForms) {
      this.userEditProtectedService
        .getRelevantCategories(this.editForms)
        .subscribe((next) => {
          this.protectedDataCategories = next;
        });
    }
  }

  saveConsent(catId: number): void {
    if (this.formType === 'all') {
      // Registrace zákazníka, souhlas nemůžu ukládat průběžně, protože zákazník ještě není založen,
      // tak si potvrzený souhlas zaznamenám do servisy.
      this.userEditProtectedService.addConsent(catId);
      const changedCategory = this.protectedDataCategories?.filter(
        (category) => category.catId === catId
      );
      if (changedCategory) {
        changedCategory[0].catConsent = true;
      }
    } else {
      this.consentAdd(catId);
    }
  }

  consentAdd(catId: number): void {
    if (catId) {
      this.session.processingSet(
        true,
        $localize`:@@ProtectedData.consentAdd.message.processing:Uděluji souhlas`
      );
      this.protectedDataCategoryConsentAdd
        .post({
          pdcCatId: catId,
        })
        .subscribe(
          () => {
            this.session.processingSet(false);
            this.session.message(
              $localize`:@@ProtectedData.consentAdd.message.ok:Souhlas byl udělen.`
            );
            this.protectedDataInit();
          },
          () => {
            this.session.processingSet(false);
            this.session.message(
              $localize`:@@ProtectedData.consentAdd.message.error:Nepodařilo se udělit souhlas.`
            );
          }
        );
    }
  }

  displayProtectedDataLevel3Info(cat: any): void {
    const dialogConfig = new DialogConfig();
    dialogConfig.data = {
      infoText: cat.catInfoLevel3,
    };
    this.dialogService.open(ProtectedDataLevel3DialogComponent, dialogConfig);
  }

  userTypeValid(userType: UserType[]): boolean {
    // Nejdřív okrajové podmínky - chybí nějaká data: zobrazuji vše
    if (!userType || userType.length < 1 || !this.userType) {
      return true;
    }
    return userType.includes(this.userType);
  }
}
