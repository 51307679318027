<dialog-title>
  <button mat-icon-button dialog-close>
    <mat-icon>arrow_back</mat-icon>
  </button>
  {{ heading }}
</dialog-title>

<dialog-content>
  <div class="message">{{ message }}</div>
  <div class="message-detail">{{ messageDetail }}</div>
</dialog-content>

<dialog-actions class="center">
  <button mat-flat-button [dialog-close]="false">Ne</button>
  <button mat-flat-button color="primary" [dialog-close]="true">Ano</button>
</dialog-actions>
