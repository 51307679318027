import { Injectable } from '@angular/core';
import { DialogConfig, DialogService } from '@og_soft/dialog';
import { first } from 'rxjs/operators';
import {
  CheckDbService,
  SessionService,
} from '@isp-sc/shared/segments/session/data-access';
import { UnavailablePageComponent } from '@isp-sc/shared/segments/session/features';

@Injectable({
  providedIn: 'root',
})
/**
 * Zajišťuje zobrazení dialogu s informacemi o nedostupnosti.
 */
export class UnavailableService {
  constructor(
    public dialog: DialogService,
    private checkDbService: CheckDbService,
    private session: SessionService
  ) {}

  startUnavailable(): void {
    this.session.processingSet(false);
    this.checkDbService
      .isDbReady()
      .pipe(first())
      .subscribe((ready) => {
        if (!ready) {
          const dconf = new DialogConfig();
          dconf.data = {
            dialog: true,
          };
          const dref = this.dialog.open(UnavailablePageComponent, dconf);
        }
      });
  }
}
