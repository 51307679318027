import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appDragDrop]',
})
export class DragDropDirective {
  @Input() highlightColor = '#cbd3d8';
  @Input() dragDropEnabled = true;
  @Output() fileDropped = new EventEmitter<any>();

  @HostBinding('style.background-color') private background?: string; // = '#cbd3d8'
  @HostBinding('style.opacity') private opacity?: string; // = '0';

  //Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.dragDropEnabled) {
      this.opacity = '0.75';
      this.background = this.highlightColor;
    }
  }

  //Dragleave listener
  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.opacity = undefined;
    this.background = undefined;
  }

  //Drop listener
  @HostListener('drop', ['$event'])
  public ondrop(evt: any) {
    evt.preventDefault();
    if (this.dragDropEnabled) {
      const files = evt.dataTransfer.files;
      if (files.length > 0) {
        this.fileDropped.emit(files);
      }
      this.opacity = undefined;
      this.background = undefined;
    }
    evt.stopPropagation();
  }
}
