import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

export const hasRequiredField = (abstractControl: AbstractControl | null): boolean => {
  if (!abstractControl) {
    return false;
  }

  if (abstractControl.validator) {
    const validator = abstractControl.validator({} as AbstractControl);
    if (validator && validator['required']) {
      return true;
    }
  }

  if (
    (abstractControl instanceof FormGroup ||
      abstractControl instanceof FormArray) &&
    abstractControl.controls
  ) {
    const controls = abstractControl.controls as {
      [key: string]: AbstractControl;
    };
    for (const controlName in controls) {
      if (controls[controlName]) {
        if (hasRequiredField(controls[controlName])) {
          return true;
        }
      }
    }
  }

  return false;
};
