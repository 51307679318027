import { DataBaseServiceConfig } from '@og_soft/data-base';
import {
  Options,
  ParamObjectType,
} from '@isp-sc/shared/segments/params/common';
import { DataParamsService } from './data-params.service';
import { ParamServices } from './param-services';
import { ParamBase } from './param-base';

export abstract class DataParamsProcessGeneralService extends DataParamsService {
  override configureUrl(config: DataBaseServiceConfig): void {
    this.baseurl = config.mangoUrl ?? '';
  }

  public transformParams(paramData: any, services: ParamServices): ParamBase {
    let group = paramData.group[0];

    if (!group) {
      group = {};
    }

    if (!group.position) {
      group.position = '';
    }

    switch (group.position) {
      case 'FIRST':
        group.position = 1;
        break;
      case 'ML_USER_ID':
        group.position = 3;
        break;
      case 'ML_P_ID':
        group.position = 5;
        break;
      case 'ML_UC_ID':
        group.position = 7;
        break;
      case 'ML_UNIT_ID':
        group.position = 9;
        break;
      case 'ML_SA_IDS':
        group.position = 11;
        break;
      case 'ML_MTT_ID':
        group.position = 13;
        break;
      case 'ML_ML_ID':
        group.position = 15;
        break;
      case 'ML_NAME':
        group.position = 17;
        break;
      case 'ML_SLA_ID':
        group.position = 19;
        break;
      case 'ML_DESC':
        group.position = 21;
        break;
      default:
        if (isNaN(group.position)) {
          // Nějak to tudy chodí víckrát, takže si ošetřuji případ, kdy už je pozice transformovaná
          group.position = 23; // Pozice nezadaná
        }
    }

    let index = null;
    if (paramData.indexed) {
      if (!paramData?.index) {
        index = 0;
      } else {
        index = paramData.index;
      }
    }

    const options: Options = {
      caption: paramData.caption,
      value: paramData?.value || '',
      id: paramData?.id || 0,
      defId: paramData.defId,
      objId: paramData?.objId || 0,
      objectType: ParamObjectType.Process,
      defaultValue: paramData.defaultValue,
      name: paramData.name,
      order: paramData.order,
      type: paramData.type,
      indexed: paramData.indexed,
      unique: paramData.unique,
      description: paramData.description,
      index,
      domain: paramData.domain,
      hrValue: paramData?.hrValue || '',
      visibilityDependency: paramData.visibilityDependency,
      editDependency: paramData.editDependency,
      requireDependency: paramData.requireDependency,
      editable: Object.prototype.hasOwnProperty.call(paramData, 'editable')
        ? paramData.editable
        : 1,
      group,
      attribute: paramData.attribute,
      visible: paramData.visibility,
      disabled: paramData.disabled,
    };
    return new ParamBase(options, services);
  }
}
