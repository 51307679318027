<ng-container *ngIf="data">
  <div class="mango-form-page-contents">
    <h1>
      {{ data.id > 0 ? '#' + data.id + ' ' : ''
      }}{{ data.ML_NAME || data.typeName }}
    </h1>
    <button
      *ngIf="guide"
      mat-fab
      color="primary"
      class="guide-button"
      (click)="displayGuide()"
    >
      <mat-icon>help</mat-icon>
    </button>

    <div [ngClass]="{ container: guide }">
      <!-- "Pravý" sloupec - nápověda k procesu -->
      <div *ngIf="guide" class="side-panel">
        <mat-card appearance="outlined" class="side-card">
          <mat-card-content>
            <div [innerHTML]="guide"></div>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- "Levý" sloupec, hlavní formulář na proces -->
      <div [ngClass]="{ core: true, 'core-column': guide }">
        <form [formGroup]="form">
          <mat-card appearance="outlined" *ngIf="visibleTransitions?.length">
            <mat-radio-group
              name="transition"
              id="transition"
              formControlName="transition"
            >
              <div>
                <div *ngFor="let transition of visibleTransitions">
                  <p>
                    <mat-radio-button
                      [value]="transition.id"
                      [checked]="visibleTransitions?.length === 1"
                    >
                      <span>{{ transition.name }}</span>
                      <!--                      <mat-icon style="font-size: 15px">arrow_forward</mat-icon>-->
                      <!--                      <span>{{transition.stateName}}</span>-->
                      <!--<mat-icon *ngIf="form.get('transition').value === transition.id" matSuffix style="margin-left: 10px" (click)="clearTransition($event)">cancel</mat-icon>-->
                    </mat-radio-button>
                    <button
                      *ngIf="form.get('transition').value === transition.id"
                      mat-icon-button
                      matSuffix
                      style="margin: -10px 0 -10px 0"
                      (click)="clearTransition($event)"
                    >
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </p>
                </div>
              </div>
            </mat-radio-group>
          </mat-card>

          <mat-card appearance="outlined">
            <app-param-form
              [paramsService]="paramsService!"
              [form]="form"
              [paramFormName]="'processCoreForm'"
              [objectId]="{ id: data.id, defId: data.typeId }"
              [attributesService]="dataService"
              [dependencyService]="dependencyService"
              (formFinished)="paramFormFinished()"
            ></app-param-form>
          </mat-card>

          <mat-card appearance="outlined" *ngIf="processNotes?.length > 0">
            <h2 i18n="@@ProcessEdit.notes.title">Poznámky</h2>
            <cdk-virtual-scroll-viewport
              itemSize="0"
              class="note-list"
              [ngStyle]="{ height: container.offsetHeight + 1 + 'px' }"
            >
              <div #container>
                <mat-list class="note-table">
                  <ng-container *ngIf="processNotes">
                    <ng-container *cdkVirtualFor="let note of processNotes">
                      <mat-list-item class="note-item">
                        <div class="note" #listItem>
                          <div class="note-header">
                            <div class="note-date">
                              {{ note.time | date : 'd.M.yyyy HH:mm' }}
                            </div>
                            <div class="note-author">{{ note.userName }}</div>
                            :&nbsp;
                          </div>
                          <div class="note-text">{{ note.note }}</div>
                        </div>
                      </mat-list-item>
                    </ng-container>
                  </ng-container>
                </mat-list>
              </div>
            </cdk-virtual-scroll-viewport>
            <div class="note-button-container">
              <button
                mat-flat-button
                color="primary"
                *ngIf="settingVisible()"
                (click)="addComment()"
                i18n="@@ProcessEdit.createNote.button"
              >
                Poznámka
              </button>
            </div>
          </mat-card>

          <mat-card appearance="outlined">
            <h2 i18n="@@ProcessEdit.attachments.title">Přílohy</h2>
            <br />
            <div *ngFor="let file of castToAny(attachmentFiles)">
              <mat-icon>attachment</mat-icon>
              <a
                href="#"
                (click)="
                  attachmentService.downloadFileCustom(
                    $event,
                    file.id,
                    file.name
                  )
                "
                >{{ file.name }}</a
              >
            </div>

            <div class="uploaded-attachments">
              <h4
                *ngIf="(attachmentService.files | async).length > 0"
                i18n="@@ProcessEdit.attachments.unsaved"
              >
                Neodeslané
              </h4>
              <div
                *ngFor="let file of attachmentService.files | async"
                class="uploaded-attachment"
              >
                <mat-icon>attachment</mat-icon>
                {{ file.name }}
                <ng-container *ngIf="!file.uploadFinished">
                  - {{ file.uploadPercentage }}%</ng-container
                >
                <a
                  href="#"
                  (click)="removeFile(file); $event.preventDefault()"
                  i18n="@@ProcessEdit.attachments.remove"
                  >Odstranit</a
                >
              </div>
            </div>

            <div
              *ngIf="!notEditable"
              class="file-upload-zone"
              appDragDrop
              (fileDropped)="addFiles($event)"
            >
              <mat-icon>cloud_upload</mat-icon>
              <span
                i18n="
                  Drag&drop nebo|popis že uživatel může do pole soubory
                  přetáhnout + slovo nebo@@ProcessCreate.attachments.dragAndDrop"
                >Přetáhněte soubory sem nebo</span
              >
              <a
                href="#"
                (click)="attachmentInput.click(); $event.preventDefault()"
                i18n="
                  Vybrat soubory|odkaz pro zvolení souborů pro nahrátí ze
                  zařízení@@ProcessCreate.attachments.uploadFiles"
                >Vyberte</a
              >
              <input
                hidden
                type="file"
                #attachmentInput
                (change)="addFiles(attachmentInput.files)"
                capture="environment"
              />
            </div>
          </mat-card>
        </form>

        <mat-toolbar>
          <div class="button-row-spacer"></div>
          <span class="process-button-row">
            <button
              mat-flat-button
              *ngIf="!processNotes?.length && settingVisible()"
              (click)="addComment()"
              color="primary"
              i18n="@@ProcessEdit.createNote.button"
            >
              Poznámka
            </button>
            <button mat-flat-button (click)="leaveProcess()">
              <span
                *ngIf="!notEditable"
                i18n="@@ProcessEdit.leaveProcess.button"
                >Zavřít bez uložení</span
              >
              <span
                *ngIf="notEditable"
                i18n="@@ProcessEdit.leaveProcess.notEditable.button"
                >Zavřít</span
              >
            </button>
            <button
              mat-flat-button
              color="primary"
              (click)="saveProcess()"
              *ngIf="!notEditable"
            >
              <span i18n="@@ProcessEdit.saveProcess.button">Odeslat</span>
            </button>
            <ng-container *ngIf="visibleButtons">
              <button
                *ngFor="let button of visibleButtons"
                mat-flat-button
                color="primary"
                (click)="saveProcess(button.id)"
                [disabled]="notEditable || button.disabled"
                [title]="button.description"
              >
                {{ button.name }}
              </button>
            </ng-container>
          </span>
        </mat-toolbar>
      </div>
      <!-- Končí master "sloupec" se samotným formulářem na proces" -->
    </div>
    <!-- Končí "container" nad oběma "sloupci" -->
  </div>
  <!-- mango-form-page-contents -->
</ng-container>
