import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import {
  UserDataEditService,
  UserEditFormService,
} from '@isp-sc/shared/segments/user/data-access';
import { hasRequiredField } from '@isp-sc/shared/ui';
import { UserData } from '@isp-sc/shared/segments/user/common';
import { Address } from '@isp-sc/shared/segments/data/common';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import {
  FormHelperService,
  SearchAddressService,
} from '@isp-sc/shared/data-access';
import { DataAddressesService } from '@isp-sc/shared/segments/data/data-access';
import { UserEditFormType } from '@isp-sc/shared/common';

@Component({
  selector: 'app-user-edit-addresses',
  templateUrl: './user-edit-addresses.component.html',
  styleUrls: ['./user-edit-addresses.component.scss'],
})
export class UserEditAddressesComponent implements OnInit {
  protected readonly hasRequiredField = hasRequiredField;
  public form: FormGroup;
  private userData?: UserData;
  public formName: string;
  public suggestedAddresses: Address[] = [];

  constructor(
    public session: SessionService,
    public formHelper: FormHelperService,
    private userEditService: UserDataEditService,
    public dataAddressSearch: DataAddressesService,
    private userEditFormService: UserEditFormService,
    public searchAddress: SearchAddressService
  ) {
    this.formName = UserEditFormType.addresses;
    this.form = this.userEditFormService.getForm(UserEditFormType.addresses);
    this.form.addControl('addressFiltering', new FormControl(''));
    this.initDependencies();
    this.userEditFormService.profileDefaultConfig(
      this.form,
      UserEditFormType.addresses
    );
  }

  ngOnInit(): void {
    if (this.session.user) {
      this.userEditService.getSingleton({}, 1800).subscribe(
        (userData) => {
          this.userData = userData;
          this.form.patchValue(this.userData);
        },
        (err) => {
          console.log('Chyba při získání dat uživatelského profilu ' + err);
        }
      );
    }
  }

  private filterAddresses(
    value: string,
    addressType:
      | 'addressSearch'
      | 'mailingAddressSearch'
      | 'billingAddressSearch'
  ): void {
    if (value) {
      this.searchAddress
        .findAddress(value)
        .pipe(debounceTime(600))
        .subscribe((next) => {
          this.suggestedAddresses = next;
          const match = this.suggestedAddresses.find(
            (address) => address.name === value
          );
          if (match) {
            this.setAddress(match, addressType);
          }
        });
    } else {
      this.suggestedAddresses = [];
    }
  }

  initDependencies(): void {
    this.form.get('addressSearch')?.valueChanges.subscribe((value) => {
      if (value) {
        this.filterAddresses(value, 'addressSearch');
      } else {
        this.suggestedAddresses = [];
      }
    });

    this.form.get('mailingAddressSearch')?.valueChanges.subscribe((value) => {
      if (value) {
        this.filterAddresses(value, 'mailingAddressSearch');
      } else {
        this.suggestedAddresses = [];
      }
    });

    this.form.get('billingAddressSearch')?.valueChanges.subscribe((value) => {
      if (value) {
        this.filterAddresses(value, 'billingAddressSearch');
      } else {
        this.suggestedAddresses = [];
      }
    });
  }

  private setAddress(
    filteredAddress: Address,
    addressType:
      | 'addressSearch'
      | 'mailingAddressSearch'
      | 'billingAddressSearch'
  ): void {
    // Toto nefunguje - vyhledávač v těch adresách často nevrací city
    // switch (addressType){
    //   case 'addressSearch':
    //     this.form.get('street').setValue(filteredAddress.street);
    //     this.form.get('houseId').setValue(filteredAddress.houseId);
    //     this.form.get('zip').setValue(filteredAddress.zip);
    //     this.form.get('city').setValue(filteredAddress.city);
    //     break;
    //   case 'mailingAddressSearch':
    //     this.form.get('mailingAddressStreet').setValue(filteredAddress.street);
    //     this.form.get('mailingAddressHouseId').setValue(filteredAddress.houseId);
    //     this.form.get('mailingAddressZip').setValue(filteredAddress.zip);
    //     this.form.get('mailingAddressCity').setValue(filteredAddress.city);
    //     break;
    //   case 'billingAddressSearch':
    //     this.form.get('billingAddressStreet').setValue(filteredAddress.street);
    //     this.form.get('billingAddressHouseId').setValue(filteredAddress.houseId);
    //     this.form.get('billingAddressZip').setValue(filteredAddress.zip);
    //     this.form.get('billingAddressCity').setValue(filteredAddress.city);
    // }

    // Alternativa - dohledání přímo v Mangu
    this.dataAddressSearch.getone(filteredAddress.id).subscribe((address) => {
      switch (addressType) {
        case 'addressSearch':
          this.form.get('street')?.setValue(address.street);
          this.form.get('houseId')?.setValue(address.houseId);
          this.form.get('zip')?.setValue(address.zip);
          this.form.get('city')?.setValue(address.city);
          break;
        case 'mailingAddressSearch':
          this.form.get('mailingAddressStreet')?.setValue(address.street);
          this.form.get('mailingAddressHouseId')?.setValue(address.houseId);
          this.form.get('mailingAddressZip')?.setValue(address.zip);
          this.form.get('mailingAddressCity')?.setValue(address.city);
          break;
        case 'billingAddressSearch':
          this.form.get('billingAddressStreet')?.setValue(address.street);
          this.form.get('billingAddressHouseId')?.setValue(address.houseId);
          this.form.get('billingAddressZip')?.setValue(address.zip);
          this.form.get('billingAddressCity')?.setValue(address.city);
      }
    });
  }

  // private setAddress(value: number): void {
  //   if (value > 0) {
  //     this.dataAddressSearch.getone(value).subscribe(address => {
  //       this.form.get('street').setValue(address.street);
  //       this.form.get('houseId').setValue(address.houseId);
  //       this.form.get('zip').setValue(address.zip);
  //       this.form.get('city').setValue(address.city);
  //
  //       // this.form.get('street').disable();
  //       // this.form.get('houseId').disable();
  //       // this.form.get('zip').disable();
  //       // this.form.get('city').disable();
  //
  //     });
  //   } else {
  //     // this.form.get('street').enable();
  //     // this.form.get('houseId').enable();
  //     // this.form.get('zip').enable();
  //     // this.form.get('city').enable();
  //   }

  // private setMailingAddress(value: number): void {
  //   if (value > 0) {
  //     this.dataAddressSearch.getone(value).subscribe(address => {
  //       this.form.get('mailingAddressStreet').setValue(address.street);
  //       this.form.get('mailingAddressHouseId').setValue(address.houseId);
  //       this.form.get('mailingAddressZip').setValue(address.zip);
  //       this.form.get('mailingAddressCity').setValue(address.city);
  //
  //       // this.form.get('mailingAddressStreet').disable();
  //       // this.form.get('mailingAddressHouseId').disable();
  //       // this.form.get('mailingAddressZip').disable();
  //       // this.form.get('mailingAddressCity').disable();
  //
  //     });
  //   } else {
  //     // this.form.get('mailingAddressStreet').enable();
  //     // this.form.get('mailingAddressHouseId').enable();
  //     // this.form.get('mailingAddressZip').enable();
  //     // this.form.get('mailingAddressCity').enable();
  //   }
  // }
  //
  // private setBillingAddress(value: number): void {
  //   if (value > 0) {
  //     this.dataAddressSearch.getone(value).subscribe(address => {
  //       this.form.get('billingAddressStreet').setValue(address.street);
  //       this.form.get('billingAddressHouseId').setValue(address.houseId);
  //       this.form.get('billingAddressZip').setValue(address.zip);
  //       this.form.get('billingAddressCity').setValue(address.city);
  //
  //       // this.form.get('billingAddressStreet').disable();
  //       // this.form.get('billingAddressHouseId').disable();
  //       // this.form.get('billingAddressZip').disable();
  //       // this.form.get('billingAddressCity').disable();
  //     });
  //   } else {
  //     // this.form.get('billingAddressStreet').enable();
  //     // this.form.get('billingAddressHouseId').enable();
  //     // this.form.get('billingAddressZip').enable();
  //     // this.form.get('billingAddressCity').enable();
  //   }
  // }

  clearAddress(item: string): void {
    this.form.get(item)?.setValue(null);
  }

  billingAddressVisible(): boolean {
    return this.form.get('billingAddress')?.value === 'OWN';
  }

  mailingAddressVisible(): boolean {
    return this.form.get('mailingAddress')?.value === 'OWN';
  }
}
