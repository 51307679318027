import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { DataUserServicesService } from './data-user-services.service';
import {
  DataBase,
  DataBaseServiceConfig,
  FilterInfo,
  GetallResult,
} from '@og_soft/data-base';
import { CachingInterceptor } from '@og_soft/cache';
import { PhoneNumberPipe } from '@og_soft/phone-number';
import {
  RecTelephonyList,
  RecTelephonyMeta,
} from '@isp-sc/shared/segments/data/common';

@Injectable({
  providedIn: 'root',
})
export class DataTelephonyListService extends DataBase<
  RecTelephonyList,
  RecTelephonyMeta
> {
  protected getAllUrl(): string {
    return 'telephony-list' as const;
  }

  constructor(
    public override http: HttpClient,
    public serviceConfig: DataBaseServiceConfig,
    public services: DataUserServicesService,
    private cachingInterceptor: CachingInterceptor
  ) {
    super(http, serviceConfig, cachingInterceptor);
  }

  override metaFetchFilterInfo(meta: RecTelephonyMeta): FilterInfo[] {
    if (meta) {
      const info: FilterInfo[] = [];
      if (meta.saName) {
        info.push({
          label: $localize`:@@DataTelephonyService.filterInfo.service.label:Služba`,
          filterNames: ['saId'],
          value: meta.saName,
          valueDetail:
            meta.saIdentType === 'PHONE'
              ? PhoneNumberPipe.prototype.transform('+' + meta.saIdent)
              : meta.saIdent,
        });
      }

      if (meta.dateFrom || meta.dateTo) {
        const dp = new DatePipe('cs-CZ');
        const dates = [];
        dates.push(
          meta.dateFrom ? dp.transform(meta.dateFrom, 'd. M. yyyy') : ''
        );
        dates.push(meta.dateTo ? dp.transform(meta.dateTo, 'd. M. yyyy') : '');
        info.push({
          label: $localize`:@@DataTelephonyService.filterInfo.service.label:Období`,
          filterNames: ['dateFrom', 'dateTo'],
          value: dates.join(' - '),
        });
      }

      return info;
    }
    return super.metaFetchFilterInfo(meta);
  }

  protected override dataPostprocess(
    data: GetallResult<RecTelephonyList>
  ): GetallResult<RecTelephonyList> {
    // Toto je dostatečně finální service, takže můžeme použít cizí service přímo zde.
    const ids: number[] = [];
    for (const service of data.data) {
      if (ids.indexOf(service.saId) < 0) {
        ids.push(service.saId);
      }
    }

    if (ids.length) {
      this.services.getall({ saIds: ids.join(',') }).subscribe((d) => {
        data.data.map((rec) => {
          for (const service of d.data) {
            if (rec.saId === service.saBaseId) {
              rec.saData = service;
            }
          }
        });
        this.onDataCollected.emit();
        return data;
      });
    }

    return super.dataPostprocess(data);
  }
}
