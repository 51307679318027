import { environment as instanceEnvironment } from './environment.instance';
import { environment as ctEnvironment } from './environment.ct';
import { environment as versionEnvironment } from './environment.version';

// Konfigurace builděného selfcare je daná dvěma nastaveními - instancí (devel, test, stable) a lokací (ct)
// Ve chvíli kdy spouštím build, nadefinuji potřebnou konfiguraci v pomocí parametrů ng build.
// např. "ng build --prod -c=IS.190,devel", "ng build --prod -c=IS.218,stable"
// environments.ts slouží potom pouze ke spojení těchto konfigurací.
// Proto není potřeba ho mít ignorovaný. Ignorované naopak budeme mít soubory environment.instance.ts
// který je nahrazovaný v rámci konfigurace instance a soubor environment.ct.ts, který je nahrazovaný
// v rámci konfigurace lokace.
// Třetí nastavení se týká aktuálního čísla verze. To je ve zvláštním souboru, protože ta je stejná
// pro všechny instance. Je v souboru environment.version.ts, do kterého se nastaví aktuální verze pro
// každou větev v svn (tj. nastavuje se při odštěpení testové větve).
export const environment = {
  ...instanceEnvironment,
  ...ctEnvironment,
  ...versionEnvironment
};
