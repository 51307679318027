import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataRmRedirectService } from '@isp-sc/shared/segments/data/data-access';

@Component({
  selector: 'app-rm-redirect',
  templateUrl: './rm-redirect.component.html',
  styleUrls: ['./rm-redirect.component.scss'],
})
export class RmRedirectComponent implements OnInit {
  private rmEncodedData?: string;

  mangoData: any;

  constructor(
    private route: ActivatedRoute,
    private srv: DataRmRedirectService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.rmEncodedData = params['data'];
      this.setup();
    });
  }

  setup() {
    this.srv.getSetup(this.rmEncodedData).subscribe(
      (d) => {
        console.log('Jsem úspěšně dostal', d);
        this.mangoData = d;
      },
      (err) => {
        console.log('Jsem ke vzteku dostal', err);
      }
    );
  }

  doMacChange(parentNodeId: number) {
    this.srv
      .doMacChange({
        mangoData: this.mangoData,
        selectedCpe: parentNodeId,
      })
      .subscribe(
        (d) => {
          console.log('Mac by měla být úspěšně změněna', d);
          window.location.href = this.mangoData.input[2];
        },
        (err) => {
          console.log('Nějak to nevyšlo :-(', err);
        }
      );
  }
}
