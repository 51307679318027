import { Injectable } from '@angular/core';
import { DataBase } from '@og_soft/data-base';
import { PopupOption } from '@isp-sc/shared/ui';
import { BankCodes } from '@isp-sc/shared/segments/data/common';

@Injectable({
  providedIn: 'root',
})
export class DataBankCodesService extends DataBase<BankCodes> {
  protected getAllUrl(): string {
    return 'bank-codes' as const;
  }

  override tranformOptions(row: BankCodes): PopupOption {
    return { id: row.bankCode, name: [row.bankCode, row.fullName].join(' ') };
  }
}
