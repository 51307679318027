import { Injectable } from '@angular/core';
import {
  DataCodeListService,
  DataParamsService,
  DataValueDomainService,
  DataValueDomainPatternService,
  ParamBase,
} from '@isp-sc/shared/segments/params/data-access';
import {
  ProcessPremisesService,
  ProcessServicesService,
  ProcessUnitsService,
} from '@isp-sc/shared/segments/process/data-access';
import { DependencyNodesService } from '@isp-sc/shared/segments/dependency/data-access';
import {
  Options,
  ParamObjectType,
} from '@isp-sc/shared/segments/params/common';
import { Observable } from 'rxjs';
import { RecNodeParam } from '@isp-sc/shared/segments/data/common';
import { map } from 'rxjs/operators';

/**
 */
@Injectable({
  providedIn: 'root',
})
export class DataNodeParamsService extends DataParamsService {
  //TODO move
  protected getAllUrl(): string {
    return 'node-params' as const;
  }

  public transformParams(
    paramData: any,
    services: {
      dataValuePatterns: DataValueDomainPatternService;
      dataCodeList: DataCodeListService;
      dataValueDomain: DataValueDomainService;
      dataProcessPremises: ProcessPremisesService;
      dataProcessServices: ProcessServicesService;
      dataProcessUnits: ProcessUnitsService;
      dependencyNodesService: DependencyNodesService;
    }
  ): ParamBase {
    const options: Options = {
      id: paramData.id,
      defId: paramData.defId,
      value: paramData.value,
      defaultValue: paramData.defaultValue,
      objectType: ParamObjectType.Node,
      objId: paramData.objId,
      name: paramData.name + (paramData.indexed ? '_' + paramData.index : ''),
      caption: paramData.caption,
      order: paramData.order,
      type: paramData.type,
      disabled: paramData.disabled,
      indexed: paramData.indexed,
      index: paramData.index,
      domain: paramData.domain,
      hrValue: paramData.hrValue,
      visibilityDependency: paramData.visibilityDependency,
      editDependency: paramData.editDependency,
      requireDependency: paramData.requireDependency,
      editable: paramData.editable,
      visible: paramData.visibility,
      group: paramData.group,
      attribute: paramData.attribute,
      description: paramData.description,
      unique: paramData.unique,
    };

    return new ParamBase(options, services);
  }

  /**
   * Transformuje parametry pro zobrazení v tabulce, už i seřazené.
   */
  public getParamsForView(
    params: any,
    cacheTimeout?: number
  ): Observable<RecNodeParam[]> {
    return this.getall(params, cacheTimeout).pipe(
      map((nodeParams) => {
        return nodeParams.data
          .map((paramData) => {
            return {
              name: paramData.name,
              index: paramData.index,
              title: paramData.caption,
              value: paramData.hrValue,
              editable: paramData.editDependency !== '',
              visible: paramData.visibility,
            };
          })
          .sort((a, b) => a.title.localeCompare(b.title));
      })
    );
  }
}
