import { Inject, Injectable } from '@angular/core';
import { DataBase, DataBaseServiceConfig } from '@og_soft/data-base';
import { UserType } from '@isp-sc/shared/segments/user/common';
import { ProtectedDataCategory } from '@isp-sc/shared/segments/protected-data/common';
import { SC_CONFIGURATION, ScConfiguration } from '@isp-sc/shared/common';
import { HttpClient } from '@angular/common/http';
import { CachingInterceptor } from '@og_soft/cache';

@Injectable({
  providedIn: 'root',
})
export abstract class ProtectedDataCategoryGeneralService extends DataBase<ProtectedDataCategory> {
  constructor(
    public override http: HttpClient,
    serviceConfig: DataBaseServiceConfig,
    private cachingInterceptor: CachingInterceptor,
    @Inject(SC_CONFIGURATION) protected scConfiguration: ScConfiguration
  ) {
    super(http, serviceConfig, cachingInterceptor);
  }

  protected override recordPostprocess(
    record: ProtectedDataCategory
  ): ProtectedDataCategory {
    record.userType = record.userTypeRaw
      ? (record.userTypeRaw.split(',') as UserType[])
      : [];
    return super.recordPostprocess(record);
  }
}
