<form [formGroup]="form" class="contacts-chiplist-is-a-fool">
  <!--*********** !!! Pokud by bylo potřeba mat-chip použít někde jinde, měla by se na ně už vytvořit komponenta-->
  <!--        Tato kompoenenta se využívá jen při editaci, při registraci využívám jednoduchý input-->

  <ng-container *ngIf="form.get('emails') as emailsControl">
    <mat-form-field
      class="mango-form-field"
      *ngIf="formHelper.isVisible(emailsControl) && !registration"
    >
      <mat-label i18n="@@UserProfile.emails.input.placeholder"
        >Emaily</mat-label
      >
      <mat-chip-grid #emailList>
        <mat-chip-row
          *ngFor="let email of emails"
          [removable]="removable"
          (removed)="removeEmail(email)"
        >
          {{ email }}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip-row>
        <input
          [matChipInputFor]="emailList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodesEmail"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="addEmail($event)"
          [required]="emailsControl | hasRequiredField"
        />
      </mat-chip-grid>
      <app-config-setting
        input="emails"
        [formName]="formName"
      ></app-config-setting>
      <mat-error
        *ngIf="
          emailsControl.invalid &&
          (emailsControl.dirty || !emailsControl.untouched)
        "
      >
        <span i18n="@@UserProfile.email.format.error"
          >Některý ze zadaných emailů nemá správný formát</span
        >
      </mat-error>
      <mat-error
        *ngIf="
          form.errors?.emailCommunication &&
          (emailsControl.dirty || !emailsControl.untouched)
        "
      >
        <span i18n="@@UserProfile.email.emailCommunication.error"
          >Email je povinný pro zajištění komunikace prostřednictvím
          emailu</span
        >
      </mat-error>
    </mat-form-field>

    <mat-form-field
      class="mango-form-field"
      *ngIf="formHelper.isVisible(emailsControl) && registration"
      [appearance]="formHelper.appearance()"
    >
      <mat-label i18n="@@UserProfile.emails.label">Email</mat-label>
      <input
        matInput
        formControlName="emails"
        id="emails"
        name="emails"
        i18n-title="@@UserProfile.emails.title"
        title="Email"
        [required]="emailsControl | hasRequiredField"
      />
      <app-config-setting
        input="emails"
        [formName]="formName"
      ></app-config-setting>
      <mat-error
        *ngIf="
          emailsControl.invalid &&
          (emailsControl.dirty || !emailsControl.untouched)
        "
      >
        {{ formHelper.getErrorMessage(emailsControl) }}
      </mat-error>
    </mat-form-field></ng-container
  >

  <ng-container *ngIf="form.get('phones') as phoneControl">
    <mat-form-field
      class="mango-form-field"
      *ngIf="formHelper.isVisible(phoneControl)"
    >
      <mat-label i18n="@@UserProfile.phones.input.placeholder"
        >Telefonní čísla</mat-label
      >
      <mat-chip-grid #phoneList>
        <mat-chip-row
          *ngFor="let phone of phones"
          [removable]="removable"
          (removed)="removePhone(phone)"
        >
          {{ phone | phoneNumber }}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip-row>
        <input
          [matChipInputFor]="phoneList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodesPhones"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="addPhone($event)"
          [required]="phoneControl | hasRequiredField"
        />
      </mat-chip-grid>
      <app-config-setting
        input="phones"
        [formName]="formName"
      ></app-config-setting>
      <mat-error
        *ngIf="
          phoneControl.invalid &&
          (phoneControl.dirty || !phoneControl.untouched)
        "
      >
        <span i18n="@@UserProfile.phones.format.error"
          >Špatný formát telefonního čísla</span
        >
      </mat-error>
      <mat-error
        *ngIf="
          form.errors?.phoneCommunication &&
          (phoneControl.dirty || !phoneControl.untouched)
        "
      >
        <span i18n="@@UserProfile.phone.phoneCommunication.error"
          >Telefon je povinný pro zajištění komunikace prostřednictvím SMS</span
        >
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="form.get('fax') as faxControl">
    <ng-container *ngIf="formHelper.isVisible(faxControl)">
      <mat-form-field
        class="mango-form-field"
        [appearance]="formHelper.appearance()"
      >
        <mat-label i18n="@@UserProfile.fax.label">Fax</mat-label>
        <input
          matInput
          formControlName="fax"
          id="fax"
          name="fax"
          i18n-title="@@UserProfile.fax.title"
          title="Fax"
          [required]="faxControl | hasRequiredField"
        />
        <app-config-setting
          input="fax"
          [formName]="formName"
        ></app-config-setting>
        <mat-error
          *ngIf="
            faxControl.invalid && (faxControl.dirty || !faxControl.untouched)
          "
        >
          {{ formHelper.getErrorMessage(faxControl) }}
        </mat-error>
      </mat-form-field>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="form.get('infoEmail') as infoEmailControl">
    <ng-container *ngIf="form.get('infoPost') as infoPostControl">
      <ng-container *ngIf="form.get('infoSms') as infoSmsControl">
        <ng-container
          *ngIf="
            formHelper.isVisible(infoEmailControl) ||
            formHelper.isVisible(infoPostControl) ||
            formHelper.isVisible(infoSmsControl)
          "
        >
          <h3
            class="mat-h3 mango-text-secondary"
            i18n="@@UserProfile.contact.type.text"
          >
            Jak si přeji být kontaktován:
          </h3>

          <div>
            <ng-container *ngIf="formHelper.isVisible(infoPostControl)">
              <mat-checkbox
                matInput
                formControlName="infoPost"
                id="infoPost"
                name="infoPost"
                i18n-title="@@UserProfile.infoPost.title"
                title="Poštou ve vytištěné podobě"
                i18n="@@UserProfile.infoPost.label"
                >Poštou ve vytištěné podobě</mat-checkbox
              >
              <app-config-setting
                input="infoPost"
                [formName]="formName"
              ></app-config-setting>
            </ng-container>
          </div>

          <div>
            <ng-container *ngIf="formHelper.isVisible(infoEmailControl)">
              <mat-checkbox
                matInput
                formControlName="infoEmail"
                id="infoEmail"
                name="infoEmail"
                i18n-title="@@UserProfile.infoEmail.title"
                title="E-mailem"
                i18n="@@UserProfile.infoEmail.label"
                >E-mailem</mat-checkbox
              >
              <app-config-setting
                input="infoEmail"
                [formName]="formName"
              ></app-config-setting>
            </ng-container>
          </div>
          <mat-error
            *ngIf="
              form.errors?.communicationChannel &&
              (infoEmailControl.dirty ||
                !infoEmailControl.untouched ||
                infoPostControl.dirty ||
                !infoPostControl.untouched)
            "
          >
            <span i18n="@@UserProfile.email.emailCommunication.error"
              >Pro zasílání dokumentů je potřeba mít vybraný alespoň jeden
              způsob komunikace - emailem nebo poštou.</span
            >
          </mat-error>

          <div>
            <ng-container *ngIf="formHelper.isVisible(infoSmsControl)">
              <mat-checkbox
                matInput
                formControlName="infoSms"
                id="infoSms"
                name="infoSms"
                i18n-title="@@UserProfile.infoSms.title"
                title="Prostřednictvím SMS"
                i18n="@@UserProfile.infoSms.label"
                >Prostřednictvím SMS</mat-checkbox
              >
              <app-config-setting
                input="infoSms"
                [formName]="formName"
              ></app-config-setting>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <div>
    <ng-container *ngIf="form.get('infoMarketing') as infoMarketingControl">
      <ng-container *ngIf="formHelper.isVisible(infoMarketingControl)">
        <mat-checkbox
          matInput
          formControlName="infoMarketing"
          id="infoMarketing"
          name="infoMarketing"
          i18n-title="@@UserProfile.infoMarketing.title"
          title="Mám zájem o marketingová sdělení"
          i18n="@@UserProfile.infoMarketing.label"
          >Mám zájem o marketingová sdělení</mat-checkbox
        >
        <app-config-setting
          input="infoMarketing"
          [formName]="formName"
        ></app-config-setting>
      </ng-container>
    </ng-container>
  </div>
</form>
