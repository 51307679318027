import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'sc-dashboard-widget',
  templateUrl: './dashboard-widget.component.html',
  styles: [
    `
      :host {
        position: absolute;
      }

      :host ::ng-deep mat-card-content p:first-of-type {
        margin-top: 0;
      }
    `,
  ],
})
export class DashboardWidgetComponent implements AfterViewInit {
  @Input() public priority = 1;
  @Input() unframed = false;
  @Input() htmlContent?: string = undefined;
  @Input() title?: string = undefined;
  @Input() subtitle?: string = undefined;

  constructor(public element: ElementRef) {}

  @ViewChild('here', { static: false }) contentContainer!: ElementRef;

  ngAfterViewInit() {
    if (this.htmlContent !== undefined) {
      this.contentContainer.nativeElement.innerHTML = this.htmlContent;
    }
  }

  getHeight() {
    return this.element.nativeElement.firstElementChild.clientHeight;
  }

  setPlacement(colidx: number) {
    this.element.nativeElement.classList.add('go-in-' + colidx);
  }

  resetPlacement() {
    this.element.nativeElement.classList.remove(
      'go-in-0',
      'go-in-1',
      'go-in-2',
      'go-in-3',
      'go-in-4',
      'go-in-5',
      'go-in-6'
    );
  }
}
