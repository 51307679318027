import { FormGroup, ValidationErrors } from '@angular/forms';

export const passwordCheckValidator: (
  control: FormGroup
) => ValidationErrors | null = (
  control: FormGroup
): ValidationErrors | null => {
  const password = control.get('password');
  const passwordCheck = control.get('passwordCheck');
  // console.log('XXXXXXXXXXXXXXXXXXXXXX validuji pass: '+password.value+' check: '+passwordCheck.value);
  return password?.value !== passwordCheck?.value
    ? { passwordCheck: true }
    : null;
};
