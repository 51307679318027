<sc-main-toolbar> </sc-main-toolbar>

<div class="mango-table-page-contents">
  <h1 i18n="@@menu.protected" *ngIf="true">Chráněné údaje</h1>
  <sc-page-caption-block></sc-page-caption-block>

  <mgt-table
    *ngIf="tablePrepared"
    #tbl
    [service]="dataService"
    [filters]="filterValues"
  >
    <mgt-header [filterValues]="filterValues" [tab]="tbl"> </mgt-header>

    <mgt-row
      *ngFor="let r of tbl.scrollItems"
      [replacing-detail]="false"
      [record]="r"
    >
      <mgt-ico>
        <img src="assets/GDPR_lock.png" alt="GDPR_lock.png" />
      </mgt-ico>

      <mgt-cell-group master>
        <mgt-cell
          i18n-title="@@ProtectedData.record.catName.title"
          title="Kategorie"
          role="title"
        >
          <!-- <span><span i18n-title="@@ProtectedData.record.catName.title">Kategorie</span> {{r.catName}}</span> -->
          <span [innerHTML]="r.catInfoLevel1"></span>
        </mgt-cell>
      </mgt-cell-group>

      <mgt-cell-group>
        <mgt-cell class="cell-right">
          <mat-icon
            *ngIf="
              r.catConsent == 0 && r.catLawfulness == 1 && r.userPdcConsent
            "
            color="warn"
            class="toggle-icon off"
            i18n-matTooltip="@@ProtectedData.event.consentAdd"
            matTooltip="Udělit souhlas"
            (click)="consentAdd(r.catId); $event.stopPropagation()"
            >toggle_off</mat-icon
          >

          <mat-icon
            *ngIf="r.catLawfulness == 2"
            class="toggle-icon disabled"
            color="accent"
            i18n-matTooltip="@@ProtectedData.event.stopPropagation"
            matTooltip="Chráněné údaje zpracováváme od okamžiku jejich poskytnutí."
            (click)="$event.stopPropagation()"
            >toggle_on</mat-icon
          >

          <mat-icon
            *ngIf="
              r.catConsent == 1 &&
              r.catLawfulness == 1 &&
              !r.catEraseInactive &&
              r.catManualErase == 0 &&
              r.userPdcConsent
            "
            class="toggle-icon"
            color="accent"
            i18n-matTooltip="@@ProtectedData.event.consentRecall"
            matTooltip="Souhlas je nyní udělen. Kliknutím můžete odvolat souhlas."
            (click)="consentRecall(r.catId, false); $event.stopPropagation()"
            >toggle_on</mat-icon
          >

          <mat-icon
            *ngIf="
              r.catConsent == 1 &&
              r.catLawfulness == 1 &&
              !r.catEraseInactive &&
              r.catManualErase == 1 &&
              r.userPdcConsent
            "
            class="toggle-icon"
            color="accent"
            i18n-matTooltip="@@ProtectedData.event.consentRecallErase"
            matTooltip="Souhlas je nyní udělen. Kliknutím můžete odvolat souhlas."
            (click)="consentRecallErase(r); $event.stopPropagation()"
            >toggle_on</mat-icon
          >

          <mat-icon
            *ngIf="
              r.catConsent == 1 &&
              r.catLawfulness == 1 &&
              r.catEraseInactive == 1 &&
              r.catManualErase == 1 &&
              r.userPdcConsent
            "
            class="toggle-icon"
            color="accent"
            i18n-matTooltip="@@ProtectedData.event.consentRecallInactive"
            matTooltip="Souhlas je nyní udělen. Kliknutím můžete odvolat souhlas, povede to však k Vašemu zneaktivnění jako zákazníka."
            (click)="consentRecallInactive(r); $event.stopPropagation()"
            >toggle_on</mat-icon
          >
        </mgt-cell>
      </mgt-cell-group>

      <div detail>
        <!--<span *ngIf="r.catEraseInactive && r.catConsent==1">Při odvolání souhlasu dojde k Vašemu zneaktivnění!</span>-->

        <div
          *ngIf="r.catInfoLevel2"
          [innerHTML]="r.catInfoLevel2"
          class="mango-text-normal"
        ></div>

        <div *ngIf="r.catInfoLevel3" class="level-3-container">
          <a
            href="javascript:void(0)"
            (click)="displayLevel3Info(r); $event.stopPropagation()"
            i18n="@@ProtectedData.button.link.level3.info"
            >Více informací</a
          >
        </div>

        <div *ngIf="r.catCountConsent > 0" class="consent-history-container">
          <!-- <div class="mat-caption" i18n-title="@@ProtectedData.record.catCountConsent.title">Historie udělení souhlasu</div> -->
          <h3 i18n="@@ProtectedData.record.catCountConsent.title">
            Historie udělení souhlasu
          </h3>
          <mgt-row
            *ngFor="let pdc1 of r.pdc"
            [expandable]="false"
            [record]="pdc1"
            class=""
          >
            <mgt-cell-group master>
              <mgt-cell desktop-only>
                <span *ngIf="pdc1.pdcDateCons" role="title">
                  <span i18n="@@ProtectedData.desktop.pdcDateCons.caption"
                    >Udělen</span
                  >
                  {{ pdc1.pdcDateCons | date : 'd. M. yyyy H:ss' }} ({{
                    pdc1.pdcChannelCons
                  }})</span
                >
                <span *ngIf="pdc1.pdcDateCons && pdc1.pdcDateRec">, </span>
                <span *ngIf="pdc1.pdcDateRec" role="title">
                  <span i18n="@@ProtectedData.desktop.pdcDateRec.caption"
                    >Odvolán</span
                  >
                  {{ pdc1.pdcDateRec | date : 'd. M. yyyy H:ss' }} ({{
                    pdc1.pdcChannelRec
                  }})</span
                >
              </mgt-cell>
              <mgt-cell mobile-only>
                <span *ngIf="pdc1.pdcDateRec" role="title">
                  <span i18n="@@ProtectedData.mobile.pdcDateRec.caption"
                    >Odvolán</span
                  >
                  {{ pdc1.pdcDateRec | date : 'd. M. yyyy H:ss' }} ({{
                    pdc1.pdcChannelRec
                  }})</span
                >
              </mgt-cell>
              <mgt-cell mobile-only>
                <span *ngIf="pdc1.pdcDateCons" role="title">
                  <span i18n="@@ProtectedData.mobile.pdcDateCons.caption"
                    >Udělen</span
                  >
                  {{ pdc1.pdcDateCons | date : 'd. M. yyyy H:ss' }} ({{
                    pdc1.pdcChannelCons
                  }})</span
                >
              </mgt-cell>
            </mgt-cell-group>
          </mgt-row>
        </div>

        <!--
         <pre>{{r|json}}</pre>
        -->
      </div>

      <mgt-actions
        *ngIf="
          r.catConsent == 1 &&
          r.catLawfulness == 1 &&
          !r.catEraseInactive &&
          r.catManualErase == 0 &&
          r.pdcUserConsent
        "
      >
        <button mat-button (click)="consentRecall(r.catId, false)">
          <mat-icon i18n="@@ProtectedData.catConsentRecall.button"
            >close</mat-icon
          >
          Odvolat souhlas
        </button>
      </mgt-actions>

      <mgt-actions
        *ngIf="
          r.catConsent == 1 &&
          r.catLawfulness == 1 &&
          !r.catEraseInactive &&
          r.catManualErase == 1 &&
          r.pdcUserConsent
        "
      >
        <button mat-button (click)="consentRecallErase(r)">
          <mat-icon i18n="@@ProtectedData.catConsentRecallErase.button"
            >close</mat-icon
          >
          Odvolat souhlas
        </button>
      </mgt-actions>

      <mgt-actions
        *ngIf="
          r.catConsent == 1 &&
          r.catLawfulness == 1 &&
          r.catEraseInactive == 1 &&
          r.pdcUserConsent
        "
      >
        <button mat-button (click)="consentRecallInactive(r)">
          <mat-icon i18n="@@ProtectedData.catConsentRecallInactive.button"
            >close</mat-icon
          >
          Odvolat souhlas
        </button>
      </mgt-actions>

      <mgt-actions
        *ngIf="r.catConsent == 0 && r.catLawfulness == 1 && r.pdcUserConsent"
      >
        <button mat-button (click)="consentAdd(r.catId)">
          <mat-icon i18n="@@ProtectedData.catConsentAdd.button">check</mat-icon>
          Udělit souhlas
        </button>
      </mgt-actions>
    </mgt-row>

    <!-- Implicitní text "Víc už toho není" na konci je myslím u této stránky nesmysl -->
    <div plug></div>
  </mgt-table>
</div>
