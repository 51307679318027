import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { UserEditFormService } from '@isp-sc/shared/segments/user/data-access';
import { FormHelperService } from '@isp-sc/shared/data-access';
import { UserEditFormType } from '@isp-sc/shared/common';
import {
  CrossFieldErrorMatcher,
  passwordCheckValidator,
} from '@isp-sc/shared/segments/selfcare/common';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss'],
})
export class PasswordChangeComponent implements OnInit {
  @Input() parentForm?: FormGroup;

  form: FormGroup;
  errorMatcher = new CrossFieldErrorMatcher();
  public formName: string;

  constructor(
    public formHelper: FormHelperService,
    private userEditFormService: UserEditFormService
  ) {
    this.formName = UserEditFormType.credentials;
    this.form = this.userEditFormService.getForm(UserEditFormType.credentials);
  }

  ngOnInit(): void {
    const passwordValidators = [];
    const passwordCheckValidators = [];

    passwordValidators.push(Validators.required);
    passwordValidators.push(
      Validators.pattern(
        '^(?!.*(.)\\1{2,})(?:(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,255}|.{12,255})$'
      )
    );

    passwordCheckValidators.push(Validators.required);

    this.form.get('password')?.setValidators(passwordValidators);
    this.form.get('passwordCheck')?.setValidators(passwordCheckValidators);
    this.form.setValidators(
      passwordCheckValidator as ValidatorFn | ValidatorFn[] | null
    );

    if (this.parentForm) {
      this.parentForm.addControl('passwordForm', this.form);
    }
  }

  getErrorMessage(component: AbstractControl | null): string {
    if (component && !component.valid) {
      if (component.hasError('required')) {
        return $localize`:Požadováno|Validační hláška u povinných polí@@PasswordChange.error.required:Required\``;
      }
      if (component.hasError('pattern')) {
        return $localize`:Příliš slabé heslo|Validační hláška - Upozornění, že heslo je příliš slabé@@PasswordChange.error.passwordTooWeak:Password too weak`;
      }
    }
    return '';
  }
}
