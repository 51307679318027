import { Injectable } from '@angular/core';
import { DataBase } from '@og_soft/data-base';

@Injectable({
  providedIn: 'root',
})
export class ProtectedDataItemsCategoriesService extends DataBase<{}> {
  protected getAllUrl(): string {
    return 'protected-data-items-categories' as const;
  }
}
