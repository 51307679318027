import { forkJoin, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import {
  MangoParamFormControl,
  ParamBase,
  ParamControlService,
} from '@isp-sc/shared/segments/params/data-access';

/**
 * Servisa, která se stará o vyhodnocení závislostí (především parametrů)
 * Pro každý typ parametrů je třeba vytvoři a použít zvláštní třídu.
 */
@Injectable({
  providedIn: 'root',
})
export abstract class DependencyService {
  // Pomocná proměnná, kterou používám jako indikátor, že byla ve formuláři s parametry provedená změna.
  private formChanged?: boolean;

  constructor(
    private paramControl: ParamControlService,
    private session: SessionService
  ) {}
  public abstract resolveDependency(
    dependencyStr: string,
    objectId: number,
    actualData: any
  ): Observable<boolean>;
  public controlDependencyResolve(
    control: MangoParamFormControl,
    formWithDependencyData: any,
    initiator: string
  ): void {
    this.formChanged = true;
    // Prvně si vyřeším závislosti, které mě mění optiony.
    // To, které se mají provést je už ošetřené uvnitř metody parametru.
    this.loadOptions(initiator, formWithDependencyData, control.param);

    // Pokud komponenta nemá nadefinovanou žádnou závislost končím.
    if (
      (control.param?.requireDependency === null ||
        control.param?.requireDependency === undefined) &&
      (control.param?.editDependency === null ||
        control.param?.editDependency === undefined) &&
      (control.param?.visibilityDependency === null ||
        control.param?.visibilityDependency === undefined)
    ) {
      return;
    }

    let visibleObservable: Observable<boolean> | Observable<null>;
    let editableObservable: Observable<boolean> | Observable<null>;
    let requiredObservable: Observable<boolean> | Observable<null>;

    if (
      control.param.requireDependency !== null &&
      control.param.requireDependency !== undefined
    ) {
      requiredObservable = this.resolveDependency(
        control.param.requireDependency,
        control.param.objId,
        formWithDependencyData
      );
    } else {
      requiredObservable = of(null);
    }

    if (
      control.param.editDependency !== null &&
      control.param.editDependency !== undefined
    ) {
      editableObservable = this.resolveDependency(
        control.param.editDependency,
        control.param.objId,
        formWithDependencyData
      );
    } else {
      editableObservable = of(null);
    }

    if (
      control.param.visibilityDependency !== null &&
      control.param.visibilityDependency !== undefined,
      control.param.editDependency !== undefined
    ) {
      visibleObservable = this.resolveDependency(
        control.param.visibilityDependency as string,
        control.param.objId,
        formWithDependencyData
      );
    } else {
      visibleObservable = of(null);
    }

    forkJoin([
      requiredObservable,
      editableObservable,
      visibleObservable,
    ]).subscribe((results) => {
      const required = results[0];
      const editable = results[1];
      const visible = results[2];

      // console.log('XXXXXXXXXXXXXXXXXXXXXX Vyhodnocení control: ' + control.param.name + ' required: ' +
      //   required + ' editable: ' + editable + ' visible: ' + visible);

      if (required !== null) {
        control.param?.setRequired(required);
        // Pokud není povinný, smažu všechny validátory a musím znovu nastavit validátory podle typu parametru
        control.clearValidators();
        const valrs = this.paramControl.getDomainValidators(
          required,
          control.param
        );
        control.setValidators(valrs.validators);
        control.setAsyncValidators(valrs.asyncValidators);
      }

      if (editable || required) {
        control.enable();
      } else {
        control.disable();
      }

      // Ještě je potřeba vyhodnotit obecnou editovatelnost (např. u procesů které nemám právo editovat)
      if (!control.param?.editable) {
        control.disable();
      }

      if (visible || editable || required) {
        control.show();
      } else {
        control.hide();
      }
    });

    return;
  }

  // Zajistí vytvoření options pro všechny parametry, které jsou selecty a mají nějakou závislost
  public loadOptions(
    initiator: string,
    formWithDependencyData: {},
    param?: ParamBase
  ): void {
    const additionalData = {
      USER_ID: this.session.user?.id,
    };
    param?.loadOptions(initiator, formWithDependencyData, additionalData);
  }

  public setFormChanged(changed: boolean): void {
    this.formChanged = changed;
  }

  public getFormChanged(): boolean | undefined {
    return this.formChanged;
  }
}
