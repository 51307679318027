import { Component, ViewChild } from '@angular/core';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { ProtectedDataCategoryService } from '@isp-sc/shared/segments/protected-data/data-access';
import { ProtectedDataCategoryConsentAddService } from '@isp-sc/shared/segments/protected-data/data-access';
import { ProtectedDataCategoryConsentRecallService } from '@isp-sc/shared/segments/protected-data/data-access';
import { ForgetTableComponent } from '@isp-sc/shared/ui';
import { DialogConfig, DialogService } from '@og_soft/dialog';
import { ProtectedDataRecallEraseDialogComponent } from '../protected-data-recall-erase-dialog/protected-data-recall-erase-dialog.component';
import { UserDataEditService } from '@isp-sc/shared/segments/user/data-access';
import { ProtectedDataCategory } from '@isp-sc/shared/segments/protected-data/common';
import { MatCheckbox } from '@angular/material/checkbox';
import { ProtectedDataLevel3DialogComponent } from '../protected-data-level-3-dialog/protected-data-level-3-dialog.component';

@Component({
  selector: 'app-protected-data',
  templateUrl: './protected-data.component.html',
  styleUrls: ['./protected-data.component.scss'],
})
export class ProtectedDataComponent {
  constructor(
    public dataService: ProtectedDataCategoryService,
    public session: SessionService,
    public protectedDataCategoryConsentAdd: ProtectedDataCategoryConsentAddService,
    public protectedDataCategoryConsentRecall: ProtectedDataCategoryConsentRecallService,
    private dialog: DialogService,
    private userEditService: UserDataEditService
  ) {
    this.session.processingSet(true);
    // Zobrazené kategorie filtruji podle typu uživatele
    userEditService.getSingleton({}, 600).subscribe((user) => {
      this.filterValues = { userType: user.type };
      this.tablePrepared = true;
      this.session.processingSet(false);
    });
  }

  public dataCat: any;
  public tablePrepared = false; // Dokud nemám data podle kterých chci filtrovat, nemá cenu zobrazovat tabulku.
  filterValues: any = {};

  @ViewChild('tbl', { static: false }) tbl?: ForgetTableComponent;

  consentSlideToggled(
    r: ProtectedDataCategory,
    changeEvent: MatCheckbox
  ): void {
    if (changeEvent.checked) {
      // Udělení souhlasu
      this.consentAdd(r.catId);
    } else {
      // Odvolání souhlasu
      if (r.catLawfulness === 2) {
        // Pro jistotu... sem se to ale nemůže dostat.
        console.log(
          'Pokus odvolat vyžadovaný souhlas (lawfullnes==2). To ani nejde.'
        );
        return;
      } else if (!r.catEraseInactive && r.catManualErase === 0) {
        this.consentRecall(r.catId, false);
      } else if (!r.catEraseInactive && r.catManualErase === 1) {
        this.consentRecallErase(r);
      } else if (r.catEraseInactive === 1 && r.catManualErase === 1) {
        this.consentRecallInactive(r);
      }
    }
  }

  consentRecallInactive(data: any): void {
    this.dataCat = data;
    const dconf = new DialogConfig();
    dconf.data = { data };
    const dref = this.dialog.open(
      ProtectedDataRecallEraseDialogComponent,
      dconf
    );
    dref.afterClosed.subscribe((result) => {
      console.error('Tady mam data ', result);
      this.tbl?.fetchAgain();
    });
  }

  consentRecallErase(data: any): void {
    this.dataCat = data;
    const dconf = new DialogConfig();
    dconf.data = { data };
    const dref = this.dialog.open(
      ProtectedDataRecallEraseDialogComponent,
      dconf
    );
    dref.afterClosed.subscribe((result) => {
      console.error('Tady mam data ', result);
      if (result) {
        this.protectedDataCategoryConsentRecall
          .post({
            pdcCatId: result.data.catId,
          })
          .subscribe(
            () => {
              this.session.message(
                $localize`:@@ProtectedData.consentRecall.message.ok:Souhlas byl odvolán.`
              );
              this.tbl?.fetchAgain();
            },
            () => {
              this.session.message(
                $localize`:@@ProtectedData.consentRecall.message.error:Nepodařilo se odvolat souhlas.`
              );
            }
          );
      }
    });
  }

  displayLevel3Info(cat: any): void {
    const dconf = new DialogConfig();
    dconf.data = {
      infoText: cat.catInfoLevel3,
    };
    this.dialog.open(ProtectedDataLevel3DialogComponent, dconf);
  }

  consentAdd(catId: number): void {
    if (catId) {
      this.protectedDataCategoryConsentAdd
        .post({
          pdcCatId: catId,
        })
        .subscribe(() => {
          this.session.message(
            $localize`:@@ProtectedData.consentAdd.message:Souhlas byl udělen.`
          );
          this.tbl?.fetchAgain();
        });
    }
  }

  logout(): void {
    this.session.logout();
  }

  consentRecall(catId: number, log: boolean): void {
    if (catId) {
      this.protectedDataCategoryConsentRecall
        .post({
          pdcCatId: catId,
        })
        .subscribe(() => {
          this.session.message(
            $localize`:@@ProtectedData.consentRecall.message:Souhlas byl odvolán.`
          );
          this.tbl?.fetchAgain();
          if (log) {
            this.logout();
          }
        });
    }
  }

  /**
   * Callback po rozbalení panelu
   *
   * Potřebujeme, aby si tabulka potom, co doběhne animace, aktualizovala
   * výšku řádku (sic jinak se panel rozbalí, ale jeho spodní okraj zmizí
   * za spodním okrajem řádku tabulky).
   */
  afterPanelExpanded(): void {
    this.tbl?.vscroller?.invalidateAllCachedMeasurements();
  }
}
