import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Tento validátor odpovídá validátoru TBornNumValidator z frontendu.
 */
export function personalIdValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }

    const born = (control.value as string).replace('/', '').replace(' ', '');

    let year: number;

    if (born.length === 9) {
      year = +born.substring(0, -7);
      if (year >= 54) {
        return { bornNum: { value: control.value } };
      } else {
        return null;
      }
    } else if (born.length === 10) {
      if (+born / 11 === Math.floor(+born / 11)) {
        return null;
      } else {
        return { bornNum: { value: control.value } };
      }
    }
    return { bornNum: { value: control.value } };
  };
}
