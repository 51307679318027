<div class="protected-data-form">
  <ng-container *ngIf="protectedDataCategories">
    <div *ngFor="let category of protectedDataCategories; let i = index">
      <div
        *ngIf="userTypeValid(category.userType)"
        class="protected-data-category-info"
      >
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="gdpr-eu-flag">
                  <img
                    style="height: 40px"
                    src="assets/GDPR_lock.png"
                    alt="GDPR_lock"
                  />
                </div>
                <div
                  class="gdpr-level1-text"
                  [innerHTML]="category.catInfoLevel1"
                ></div>

                <div class="consent-controls">
                  <ng-container
                    *ngIf="
                      category.catLawfulness === 1;
                      else consentPresentDummyButton
                    "
                  >
                    <mat-icon
                      *ngIf="!category.catConsent"
                      class="toggle-icon off"
                      color="warn"
                      i18n-matTooltip="@@UserProfile.event.consentAdd"
                      matTooltip="Udělit souhlas"
                      (click)="
                        saveConsent(category.catId); $event.stopPropagation()
                      "
                      >toggle_off</mat-icon
                    >

                    <mat-icon
                      *ngIf="category.catConsent"
                      class="toggle-icon disabled"
                      color="accent"
                      i18n-matTooltip="@@UserProfile.event.info.stopPropagation"
                      matTooltip="Souhlas je nyní udělen. Odvolat ho můžete na stránce „Chráněné údaje“."
                      (click)="$event.stopPropagation()"
                      >toggle_on</mat-icon
                    >
                  </ng-container>

                  <ng-template #consentPresentDummyButton>
                    <mat-icon
                      class="toggle-icon disabled"
                      color="accent"
                      i18n-matTooltip="@@UserProfile.event.stopPropagation"
                      matTooltip="Chráněné údaje zpracováváme od okamžiku jejich poskytnutí."
                      (click)="$event.stopPropagation()"
                      >toggle_on</mat-icon
                    >
                  </ng-template>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div
              *ngIf="category.catInfoLevel2"
              [innerHTML]="category.catInfoLevel2"
              class="mango-text-secondary"
            ></div>
            <p>
              <a
                href="javascript:void(0)"
                (click)="displayProtectedDataLevel3Info(category)"
                i18n="@@UserProfile.button.link.level3.info"
                >Více informací</a
              >
            </p>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </ng-container>
</div>
