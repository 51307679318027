<sc-main-toolbar>
  <!--  <h1 i18n="@@menu.user-payments">Mé platby</h1>-->
  <!--  -->
  <!--  <div actions>-->
  <!--    <button mat-icon-button (click)="tableFilters.toggleFiltering(filterValues); $event.stopPropagation()">-->
  <!--      <mat-icon>filter_list</mat-icon>-->
  <!--    </button>-->
  <!--  </div>-->
</sc-main-toolbar>

<div class="mango-table-page-contents">
  <h1 i18n="@@menu.user-payments">Mé platby</h1>
  <sc-page-caption-block></sc-page-caption-block>

  <mgt-header [filterValues]="filterValues" [tab]="tab!">
    <mgt-filters
      #tableFilters
      [formGroup]="filters"
      (applyFilters)="filterValues = $event"
    >
      <div class="filter-row">
        <mat-form-field class="mango-form-field">
          <datetime-control
            [pickerType]="DatetimeControlPickerType.calendar"
            formControlName="dateFrom"
            name="dateFrom"
            #df1
            i18n-placeholder="@@UserPayments.filters.dateFrom.placeholder"
            placeholder="Od"
            [max]="filters.get('dateTo').value"
          ></datetime-control>
          <datetime-control-trigger
            matSuffix
            [for]="df1"
          ></datetime-control-trigger>
          <mat-error *ngIf="filters.controls.dateFrom.errors">{{
            formHelper.getErrorMessage(filters.get('dateFrom'))
          }}</mat-error>
        </mat-form-field>

        <mat-form-field class="mango-form-field">
          <datetime-control
            [pickerType]="DatetimeControlPickerType.calendar"
            formControlName="dateTo"
            name="dateTo"
            #df2
            i18n-placeholder="@@UserPayments.filters.dateTo.placeholder"
            placeholder="Do"
            [min]="filters.get('dateFrom').value"
            [errorStateMatcher]="errorMatcher"
          ></datetime-control>
          <datetime-control-trigger
            matSuffix
            [for]="df2"
          ></datetime-control-trigger>
          <mat-error *ngIf="filters.controls.dateTo.errors">{{
            formHelper.getErrorMessage(filters.get('dateTo'))
          }}</mat-error>
          <mat-error *ngIf="filters.errors">{{
            formHelper.getErrorMessage(filters.get('dateTo'), filters)
          }}</mat-error>
        </mat-form-field>
      </div>
    </mgt-filters>
  </mgt-header>

  <mgt-table #tbl [service]="dataService" [filters]="filterValues">
    <mgt-row
      [replacing-detail]="false"
      *ngFor="let r of tbl.scrollItems"
      [record]="r"
    >
      <mgt-cell-group master>
        <mgt-cell
          i18n-title="@@UserPayments.record.typePayment.title"
          title="Typ platby"
          role="title"
        >
          {{ r.typePayment }}
        </mgt-cell>
        <mgt-cell
          i18n-title="@@UserPayments.record.datePayment.title"
          title="Datum platby"
          class="mango-no-wrap"
          role="result"
        >
          {{ r.datePayment | date : 'd. M. yyyy' }}
        </mgt-cell>
      </mgt-cell-group>
      <mgt-cell-group>
        <mgt-cell
          i18n-title="@@UserPayments.record.amountPayment.title"
          title="Částka"
          role="result"
          class="cell-monetary"
        >
          {{ r.amountPayment | currency : getCurrencySymbol() }}
        </mgt-cell>
      </mgt-cell-group>

      <div detail>
        <div *ngIf="r.vsPayment" class="detail-field">
          <div class="mat-caption">
            <span i18n="@@UserPayments.record.vsPayment.caption"
              >Variabilní symbol</span
            >
          </div>
          <div class="value">{{ r.vsPayment }}</div>
        </div>

        <div *ngIf="r.notePayment && notePaymentVisible()" class="detail-field">
          <div class="mat-caption">
            <span i18n="@@UserPayments.record.notePayment.caption"
              >Poznámka</span
            >
          </div>
          <div class="value">{{ r.notePayment }}</div>
        </div>

        <br *ngIf="r.service" />
        <span *ngIf="r.service" class="mat-caption"
          ><span i18n="@@UserPayments.service.caption"
            >Uhrazené služby</span
          ></span
        >
        <mgt-row
          *ngFor="let sa of castToAny(r.service)"
          [expandable]="false"
          [record]="sa"
        >
          <mgt-cell-group master>
            <mgt-cell role="title"
              >{{ sa.name || 'Služba' }}
              {{ sa.paramIdent ? '(' + sa.paramIdent + ')' : '' }}</mgt-cell
            >
            <mgt-cell mobile-only
              >{{ sa.periodStart | date : 'd. M. yyyy' }}
              {{ sa.periodEnd | date : ' - d. M. yyyy' }}</mgt-cell
            >
          </mgt-cell-group>
          <mgt-cell-group mobile-only>
            <mgt-cell role="result" class="cell-monetary">{{
              sa.amount | currency : getCurrencySymbol()
            }}</mgt-cell>
          </mgt-cell-group>
          <mgt-cell-group desktop-only>
            <mgt-cell
              >{{ sa.periodStart | date : 'dd. MM. yyyy' }}
              {{ sa.periodEnd | date : ' - dd. MM. yyyy' }}</mgt-cell
            >
          </mgt-cell-group>
          <mgt-cell-group desktop-only>
            <mgt-cell role="result" class="cell-monetary">{{
              sa.amount | currency : getCurrencySymbol()
            }}</mgt-cell>
          </mgt-cell-group>
        </mgt-row>

        <!--
         <pre>{{r|json}}</pre>
        -->
      </div>

      <mgt-actions>
        <a
          mat-button
          *ngIf="r.pDoc"
          [href]="printUtil.docLinkUrl(r.pDoc, r.plIdHash)"
          target="_blank"
          ><mat-icon>print</mat-icon>
          <span i18n="@@UserPayments.printCash.button">Pokladní doklad</span></a
        >
        <a
          mat-button
          *ngIf="r.expDoc"
          [href]="printUtil.docLinkUrl('cd', r.expDoc)"
          target="_blank"
          ><mat-icon>print</mat-icon>
          <span i18n="@@UserPayments.print.button">Zálohový doklad</span></a
        >
      </mgt-actions>
    </mgt-row>

    <div no-data-alternate-content>
      <page-empty
        i18n-message="@@UserPayments.PageEmpty.message"
        message="Není co zobrazit."
      ></page-empty>
    </div>
  </mgt-table>
</div>
