import { Injectable } from '@angular/core';
import { DataBase } from '@og_soft/data-base';

@Injectable({
  providedIn: 'root',
})
export class UserRegistrationService extends DataBase<any> {
  protected getAllUrl(): string {
    return 'registration' as const;
  }
}
