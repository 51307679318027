<div [innerHTML]="guide"></div>
<dialog-actions>
  <button
    mat-flat-button
    dialog-close
    i18n="
      Zavřít|Tlačítko na zavření dialogu v dialogu při uzavření
      ticketu@@ProcessFinish.button.Close"
  >
    Zavřít
  </button>
</dialog-actions>
