import { Injectable } from '@angular/core';
import { ProtectedDataCategoryGeneralService } from './protected-data-category-general.service';

@Injectable({
  providedIn: 'root',
})
export class ProtectedDataCategoryService extends ProtectedDataCategoryGeneralService {
  protected getAllUrl(): string {
    return 'protected-data-categories' as const;
  }
}
