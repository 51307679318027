import { Injectable } from '@angular/core';
import { DataBaseServiceConfig, GetallResult } from '@og_soft/data-base';
import { HttpClient } from '@angular/common/http';
import { CachingInterceptor } from '@og_soft/cache';
import {
  DataNodesService,
  DataUserServicesService,
} from '@isp-sc/shared/segments/data/data-access';
import { DataNodeParamsService } from '@isp-sc/shared/segments/csob/data-access';
import { RecNode } from '@isp-sc/shared/segments/data/common';

@Injectable({
  providedIn: 'root',
})
export class UserNodesService extends DataNodesService {
  constructor(
    public override http: HttpClient,
    serviceConfig: DataBaseServiceConfig,
    public saService: DataUserServicesService,
    public dataNodeParamsService: DataNodeParamsService,
    private cachingInterceptor: CachingInterceptor
  ) {
    super(http, serviceConfig, cachingInterceptor);
  }

  protected override dataPostprocess(
    data: GetallResult<RecNode>
  ): GetallResult<RecNode> {
    // Pozor toto sice dotáhne data z dalších servis, ale je to asynchronní. Tedy v závislosti na rychlosti odezvy se
    // nejprve zobrazí data service první a až po čase se dotáhnou data service druhé.
    let ids: number[] = [];
    for (const node of data.data) {
      ids = ids.concat(node.saIds);
      // Dotažení parametrů. Tahat je  v samotném dotaze bylo příliš komplikované. tad yse naví data zacachují a
      // při otevření dialogu se nemusejí načítat znovu.
      // Zjistím si u toho i jestli má zařízení nějaké editovatelné parametry, kvůli zobrazení tlačítka na editaci.
      this.dataNodeParamsService
        .getParamsForView({ id: node.id, defId: undefined }, 600)
        .subscribe((params) => {
          node.params = params;
          let editable = false;
          for (const param of node.params) {
            if (param.editable) {
              editable = true;
            }
          }
          node.editable = editable;
        });
    }

    // Natažení služeb
    this.saService
      .getall({ saIds: ids.filter((v, i, a) => a.indexOf(v) === i).join(',') })
      .subscribe((d) => {
        data.data.map((rec) => {
          for (const node of d.data) {
            if (rec.saIds.indexOf(node.saId) >= 0) {
              if (!rec.sa) {
                rec.sa = [];
              }
              rec.sa.push(node);
            }
          }
        });
        this.onDataCollected.emit();
        return data;
      });

    return super.dataPostprocess(data);
  }
}
