import { Injectable } from '@angular/core';
import { DataBase } from '@og_soft/data-base';
import { UserData } from '@isp-sc/shared/segments/user/common';

@Injectable({
  providedIn: 'root',
})
export class UserDataEditService extends DataBase<UserData> {
  protected getAllUrl(): string {
    return 'eu-user' as const;
  }
}
