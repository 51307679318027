import { Component } from '@angular/core';

@Component({
  selector: 'scloud-popup-option-label',
  template: `<ng-content></ng-content>`,
  styles: [
    `
      :host {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        line-break: anywhere;
      }
    `,
  ],
})
export class PopupOptionLabelComponent {}
