import { inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateFn,
} from '@angular/router';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';

export const AuthGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean => {
  const session = inject(SessionService);
  const router = inject(Router);
  const url: string = state.url;

  if (session.isLoggedIn()) {
    if (session.canView(next.routeConfig?.path ?? '')) {
      return true;
    }
    router.navigate(['/unauthorized']);
    return false;
  }

  session.redirectUrl = url;

  router.navigate(['/login']);
  return false;
};
