import { Injectable } from '@angular/core';
import { DataBase } from '@og_soft/data-base';
import { ProcessParamDef } from '@isp-sc/shared/segments/process/common';

@Injectable({
  providedIn: 'root',
})
export class ProcessParamsDefService extends DataBase<ProcessParamDef> {
  protected getAllUrl(): string {
    return 'process-params-def' as const;
  }
}
