import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { InstallationConfigService } from '@isp-sc/shared/data-access';
import { SC_CONFIGURATION, ScConfiguration } from '@isp-sc/shared/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  message?: string;
  hidePassword = true;
  displayRegistration = false;
  public appVersion: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public session: SessionService,
    private installationConfigService: InstallationConfigService,
    @Inject(SC_CONFIGURATION) public scConfiguration: ScConfiguration
  ) {
    this.appVersion =
      'App version: ' +
      scConfiguration.appSetupName +
      ' ' +
      scConfiguration.version +
      ', built at ' +
      scConfiguration.buildDate +
      ', last revision: ' +
      scConfiguration.vcsLastCommit;

    // Před přesměrováním na login page mohlo dojít k nějaké chybě a v session
    // mohlo zůstat načítání. Pro jistotu ho tedy tady ukončím.
    this.session.processingSet(false);
    this.installationConfigService
      .displayRegistration()
      .subscribe((display) => {
        this.displayRegistration = display;
      });
    this.loginForm = this.formBuilder.group({
      login: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    // Při zobrazení login page musím vypláchnout chace, jinak mi v některých servisách (v závislosti na
    // tom kde mám nastavené cachování) můžou zůstat zacachovaná data dříve přihlášeného uživatele.
    // Vypláchnutí cache - cache v DataBase tříde má statickou metodu na propláchnutí kompletní cache.
    // Dostávám se k ní trochu přes ruku, prostřednictvím jakékoli třídy která dědí DataBase,
    // bylo by lepší mít ji jako statickou v DataBase. Musím navíc uměle potlačit ts chybu,
    // nicméně to funguje.

    this.installationConfigService.clearCache();
  }

  onSubmit(): void {
    this.submitted = true;

    this.session.closeMessage(); // Aby mi při zalogování vždy zmizelo upozornění, že došlo k odhlášení

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.session.processingSet(true);
    this.session
      .login(
        this.loginForm.controls['login'].value,
        this.loginForm.controls['password'].value
      )
      .pipe(first())
      .subscribe(
        (data) => {
          this.session.processingSet(false);
          this.router.navigateByUrl(this.solveRedirect());
        },
        (error) => {
          this.session.message(
            $localize`:@@ScLogin.login.error.message:Špatné jméno nebo heslo`
          );
          this.session.processingSet(false);
        }
      );
  }

  register(): void {
    this.router.navigateByUrl(SessionService.MAIN_PAGE);
  }

  private solveRedirect(): string {
    let redirect: string;
    if (this.session.redirectUrl && this.session.redirectUrl !== '/login') {
      redirect = this.session.redirectUrl;
    } else {
      redirect = SessionService.MAIN_PAGE;
    }
    this.session.redirectUrl = undefined;
    return redirect;
  }
}
