import { Injectable } from '@angular/core';
import { DataParamsProcessGeneralService } from './data-params-process-general-service';

@Injectable({
  providedIn: 'root',
})
export class DataParamsNewProcessService extends DataParamsProcessGeneralService {
  protected getAllUrl(): string {
    return 'process-params-def' as const;
  }
}
