import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { first, tap } from 'rxjs/operators';
import {
  CheckDbService,
  SessionService,
} from '@isp-sc/shared/segments/session/data-access';
import { UnavailableService } from '@isp-sc/shared/segments/process/data-access';
import '@angular/localize/init';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'ISP Demo - samoobsluha';

  public theme = environment.theme || 'default-theme';

  constructor(
    public session: SessionService,
    private checkDbService: CheckDbService,
    private unavailable: UnavailableService
  ) {
    this.session.processingSet(true, '');
    this.checkDbService
      .isDbReady()
      .pipe(
        first(),
        tap({
          next: (ready) => {
            if (ready) {
              this.session.checkLogin();
            } else {
              this.unavailable.startUnavailable();
            }
          },
          error: (error) => {
            this.unavailable.startUnavailable();
          },
        })
      )
      .subscribe();
  }
}
