<div style="display: block">
  <canvas
    baseChart
    [width]="width"
    [height]="height"
    [data]="data"
    [options]="options"
    [type]="type"
    [legend]="false"
  >
  </canvas>
</div>
