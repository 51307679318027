import {Component, ElementRef, Input, OnInit, ViewChild, HostBinding} from '@angular/core';
import {ForgetTableRowComponent} from '../forget-table-row/forget-table-row.component';

@Component({
  selector: 'mgt-actions',
  template: `
    <div #envelope [ngClass]="{
      envelope: true,
      scrollable: scrollable
    }">
      <div #container class="container"
           (panstart)="onPanStart($event)"
           (panmove)="onPanMove($event)"
           (panend)="onPanEnd($event)"
           [style.left.px]="posX"
      >
        <div *ngIf="scrollable" class="actions-scroll-left">
          <div class="actions-scroll-arrow"></div>
        </div>
        <ng-content select=":not([detail-only])"></ng-content>
        <div *ngIf="scrollable" class="actions-scroll-right">
          <div class="actions-scroll-arrow"></div>
        </div>
      </div>
    </div>

    <span *ngIf="!row.collapseFinished">
      <ng-content select="[detail-only]"></ng-content>
    </span>
    <!-- Vrchnost nechce:
    <button
            *ngIf="row.expandable && row.collapseFinished"
            mat-button
            (click)="moreOnClick($event)">
      Podrobnosti
      <mat-icon>more_vert</mat-icon>
    </button>
    -->
  `,
  styleUrls: [`./mgt-actions.component.scss`],
})
export class MgtActionsComponent implements OnInit {
  private _scrollable = false;
  @Input()
  get scrollable(): boolean {
    return this._scrollable;
  }
  set scrollable(value: boolean) {
    this._scrollable = value;
  }

  constructor(public row: ForgetTableRowComponent
              // , private elementRef: ElementRef
              ) { }

  ngOnInit() {
    // Inform row we belong to that ve are here.
    this.row.hasActions = true;
  }

  ngAfterContentChecked() {
    this.empty = !(this.container && this.container.nativeElement.children.length);
  }

  @ViewChild('envelope', {static: false}) envelope?: ElementRef;
  @ViewChild('container', {static: false}) container?: ElementRef;

  @HostBinding('class.there-is-no-action') empty = true;
  /*
  public get empty() {
    return !(this.container && this.container.nativeElement.children.length);
  }
   */

  moreOnClick(event:Event) {
    if (this.row.expandable) {
      this.row.expanded = true;
    }
  }

  /* Vodorovné posouvání scrollovatelných akcí */
  posX = 0; // Aktuální pozice
  private startX = 0; // Pozice při začátku PAN (během PAN jsou v události relativní souřadnice k tomuto začátku).

  /**
   *  Vrací maximální možný posun vlevo. Jinými slovy minimální Xovou souřadnicí výsledné pozice akcí.
   */
  get panMin(): number {
    return this.envelope?.nativeElement.clientWidth - this.container?.nativeElement.clientWidth;
  }

  /**
   * Je potřeba jen poznamenat počáteční pozici. Následné události mají relativní souřadnice k tomuto počátku.
   * @param event
   */
  onPanStart(event: any): void {
    if (this.scrollable) {
      this.startX = this.posX;
    }
  }

  /**
   * Zpracování posunu během posouvání. Jsou hlídány meze, ty se mohou o něco překročit, aby bylo jasné, že se dál nemá
   * již posouvat.
   * @param event
   */
  onPanMove(event: any): void {
    if (this.scrollable) {
      event.preventDefault();
      let newX = this.startX + event.deltaX;
      const margin = this.envelope?.nativeElement.clientWidth * 0.8;
      if (newX > margin) {
        newX = margin;
      } else if (newX < this.panMin - margin) {
        newX = this.panMin - margin;
      }
      this.posX = newX;
    }
  }

  /**
   * Finální ukončení posouvání. Pokud se posunulo moc, tak se akce musí zarovnat - to je poslední korekce.
   * Zatím není implementování zarovnání "na celou akci", nebylo by úplně jednoduché.
   * @param event
   */
  onPanEnd(event: any): void {
    if (this.scrollable) {
      if (this.posX > 0) {
        this.posX = 0;
      } else if (this.posX < this.panMin) {
        this.posX = this.panMin;
      }
    }
  }
}
