import { Component } from '@angular/core';
import { DialogRef } from '@og_soft/dialog';
import { first } from 'rxjs/operators';
import { interval, Subscription } from 'rxjs';
import {
  CheckDbService,
  SessionService,
} from '@isp-sc/shared/segments/session/data-access';

@Component({
  selector: 'app-unavailable-page',
  templateUrl: './unavailable-page.component.html',
  styleUrls: ['./unavailable-page.component.scss'],
})
export class UnavailablePageComponent {
  private refreshSubscription: Subscription;

  constructor(
    private dlgRef: DialogRef,
    private checkDbService: CheckDbService,
    public session: SessionService
  ) {
    this.refreshSubscription = interval(10000).subscribe(() => {
      console.log('Zkouším jestli už se můžu připojit.');
      this.checkDb();
    });
  }

  reload(): void {
    this.session.processingSet(true);
    this.checkDb();
  }

  private checkDb(): void {
    this.checkDbService
      .isDbReady()
      .pipe(first())
      .subscribe(
        (ready) => {
          if (ready) {
            console.log('XXXXXXXXXXXXXXXXXX už se můžu připojit');
            this.refreshSubscription.unsubscribe();
            this.dlgRef.close();
            this.session.checkLogin();
          } else {
            this.session.processingSet(false);
          }
        },
        () => {
          this.session.processingSet(false);
        }
      );
  }
}
