<mat-card
  appearance="outlined"
  *ngIf="displayType === 'card'"
  class="display-type-card"
>
  <mat-card-header>
    <mat-card-title>{{ title }}</mat-card-title>
    <!-- <mat-card-subtitle *ngIf="subtitle">{{subtitle}}</mat-card-subtitle> -->
  </mat-card-header>
  <mat-card-content>
    <div class="pane-flex">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </mat-card-content>

  <!-- Jedna varianta editačního tlačítka - dole standardními akcemi karty -->
  <!--
  <mat-card-actions *ngIf="canEdit">
    <button mat-button>
      <mat-icon>edit</mat-icon> Upravit
    </button>
  </mat-card-actions>
  -->

  <!-- Druhá varianta editačního tlačítka - fab vpravo nahoře -->
  <button
    [disableRipple]="true"
    mat-button
    *ngIf="canEdit || session.isConfigUser()"
    class="pane-edit"
    (click)="editButtonClicked($event)"
  >
    <!-- <mat-icon>{{editBtnIcon || 'edit'}}</mat-icon> -->
    {{ editBtnText || editUserProfile() }}
    <app-config-setting
      [fullName]="'SELFCARE.user-profile.view.' + name + '.button.edit'"
    ></app-config-setting>
  </button>
</mat-card>

<mat-expansion-panel *ngIf="displayType === 'pane'" class="display-type-pane">
  <mat-expansion-panel-header>
    <mat-panel-title>{{ title }}</mat-panel-title>
    <mat-panel-description *ngIf="subtitle">{{
      subtitle
    }}</mat-panel-description>
  </mat-expansion-panel-header>

  <div
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutGap="20px"
    fxLayoutAlign="start stretch"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</mat-expansion-panel>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
