import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  DataBillingService,
  DataUserServicesService,
} from '@isp-sc/shared/segments/data/data-access';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { DialogConfig, DialogService } from '@og_soft/dialog';
import {
  FormHelperService,
  UtilPrintService,
} from '@isp-sc/shared/data-access';
import { ForgetTableComponent } from '@isp-sc/shared/ui';
import { CardPaymentComponent } from '@isp-sc/isp-sc/segments/selfcare/features';
import { CrossFieldErrorMatcher } from '@isp-sc/shared/segments/selfcare/common';
import { MangoFormControl } from '@isp-sc/shared/segments/mango-processes/common';
import {
  dateRangeValidate,
  DatetimeControlPickerType,
} from '@og_soft/datetime-control';

@Component({
  selector: 'app-eu-billing',
  templateUrl: './eu-billing.component.html',
  styleUrls: ['./eu-billing.component.scss'],
})
export class EuBillingComponent implements OnInit {
  protected readonly DatetimeControlPickerType = DatetimeControlPickerType;

  constructor(
    private route: ActivatedRoute,
    public dataService: DataBillingService,
    public printUtil: UtilPrintService,
    public session: SessionService,
    public dialog: DialogService,
    public dataUserServices: DataUserServicesService,
    public formHelper: FormHelperService
  ) {
    this.filters = new FormGroup(
      {
        dateFrom: new MangoFormControl(''),
        dateTo: new MangoFormControl(''),
        saIds: new MangoFormControl(''),
      },
      { validators: dateRangeValidate('dateFrom', 'dateTo') }
    );
  }

  filters: FormGroup;
  filterValues: any = {};

  private subParams: any;

  @ViewChild('tbl', { static: false }) public tab?: ForgetTableComponent;

  @ViewChild('formCardPay', { static: false })
  formCardPay?: CardPaymentComponent;

  // just specific error type, otherwise it will highlight on any error within the group
  errorMatcher = new CrossFieldErrorMatcher(['dateRangeInvalid']);

  ngOnInit(): void {
    this.filters.patchValue(this.filterValues);

    this.subParams = this.route.params.subscribe((params) => {
      this.filterValues = params['saIds'] ? { saIds: params['saIds'] } : {};
      this.filters.patchValue(this.filterValues);
    });
  }

  getCurrencySymbol(): string {
    return this.session.locDefaults.currencyCode;
  }

  debt(amountPay: number): number {
    if (amountPay > 0) {
      return 1;
    }
    return 0;
  }

  payment(data: any): void {
    const dconfp = new DialogConfig();
    dconfp.data = {
      amount: data.amountPay,
      varsym: data.nameTax,
      typeAttach: null,
      saId: 0,
    };
    //    this.formCardPay.itemDisabled("amount");
    //    this.formCardPay.itemDisabled("varsym");

    const dref = this.dialog.open(CardPaymentComponent, dconfp);
    dref.afterClosed.subscribe((result) => {
      // if (result)
      // console.error("Tady mam data ", result)
    });
  }

  isAfterMaturity(r: any): boolean {
    const maturity = new Date(r.dateMaturity);
    const now = new Date();
    return maturity.getTime() <= now.getTime();
  }

  typePayVisible(): boolean {
    const opt = this.session.getOption('SELFCARE.eu-billing.typePay');
    return !(opt && opt.includes('hidden'));
  }
}
