import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { UserData } from '@isp-sc/shared/segments/user/common';
import {
  FormHelperService,
  InstallationConfigService,
} from '@isp-sc/shared/data-access';
import {
  UserDataEditService,
  UserEditFormService,
} from '@isp-sc/shared/segments/user/data-access';
import { UserEditFormType } from '@isp-sc/shared/common';
import { debounceTime } from 'rxjs/operators';
import { MangoFormControl } from '@isp-sc/shared/segments/mango-processes/common';
import { dateRangeValidator} from "@isp-sc/shared/segments/selfcare/common";
import { DatetimeControlPickerType } from '@og_soft/datetime-control';

@Component({
  selector: 'app-user-edit-personal',
  templateUrl: './user-edit-personal.component.html',
  styleUrls: ['./user-edit-personal.component.scss'],
})
export class UserEditPersonalComponent implements OnInit {
  protected readonly DatetimeControlPickerType = DatetimeControlPickerType;
  public form: FormGroup;
  private userData?: UserData;
  public formName: string;

  constructor(
    public session: SessionService,
    public formHelper: FormHelperService,
    private userEditService: UserDataEditService,
    private installationConfigService: InstallationConfigService,
    private userEditFormService: UserEditFormService
  ) {
    this.formName = UserEditFormType.personal;
    this.form = this.userEditFormService.getForm(UserEditFormType.personal);
    this.initDependencies();
    this.userEditFormService.profileDefaultConfig(
      this.form,
      UserEditFormType.personal
    );
  }

  ngOnInit(): void {
    console.log('INIT', this.form.value);
    if (this.session.user) {
      this.userEditService.getSingleton({}, 1800).subscribe(
        (userData) => {
          this.userData = userData;
          this.form.patchValue(this.userData);
          console.log('INIT DATA', this.form.value);
          this.initConfiguration();
        },
        (err) => {
          console.log('Chyba při získání dat uživatelského profilu ' + err);
        }
      );
    } else {
      this.initConfiguration();
    }
  }

  private initConfiguration(): void {
    this.configureFirstName();
    this.configureLastName();
    this.configureCompanyName();
    this.configureDayOfBirth();
    this.configureCompanyVatId();
    this.configureVatRegistered();
    this.configureVatRegisteredFrom();
    if (this.session.user) {
      this.configureCompValidators(
        this.session.user.options.COMP_ID_VALIDATOR,
        this.session.user.options.COMP_FID_VALIDATOR
      );
    } else {
      this.installationConfigService
        .registrationOptions()
        .subscribe((validators) => {
          this.configureCompValidators(
            validators['COMP_ID_VALIDATOR'],
            validators['COMP_FID_VALIDATOR']
          );
        });
    }
  }

  initDependencies(): void {
    this.form
      .get('type')
      ?.valueChanges.pipe(debounceTime(300))
      .subscribe(() => {
        this.configureFirstName();
        this.configureLastName();
        this.configureCompanyName();
        this.configureDayOfBirth();
        this.configureVatRegistered();
        this.configureVatRegisteredFrom();
        this.userEditFormService.userTypeUpdate(); // A dám vědět komponentě na chráněná data
      });

    this.form
      .get('companyVatId')
      ?.valueChanges.pipe(debounceTime(300))
      .subscribe(() => {
        this.configureVatRegistered();
        this.configureVatRegisteredFrom();
      });

    this.form.get('companyFid')?.valueChanges.subscribe(() => {
      this.configureVatRegistered();
      this.configureVatRegisteredFrom();
    });

    this.form.get('vatRegistered')?.valueChanges.subscribe(() => {
      this.configureVatRegisteredFrom();
    });
  }

  private configureFirstName(): void {
    const control = this.form.get('firstName') as MangoFormControl;
    if (
      this.form.get('type')?.value === 'S' ||
      this.form.get('type')?.value === 'F'
    ) {
      control.setValidators(Validators.required);
    } else {
      control.clearValidators();
    }
  }

  private configureLastName(): void {
    const control = this.form.get('lastName') as MangoFormControl;
    if (
      this.form.get('type')?.value === 'S' ||
      this.form.get('type')?.value === 'F'
    ) {
      control.setValidators(Validators.required);
    } else {
      control.clearValidators();
    }
  }

  private configureCompanyName(): void {
    const control = this.form.get('companyName') as MangoFormControl;
    if (
      this.form.get('type')?.value === 'S' ||
      this.form.get('type')?.value === 'F'
    ) {
      control.clearValidators();
    } else {
      control.setValidators(Validators.required);
    }
  }

  private configureDayOfBirth(): void {
    const control = this.form.get('dayOfBirth') as MangoFormControl;
    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth();
    const day = d.getDate();
    const min = new Date(year - 110, month, day);
    const max = new Date(year - 18, month, day);

    if (
      (this.form.get('type')?.value === 'S' ||
        this.form.get('type')?.value === 'F') &&
      this.session.getOption('USERS.BIRTHDAY.MANDATORY') === 2
    ) {
      control.setValidators([
        Validators.required,
        dateRangeValidator(min, max),
      ]);
    } else {
      control.setValidators([dateRangeValidator(min, max)]);
    }
    control.updateValueAndValidity();
  }

  private configureCompanyVatId(): void {
    const control = this.form.get('companyVatId') as MangoFormControl;
    if (this.userData && this.userData.icoDph === '1') {
      control.show();
      control.setValidators(
        Validators.pattern(this.session.user?.options.COMP_VATID_VALIDATOR)
      );
    } else {
      control.hide();
      control.clearValidators();
    }
  }

  private configureVatRegistered(): void {
    const control = this.form.get('vatRegistered') as MangoFormControl;
    if (
      (this.form.get('type')?.value === 'F' ||
        this.form.get('type')?.value === 'P') &&
      this.userData &&
      (this.form.get('companyFid')?.value !== '' ||
        this.userData.companyFid !== '') &&
      (this.userData.icoDph === '0' ||
        (this.form.get('companyVatId')?.value !== '' &&
          this.userData.icoDph === '1'))
    ) {
      if (this.userData && this.userData.icoDph === '0') {
        if (this.form.get('companyFid')?.value === '') {
          control.disable();
          control.setValue(false);
        } else {
          control.enable();
          //          control.setValue(this.userData.vatRegistered == 1 ? true : false);
          control.setValue(this.userData.vatRegistered);
        }
        control.show();
      } else {
        control.hide();
        if (this.userData && this.userData.providerType === 'NONE') {
          control.setValue(true);
        }
      }
    } else {
      control.hide();
      if (this.userData && this.userData.providerType === 'NONE') {
        control.setValue(false);
      }
    }
  }

  private configureVatRegisteredFrom(): void {
    const control = this.form.get('vatRegisteredFrom') as MangoFormControl;
    if (
      (this.form.get('type')?.value === 'F' ||
        this.form.get('type')?.value === 'P') &&
      this.userData &&
      ((this.userData.icoDph === '0' &&
        this.form.get('vatRegistered')?.value !==
          this.userData.vatRegistered) ||
        (this.userData.icoDph === '1' &&
          ((this.form.get('companyVatId')?.value !== '' &&
            this.userData.companyVatId === '') ||
            (this.form.get('companyVatId')?.value === '' &&
              this.userData.companyVatId !== ''))))
    ) {
      control.show();
      control.setValidators([
        dateRangeValidator(
          new Date(this.userData.vatRegisteredFrom),
          undefined
        ),
      ]);
      control.setValue(this.userData.vatRegisteredFrom);
    } else {
      control.hide();
      control.clearValidators();
      control.setValue('');
    }
  }

  private configureCompValidators(
    compIdValidator: string,
    compFidValidator: string
  ): void {
    if (compIdValidator) {
      this.form
        .get('companyId')
        ?.setValidators(Validators.pattern(compIdValidator));
    } else {
      throw Error('Chybí validátor: COMP_ID_VALIDATOR');
    }

    if (compFidValidator) {
      this.form
        .get('companyFid')
        ?.setValidators(Validators.pattern(compFidValidator));
    } else {
      throw Error('Chybí validátor: COMP_FID_VALIDATOR');
    }
  }
}
