import { Inject, Injectable } from '@angular/core';
import { DataBase, DataBaseServiceConfig } from '@og_soft/data-base';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ScConfiguration,
  SC_CONFIGURATION,
  InstallationConfigData,
  FObject,
} from '@isp-sc/shared/common';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { HttpClient } from '@angular/common/http';
import { CachingInterceptor } from '@og_soft/cache';

@Injectable({
  providedIn: 'root',
})
/**
 * Servisa slouží ke konfiguraci formuláře pro registrace. Je velmi úzce uzpůsobená na získání
 * jen určitých vyjmenovaných práv (omezeno v BE). Proto i implementace je celkem proprietární.
 * Nechci zobecňovat právě proto, aby to nevedlo k tomu,ž e si budeme nezabezpečeně používat
 * nějaké větší množství práv.
 */
export class InstallationConfigService extends DataBase<any> {
  // getallUrl = 'installation-config';

  constructor(
    public override http: HttpClient,
    serviceConfig: DataBaseServiceConfig,
    private cachingInterceptor: CachingInterceptor,
    @Inject(SC_CONFIGURATION) private scConfiguration: ScConfiguration
  ) {
    super(http, serviceConfig, cachingInterceptor);
  }
  protected getAllUrl(): string {
    return 'installation-config' as const;
  }

  /**
   * Vrací objekt s konfigurací registračního formuláře.
   */
  getRegistrationFormConfig(): Observable<FObject> {
    return this.getOneBy({ ct: this.scConfiguration.ct }, 3600).pipe(
      map((data: InstallationConfigData) => {
        const sorted = data.options; // data are sorted from the database

        // Create objects from individual rights
        const filtered: FObject = {};
        let last: string | null = null;

        for (const item of sorted) {
          // For each key, only take the first value (they are sorted, the highest is first)
          if (
            item.k.startsWith(SessionService.RIGHT_BASE + '.registration') &&
            item.k !== last
          ) {
            last = item.k;
            const parts = item.k.split('.');
            let obj: any;

            if (parts.length === 3) {
              filtered[parts[2]] = item.v;
            } else {
              if (!Object.prototype.hasOwnProperty.call(filtered, parts[2])) {
                filtered[parts[2]] = {};
              }
              obj = filtered[parts[2]];
            }

            for (let j = 3; j < parts.length; j++) {
              if (j === parts.length - 1) {
                obj[parts[j]] = item.v;
              } else {
                if (!Object.prototype.hasOwnProperty.call(obj, parts[j])) {
                  obj[parts[j]] = {};
                }
              }
              obj = obj[parts[j]];
            }
          }
        }

        return filtered;
      })
    );
  }

  displayRegistration(): Observable<boolean> {
    return this.getOneBy({ ct: this.scConfiguration.ct }, 3600).pipe(
      map((data) => {
        for (const item of data.options) {
          if (item.k === SessionService.RIGHT_BASE + '.registration') {
            return item.v === '1';
          }
        }
        return false;
      })
    );
  }

  registrationOptions(): Observable<FObject> {
    return this.getOneBy({ ct: this.scConfiguration.ct }, 3600).pipe(
      map((data) => {
        const rights: FObject = {};
        for (const item of data.options) {
          rights[item.k] = item.v;
        }
        return rights;
      })
    );
  }

  getLocDef(): Observable<any> {
    return this.getOneBy({ ct: this.scConfiguration.ct }, 3600).pipe(
      map((data) => {
        return data.locDef;
      })
    );
  }
}
