import { Injectable } from '@angular/core';
import { GetallResult } from '@og_soft/data-base';
import { Observable } from 'rxjs';
import { ProtectedDataCategory } from '@isp-sc/shared/segments/protected-data/common';
import { ProtectedDataCategoryGeneralService } from './protected-data-category-general.service';

@Injectable({
  providedIn: 'root',
})
export class ProtectedDataCategoryPublicService extends ProtectedDataCategoryGeneralService {
  protected getAllUrl(): string {
    return 'protected-data-categories-public' as const;
  }

  override getall(
    params?: any,
    cacheTimeout?: number,
    urlExpandParams?: any
  ): Observable<GetallResult<ProtectedDataCategory>> {
    if (!params) {
      params = {};
    }
    params.ct = this.scConfiguration.ct; // Use 'this' to access scConfiguration
    return super.getall(params, cacheTimeout, urlExpandParams);
  }
}
