import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DataBase, DataBaseServiceConfig } from '@og_soft/data-base';
import { HttpClient } from '@angular/common/http';
import { CachingInterceptor } from '@og_soft/cache';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { ParamObjectType } from '@isp-sc/shared/segments/params/common';
import { DataValueDomainPatternService } from './data-value-domain-pattern.service';
import { DataCodeListService } from './data-code-list.service';
import { DataValueDomainService } from './data-value-domain.service';
import { ParamServices } from './param-services';
import { ParamBase } from './param-base';
import {
  ProcessPremisesService,
  ProcessServicesService,
  ProcessUnitsService,
} from '@isp-sc/shared/segments/process/data-access';
import { Process } from '@isp-sc/shared/segments/params/common';

@Injectable({
  providedIn: 'root',
})
export abstract class DataProcessGeneralService extends DataBase<Process> {
  private readonly _processChanged: BehaviorSubject<boolean>;

  constructor(
    override http: HttpClient,
    private serviceConfig: DataBaseServiceConfig,
    private cachingInterceptor: CachingInterceptor,
    protected dataValuePatterns: DataValueDomainPatternService,
    protected dataCodeList: DataCodeListService,
    protected dataValueDomain: DataValueDomainService,
    protected dataProcessPremises: ProcessPremisesService,
    protected dataProcessUnits: ProcessUnitsService,
    protected dataProcessServices: ProcessServicesService,
    protected session: SessionService
  ) {
    super(http, serviceConfig, cachingInterceptor);
    this._processChanged = new BehaviorSubject<boolean>(false);
  }

  transformDataToParams(data: Process, services: ParamServices): ParamBase[] {
    const params = [];
    let param = new ParamBase(
      {
        name: 'ML_P_ID',
        value: data.ML_P_ID ? data.ML_P_ID.toString() : null,
        objId: data.id ? data.id : undefined,
        objectType: ParamObjectType.Process,
        caption: $localize`:@@DataProcess.atribute.caption.premise:Provozovna`,
        order: Number.MIN_VALUE,
        group: { position: 4, id: null, type: null, caption: null },
        type: 'SELECT',
        domain: 'attribute::PREMISE',
        visibilityDependency: data.visibilityDependencyPremises,
        editDependency: data.editDependencyPremises,
        requireDependency: data.requireDependencyPremises,
        attribute: true,
        editable: data.editable,
      },
      services
    );
    params.push(param);

    param = new ParamBase(
      {
        name: 'ML_UNIT_ID',
        value: data.ML_UNIT_ID ? data.ML_UNIT_ID.toString() : null,
        objId: data.id ? data.id : undefined,
        objectType: ParamObjectType.Process,
        caption: $localize`:@@DataProcess.atribute.caption.unit:Místo poskytování služeb`,
        order: Number.MIN_VALUE,
        group: { position: 8, id: null, type: null, caption: null },
        type: 'SELECT',
        domain: 'attribute::UNIT',
        visibilityDependency: data.visibilityDependencyUnits,
        editDependency: data.editDependencyUnits,
        requireDependency: data.requireDependencyUnits,
        attribute: true,
        editable: data.editable,
      },
      services
    );
    params.push(param);

    param = new ParamBase(
      {
        name: 'ML_SA_IDS',
        value: data.ML_SA_IDS ? [data.ML_SA_IDS.join()] : null,
        objId: data.id ? data.id : undefined,
        objectType: ParamObjectType.Process,
        caption: $localize`:@@DataProcess.atribute.caption.services:Služby`,
        order: Number.MIN_VALUE,
        group: { position: 10, id: null, type: null, caption: null },
        type: 'SELECT', // Tento atribut je zvláštního typu, a nemá odpovídající parametrový typ
        index: 0,
        indexed: true,
        unique: true,
        domain: 'attribute::SERVICE',
        visibilityDependency: data.visibilityDependencyServices,
        editDependency: data.editDependencyServices,
        requireDependency: data.requireDependencyServices,
        attribute: true,
        editable: data.editable,
      },
      services
    );
    params.push(param);

    param = new ParamBase(
      {
        name: 'ML_NAME',
        value: data.id ? data.ML_NAME : data.typeName,
        defaultValue: data.id ? data.ML_NAME : data.typeName,
        objId: data.id ? data.id : undefined,
        objectType: ParamObjectType.Process,
        caption: $localize`:@@DataProcess.atribute.caption.name:Název`,
        order: Number.MIN_VALUE,
        group: { position: 16, id: null, type: null, caption: null },
        type: 'STRING',
        visibilityDependency: data.visibilityDependencyMlName,
        editDependency: data.editDependencyMlName,
        requireDependency: data.requireDependencyMlName,
        attribute: true,
        editable: data.editable,
      },
      services
    );
    params.push(param);

    param = new ParamBase(
      {
        name: 'ML_DESC',
        value: data.ML_DESC,
        objId: data.id ? data.id : undefined,
        objectType: ParamObjectType.Process,
        caption: $localize`:@@DataProcess.atribute.caption.description:Popis`,
        order: Number.MIN_VALUE,
        group: { position: 20, id: null, type: null, caption: null },
        type: 'TEXT',
        visibilityDependency: data.visibilityDependencyMlDesc,
        editDependency: data.editDependencyMlDesc,
        requireDependency: data.requireDependencyMlDesc,
        attribute: true,
        editable: data.editable,
      },
      services
    );
    params.push(param);

    return params;
  }

  getServices(): ParamServices {
    return {
      dataValuePatterns: this.dataValuePatterns,
      dataCodeList: this.dataCodeList,
      dataValueDomain: this.dataValueDomain,
      dataProcessPremises: this.dataProcessPremises,
      dataProcessServices: this.dataProcessServices,
      dataProcessUnits: this.dataProcessUnits,
    };
  }

  getProcessChanged(): Observable<boolean> {
    return this._processChanged;
  }

  override put(
    id: string,
    data: any,
    params?: any,
    urlExpandParams?: any
  ): Observable<Process> {
    return super.put(id, data, params, urlExpandParams).pipe(
      tap(() => {
        this._processChanged.next(true);
      })
    );
  }
}
