import { Injectable } from '@angular/core';
import { DataBase } from '@og_soft/data-base';
import { CsobIntegrationTestResult } from '@isp-sc/shared/segments/csob/common';

@Injectable({
  providedIn: 'root',
})
/**
 * Servisa pro testování platby kartou
 */
export class CsobReverseService extends DataBase<CsobIntegrationTestResult> {
  protected getAllUrl(): string {
    return 'payment-3dsecure-reverse' as const;
  }
}
