import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import {
  DataUnitsService,
  DataUserServicesService,
} from '@isp-sc/shared/segments/data/data-access';
import { filter } from 'rxjs/operators';
import { InetDataService } from '@isp-sc/shared/data-access';
import { MangoFormControl } from '@isp-sc/shared/segments/mango-processes/common';
import { BytesPipe } from '@isp-sc/shared/ui';
import { PopupOption } from '@isp-sc/shared/ui';
import { RecUnit, RecUserServices } from '@isp-sc/shared/segments/data/common';
import { ChartOptions } from 'chart.js';

@Component({
  selector: 'app-data-view',
  templateUrl: './data-view.component.html',
  styleUrls: ['./data-view.component.scss'],
})
export class DataViewComponent implements OnInit {
  protected readonly filter = filter;
  form: FormGroup;

  private _services: RecUserServices[] | null = null;

  @Input()
  public get services(): RecUserServices[] | null {
    return this._services;
  }
  public get servicesLength(): number | null {
    return this.services === null ? null : this.services.length;
  }

  @ViewChild(MatTabGroup, { static: false }) public tabGroup?: MatTabGroup;
  @ViewChild(MatTab, { static: false }) public tab?: MatTab;

  private _inetData: any[] = [];
  public monthGraph2Data: any[] = [];
  public monthGraph2Options: ChartOptions[] = [];

  constructor(
    public dataService: DataUserServicesService,
    public inetData: InetDataService,
    public unitsService: DataUnitsService
  ) {
    this.form = new FormGroup({
      ips: new MangoFormControl(''),
    });
  }

  ngOnInit() {
    this.form.controls['ips'].valueChanges.subscribe((value) => {
      this.ipChange(value);
    });

    this.dataService.getall({ scFlags: 'SCF_DATA', saStates: '0,1' }).subscribe(
      (p) => {
        this._services = p.data;
      },
      (error) => {
        console.log('DataViewComponent error', error);
      }
    );
  }

  protected tabSaIdToIndex(saId: number): number | undefined {
    let i = 0;
    for (i = 0; this.services !== null && i < this.services.length; i++) {
      if (this.services[i].saBaseId == saId) {
        return i;
      }
    }
    return undefined;
  }

  /**
   * Podle aktuálně vybrané záložky vrací id služby.
   */
  protected get tabSaId(): number | null {
    if (!this.tabGroup) {
      return null; // Asking too early.
    }

    return this.services && this.tabGroup.selectedIndex !== null
      ? this.services[this.tabGroup.selectedIndex].saBaseId
      : null;
  }

  /**
   * Vrací seznam jednotek na kterých je služba poskytována. Pokud ještě informace o jednotkách nejsou k dispozici, tak
   * je asynchronně načítá ze serveru.
   * @param index
   */

  public unitsGet(index: number | null = null): RecUnit[] | null {
    if (index === null) {
      if (!this.tabGroup) {
        return null;
      }
      index = this.tabGroup.selectedIndex;
    }
    if (index !== null && this.services) {
      if (!this.services[index].units) {
        this.unitsService
          .getall({ saId: +this.services[index].saId })
          .subscribe((units) => {
            if (this._services) {
              this._services[index].units = units.data;
            }
          });
      }

      return this.services[index].units ?? null;
    }
    return null;
  }
  /**
   * Pro zadanou záložku (identifikovanou indexem) vrací text adresy pro zobrazení v záložce.
   * @param index
   */

  public labelUnitGet(index: number): string | null {
    const units = this.unitsGet(index);
    return units?.find((u) => u !== undefined)?.addressShort ?? null;
  }

  /**
   * Vrací záznamy jednotek, na kterých je daná služba.
   */

  public get units(): RecUnit[] | null {
    return this.unitsGet();
  }

  /**
   * Vrací parametry, které jsou k zobrazení v selfCare.
   */

  public get serviceParams(): any[] | null {
    if (!this.tabGroup) {
      return null; // Asking too early.
    }
    if (this.services && this.tabGroup.selectedIndex !== null) {
      return this.services[this.tabGroup.selectedIndex].params
        .filter((p) => p.view.indexOf('selfcare') >= 0)
        .sort((a, b) => a.title.localeCompare(b.title));
    }
    return null;
  }

  public ipChange(value: number) {
    if (this.tabSaId) {
      let labels = this.inetData.inetDataLabels(this.tabSaId, 'month');
      if (labels.length && this.tab?.position) {
        this.monthGraph2DataSet(
          this.tab.position,
          labels,
          this.inetData.inetDataAccumulated(
            this.tabSaId,
            'month',
            'in',
            value === undefined
              ? 'all'
              : this.inetData.inetIps(this.tabSaId)[value]
          ),
          this.inetData.inetDataAccumulated(
            this.tabSaId,
            'month',
            'out',
            value === undefined
              ? 'all'
              : this.inetData.inetIps(this.tabSaId)[value]
          )
        );
        if (this.services !== null) {
          this.monthGraph2OptionsSet(this.services[this.tab.position].saBaseId);
        }
      }
    }
  }

  public inetIps(): PopupOption[] | null {
    return this.tabSaId
      ? this.inetData.inetIps(this.tabSaId).map((v, i) => {
          const popupOption: PopupOption = {
            id: String(i),
            name: v,
          };
          return popupOption;
        })
      : null;
  }

  public inetDataGet(saId: number, ip?: string) {
    let i = this.tabSaIdToIndex(saId);
    if (i !== undefined && !this._inetData[i]) {
      this.inetData.getone(saId).subscribe(
        (p) => {
          let labels = this.inetData.inetDataLabels(saId, 'month');
          if (labels.length) {
            this._inetData[i] = p;
            this.monthGraph2DataSet(
              i,
              labels,
              this.inetData.inetDataAccumulated(saId, 'month', 'in'),
              this.inetData.inetDataAccumulated(saId, 'month', 'out')
            );
            this.monthGraph2OptionsSet(saId);
          }
        },
        (error) => {
          console.log(
            'Error fetching inetData for service',
            saId,
            '/',
            i,
            'error',
            error
          );
        }
      );
      this.inetData.fetchData(saId);
    } else if (i !== undefined) {
      return this._inetData[i];
    }
  }

  public inetDataSumGet(saId: number, typ: string, inOut: string, ip?: number) {
    return this.inetData.inetSum(
      saId,
      typ,
      inOut,
      ip === undefined ? 'all' : this.inetData.inetIps(saId)[ip]
    );
  }

  public graphMaxYGet(saId: number) {
    let dataIn = this.inetDataSumGet(
      saId,
      'month',
      'in',
      this.form.controls['ips'].value
    );
    let dataOut = this.inetDataSumGet(
      saId,
      'month',
      'out',
      this.form.controls['ips'].value
    );

    let calculated = BytesPipe.prototype.calculate(
      Math.max(dataIn ?? 0, dataOut ?? 0)
    );
    return (
      Math.ceil(calculated.value / 9) * 10 * Math.pow(1024, calculated.scale)
    );
  }

  protected monthGraph2DataSet(
    i: number,
    labels: any,
    dataIn: any,
    dataOut: any
  ) {
    let maxIn = Math.max(...dataIn);
    let maxOut = Math.max(...dataOut);

    this.monthGraph2Data[i] = {
      labels,
      datasets: [
        {
          label: 'In',
          borderColor: 'rgba(0, 0, 255, 1)',
          backgroundColor: 'rgba(0, 0, 255, 0.1)',
          fill: maxIn > maxOut ? 1 : 'origin',
          data: dataIn,
        },
        {
          label: 'Out',
          lineTension: 0,
          pointRadius: 0,
          borderColor: 'rgba(0, 192, 0, 1)',
          backgroundColor: 'rgba(0, 192, 0, 0.1)',
          fill: maxOut >= maxIn ? 0 : 'origin',
          data: dataOut,
        },
      ],
    };
  }

  protected monthGraphDataGet(saId: number) {
    let i = this.tabSaIdToIndex(saId);
    if (i !== undefined) {
      return this.monthGraph2Data[i];
    }
    return undefined;
  }

  protected monthGraph2OptionsSet(saId: number) {
    let i = this.tabSaIdToIndex(saId);
    if (i !== undefined) {
      this.monthGraph2Options[i] = {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          x: {
            grid: {
              display: true,
            },
            ticks: {
              maxTicksLimit: 2,
              maxRotation: 0,
              display: true,
            },
          },
          y: {
            position: 'left',
            display: true,
            grid: {
              display: true,
            },
            max: this.graphMaxYGet(saId),
            ticks: {
              maxTicksLimit: 2,
              display: true,
              callback: (value: string | number) => {
                return BytesPipe.prototype.transform(value, 0);
              },
            },
          },
        },
        plugins: {
          tooltip: {
            mode: 'index',
            callbacks: {
              label: (context: any) => {
                return (
                  context.dataset.label +
                  ': ' +
                  BytesPipe.prototype.transform(context.raw)
                );
              },
            },
          },
        },
      };
    }
  }

  protected monthGraph2OptionsGet(saId: number): ChartOptions | undefined {
    let i = this.tabSaIdToIndex(saId);
    if (i !== undefined) {
      return this.monthGraph2Options[i];
    }
    return undefined;
  }

  public fupGet(saId: number) {
    const index = this.tabSaIdToIndex(saId);
    if (index !== undefined) {
      let fup = this.services === null ? null : this.services[index].fup;
      return fup;
    }
    return undefined;
  }

  private fupGraphData: any[] = [];

  public fupGraphDataGet(saId: number) {
    let i = this.tabSaIdToIndex(saId);
    if (
      i !== undefined &&
      (this.fupGraphData[i] === null || this.fupGraphData[i] === undefined)
    ) {
      let fup = this.fupGet(saId);
      if (fup !== null && fup !== undefined) {
        let colors = [];
        let data = [];
        if (fup.data > fup.limit) {
          colors = ['#0000ff', '#ff0000'];
          data = [fup.limit, fup.data - fup.limit];
        } else {
          colors = ['#0000ff'];
          data = [fup.data, fup.limit - fup.data];
        }
        this.fupGraphData[i] = {
          labels: [],
          datasets: [{ backgroundColor: colors, data: data }],
        };
      }
    }

    if (i !== undefined) {
      return this.fupGraphData[i];
    }
  }
  // Stránkovadlo si samo hlídá meze, nemusíme testovat přetečení
  public goPrevious() {
    if (this.tabGroup && this.tabGroup.selectedIndex !== null) {
      this.tabGroup.selectedIndex = (this.tabGroup.selectedIndex ?? 1) - 1;
    }
  }
  // Stránkovadlo si samo hlídá meze, nemusíme testovat přetečení
  public goNext() {
    if (this.tabGroup && this.tabGroup.selectedIndex !== null) {
      this.tabGroup.selectedIndex = (this.tabGroup.selectedIndex ?? 0) + 1;
    }
  }
}
