import { Injectable } from '@angular/core';
import { DataBase } from '@og_soft/data-base';
import { RecUserUnicreditData } from '@isp-sc/shared/segments/user/common';

@Injectable({
  providedIn: 'root',
})
export class UserDataUnicreditService extends DataBase<RecUserUnicreditData> {
  protected getAllUrl(): string {
    return 'user-unicredit' as const;
  }
}
