import { Injectable } from '@angular/core';
import { DataBase } from '@og_soft/data-base';
import { RecUnit } from '@isp-sc/shared/segments/data/common';

@Injectable({
  providedIn: 'root',
})
export class DataUnitsService extends DataBase<RecUnit> {
  protected getAllUrl(): string {
    return 'units' as const;
  }

  public static shortenAddress(address?: string): string | undefined {
    if (address) {
      return address.replace(/,\s*\d+$/, '');
    }
    return address;
  }

  protected override recordPostprocess(record: RecUnit): RecUnit {
    record.addressShort = DataUnitsService.shortenAddress(record.address);
    return super.recordPostprocess(record);
  }
}
