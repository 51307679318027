import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'page-loading',
  template: `
    <div #pageLoading class="page-loading">
      <p>{{ message }}</p>
      <mat-progress-spinner #spinner color="primary" mode="indeterminate">
      </mat-progress-spinner>
    </div>
  `,
  styleUrls: ['./page-loading.component.scss'],
})
export class PageLoadingComponent implements OnInit {
  @Input()
  message = $localize`:@@PageLoading.general.message:Získávám data, chviličku prosím`;

  @ViewChild('pageLoading', { static: true }) el?: ElementRef;

  @ViewChild('spinner', { static: true }) spinner!: MatProgressSpinner;

  ngOnInit(): void {
    const diameter: number = Math.min(
      Math.floor(this.el?.nativeElement.offsetWidth * 0.4),
      166
    );
    const stroke: number = Math.max(Math.round(diameter / 30), 4);
    this.spinner.diameter = diameter;
    this.spinner.strokeWidth = stroke;
  }
}
