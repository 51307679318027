import { Component, OnInit, ViewChild } from '@angular/core';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import {
  DataScDashboardMessagesService,
  UserDebtService,
  UserServicesStateService,
} from '@isp-sc/shared/segments/data/data-access';
import { UserDataUnicreditService } from '@isp-sc/shared/segments/user/data-access';
import { UnconfirmedAgreementsService } from '@isp-sc/shared/segments/agreement/data-access';
import { DialogConfig, DialogService } from '@og_soft/dialog';
import {
  DashboardComponent,
  PhonetCreditComponent,
} from '@isp-sc/isp-sc/segments/selfcare/features';
import { CardPaymentComponent } from '@isp-sc/isp-sc/segments/selfcare/features';
import { DataProcessTypesService } from '@isp-sc/shared/segments/mango-processes/data-access';
import { PhonetCreditGetService } from '@isp-sc/shared/segments/phonet/data-access';
import { RecScMessage } from '@isp-sc/shared/segments/data/common';
import { ProcessType } from '@isp-sc/shared/segments/mango-processes/common';

@Component({
  selector: 'app-selfcare',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  protected readonly parseInt = parseInt;

  constructor(
    public session: SessionService,
    private euMessagesService: DataScDashboardMessagesService,
    private dataUnicredit: UserDataUnicreditService,
    private phonetCredit: PhonetCreditGetService,
    private servicesState: UserServicesStateService,
    private unconfirmedAgreements: UnconfirmedAgreementsService,
    private userDebt: UserDebtService,
    public dataTypesService: DataProcessTypesService,
    public dialog: DialogService
  ) {
    this.dataTypesService.getTypesForInsert().subscribe((next) => {
      // console.log('Typy procesů pro založení nového: ',next);
      this.processTypes = next.data;
      this.relayoutDashboard();
    });
  }

  @ViewChild(DashboardComponent, { static: false })
  dashboard?: DashboardComponent;

  public euMessages: RecScMessage[] | null = null;
  public unicreditBalance?: number;
  public userOffLimit: number | null = null;
  public debtAll: number | null = null;
  public saPhonet: number | null = null;
  public ucVarSymbol: string | null = null;
  // public today: Date = new Date();
  public priorityOff = 1;
  public priorityUc = 1;
  public priorityDebt = 1;
  public priorityState = 1;
  public priorityPhonet = 1;
  public saState?: string;
  public debtAfterMaturity?: number;
  public debtToMaturity: number | null = null;
  public debtWithoutUc?: number;
  public userVs?: number;
  public phonetData: any;
  public processTypes?: ProcessType[];
  public unconfirmedAgreementsCount = null;

  relayoutDashboard(): void {
    setTimeout(() => {
      this.dashboard?.doRelayout();
    });
  }

  ngOnInit(): void {
    // Data pro "zprávy v selfcare"
    this.euMessagesService.getall().subscribe((messagesData) => {
      this.euMessages = messagesData.data;
      this.relayoutDashboard();
    });

    // PRIORITA:
    // Priorita zpráv v SC je nadefinována následovně. V SC existuje pět bloků, ve kterých jsou karty umístěny.
    // Číslo priority je vždy trojciferné, s tímže první cifra (1-5) určuje blok a další dvě cifry pořadí v bloku.
    // 5. uživatelské karty vytvořené s umístěním před kritickými kartami - pořadí si určí uživatel v definici - 501-599
    // 4. variabilní systémové karty s prioritou kritická - pořadí bude dané v kódu - 401-499
    // 3. uživatelské karty vytvořené s umístěním za kritickými kartami - pořadí si určí uživatel v definici - 301-399
    // 2. variabilní systémové karty s prioritou standardní - pořadí bude dané v kódu - 201-299
    // 1. uživatelské karty vytvořené s umístěním za standardními kartami - pořadí si určí uživatel v definici - 101-199
    //
    // Prioritu v rámci systémových karet jsme si nadefinovali následovně:
    // pevná priorita
    //  nové požadavky - standardní priorita, pořadí 6 - 201
    //  výše hovorného (Phonet) - standardní priorita, pořadí 4 - 203
    // variabilní priorita
    //  zůstatek kreditu
    //    standartní priorita, pořadí 2 - 205
    //    pokud je zůstatek < 1, tak priorita kritická  - 405
    //  počet offline dní
    //    standartní priorita, pořadí 3 - 204
    //    pokud je počet > 90% z option UNICREDIT.ACTUALPAID.OFFLINE.LIMIT, tak priorita kritická - 404
    //  stav
    //    standardní priorita, pořadí 5 - 202
    //    pokud je služba zablokovaná, tak priorita kritická - 402
    //  vyúčtování
    //    standardní priorita, pořadí 1 - 206
    //    pokud existuje dluh po splatnosti se zohledněním UC, tak priorita kritická - 406

    // Data pro unicredit - jen pokud má zákazník aktvní službu unicredit (předplatné)
    if (this.widgetVisible('unicredit')) {
      this.dataUnicredit.getone(this.session.user?.id).subscribe((d) => {
        this.unicreditBalance = d.ucBalance;
        this.ucVarSymbol = d.ucVarSymbol;
        if (this.unicreditBalance < 1) {
          this.priorityUc = 405;
        } else {
          this.priorityUc = 205;
        }
        this.relayoutDashboard();
      });
    }

    // Data pro offlimit - jen pro IMAFEX
    if (this.widgetVisible('offlimit')) {
      this.dataUnicredit.getone(this.session.user?.id).subscribe((d) => {
        this.userOffLimit = d.userOffLimit;
        if (this.userOffLimit > 55) {
          this.priorityOff = 404;
        } else {
          this.priorityOff = 204;
        }
        this.relayoutDashboard();
      });
    }

    // Data pro phonet -
    if (this.widgetVisible('phonet')) {
      this.priorityPhonet = 203;
      // Načteme nejprve jen seznam služeb. Detail zůstatku se zjišťuje dalšími cally do Phonet a trvá to. Proto je
      // zjistíme druhým dotazem s komplet daty.
      this.phonetCredit
        .getall({ euId: this.session.user?.id, quick: 1 })
        .subscribe((p) => {
          this.phonetData = p.data;
          this.phonetCredit
            .getall({ euId: this.session.user?.id, quick: 0 })
            .subscribe((p2) => {
              this.phonetData = p2.data;
              this.relayoutDashboard();
            });
          this.relayoutDashboard();
        });

      this.dataUnicredit.getone(this.session.user?.id).subscribe((d) => {
        this.saPhonet = d.saPhonet;
        this.relayoutDashboard();
      });
    }

    if (this.widgetVisible('state')) {
      this.servicesState.getone(this.session.user?.id).subscribe((d) => {
        this.saState = d.saState;
        if (this.saState === 'B') {
          this.priorityState = 402;
        } else {
          this.priorityState = 202;
        }
        this.relayoutDashboard();
      });
    }

    if (this.widgetVisible('debt')) {
      this.userDebt.getone(this.session.user?.id).subscribe((d) => {
        this.debtAll = d.debtAll;
        this.debtAfterMaturity = d.debtAfterMaturity;
        this.debtWithoutUc = d.debtWithoutUc;
        this.userVs = d.userVs;
        if (d.debtAll !== null) {
          this.debtToMaturity = this.debtAll - this.debtAfterMaturity;
        } else {
          this.debtToMaturity = null;
        }

        if (this.debtAfterMaturity > 0) {
          this.priorityDebt = 406;
        } else {
          this.priorityDebt = 206;
        }
        this.relayoutDashboard();
      });
    }

    if (this.widgetVisible('unconfirmed-agreements')) {
      this.unconfirmedAgreements.getOneBy().subscribe((d) => {
        this.unconfirmedAgreementsCount = d.unconfirmedAgr;
      });
    }
  }

  widgetVisible(name: string): boolean {
    const opt = this.session.getOption('SELFCARE.home.' + name);
    return !(opt && opt.includes('hidden'));
  }

  get currencySymbol(): string {
    return this.session.locDefaults.currencyCode;
  }

  paymentUc(ucVarSymbol: any): void {
    const dconfp = new DialogConfig();
    dconfp.data = {
      amount: 0,
      varsym: ucVarSymbol,
      typeAttach: null,
      saId: 0,
    };

    const dref = this.dialog.open(CardPaymentComponent, dconfp);
    dref.afterClosed.subscribe((result) => {
      // if (result)
      // console.error("Tady mam data ", result)
    });
  }

  paymentDebt(debtAll: any, userVs: number): void {
    const dconfp = new DialogConfig();
    dconfp.data = {
      amount: debtAll,
      varsym: userVs,
      typeAttach: 19,
      saId: 0,
    };

    const dref = this.dialog.open(CardPaymentComponent, dconfp);
    dref.afterClosed.subscribe((result) => {
      // if (result)
      // console.error("Tady mam data ", result)
    });
  }

  phonetLoad(): void {
    const dialogConfig = new DialogConfig();
    dialogConfig.data = {
      amount: 0,
      userServices: 0,
    };
    this.dialog.open(PhonetCreditComponent, dialogConfig);
  }
}
