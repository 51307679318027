import { Injectable } from '@angular/core';
import { DataBase } from '@og_soft/data-base';

@Injectable({
  providedIn: 'root',
})
export class DataUserContactsService extends DataBase<any> {
  protected getAllUrl(): string {
    return 'eu-contacts' as const;
  }
}
