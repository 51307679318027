// src/app/chart/chart.component.ts
import { Component, Input, OnInit } from '@angular/core';
import {
  Chart,
  ChartConfiguration,
  ChartOptions,
  ChartType,
  PointElement,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  LineController,
  LineElement,
  Filler,
} from 'chart.js';

// Register Chart.js components
Chart.register(
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  LineController,
  LineElement,
  Filler,
  PointElement
);

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit {
  @Input() data?: ChartConfiguration<'line'>['data'];
  @Input() options?: ChartOptions;
  @Input() width?: number;
  @Input() height?: number;
  @Input() type: ChartType = 'line';

  ngOnInit() {
    console.log('DATA', this.data);
  }
}
