import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { ScConfiguration, SC_CONFIGURATION } from '@isp-sc/shared/common';

@Injectable({
  providedIn: 'root',
})
export class TestGuard implements CanActivate {
  constructor(
    @Inject(SC_CONFIGURATION) private scConfiguration: ScConfiguration
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return (
      this.scConfiguration.ct === 190 &&
      this.scConfiguration.dbVersion !== 'stable'
    );
  }
}
