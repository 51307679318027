import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DataBase,
  DataBaseServiceConfig,
  FilterInfo,
} from '@og_soft/data-base';
import { CachingInterceptor } from '@og_soft/cache';
import { RecDocumentsOtherMeta } from '@isp-sc/shared/segments/data/common';
import { RecDocumentsOther } from '@isp-sc/shared/segments/data/common';

@Injectable({
  providedIn: 'root',
})
export class DataDocumentsOtherService extends DataBase<RecDocumentsOther> {
  protected getAllUrl(): string {
    return 'documents-other' as const;
  }

  constructor(
    public override http: HttpClient,
    public serviceConfig: DataBaseServiceConfig,
    private cachingInterceptor: CachingInterceptor
  ) {
    super(http, serviceConfig, cachingInterceptor);
  }

  override metaFetchFilterInfo(meta: RecDocumentsOtherMeta): FilterInfo[] {
    if (meta) {
      const info: FilterInfo[] = [];
      if (meta.docType) {
        info.push({
          label: $localize`:@@DataDocumentOther.filterInfo.type.label:Typ dokumentu`,
          filterNames: ['docType'],
          value: meta.cddtTitle,
        });
      }

      return info;
    }
    return super.metaFetchFilterInfo(meta);
  }
}
