import { Injectable } from '@angular/core';
import { DataBase, DataBaseServiceConfig } from '@og_soft/data-base';
import { HttpClient } from '@angular/common/http';
import { CachingInterceptor } from '@og_soft/cache';
import { ProtectedDataCategoryService } from './protected-data-category-service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RecProtectedDataCategoryConsentAddData } from '@isp-sc/shared/segments/params/common';

@Injectable({
  providedIn: 'root',
})
export class ProtectedDataCategoryConsentAddService extends DataBase<RecProtectedDataCategoryConsentAddData> {
  constructor(
    public override http: HttpClient,
    public serviceConfig: DataBaseServiceConfig,
    private cachingInterceptor: CachingInterceptor,
    private protectedDataCategoryService: ProtectedDataCategoryService
  ) {
    super(http, serviceConfig, cachingInterceptor);
  }

  protected getAllUrl(): string {
    return 'protected-data-category-consent-add' as const;
  }

  override post(
    data: any,
    options?: any,
    urlExpandParams?: any
  ): Observable<RecProtectedDataCategoryConsentAddData> {
    return super.post(data, options, urlExpandParams).pipe(
      tap(() => {
        // Pokud odeberu souhlas, invaliduji cache servise kterou využívám v mém profilu na zjišťování
        // toho, pro které itemy byl udělen souhlas.
        this.protectedDataCategoryService.clearCache();
      })
    );
  }
}
