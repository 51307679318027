import { ProtectedDataComponent } from '@isp-sc/isp-sc/segments/selfcare/features';
import { RouterModule, Routes } from '@angular/router';
import {
  CardResponseComponent,
  ContactsComponent,
  CsobIntegrationTestComponent,
  DataViewComponent,
  DocumentsAgreementsComponent,
  DocumentsOtherComponent,
  EditorBlockPageComponent,
  EuBillingComponent,
  EuUnicreditComponent,
  HomeComponent,
  NodesComponent,
  ProcessEditComponent,
  ProcessListComponent,
  RmRedirectComponent,
  ScGetDocumentComponent,
} from '@isp-sc/isp-sc/segments/selfcare/pages';
import {
  UserPaymentsComponent,
  UserProfileComponent,
  UserServicesComponent,
} from '@isp-sc/isp-sc/segments/user/pages';
import { TelephonyListComponent } from '@isp-sc/isp-sc/segments/selfcare/pages';
import { ProcessEditCanDeactivate } from '@isp-sc/isp-sc/segments/selfcare/features';
import {
  LoginComponent,
  PasswordComponent,
  RegistrationComponent,
  VerifyRegistrationComponent,
} from '@isp-sc/shared/segments/auth/features';
import { NgModule } from '@angular/core';
import { AuthGuard, TestGuard } from '@isp-sc/shared/data-access';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' }, // TODO: revidovat
  { path: 'selfcare', component: HomeComponent, data: { title: 'Nástěnka' } },
  {
    path: 'user-profile',
    component: UserProfileComponent,
    data: { title: 'Můj profil' },
    canActivate: [AuthGuard],
  },
  {
    path: 'contacts',
    component: ContactsComponent,
    data: { title: 'Mé kontakty' },
    canActivate: [AuthGuard],
  },
  {
    path: 'billing',
    component: EuBillingComponent,
    data: { title: 'Vyúčtování' },
    canActivate: [AuthGuard],
  },
  {
    path: 'billing/:saIds',
    component: EuBillingComponent,
    data: { title: 'Vyúčtování' },
    canActivate: [AuthGuard],
  },
  {
    path: 'user-payments',
    component: UserPaymentsComponent,
    data: { title: 'Platby' },
    canActivate: [AuthGuard],
  },
  {
    path: 'user-services',
    component: UserServicesComponent,
    data: { title: 'Mé služby' },
    canActivate: [AuthGuard],
  },
  {
    path: 'user-services/:id',
    component: UserServicesComponent,
    data: { title: 'Mé služby' },
    canActivate: [AuthGuard],
  },
  {
    path: 'unicredit',
    component: EuUnicreditComponent,
    data: { title: 'UniCredit' },
    canActivate: [AuthGuard],
  },
  {
    path: 'protected',
    component: ProtectedDataComponent,
    data: { title: 'Chráněná data' },
    canActivate: [AuthGuard],
  },
  {
    path: 'telephony',
    component: TelephonyListComponent,
    data: { title: 'Výpis hovorů' },
    canActivate: [AuthGuard],
  },
  {
    path: 'telephony/:saId',
    component: TelephonyListComponent,
    data: { title: 'Výpis hovorů' },
    canActivate: [AuthGuard],
  },
  {
    path: 'data',
    component: DataViewComponent,
    data: { title: 'Datové služby' },
    canActivate: [AuthGuard],
  },
  {
    path: 'process',
    component: ProcessListComponent,
    data: { title: 'Požadavky' },
    canActivate: [AuthGuard],
  },
  {
    path: 'process/:id',
    component: ProcessEditComponent,
    data: { title: 'Požadavek' },
    canActivate: [AuthGuard],
    canDeactivate: [ProcessEditCanDeactivate],
  },
  {
    path: 'nodes',
    component: NodesComponent,
    data: { title: 'Zařízení' },
    canActivate: [AuthGuard],
  },
  {
    path: 'nodes/:saId',
    component: NodesComponent,
    data: { title: 'Zařízení' },
    canActivate: [AuthGuard],
  },
  {
    path: 'page-editor-block/:id',
    component: EditorBlockPageComponent,
    data: {},
  },
  {
    path: 'documents-agreements',
    component: DocumentsAgreementsComponent,
    data: { title: 'Smluvní dokumenty' },
    canActivate: [AuthGuard],
  },
  {
    path: 'documents-other',
    component: DocumentsOtherComponent,
    data: { title: 'Ostatní dokumenty' },
    canActivate: [AuthGuard],
  },
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  {
    path: 'password',
    component: PasswordComponent,
    data: { title: 'Změna hesla' },
  },
  {
    path: 'registration',
    component: RegistrationComponent,
    data: { title: 'Registrace' },
  },
  { path: 'get-document/:id', component: ScGetDocumentComponent },
  { path: 'rm-redirect/:data', component: RmRedirectComponent },
  {
    path: 'csob-integration-test',
    component: CsobIntegrationTestComponent,
    canActivate: [TestGuard],
  },
  {
    path: 'csob-integration-test/:',
    component: CsobIntegrationTestComponent,
    canActivate: [TestGuard],
  },
  { path: 'verify-registration', component: VerifyRegistrationComponent },
  {
    path: 'registration-config',
    component: RegistrationComponent,
    data: { title: 'Konfigurace registračního formuláře' },
    canActivate: [AuthGuard],
  },
  { path: 'cardresp/:red', component: CardResponseComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
