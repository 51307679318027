import { NgModule } from '@angular/core';
import { ScNavComponent } from './navigation/sc-nav.component';
import { MatIcon } from '@angular/material/icon';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { MatListItem, MatNavList } from '@angular/material/list';
import { MatBadge } from '@angular/material/badge';
import {
  MatSidenav,
  MatSidenavContainer,
  MatSidenavContent,
} from '@angular/material/sidenav';
import { ScNavMenuItemComponent } from './navigation-menu-item/sc-nav-menu-item.component';

@NgModule({
  declarations: [ScNavComponent, ScNavMenuItemComponent],
  imports: [
    MatIcon,
    NgIf,
    RouterLink,
    MatNavList,
    MatListItem,
    NgClass,
    MatBadge,
    NgForOf,
    RouterLinkActive,
    RouterOutlet,
    MatSidenavContent,
    MatSidenav,
    MatSidenavContainer,
  ],
  exports: [ScNavComponent, ScNavMenuItemComponent],
})
export class NavigationModule {}
