import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { DisplayEditPaneComponent } from '@isp-sc/shared/segments/selfcare/features';

@Directive({
  selector: '[appDisplayPaneItem]',
})
export class DisplayPaneItemDirective implements OnInit {
  @Input('appDisplayPaneItem') name?: string;

  constructor(
    private pane: DisplayEditPaneComponent,
    private session: SessionService,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    const optionsKey =
      'SELFCARE.' + this.pane.formName + '.' + this.pane.name + '.' + this.name;
    const myOptions = this.session.getOption(optionsKey);
    if (myOptions && myOptions.includes('hidden')) {
      this.el.nativeElement.style.display = 'none';
    }
    if (this.el.nativeElement.firstChild.classList) {
      this.el.nativeElement.firstChild.classList.add('mat-caption');
      this.el.nativeElement.firstChild.classList.add('mango-text-secondary');
    }
    if (this.el.nativeElement.lastChild.classList) {
      this.el.nativeElement.lastChild.classList.add('mango-text-normal');
    }
  }
}
