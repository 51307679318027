import { Injectable } from '@angular/core';
import { DataBase } from '@og_soft/data-base';
import { AgreementConfirmData } from '@isp-sc/shared/segments/agreement/common';

@Injectable({
  providedIn: 'root',
})
export class AgreementConfirmService extends DataBase<AgreementConfirmData> {
  // getallUrl = "documents-agreements-confirmed";

  protected getAllUrl(): string {
    return 'documents-agreements-confirmed' as const;
  }
}
