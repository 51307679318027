import { AbstractControl, ValidatorFn } from '@angular/forms';

export function numberDecimalPlacesValidator(
  maxPrecision: number
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const num = control.value;

    // Pozor, zde narážíme na problém se zaokrouhlováním
    // Pokud jde o hodnotu z inputu typu number, tak ji JS v nějakou chvíli musí zaokrouhlit
    // - zaokrouhlená hodnoty přijde už z toho value controlu (samotné textové hodnotě inputu
    // nemáme v tuto chvíli jednoduchý přístup). Proto validace nemusí vždy vrátit očekávaný výstup.
    // Předpokládám, že pro jednoduché případy to bude fungovat v pořádku.
    // Ale např. pro 0,99999999999999999 (to už bude js reprezentovat jako 1) validace projde,
    // i když je omezená na menší množství desetinných míst.

    return (num * 10 ** maxPrecision) % 1 !== 0
      ? {
          decimalPlaces: {
            value: control.value,
            precision: maxPrecision,
          },
        }
      : null;
  };
}
