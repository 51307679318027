import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ForgetTableRowComponent } from '../forget-table-row/forget-table-row.component';

@Component({
  selector: 'mgt-cell',

  template: `<ng-content></ng-content>`,

  styles: [
    `
      :host {
        display: block;
      }

      :host[desktop-only].mobile {
        display: none;
      }

      :host[mobile-only]:not(.mobile) {
        display: none;
      }

      :host[detail-only] {
        display: none;
      }

      :host.cell-right {
        text-align: right;
      }

      :host.cell-monetary {
        white-space: nowrap;
        text-align: right;
      }
    `,
  ],
})
export class MgtCellComponent implements OnInit {
  /**
   * Název sloupce (nyní se používá v "automatickém detailu").
   */
  @Input()
  title?: string;

  /**
   * Role sloupce - buď nezadaná, nebo jedna z "title", "description", "result", "freaky", "detailonly".
   */
  @Input()
  role?: string;

  /* Informace od deviceDetectorService, že jsme na mobilu. */
  @HostBinding('class.mobile')
  public mobile = false;

  /**
   * @ignore
   */
  constructor(
    protected deviceDetector: DeviceDetectorService,
    protected ownerRow: ForgetTableRowComponent,
    protected elementRef: ElementRef
  ) {
    this.mobile = deviceDetector.isMobile();
  }

  /**
   * @ignore
   */
  ngOnInit() {
    if (this.title && this.role && this.elementRef.nativeElement) {
      this.ownerRow.columnSelfregister(
        this.title,
        this.role,
        this.elementRef.nativeElement
      );
    }
  }
}
