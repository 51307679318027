import { ErrorHandler, Inject, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { ScConfiguration, SC_CONFIGURATION } from '@isp-sc/shared/common';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(
    @Inject(SC_CONFIGURATION) private scConfiguration: ScConfiguration
  ) {}
  handleError(error: any): void {
    const err = error.originalError || error;
    // Prozatím nechme chyby logovat do konzole, pro debugování je to důlěžité
    // Až nasadíme k používání a bude dobře fungovat sentry, tak můžeme potlačit
    //   if (!environment.production) console.error(err);
    console.error(err);
    if (this.scConfiguration.sentryDsn) {
      Sentry.captureException(error.originalError || error);
    }
    // Sentry.showReportDialog({eventId});
  }
}
