import { Component, OnInit, ViewChild } from '@angular/core';
import {
  dateRangeValidate,
  DatetimeControlPickerType,
} from '@og_soft/datetime-control';
import { FormGroup } from '@angular/forms';
import { ForgetTableComponent } from '@isp-sc/shared/ui';
import { CrossFieldErrorMatcher } from '@isp-sc/shared/segments/selfcare/common';
import { ActivatedRoute } from '@angular/router';
import {
  DataTelephonyListService,
  DataUserServicesService,
} from '@isp-sc/shared/segments/data/data-access';
import { FormHelperService } from '@isp-sc/shared/data-access';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { MangoFormControl } from '@isp-sc/shared/segments/mango-processes/common';
import { Location } from '@angular/common';
import { RecTelephonyList } from '@isp-sc/shared/segments/data/common';

@Component({
  selector: 'app-telephony-list',
  templateUrl: './telephony-list.component.html',
  styleUrls: ['./telephony-list.component.scss'],
})
export class TelephonyListComponent implements OnInit {
  protected readonly DatetimeControlPickerType = DatetimeControlPickerType;
  filters: FormGroup;
  filterValues: any = {};

  private subParams: any;

  @ViewChild(ForgetTableComponent, { static: false })
  public tab!: ForgetTableComponent;

  // just specific error type, otherwise it will highlight on any error within the group
  errorMatcher = new CrossFieldErrorMatcher(['dateRangeInvalid']);

  constructor(
    private route: ActivatedRoute,
    public dataService: DataTelephonyListService,
    public dataUserServices: DataUserServicesService,
    private location: Location,
    public formHelper: FormHelperService,
    public session: SessionService
  ) {
    this.filters = new FormGroup(
      {
        dateFrom: new MangoFormControl(''),
        dateTo: new MangoFormControl(''),
        saId: new MangoFormControl(''),
      },
      { validators: dateRangeValidate('dateFrom', 'dateTo') }
    );
  }

  ngOnInit(): void {
    this.filters.patchValue(this.filterValues);

    this.subParams = this.route.params.subscribe((params) => {
      this.filterValues = params['saId'] ? { saId: params['saId'] } : {};
      this.filters?.patchValue(this.filterValues);
    });
  }

  public updateFilter(param: any): void {
    const currentRoute = '/' + this.route.snapshot.url[0].path + '/';
    if (param && param.saId != null) {
      this.location.replaceState(currentRoute + param.saId);
    } else {
      this.location.replaceState(currentRoute);
    }
    // this.tab.filtersApply(param);
    this.filterValues = param;
  }

  protected secDiff(i: number, j: number, items: RecTelephonyList[]): boolean {
    if (i < 0 || i >= items.length || j < 0 || j >= items.length) {
      return true;
    }
    return (
      new Date(items[i].tm).toDateString() !==
      new Date(items[j].tm).toDateString()
    );
  }

  public secHeaderShow(i: number, items: RecTelephonyList[]): boolean {
    return this.secDiff(i - 1, i, items);
  }

  public secFirst(i: number, items: RecTelephonyList[]): boolean {
    return this.secDiff(i - 1, i, items);
  }

  public secLast(i: number, items: RecTelephonyList[]): boolean {
    return this.secDiff(i, i + 1, items);
  }

  public getCurrencySymbol(): string {
    return this.session.locDefaults.currencyCode;
  }
}
