<div *ngFor="let group of castToAny(paramGroups)">
  <div *ngIf="getGroupVisibility(group)">
    <!--<div [ngClass]="group.index%2 ? 'even' : 'odd' ">-->
    <h4
      *ngIf="group.caption && getGroupVisibility(group) && !(group.index > 0)"
    >
      {{ group.caption }}
    </h4>

    <div *ngIf="group.type !== 'TABLE'">
      <div
        *ngFor="let pars of group.params; let index = index"
        [ngClass]="index % 2 ? 'even' : 'odd'"
        style="padding: 10px"
      >
        <div *ngFor="let param of pars" class="form-row">
          <app-param
            [param]="param"
            [dependencyDataForm]="form"
            [form]="form?.get(paramFormName)"
            [dependencyService]="dependencyService"
            (addIndexed)="indexedParamAdd($event)"
            (deleteIndexed)="indexedParamDelete($event)"
          ></app-param>
        </div>
        <button
          *ngIf="group.type === 'TABLE' && pars[0].index !== 0"
          mat-stroked-button
          style="width: 100%; background-color: lightgray"
          (click)="removeTableRow(group, index)"
        >
          <mat-icon>remove</mat-icon>
          Smazat
        </button>
      </div>
    </div>
    <div *ngIf="group.type === 'TABLE'">
      <mat-accordion>
        <mat-expansion-panel
          *ngFor="let pars of group.params; let index = index"
          [ngClass]="index % 2 ? 'even' : 'odd'"
          style="padding: 10px"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ pars[0].caption }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div *ngFor="let param of pars" class="form-row">
            <app-param
              [param]="param"
              [dependencyDataForm]="form"
              [form]="form?.get(paramFormName)"
              (addIndexed)="indexedParamAdd($event)"
              (deleteIndexed)="indexedParamDelete($event)"
            ></app-param>
          </div>

          <mat-action-row>
            <button
              *ngIf="group.type === 'TABLE' && pars[0].index !== 0"
              mat-button
              color="primary"
              (click)="removeTableRow(group, index)"
            >
              Smazat
            </button>
          </mat-action-row>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <button
      *ngIf="group.type === 'TABLE'"
      mat-stroked-button
      style="width: 100%; background-color: lightgray; margin-top: 10px"
      (click)="addTableRow(group)"
    >
      <mat-icon>add</mat-icon>
      Nový
    </button>
  </div>
</div>
