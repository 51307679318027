import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { DataUserServicesService } from './data-user-services.service';
import {
  DataBase,
  DataBaseServiceConfig,
  FilterInfo,
  GetallResult,
} from '@og_soft/data-base';
import { CachingInterceptor } from '@og_soft/cache';
import { PhoneNumberPipe } from '@og_soft/phone-number';
import {
  RecBilling,
  RecBillingMeta,
} from '@isp-sc/shared/segments/data/common';

@Injectable({
  providedIn: 'root',
})
export class DataBillingService extends DataBase<RecBilling> {
  protected getAllUrl(): string {
    return 'eu-billing' as const;
  }

  constructor(
    public override http: HttpClient,
    serviceConfig: DataBaseServiceConfig,
    public userServices: DataUserServicesService,
    private cachingInterceptor: CachingInterceptor
  ) {
    super(http, serviceConfig, cachingInterceptor);
  }

  protected override dataPostprocess(
    data: GetallResult<RecBilling>
  ): GetallResult<RecBilling> {
    // FIXME optimalizovat volání getone nahradit za get all. Viz např. user-nodes.service.ts
    // Pak se bude méně často volat ten onDataCollected event.
    for (const result of data.data) {
      for (const service of result.sa) {
        this.userServices.getone(service.id).subscribe((d) => {
          if (d) {
            service.name = d.saName;
            service.paramIdent = DataUserServicesService.saIdentGet(d);
            this.onDataCollected.emit();
          }
        });
      }
      this.userServices.getone(result.saId).subscribe((d) => {
        if (d) {
          result.name = d.saName;
          result.paramIdent = DataUserServicesService.saIdentGet(d) ?? '';
          this.onDataCollected.emit();
        }
      });
    }
    return super.dataPostprocess(data);
  }

  override metaFetchFilterInfo(meta: RecBillingMeta): FilterInfo[] {
    if (meta) {
      const info: FilterInfo[] = [];
      if (meta.dateFrom || meta.dateTo) {
        const dp = new DatePipe('cs-CZ');
        const dates = [];
        dates.push(
          meta.dateFrom ? dp.transform(meta.dateFrom, 'd. M. yyyy') : ''
        );
        dates.push(meta.dateTo ? dp.transform(meta.dateTo, 'd. M. yyyy') : '');
        info.push({
          label: $localize`:@@DataBillingService.filterInfo.date.label:Období`,
          filterNames: ['dateFrom', 'dateTo'],
          value: dates.join(' - '),
        });
      }
      if (meta.saNames) {
        info.push({
          label: $localize`:@@DataBillingService.filterInfo.service.label:Služba`,
          filterNames: ['saIds'],
          value: meta.saNames[0].saName,
          valueDetail:
            meta.saNames[0].saIdentType === 'PHONE' && meta.saNames[0].saIdent
              ? PhoneNumberPipe.prototype.transform(
                  '+' + meta.saNames[0].saIdent
                )
              : meta.saNames[0].unitAddress,
        });
      }
      return info;
    }
    return super.metaFetchFilterInfo(meta);
  }
}
