import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { DialogRef } from '@og_soft/dialog';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { FormHelperService } from '@isp-sc/shared/data-access';
import { UserDataUnicreditService } from '@isp-sc/shared/segments/user/data-access';
import { MangoFormControl } from '@isp-sc/shared/segments/mango-processes/common';
import { numberDecimalPlacesValidator } from '@isp-sc/shared/segments/selfcare/common';
import {
  PhonetCreditLoadService,
  PhonetCreditService,
} from '@isp-sc/shared/segments/phonet/data-access';

@Component({
  selector: 'sc-phonet-credit',
  templateUrl: './phonet-credit.component.html',
  styleUrls: ['./phonet-credit.component.scss'],
})
export class PhonetCreditComponent implements OnInit {
  form: FormGroup;

  public balance = 0;

  constructor(
    public session: SessionService,
    public formHelper: FormHelperService,
    public dataUserUnicredit: UserDataUnicreditService,
    public phonetCreditServices: PhonetCreditService,
    public phonetCreditLoad: PhonetCreditLoadService,
    private dlgRef: DialogRef
  ) {
    this.form = new FormGroup({
      userServices: new MangoFormControl(''),
      amount: new MangoFormControl('', [
        Validators.required,
        Validators.min(0.001),
        Validators.max(999999),
        numberDecimalPlacesValidator(
          Number(this.session.options.PRICE_DECIMAL_PRECISION)
        ),
      ]),
    });
    this.form.controls['userServices'].setValue(0);
  }

  ngOnInit(): void {
    this.dataUserUnicredit.getone(this.session.user?.id).subscribe(
      (next) => {
        if (next !== null) {
          this.balance = next.ucBalance;
        }
      },
      (err) => {
        console.log('err', err);
      }
    );
  }

  public valuesSet(values: any): void {
    this.form.setValue(values);
  }

  public save(): void {
    const formData = this.form.value;
    if (this.form.valid) {
      this.phonetCreditLoad.post(formData).subscribe(
        () => {
          this.session.message(
            $localize`:@@PhonetCredit.creditLoad.message:OK. Všechno máme uloženo.`
          );
        },
        (err) => {
          console.error('Tak to tak úplně nevyšlo.', err);
        }
      );
      this.dlgRef.close();
    } else {
      this.session.message(
        $localize`:@@PhonetCredit.creditLoad.validate.message:Některé položky nejsou vyplněné nebo nemají správnou hodnotu.`
      );
      this.formHelper.markDirty(this.form);
    }
  }

  balanceGet(): number {
    return this.balance;
  }

  getCurrencySymbol(): string {
    return this.session.locDefaults.currencyCode;
  }
}
