import { Injectable } from '@angular/core';
import { DataBase } from '@og_soft/data-base';
import { PopupOption } from '@isp-sc/shared/ui';
import {
  RecDocumentsOtherMeta,
  RecDocumentsOtherTypes,
} from '@isp-sc/shared/segments/data/common';

@Injectable({
  providedIn: 'root',
})
export class DataDocumentsOtherTypesService extends DataBase<
  RecDocumentsOtherTypes,
  RecDocumentsOtherMeta
> {
  protected getAllUrl(): string {
    return 'documents-other-types' as const;
  }

  override tranformOptions(row: any): PopupOption {
    return { id: row.cddtType, name: row.cddtTitle };
  }
}
