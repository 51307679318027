export enum DomainType {
  dom_unknown,
  dom_value,
  dom_range,
  dom_code_list,
  dom_subdefs,
  dom_any,
  dom_table,
  dom_preg,
  dom_attribute,
  dom_complicated, // obor hodnot který zde nedovedu rozparsovat, proto posílám na server ke zpracování
}
