import { Injectable } from '@angular/core';
import { DataBase } from '@og_soft/data-base';
import { RecScMessage } from '@isp-sc/shared/segments/data/common';

@Injectable({
  providedIn: 'root',
})
export class DataScDashboardMessagesService extends DataBase<RecScMessage> {
  protected getAllUrl(): string {
    return '/sc-dashboard-messages' as const;
  }
}
