import { Component, Input } from '@angular/core';

@Component({
  selector: 'page-empty',
  template: `
    <div class="page-empty">
      <p>{{ message }}</p>
      <mat-icon>sentiment_dissatisfied</mat-icon>
    </div>
  `,
  styleUrls: ['./page-empty.component.scss'],
})
export class PageEmptyComponent {
  @Input()
  message = 'Bohužel zde nic není';
}
