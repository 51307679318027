import { Injectable } from '@angular/core';
import {
  DataBase,
  DataBaseServiceConfig,
  GetallResult,
} from '@og_soft/data-base';
import { PopupOption } from '@isp-sc/shared/ui';
import { Observable } from 'rxjs';
import { ProcessNote } from '@isp-sc/shared/segments/mango-processes/common';

@Injectable({
  providedIn: 'root',
})
export class DataProcessNotesService extends DataBase<ProcessNote> {
  // getallUrl = "process-history";
  protected getAllUrl(): string {
    return 'process-history' as const;
  }

  override configureUrl(config: DataBaseServiceConfig) {
    this.baseurl = config.mangoUrl ?? '';
  }

  override tranformOptions(row: any): PopupOption {
    return { id: row.id.toString(), name: row.note };
  }

  fetchProcessNotes(processId: number): Observable<GetallResult<ProcessNote>> {
    return this.getall({ id: processId, 'types[]': [13] });
  }

  sendNote(processId: number, noteText: string): Observable<ProcessNote> {
    const now = new Date();

    const note: ProcessNote = {
      id: 0,
      processId: processId,
      note: noteText,
      time: now.toISOString(),
      user: true,
    };

    return this.post(note);
  }
}
