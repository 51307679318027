import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ScNavComponent } from '@isp-sc/isp-sc/segments/selfcare/features';

@Component({
  selector: 'app-editor-block-page',
  templateUrl: './editor-block-page.component.html',
  styleUrls: ['./editor-block-page.component.scss'],
})
export class EditorBlockPageComponent implements OnInit, OnDestroy {
  public subscibtionParams?: Subscription;

  public ttId = 0;
  ngOnInit() {
    this.subscibtionParams = this.route.params.subscribe((params) => {
      this.ttId = params['id'];
    });
  }

  get editorBlockTitle(): string {
    return this.scnav.editorBlockTitle(this.ttId);
  }

  constructor(private route: ActivatedRoute, private scnav: ScNavComponent) {}

  ngOnDestroy(): void {
    this.subscibtionParams?.unsubscribe();
  }
}
