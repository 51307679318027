import { Injectable } from '@angular/core';
import { DataBase } from '@og_soft/data-base';
import { PopupOption } from '@isp-sc/shared/ui';
import { RecPhonetCreditLoadData } from '@isp-sc/shared/segments/phonet/common';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

@Injectable({
  providedIn: 'root',
})
export class PhonetCreditService extends DataBase<RecPhonetCreditLoadData> {
  protected getAllUrl(): string {
    return 'phonet-credit' as const;
  }

  override tranformOptions(row: any): PopupOption {
    const phoneUtil = PhoneNumberUtil.getInstance();
    let name = row.saPhonetName;
    if (row.saPhonetNum) {
      const phoneNum = phoneUtil.parse('+' + row.saPhonetNum);
      name +=
        ' (' +
        phoneUtil.format(phoneNum, PhoneNumberFormat.INTERNATIONAL) +
        ')';
    }
    return { id: row.saPhonetId, name };
  }
}
