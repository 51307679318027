import { NgModule } from '@angular/core';
import { PageEmptyComponent } from './page-empty/page-empty.component';
import { MatIcon } from '@angular/material/icon';

@NgModule({
  declarations: [PageEmptyComponent],
  imports: [MatIcon],
  exports: [PageEmptyComponent],
})
export class PageEmptyModule {}
