<dialog-title>
  <h3 i18n="@@UnavailablePage.title">Stránka je dočasně nedostupná</h3>
</dialog-title>

<dialog-content>
  <p>
    <span i18n="@@UnavailablePage.message"
      >Stránka je dočasně nedostupná z důvodu probíhající údržby. Zkuste to
      prosím za chvíli znovu.</span
    >
  </p>
</dialog-content>

<dialog-actions>
  <button
    mat-flat-button
    color="primary"
    i18n="@@UnavailablePage.reload.button"
    (click)="reload()"
  >
    Zkusit znovu
  </button>
</dialog-actions>
<app-session-processing></app-session-processing>
