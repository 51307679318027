import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DialogConfig, DialogRef } from '@og_soft/dialog';
import {
  DataParamsServiceActiveService,
  ParamControlService,
} from '@isp-sc/shared/segments/params/data-access';
import { ParamFormComponent } from '@isp-sc/shared/segments/params/features';
import { DataUserServicesService } from '@isp-sc/shared/segments/data/data-access';
import { MangoFormControl } from '@isp-sc/shared/segments/mango-processes/common';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { FormHelperService } from '@isp-sc/shared/data-access';
import { DependencyServiceService } from '@isp-sc/shared/segments/dependency/data-access';

@Component({
  selector: 'app-user-service-edit',
  templateUrl: './user-service-edit.component.html',
  styleUrls: ['./user-service-edit.component.scss'],
  providers: [ParamControlService],
})
export class UserServiceEditComponent implements OnInit {
  private _saId: number | null = null;
  get saId(): number | null {
    return this._saId;
  }
  set saId(id: number) {
    this._saId = id;
  }

  private _caption: string | null = null;
  get caption(): string | null {
    return this._caption;
  }
  set caption(s: string) {
    this._caption = s;
  }

  @ViewChild(ParamFormComponent, { static: false })
  paramForm?: ParamFormComponent;

  public form: FormGroup;

  constructor(
    private dconfig: DialogConfig,
    public saDataService: DataUserServicesService,
    public saParamsService: DataParamsServiceActiveService,
    private session: SessionService,
    private formHelper: FormHelperService,
    public dependencyService: DependencyServiceService,
    private dlgRef: DialogRef
  ) {
    this.saId = dconfig.data.saId;
    this.caption =
      dconfig.data.saData.saName +
      (DataUserServicesService.saIdentGet(dconfig.data.saData, false)
        ? ' (' + DataUserServicesService.saIdentGet(dconfig.data.saData) + ')'
        : '');
    this.form = new FormGroup({
      // Sem si dám svoje, pokud je budu potřebovat, např. jméno služby.
      // dateFrom: new MangoFormControl(''),
      // dateTo: new MangoFormControl(''),
      // phoneNum: new MangoFormControl('')
      saName: new MangoFormControl(''),
    });
  }

  ngOnInit(): void {
    this.session.processingSet(true);
  }

  public save(): void {
    const formData = this.form.value;
    formData.saId = this.saId;
    console.log('Ukládaná data ', formData);
    if (this.form.valid) {
      this.saDataService.post(formData).subscribe(
        (saData) => {
          console.log('Data saved, read back.', saData);
          this.session.message(
            $localize`:@@UserServiceEdit.saDataService.message:OK. Všechno máme uloženo.`
          );
          this.saParamsService.clearCache();
          this.dlgRef.close(saData);
        },
        (err) => {
          console.error('Tak to tak úplně nevyšlo.', err);
          this.dlgRef.close();
        }
      );
      // console.log('Ukládaná data ', formData);
    } else {
      this.session.message(
        $localize`:@@UserServiceEdit.form.validate.message:Některé položky nejsou vyplněné nebo nemají správnou hodnotu.`
      );
      this.formHelper.markDirty(this.form);
    }
  }

  onParamsReady(): void {
    this.session.processingSet(false);
  }
}
