import { NgModule } from '@angular/core';
import { ScMainToolbarComponent } from './sc-main-toolbar/sc-main-toolbar.component';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatButton, MatIconButton } from '@angular/material/button';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatBadge } from '@angular/material/badge';
import { CurrencyPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import {MatToolbar, MatToolbarModule} from '@angular/material/toolbar';

@NgModule({
  declarations: [ScMainToolbarComponent],
  imports: [
    MatMenuTrigger,
    MatButton,
    MatMenu,
    RouterLink,
    MatMenuItem,
    CurrencyPipe,
    MatToolbarModule,
    RouterLinkActive,
    MatDivider,
    MatIcon,
    MatBadge,
    MatIconButton,
    NgIf,
    MatTooltip,
    NgClass,
    MatToolbar,
    NgForOf,
  ],
  exports: [ScMainToolbarComponent],
})
export class MainToolbarModule {}
