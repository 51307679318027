import { MangoFormControl } from '@isp-sc/shared/segments/mango-processes/common';
import { ParamBase } from './param-base';

export class MangoParamFormControl extends MangoFormControl {
  get param(): ParamBase | undefined {
    return this._param;
  }

  set param(value: ParamBase) {
    this._param = value;
  }

  private _param?: ParamBase;
}
