<form [formGroup]="form">
  <!--  <div *ngIf="formHelper.isVisible(form.get('addressSearch'))">-->
  <!--    <mat-form-field class="mango-form-field">-->
  <!--      <popup-list formControlName="addressSearch" id="addressSearch" name="addressSearch" [dataSource]="dataAddressSearch" [filterDb]='true' [noValue]="null" [defaultLimit]="999" [exactOnly]="true"-->
  <!--                  i18n-placeholder="@@UserProfile.addressSearch.placeholder" placeholder="Vyhledání adresy" i18n-title="@@UserProfile.addressSearch.title" title="Zde jednoduše vyhledejte adresu"></popup-list>-->
  <!--      <mat-icon *ngIf="form.get('addressSearch').value !== null" matSuffix (click)="clearAddress('addressSearch')">cancel</mat-icon>-->
  <!--      <app-config-setting input="addressSearch" [formName]="formName"></app-config-setting>-->
  <!--    </mat-form-field>-->
  <!--  </div>-->

  <!--  <div *ngIf="formHelper.isVisible(form.get('addressSearch'))">-->
  <!--    <mat-form-field class="mango-form-field">-->
  <!--      <mat-select formControlName="addressSearch" id="addressSearch" name="addressSearch"-->
  <!--                  i18n-placeholder="@@UserProfile.addressSearch.placeholder" placeholder="Vyhledání adresy">-->
  <!--        <mat-option>-->
  <!--          <ngx-mat-select-search formControlName="addressFiltering"-->
  <!--                                 i18n-placeholderLabel="@@UserProfile.addressSearch.searching.placeholder"-->
  <!--                                 placeholderLabel="Vyhledejte adresu"-->
  <!--                                 i18n-noEntriesFoundLabel="@@UserProfile.addressSearch.noEntriesFoundLabel.placeholder"-->
  <!--                                 noEntriesFoundLabel="Žádná adresa nenalezena"-->
  <!--          ></ngx-mat-select-search>-->
  <!--        </mat-option>-->
  <!--        <mat-option *ngFor="let option of suggestedAddresses" [value]="option.id">-->
  <!--          {{option.name}}-->
  <!--        </mat-option>-->
  <!--      </mat-select>-->
  <!--      <app-config-setting input="addressSearch" [formName]="formName"></app-config-setting>-->
  <!--    </mat-form-field>-->
  <!--  </div>-->

  <div *ngIf="form.get('addressSearch') as addressSearchControl">
    <mat-form-field
      class="mango-form-field"
      *ngIf="formHelper.isVisible(addressSearchControl)"
    >
      <input
        type="text"
        formControlName="addressSearch"
        id="addressSearch"
        name="addressSearch"
        i18n-placeholder="@@UserProfile.addressSearch.placeholder"
        placeholder="Vyhledání adresy"
        aria-label="Number"
        matInput
        [matAutocomplete]="auto"
      />
      <mat-autocomplete [autoActiveFirstOption]="true" #auto="matAutocomplete">
        <mat-option
          *ngFor="let option of suggestedAddresses"
          [value]="option.name"
        >
          {{ option.name }}
        </mat-option>
      </mat-autocomplete>
      <app-config-setting
        input="addressSearch"
        [formName]="formName"
      ></app-config-setting>
    </mat-form-field>
  </div>
  <ng-container *ngIf="form.get('street') as streetControl">
    <mat-form-field
      *ngIf="formHelper.isVisible(streetControl)"
      class="mango-form-field"
      [appearance]="formHelper.appearance()"
    >
      <mat-label i18n="@@UserProfile.street.label">Ulice</mat-label>
      <input
        matInput
        formControlName="street"
        id="street"
        name="street"
        i18n-title="@@UserProfile.street.title"
        title="Ulice"
        [required]="streetControl | hasRequiredField"
      />
      <app-config-setting
        input="street"
        [formName]="formName"
      ></app-config-setting>
      <mat-error
        *ngIf="
          streetControl.invalid &&
          (streetControl.dirty || !streetControl.untouched)
        "
      >
        {{ formHelper.getErrorMessage(streetControl) }}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="form.get('houseId') as houseIdControl">
    <mat-form-field
      *ngIf="formHelper.isVisible(houseIdControl)"
      class="mango-form-field"
      [appearance]="formHelper.appearance()"
    >
      <mat-label i18n="@@UserProfile.houseId.label">Číslo</mat-label>
      <input
        matInput
        formControlName="houseId"
        id="houseId"
        name="houseId"
        i18n-title="@@UserProfile.houseId.title"
        title="Č.p."
        [required]="houseIdControl | hasRequiredField"
      />
      <app-config-setting
        input="houseId"
        [formName]="formName"
      ></app-config-setting>
      <mat-error
        *ngIf="
          houseIdControl.invalid &&
          (houseIdControl.dirty || !houseIdControl.untouched)
        "
      >
        {{ formHelper.getErrorMessage(houseIdControl) }}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="form.get('zip') as zipControl">
    <mat-form-field
      *ngIf="formHelper.isVisible(zipControl)"
      class="mango-form-field"
      [appearance]="formHelper.appearance()"
    >
      <mat-label i18n="@@UserProfile.zip.label">PSČ</mat-label>
      <input
        matInput
        formControlName="zip"
        id="zip"
        name="zip"
        i18n-title="@@UserProfile.zip.title"
        title="PSČ"
        [required]="zipControl | hasRequiredField"
      />
      <app-config-setting
        input="zip"
        [formName]="formName"
      ></app-config-setting>
      <mat-error
        *ngIf="
          zipControl.invalid && (zipControl.dirty || !zipControl.untouched)
        "
      >
        {{ formHelper.getErrorMessage(zipControl) }}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="form.get('city') as cityControl">
    <mat-form-field
      *ngIf="formHelper.isVisible(cityControl)"
      class="mango-form-field"
      [appearance]="formHelper.appearance()"
    >
      <mat-label i18n="@@UserProfile.city.label">Obec</mat-label>
      <input
        matInput
        formControlName="city"
        id="city"
        name="city"
        i18n-title="@@UserProfile.city.title"
        title="Obec"
        [required]="cityControl | hasRequiredField"
      />
      <app-config-setting
        input="city"
        [formName]="formName"
      ></app-config-setting>
      <mat-error
        *ngIf="
          cityControl.invalid && (cityControl.dirty || !cityControl.untouched)
        "
      >
        {{ formHelper.getErrorMessage(cityControl) }}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="form.get('mailingAddress') as mailingAddressControl">
    <div *ngIf="formHelper.isVisible(mailingAddressControl)">
      <h3 class="mat-h3 mango-text-secondary">
        <span i18n="@@UserProfile.deliveryAddress.text">Doručovací adresa</span>
      </h3>
      <mat-radio-group
        name="mailingAddress"
        id="mailingAddress"
        formControlName="mailingAddress"
      >
        <div>
          <mat-radio-button
            class="mango-radio-button"
            matInput
            value="AS1"
            i18n="@@UserProfile.radio.mailingAddress.AS1"
            >Jako trvalé bydliště/sídlo</mat-radio-button
          ><br />
          <mat-radio-button
            class="mango-radio-button"
            matInput
            value="OWN"
            i18n="@@UserProfile.radio.mailingAddress.OWN"
            >Vlastní</mat-radio-button
          >
        </div>
      </mat-radio-group>
      <app-config-setting
        input="mailingAddress"
        [formName]="formName"
      ></app-config-setting>
    </div>
  </ng-container>
  <p></p>

  <div *ngIf="mailingAddressVisible()">
    <!--    <div *ngIf="formHelper.isVisible(form.get('mailingAddressSearch'))">-->
    <!--      <mat-form-field class="mango-form-field">-->
    <!--        <popup-list formControlName="mailingAddressSearch" id="mailingAddressSearch" name="mailingAddressSearch" [dataSource]="dataAddressSearch" [filterDb]='true' [noValue]="null" [defaultLimit]="999" [exactOnly]="true"-->
    <!--                    i18n-placeholder="@@UserProfile.mailingAddressSearch.placeholder" placeholder="Vyhledání doručovací adresy" i18n-title="@@UserProfile.mailingAddressSearch.title" title="Zde jednoduše vyhledejte adresu"></popup-list>-->
    <!--        <mat-icon *ngIf="form.get('mailingAddressSearch').value !== null" matSuffix (click)="clearAddress('mailingAddressSearch')">cancel</mat-icon>-->
    <!--        <app-config-setting input="mailingAddressSearch" [formName]="formName"></app-config-setting>-->
    <!--      </mat-form-field>-->
    <!--    </div>   -->

    <!--    <div *ngIf="formHelper.isVisible(form.get('mailingAddressSearch'))">-->
    <!--      <mat-form-field class="mango-form-field">-->
    <!--        <mat-select formControlName="mailingAddressSearch" id="mailingAddressSearch" name="mailingAddressSearch"-->
    <!--                    i18n-placeholder="@@UserProfile.mailingAddressSearch.placeholder" placeholder="Vyhledání doručovací adresy">-->
    <!--          <mat-option>-->
    <!--            <ngx-mat-select-search formControlName="addressFiltering"-->
    <!--                                   i18n-placeholderLabel="@@UserProfile.addressSearch.searching.placeholder"-->
    <!--                                   placeholderLabel="Vyhledejte adresu"-->
    <!--                                   i18n-noEntriesFoundLabel="@@UserProfile.addressSearch.noEntriesFoundLabel.placeholder"-->
    <!--                                   noEntriesFoundLabel="Žádná adresa nenalezena"-->
    <!--            ></ngx-mat-select-search>-->
    <!--          </mat-option>-->
    <!--          <mat-option *ngFor="let option of suggestedAddresses" [value]="option.id">-->
    <!--            {{option.name}}-->
    <!--          </mat-option>-->
    <!--        </mat-select>-->
    <!--      </mat-form-field>-->
    <!--    </div>-->

    <div
      *ngIf="form.get('mailingAddressSearch') as mailingAddressSearchControl"
    >
      <mat-form-field
        *ngIf="formHelper.isVisible(mailingAddressSearchControl)"
        class="mango-form-field"
      >
        <input
          type="text"
          formControlName="mailingAddressSearch"
          id="mailingAddressSearch"
          name="mailingAddressSearch"
          i18n-placeholder="@@UserProfile.mailingAddressSearch.placeholder"
          placeholder="Vyhledání adresy"
          aria-label="Number"
          matInput
          [matAutocomplete]="auto"
        />
        <mat-autocomplete
          [autoActiveFirstOption]="true"
          #auto="matAutocomplete"
        >
          <mat-option
            *ngFor="let option of suggestedAddresses"
            [value]="option.name"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
        <app-config-setting
          input="mailingAddressSearch"
          [formName]="formName"
        ></app-config-setting>
      </mat-form-field>
    </div>

    <ng-container
      *ngIf="
        form.get('mailingAddressRecipient') as mailingAddressRecipientControl
      "
    >
      <mat-form-field
        *ngIf="formHelper.isVisible(mailingAddressRecipientControl)"
        class="mango-form-field"
        [appearance]="formHelper.appearance()"
      >
        <mat-label i18n="@@UserProfile.mailingAddressRecipient.label"
          >Příjemce</mat-label
        >
        <input
          matInput
          formControlName="mailingAddressRecipient"
          id="mailingAddressRecipient"
          name="mailingAddressRecipient"
          i18n-title="@@UserProfile.mailingAddressRecipient.title"
          title="Příjemce"
          [required]="mailingAddressRecipientControl | hasRequiredField"
        />
        <app-config-setting
          input="mailingAddressRecipient"
          [formName]="formName"
        ></app-config-setting>
        <mat-error
          *ngIf="
            mailingAddressRecipientControl.invalid &&
            (mailingAddressRecipientControl.dirty ||
              !mailingAddressRecipientControl.untouched)
          "
        >
          {{ formHelper.getErrorMessage(mailingAddressRecipientControl) }}
        </mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container
      *ngIf="form.get('mailingAddressStreet') as mailingAddressStreetControl"
    >
      <mat-form-field
        *ngIf="formHelper.isVisible(mailingAddressStreetControl)"
        class="mango-form-field"
        [appearance]="formHelper.appearance()"
      >
        <mat-label i18n="@@UserProfile.mailingAddressStreet.label"
          >Ulice</mat-label
        >
        <input
          matInput
          formControlName="mailingAddressStreet"
          id="mailingAddressStreet"
          name="mailingAddressStreet"
          i18n-title="@@UserProfile.mailingAddressStreet.title"
          title="Ulice"
          [required]="mailingAddressStreetControl | hasRequiredField"
        />
        <app-config-setting
          input="mailingAddressStreet"
          [formName]="formName"
        ></app-config-setting>
        <mat-error
          *ngIf="
            mailingAddressStreetControl.invalid &&
            (mailingAddressStreetControl.dirty ||
              !mailingAddressStreetControl.untouched)
          "
        >
          {{ formHelper.getErrorMessage(mailingAddressStreetControl) }}
        </mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container
      *ngIf="form.get('mailingAddressHouseId') as mailingAddressHouseIdControl"
    >
      <mat-form-field
        *ngIf="formHelper.isVisible(mailingAddressHouseIdControl)"
        class="mango-form-field"
        [appearance]="formHelper.appearance()"
      >
        <mat-label i18n="@@UserProfile.mailingAddressHouseId.label"
          >Číslo</mat-label
        >
        <input
          matInput
          formControlName="mailingAddressHouseId"
          id="mailingAddressHouseId"
          name="mailingAddressHouseId"
          i18n-title="@@UserProfile.mailingAddressHouseId.title"
          title="Č.p."
          [required]="mailingAddressHouseIdControl | hasRequiredField"
        />
        <app-config-setting
          input="mailingAddressHouseId"
          [formName]="formName"
        ></app-config-setting>
        <mat-error
          *ngIf="
            mailingAddressHouseIdControl.invalid &&
            (mailingAddressHouseIdControl.dirty ||
              !mailingAddressHouseIdControl.untouched)
          "
        >
          {{ formHelper.getErrorMessage(mailingAddressHouseIdControl) }}
        </mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container
      *ngIf="form.get('mailingAddressZip') as mailingAddressZipControl"
    >
      <mat-form-field
        *ngIf="formHelper.isVisible(mailingAddressZipControl)"
        class="mango-form-field"
        [appearance]="formHelper.appearance()"
      >
        <mat-label i18n="@@UserProfile.mailingAddressZip.label">PSČ</mat-label>
        <input
          matInput
          formControlName="mailingAddressZip"
          id="mailingAddressZip"
          name="mailingAddressZip"
          i18n-title="@@UserProfile.mailingAddressZip.title"
          title="PSČ"
          [required]="mailingAddressZipControl | hasRequiredField"
        />
        <app-config-setting
          input="mailingAddressZip"
          [formName]="formName"
        ></app-config-setting>
        <mat-error
          *ngIf="
            mailingAddressZipControl.invalid &&
            (mailingAddressZipControl.dirty ||
              !mailingAddressZipControl.untouched)
          "
        >
          {{ formHelper.getErrorMessage(mailingAddressZipControl) }}
        </mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container
      *ngIf="form.get('mailingAddressCity') as mailingAddressCityControl"
    >
      <mat-form-field
        *ngIf="formHelper.isVisible(mailingAddressCityControl)"
        class="mango-form-field"
        [appearance]="formHelper.appearance()"
      >
        <mat-label i18n="@@UserProfile.mailingAddressCity.label"
          >Obec</mat-label
        >
        <input
          matInput
          formControlName="mailingAddressCity"
          id="mailingAddressCity"
          name="mailingAddressCity"
          i18n-title="@@UserProfile.mailingAddressCity.title"
          title="Obec"
          [required]="mailingAddressCityControl | hasRequiredField"
        />
        <app-config-setting
          input="mailingAddressCity"
          [formName]="formName"
        ></app-config-setting>
        <mat-error
          *ngIf="
            mailingAddressCityControl.invalid &&
            (mailingAddressCityControl.dirty ||
              !mailingAddressCityControl.untouched)
          "
        >
          {{ formHelper.getErrorMessage(mailingAddressCityControl) }}
        </mat-error>
      </mat-form-field>
    </ng-container>
  </div>

  <ng-container *ngIf="form.get('billingAddress') as billingAddressControl">
    <div *ngIf="formHelper.isVisible(billingAddressControl)">
      <h3 class="mat-h3 mango-text-secondary">
        <span i18n="@@UserProfile.billingAddress.text">Fakturační adresa</span>
      </h3>
      <mat-radio-group
        name="billingAddress"
        id="billingAddress"
        formControlName="billingAddress"
      >
        <div>
          <mat-radio-button
            class="mango-radio-button"
            matInput
            value="AS1"
            i18n="@@UserProfile.radio.billingAddress.AS1"
            >Jako trvalé bydliště/sídlo</mat-radio-button
          ><br />
          <mat-radio-button
            class="mango-radio-button"
            matInput
            value="AS2"
            i18n="@@UserProfile.radio.billingAddress.AS2"
            >Jako doručovací</mat-radio-button
          ><br />
          <mat-radio-button
            class="mango-radio-button"
            matInput
            value="OWN"
            i18n="@@UserProfile.radio.billingAddress.AS3"
            >Vlastní</mat-radio-button
          >
        </div>
      </mat-radio-group>
      <app-config-setting
        input="billingAddress"
        [formName]="formName"
      ></app-config-setting>
    </div>
  </ng-container>
  <div *ngIf="billingAddressVisible()">
    <!--    <div *ngIf="formHelper.isVisible(form.get('billingAddressSearch'))">-->
    <!--      <mat-form-field class="mango-form-field">-->
    <!--        <popup-list formControlName="billingAddressSearch" id="billingAddressSearch" name="billingAddressSearch" [dataSource]="dataAddressSearch" [filterDb]='true' [noValue]="null" [defaultLimit]="999" [exactOnly]="true"-->
    <!--                    i18n-placeholder="@@UserProfile.billingAddressSearch.label" placeholder="Vyhledání fakturační adresy" i18n-title="@@UserProfile.billingAddressSearch.title" title="Zde jednoduše vyhledejte adresu"></popup-list>-->
    <!--        <mat-icon *ngIf="form.get('billingAddressSearch').value !== null" matSuffix (click)="clearAddress('billingAddressSearch')">cancel</mat-icon>-->
    <!--        <app-config-setting input="billingAddressSearch" [formName]="formName"></app-config-setting>-->
    <!--      </mat-form-field>-->
    <!--    </div>-->
    <!--    -->

    <!--    <div *ngIf="formHelper.isVisible(form.get('billingAddressSearch'))">-->
    <!--      <mat-form-field class="mango-form-field">-->
    <!--        <mat-select formControlName="billingAddressSearch" id="billingAddressSearch" name="billingAddressSearch"-->
    <!--                    i18n-placeholder="@@UserProfile.addressSearch.placeholder" placeholder="Vyhledání fakturační adresy">-->
    <!--          <mat-option>-->
    <!--            <ngx-mat-select-search formControlName="addressFiltering"-->
    <!--                                   i18n-placeholderLabel="@@UserProfile.addressSearch.searching.placeholder"-->
    <!--                                   placeholderLabel="Vyhledejte adresu"-->
    <!--                                   i18n-noEntriesFoundLabel="@@UserProfile.addressSearch.noEntriesFoundLabel.placeholder"-->
    <!--                                   noEntriesFoundLabel="Žádná adresa nenalezena"-->
    <!--            ></ngx-mat-select-search>-->
    <!--          </mat-option>-->
    <!--          <mat-option *ngFor="let option of suggestedAddresses" [value]="option.id">-->
    <!--            {{option.name}}-->
    <!--          </mat-option>-->
    <!--        </mat-select>-->
    <!--        <app-config-setting input="billingAddressSearch" [formName]="formName"></app-sc-config-setting>-->
    <!--      </mat-form-field>-->
    <!--    </div>-->

    <div
      *ngIf="form.get('billingAddressSearch') as billingAddressSearchControl"
    >
      <mat-form-field
        *ngIf="formHelper.isVisible(billingAddressSearchControl)"
        class="mango-form-field"
      >
        <input
          type="text"
          formControlName="billingAddressSearch"
          id="billingAddressSearch"
          name="billingAddressSearch"
          i18n-placeholder="@@UserProfile.billingAddressSearch.placeholder"
          placeholder="Vyhledání adresy"
          aria-label="Number"
          matInput
          [matAutocomplete]="auto"
        />
        <mat-autocomplete
          [autoActiveFirstOption]="true"
          #auto="matAutocomplete"
        >
          <mat-option
            *ngFor="let option of suggestedAddresses"
            [value]="option.name"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
        <app-config-setting
          input="billingAddressSearch"
          [formName]="formName"
        ></app-config-setting>
      </mat-form-field>
    </div>

    <ng-container
      *ngIf="
        form.get('billingAddressRecipient') as billingAddressRecipientControl
      "
    >
      <mat-form-field
        *ngIf="formHelper.isVisible(billingAddressRecipientControl)"
        class="mango-form-field"
        [appearance]="formHelper.appearance()"
      >
        <mat-label i18n="@@UserProfile.billingAddressRecipient.label"
          >Příjemce</mat-label
        >
        <input
          matInput
          formControlName="billingAddressRecipient"
          id="billingAddressRecipient"
          name="billingAddressRecipient"
          i18n-title="@@UserProfile.billingAddressRecipient.title"
          title="Příjemce"
          [required]="billingAddressRecipientControl | hasRequiredField"
        />
        <app-config-setting
          input="billingAddressRecipient"
          [formName]="formName"
        ></app-config-setting>
        <mat-error
          *ngIf="
            billingAddressRecipientControl.invalid &&
            (billingAddressRecipientControl.dirty ||
              !billingAddressRecipientControl.untouched)
          "
        >
          {{ formHelper.getErrorMessage(billingAddressRecipientControl) }}
        </mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container
      *ngIf="form.get('billingAddressStreet') as billingAddressStreetControl"
    >
      <mat-form-field
        *ngIf="formHelper.isVisible(billingAddressStreetControl)"
        class="mango-form-field"
        [appearance]="formHelper.appearance()"
      >
        <mat-label i18n="@@UserProfile.billingAddressStreet.label"
          >Ulice</mat-label
        >
        <input
          matInput
          formControlName="billingAddressStreet"
          id="billingAddressStreet"
          name="billingAddressStreet"
          i18n-title="@@UserProfile.billingAddressStreet.title"
          title="Ulice"
          [required]="billingAddressStreetControl | hasRequiredField"
        />
        <app-config-setting
          input="billingAddressStreet"
          [formName]="formName"
        ></app-config-setting>
        <mat-error
          *ngIf="
            billingAddressStreetControl.invalid &&
            (billingAddressStreetControl.dirty ||
              !billingAddressStreetControl.untouched)
          "
        >
          {{ formHelper.getErrorMessage(billingAddressStreetControl) }}
        </mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container
      *ngIf="form.get('billingAddressHouseId') as billingAddressHouseIdControl"
    >
      <mat-form-field
        *ngIf="formHelper.isVisible(billingAddressHouseIdControl)"
        class="mango-form-field"
        [appearance]="formHelper.appearance()"
      >
        <mat-label i18n="@@UserProfile.billingAddressHouseId.label"
          >Číslo</mat-label
        >
        <input
          matInput
          formControlName="billingAddressHouseId"
          id="billingAddressHouseId"
          name="billingAddressHouseId"
          i18n-title="@@UserProfile.billingAddressHouseId.title"
          title="Č.p."
          [required]="billingAddressHouseIdControl | hasRequiredField"
        />
        <app-config-setting
          input="billingAddressHouseId"
          [formName]="formName"
        ></app-config-setting>
        <mat-error
          *ngIf="
            billingAddressHouseIdControl.invalid &&
            (billingAddressHouseIdControl.dirty ||
              !billingAddressHouseIdControl.untouched)
          "
        >
          {{ formHelper.getErrorMessage(billingAddressHouseIdControl) }}
        </mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container
      *ngIf="form.get('billingAddressZip') as billingAddressZipControl"
    >
      <mat-form-field
        *ngIf="formHelper.isVisible(billingAddressZipControl)"
        class="mango-form-field"
        [appearance]="formHelper.appearance()"
      >
        <mat-label i18n="@@UserProfile.billingAddressZip.label">PSČ</mat-label>
        <input
          matInput
          formControlName="billingAddressZip"
          id="billingAddressZip"
          name="billingAddressZip"
          i18n-title="@@UserProfile.billingAddressZip.title"
          title="PSČ"
          [required]="billingAddressZipControl | hasRequiredField"
        />
        <app-config-setting
          input="billingAddressZip"
          [formName]="formName"
        ></app-config-setting>
        <mat-error
          *ngIf="
            billingAddressZipControl.invalid &&
            (billingAddressZipControl.dirty ||
              !billingAddressZipControl.untouched)
          "
        >
          {{ formHelper.getErrorMessage(billingAddressZipControl) }}
        </mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container
      *ngIf="form.get('billingAddressCity') as billingAddressCityControl"
    >
      <mat-form-field
        *ngIf="formHelper.isVisible(billingAddressCityControl)"
        class="mango-form-field"
        [appearance]="formHelper.appearance()"
      >
        <mat-label i18n="@@UserProfile.billingAddressCity.label"
          >Obec</mat-label
        >
        <input
          matInput
          formControlName="billingAddressCity"
          id="billingAddressCity"
          name="billingAddressCity"
          i18n-title="@@UserProfile.billingAddressCity.title"
          title="Obec"
          [required]="billingAddressCityControl | hasRequiredField"
        />
        <app-config-setting
          input="billingAddressCity"
          [formName]="formName"
        ></app-config-setting>
        <mat-error
          *ngIf="
            billingAddressCityControl.invalid &&
            (billingAddressCityControl.dirty ||
              !billingAddressCityControl.untouched)
          "
        >
          {{ formHelper.getErrorMessage(billingAddressCityControl) }}
        </mat-error>
      </mat-form-field>
    </ng-container>
  </div>
</form>
