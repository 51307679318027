import { Injectable } from '@angular/core';
import { DataBase, FilterInfo } from '@og_soft/data-base';
import { PhoneNumberPipe } from '@og_soft/phone-number';
import { PopupOption } from '@isp-sc/shared/ui';
import { DataUnitsService } from './data-units.service';
import { RecNode, RecNodeMeta } from '@isp-sc/shared/segments/data/common';

@Injectable({
  providedIn: 'root',
})
export class DataNodesService extends DataBase<RecNode, RecNodeMeta> {
  protected getAllUrl(): string {
    return 'nodes' as const;
  }

  override metaFetchFilterInfo(meta: RecNodeMeta): FilterInfo[] {
    if (meta) {
      const info: FilterInfo[] = [];
      if (meta.saName) {
        const saName = meta.saName;
        let detail = '';
        if (meta.saIdent) {
          detail =
            ' (' +
            (meta.saIdentType === 'PHONE'
              ? PhoneNumberPipe.prototype.transform('+' + meta.saIdent)
              : meta.saIdent) +
            ')';
        } else if (meta.unitAddress) {
          detail =
            ' (' + DataUnitsService.shortenAddress(meta.unitAddress) + ')';
        }
        info.push({
          label: $localize`:@@DataNodesService.filterInfo.service.label:Služba`,
          filterNames: ['saId'],
          value: saName,
          valueDetail: detail,
        });
      }

      return info;
    }
    return super.metaFetchFilterInfo(meta);
  }

  override tranformOptions(row: any): PopupOption {
    return {
      id: row.id,
      name: row.name + (row.parIdent ? ', ' + row.parIdent : ''),
    };
  }
}
