import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterInfo } from '@og_soft/data-base';
import { ProcessMeta } from '@isp-sc/shared/segments/params/common';
import { DatePipe } from '@angular/common';
import { ParamServices } from './param-services';
import { ParamBase } from './param-base';
import { Process } from '@isp-sc/shared/segments/params/common';
import { DataProcessGeneralService } from './data-process-general.service';

// Data pro existující a nové procesy získávám z jiných endpointů, ala mají stejný formát.
@Injectable({
  providedIn: 'root',
})
export class DataProcessService extends DataProcessGeneralService {
  // getallUrl = 'processes';

  protected getAllUrl(): string {
    return 'processes' as const;
  }

  getAttributesAsParams(id: number, defId: number): Observable<ParamBase[]> {
    return this.getone(id, { defId }).pipe(
      map((param) => {
        return this.transformDataToParams(param, this.getServices());
      })
    );
  }

  protected override recordPostprocess(record: Process): Process {
    if (record.lastActivity) {
      record.lastActivityObj = new Date(record.lastActivity);
    }
    return record;
  }

  override getServices(): ParamServices {
    return {
      dataValuePatterns: this.dataValuePatterns,
      dataCodeList: this.dataCodeList,
      dataValueDomain: this.dataValueDomain,
      dataProcessPremises: this.dataProcessPremises,
      dataProcessServices: this.dataProcessServices,
      dataProcessUnits: this.dataProcessUnits,
    };
  }

  override metaFetchFilterInfo(meta: ProcessMeta): FilterInfo[] {
    if (meta) {
      const info: FilterInfo[] = [];
      if (meta.fulltext) {
        info.push({
          label: $localize`:@@DataProcess.filterInfo.fulltext.label:Fulltext`,
          filterNames: ['fulltext'],
          value: '"' + meta.fulltext + '"',
        });
      }
      if (meta.dateFrom || meta.dateTo) {
        const dp = new DatePipe('cs-CZ');
        const dates = [];
        dates.push(
          meta.dateFrom ? dp.transform(meta.dateFrom, 'd. M. yyyy') : ''
        );
        dates.push(meta.dateTo ? dp.transform(meta.dateTo, 'd. M. yyyy') : '');
        info.push({
          label: $localize`:@@DataProcess.filterInfo.date.label:Nahlášeno`,
          filterNames: ['dateFrom', 'dateTo'],
          value: dates.join(' - '),
        });
      }
      if (meta.typeName) {
        info.push({
          label: $localize`:@@DataProcess.filterInfo.type.label:Typ požadavku`,
          filterNames: ['typeId'],
          value: meta.typeName,
        });
      }
      if (meta.unitName) {
        info.push({
          label: $localize`:@@DataProcess.filterInfo.unit.label:Místo poskytování služeb`,
          filterNames: ['unit'],
          value: meta.unitName,
        });
      }

      return info;
    }
    return super.metaFetchFilterInfo(meta);
  }
}
