import { FormGroup, ValidationErrors } from '@angular/forms';

export const communicationChannelValidator: (
  control: FormGroup
) => ValidationErrors | null = (
  control: FormGroup
): ValidationErrors | null => {
  const infoEmail = control.get('infoEmail');
  const infoPost = control.get('infoPost');
  return !infoEmail?.value && !infoPost?.value
    ? { communicationChannel: true }
    : null;
};
