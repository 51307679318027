<form [formGroup]="form">
  <ng-container *ngIf="form.get('type') as typeControl">
    <mat-radio-group
      name="type"
      id="type"
      formControlName="type"
      *ngIf="formHelper.isVisible(typeControl)"
    >
      <div>
        <mat-radio-button
          class="mango-radio-button"
          value="S"
          i18n="@@UserProfile.radio.type.S"
          >Soukromá osoba
        </mat-radio-button>
        <br />
        <mat-radio-button
          class="mango-radio-button"
          value="F"
          i18n="@@UserProfile.radio.type.F"
          >Fyzická osoba
        </mat-radio-button>
        <br />
        <mat-radio-button
          class="mango-radio-button"
          value="P"
          i18n="@@UserProfile.radio.type.P"
          >Právnická osoba
        </mat-radio-button>
      </div>

      <mat-error
        *ngIf="
          typeControl.invalid && (typeControl.dirty || !typeControl.untouched)
        "
      >
        {{ formHelper.getErrorMessage(typeControl) }}
      </mat-error> </mat-radio-group
    ><app-config-setting
      input="type"
      [formName]="formName"
    ></app-config-setting>
  </ng-container>

  <ng-container *ngIf="form.get('degree') as degreeControl">
    <mat-form-field
      *ngIf="formHelper.isVisible(degreeControl)"
      class="mango-form-field"
      [appearance]="formHelper.appearance()"
    >
      <mat-label i18n="@@UserProfile.degree.label">Titul před</mat-label>
      <input
        matInput
        formControlName="degree"
        id="degree"
        name="degree"
        i18n-title="@@UserProfile.degree.title"
        title="Titul před"
        [required]="degreeControl | hasRequiredField"
      />
      <app-config-setting
        input="degree"
        [formName]="formName"
      ></app-config-setting>
      <mat-error
        *ngIf="
          degreeControl.invalid &&
          (degreeControl.dirty || !degreeControl.untouched)
        "
      >
        {{ formHelper.getErrorMessage(degreeControl) }}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="form.get('firstName') as firstNameControl">
    <mat-form-field
      *ngIf="formHelper.isVisible(firstNameControl)"
      class="mango-form-field"
      [appearance]="formHelper.appearance()"
    >
      <mat-label i18n="@@UserProfile.firstName.label">Jméno</mat-label>
      <input
        matInput
        formControlName="firstName"
        id="firstName"
        name="firstName"
        i18n-title="@@UserProfile.firstName.title"
        title="Jméno"
        [required]="firstNameControl | hasRequiredField"
      />
      <app-config-setting
        input="firstName"
        [formName]="formName"
      ></app-config-setting>
      <mat-error
        *ngIf="
          firstNameControl.invalid &&
          (firstNameControl.dirty || !firstNameControl.untouched)
        "
      >
        {{ formHelper.getErrorMessage(firstNameControl) }}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="form.get('lastName') as lastNameControl">
    <mat-form-field
      *ngIf="formHelper.isVisible(lastNameControl)"
      class="mango-form-field"
      [appearance]="formHelper.appearance()"
    >
      <mat-label i18n="@@UserProfile.lastName.label">Příjmení</mat-label>
      <input
        matInput
        formControlName="lastName"
        id="lastName"
        name="lastName"
        i18n-title="@@UserProfile.lastName.title"
        title="Příjmení"
        [required]="lastNameControl | hasRequiredField"
      />
      <app-config-setting
        input="lastName"
        [formName]="formName"
      ></app-config-setting>
      <mat-error
        *ngIf="
          lastNameControl.invalid &&
          (lastNameControl.dirty || !lastNameControl.untouched)
        "
      >
        {{ formHelper.getErrorMessage(lastNameControl) }}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="form.get('degreeBehind') as degreeBehindControl">
    <mat-form-field
      *ngIf="formHelper.isVisible(degreeBehindControl)"
      class="mango-form-field"
      [appearance]="formHelper.appearance()"
    >
      <mat-label i18n="@@UserProfile.degreeBehind.label">Titul za</mat-label>
      <input
        matInput
        formControlName="degreeBehind"
        id="degreeBehind"
        name="degreeBehind"
        i18n-title="@@UserProfile.degreeBehind.title"
        title="Titul za"
        [required]="degreeBehindControl | hasRequiredField"
      />
      <app-config-setting
        input="degreeBehind"
        [formName]="formName"
      ></app-config-setting>
      <mat-error
        *ngIf="
          degreeBehindControl.invalid &&
          (degreeBehindControl.dirty || !degreeBehindControl.untouched)
        "
      >
        {{ formHelper.getErrorMessage(degreeBehindControl) }}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="form.get('companyName') as companyNameControl">
    <mat-form-field
      *ngIf="formHelper.isVisible(companyNameControl)"
      class="mango-form-field"
      [appearance]="formHelper.appearance()"
    >
      <mat-label i18n="@@UserProfile.companyName.label">Společnost</mat-label>
      <input
        matInput
        formControlName="companyName"
        id="companyName"
        name="companyName"
        i18n-title="@@UserProfile.companyName.title"
        title="Společnost"
        [required]="companyNameControl | hasRequiredField"
      />
      <app-config-setting
        input="companyName"
        [formName]="formName"
      ></app-config-setting>
      <mat-error
        *ngIf="
          companyNameControl.invalid &&
          (companyNameControl.dirty || !companyNameControl.untouched)
        "
      >
        {{ formHelper.getErrorMessage(companyNameControl) }}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="form.get('companyId') as companyIdControl">
    <mat-form-field
      *ngIf="formHelper.isVisible(companyIdControl)"
      class="mango-form-field"
      [appearance]="formHelper.appearance()"
    >
      <mat-label i18n="@@UserProfile.companyId.label">IČ</mat-label>
      <input
        matInput
        formControlName="companyId"
        id="companyId"
        name="companyId"
        i18n-title="@@UserProfile.companyId.title"
        title="IČ"
        [required]="companyIdControl | hasRequiredField"
      />
      <app-config-setting
        input="companyId"
        [formName]="formName"
      ></app-config-setting>
      <mat-error
        *ngIf="
          companyIdControl.invalid &&
          (companyIdControl.dirty || !companyIdControl.untouched)
        "
      >
        {{ formHelper.getErrorMessage(companyIdControl) }}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="form.get('companyFid') as companyFidControl">
    <mat-form-field
      *ngIf="formHelper.isVisible(companyFidControl)"
      class="mango-form-field"
      [appearance]="formHelper.appearance()"
    >
      <mat-label i18n="@@UserProfile.companyFid.label">DIČ</mat-label>
      <input
        matInput
        formControlName="companyFid"
        id="companyFid"
        name="companyFid"
        i18n-title="@@UserProfile.companyFid.title"
        title="DIČ"
        [required]="companyFidControl | hasRequiredField"
      />
      <app-config-setting
        input="companyFid"
        [formName]="formName"
      ></app-config-setting>
      <mat-error
        *ngIf="
          companyFidControl.invalid &&
          (companyFidControl.dirty || !companyFidControl.untouched)
        "
      >
        {{ formHelper.getErrorMessage(companyFidControl) }}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="form.get('companyVatId') as companyVatIdControl">
    <mat-form-field
      *ngIf="formHelper.isVisible(companyVatIdControl)"
      class="mango-form-field"
      [appearance]="formHelper.appearance()"
    >
      <mat-label i18n="@@UserProfile.companyVatId.label">IČ DPH</mat-label>
      <input
        matInput
        formControlName="companyVatId"
        id="companyVatId"
        name="companyVatId"
        i18n-title="@@UserProfile.companyVatId.title"
        title="IČ DPH"
        [required]="companyVatIdControl | hasRequiredField"
      />
      <app-config-setting
        input="companyVatId"
        [formName]="formName"
      ></app-config-setting>
      <mat-error
        *ngIf="
          companyVatIdControl.invalid &&
          (companyVatIdControl.dirty || !companyVatIdControl.untouched)
        "
      >
        {{ formHelper.getErrorMessage(companyVatIdControl) }}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="form.get('vatRegistered') as vatRegisteredControl">
    <mat-checkbox
      *ngIf="formHelper.isVisible(vatRegisteredControl)"
      matInput
      formControlName="vatRegistered"
      id="vatRegistered"
      name="vatRegistered"
      i18n-title="@@UserProfile.vatRegistered.title"
      title="Určuje, zda je zákazník plátce DPH. Musí mít vyplněno DIČ a musí být typ fyzická nebo právnická osoba."
      i18n="@@UserProfile.vatRegistered.label"
      >Plátce DPH
    </mat-checkbox>
    <app-config-setting
      input="vatRegistered"
      [formName]="formName"
    ></app-config-setting>
  </ng-container>

  <ng-container
    *ngIf="form.get('vatRegisteredFrom') as vatRegisteredFromControl"
  >
    <mat-form-field
      *ngIf="formHelper.isVisible(vatRegisteredFromControl)"
      class="mango-form-field"
      [appearance]="formHelper.appearance()"
    >
      <mat-label i18n="@@UserProfile.vatRegisteredFrom.label"
        >Datum změny</mat-label
      >
      <datetime-control
        [pickerType]="DatetimeControlPickerType.calendar"
        formControlName="vatRegisteredFrom"
        id="vatRegisteredFrom"
        name="vatRegisteredFrom"
        title="Datum, od kterého platí, zda zákazník je plátce DPH nebo není. Změna musí být od 1. v měsíci."
        [required]="form.get('vatRegisteredFrom') | hasRequiredField"
        #df2
      ></datetime-control>
      <datetime-control-trigger
        matSuffix
        [for]="df2"
      ></datetime-control-trigger>
      <mat-error *ngIf="form.controls.vatRegisteredFrom.errors">{{
        formHelper.getErrorMessage(vatRegisteredFromControl)
      }}</mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="form.get('statutary') as statutaryControl">
    <mat-form-field
      *ngIf="formHelper.isVisible(statutaryControl)"
      class="mango-form-field"
      [appearance]="formHelper.appearance()"
    >
      <mat-label i18n="@@UserProfile.statutary.label"
        >Statutární orgán</mat-label
      >
      <input
        matInput
        formControlName="statutary"
        id="statutary"
        name="statutary"
        i18n-title="@@UserProfile.statutary.title"
        title="Statutární orgán"
        [required]="statutaryControl | hasRequiredField"
      />
      <app-config-setting
        input="statutary"
        [formName]="formName"
      ></app-config-setting>
      <mat-error
        *ngIf="
          statutaryControl.invalid &&
          (statutaryControl.dirty || !statutaryControl.untouched)
        "
      >
        {{ formHelper.getErrorMessage(statutaryControl) }}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="form.get('personalNumber') as personalNumberControl">
    <mat-form-field
      *ngIf="formHelper.isVisible(personalNumberControl)"
      class="mango-form-field"
      [appearance]="formHelper.appearance()"
    >
      <mat-label i18n="@@UserProfile.personalNumber.label"
        >Rodné číslo</mat-label
      >
      <input
        matInput
        formControlName="personalNumber"
        id="personalNumber"
        name="personalNumber"
        i18n-title="@@UserProfile.personalNumber.title"
        title="Rodné číslo"
        [required]="personalNumberControl | hasRequiredField"
      />
      <app-config-setting
        input="personalNumber"
        [formName]="formName"
      ></app-config-setting>
      <mat-error
        *ngIf="
          personalNumberControl.invalid &&
          (personalNumberControl.dirty || !personalNumberControl.untouched)
        "
      >
        {{ formHelper.getErrorMessage(personalNumberControl) }}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="form.get('dayOfBirth') as dayOfBirthControl">
    <mat-form-field
      *ngIf="formHelper.isVisible(dayOfBirthControl)"
      class="mango-form-field"
      [appearance]="formHelper.appearance()"
    >
      <mat-label i18n="@@UserProfile.dayOfBirth.label"
        >Datum narození</mat-label
      >
      <datetime-control
        [pickerType]="DatetimeControlPickerType.calendar"
        formControlName="dayOfBirth"
        id="dayOfBirth"
        name="dayOfBirth"
        i18n-title="@@UserProfile.dayOfBirth.title"
        title="Datum narození"
        [required]="dayOfBirthControl | hasRequiredField"
        #df1
      ></datetime-control>
      <datetime-control-trigger
        matSuffix
        [for]="df1"
      ></datetime-control-trigger>
      <mat-error
        *ngIf="
          dayOfBirthControl.invalid &&
          (dayOfBirthControl.dirty || !dayOfBirthControl.untouched) &&
          dayOfBirthControl.errors
        "
        >{{ formHelper.getErrorMessage(dayOfBirthControl) }}</mat-error
      >
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="form.get('documentNumber') as documentNumberControl">
    <mat-form-field
      *ngIf="formHelper.isVisible(documentNumberControl)"
      class="mango-form-field"
      [appearance]="formHelper.appearance()"
    >
      <mat-label i18n="@@UserProfile.documentNumber.label"
        >Číslo občanského průkazu</mat-label
      >
      <input
        matInput
        formControlName="documentNumber"
        id="documentNumber"
        name="documentNumber"
        i18n-title="@@UserProfile.documentNumber.title"
        title="Číslo občanského průkazu"
        [required]="documentNumberControl | hasRequiredField"
      />
      <app-config-setting
        input="documentNumber"
        [formName]="formName"
      ></app-config-setting>
      <mat-error
        *ngIf="
          documentNumberControl.invalid &&
          (documentNumberControl.dirty || !documentNumberControl.untouched)
        "
      >
        {{ formHelper.getErrorMessage(documentNumberControl) }}
      </mat-error>
    </mat-form-field>
  </ng-container>
</form>
