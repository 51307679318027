import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatError,
  MatFormField,
  MatFormFieldModule,
  MatLabel,
  MatPrefix,
  MatSuffix,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
  MatOption,
} from '@angular/material/autocomplete';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatRipple } from '@angular/material/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { PopupColorBadgeComponent } from './popup-list/popup-color-badge.component';
import { PopupOptionLabelComponent } from './popup-list/popup-option-label.component';
import { PopupListComponent } from './popup-list/popup-list.component';

@NgModule({
  declarations: [
    PopupListComponent,
    PopupOptionLabelComponent,
    PopupColorBadgeComponent,
  ],
  imports: [
    CommonModule,
    MatFormField,
    MatInput,
    ReactiveFormsModule,
    MatAutocomplete,
    MatAutocompleteTrigger,
    MatOption,
    MatIcon,
    MatLabel,
    MatSuffix,
    MatIconButton,
    MatRipple,
    SvgIconComponent,
    MatError,
    MatPrefix,
    MatFormFieldModule,
  ],
  exports: [
    PopupListComponent,
    PopupColorBadgeComponent,
    PopupOptionLabelComponent,
  ],
})
export class PopupListModule {}
