import { Injectable } from '@angular/core';
import { DataBase } from '@og_soft/data-base';
import { Address } from '@isp-sc/shared/segments/data/common';

@Injectable({
  providedIn: 'root',
})
export class DataAddressesService extends DataBase<Address> {
  protected getAllUrl(): string {
    return 'search-address' as const;
  }
}
