import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogConfig, DialogService } from '@og_soft/dialog';
import { DataUnicreditService } from '@isp-sc/shared/segments/data/data-access';
import { UserDataUnicreditService } from '@isp-sc/shared/segments/user/data-access';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { CardPaymentComponent } from '@isp-sc/isp-sc/segments/selfcare/features';
import { PhonetCreditComponent } from '@isp-sc/isp-sc/segments/selfcare/features';

@Component({
  selector: 'app-eu-unicredit',
  templateUrl: './eu-unicredit.component.html',
  styleUrls: ['./eu-unicredit.component.scss'],
})
export class EuUnicreditComponent implements OnInit {
  constructor(
    public dataService: DataUnicreditService,
    public dataUserUnicredit: UserDataUnicreditService,
    public session: SessionService,
    public dialog: DialogService
  ) {}
  public varSym?: string;
  public balance?: number;
  public phonet?: number;
  public saPhonet?: number;

  @ViewChild('formCardPay', { static: false })
  formCardPay?: CardPaymentComponent;
  @ViewChild('formPhonetCredit', { static: false })
  formPhonetCredit?: PhonetCreditComponent;

  ngOnInit(): void {
    this.dataUserUnicredit.getone(this.session.user?.id).subscribe(
      (next) => {
        if (next !== null) {
          this.varSym = next.ucVarSymbol;
          this.balance = next.ucBalance;
          this.phonet = next.ucPhonet;
          this.saPhonet = next.saPhonet;
        }
      },
      (err) => {
        console.log('err', err);
      }
    );
  }

  payment(): void {
    const dconfp = new DialogConfig();
    dconfp.data = {
      amount: 0,
      varsym: this.varSym,
      typeAttach: null,
      saId: 0,
    };
    const dref = this.dialog.open(CardPaymentComponent, dconfp);
  }

  phonetCredit(): void {
    const dconf = new DialogConfig();
    dconf.data = {
      amount: 0,
      userServices: 0,
    };

    const dref = this.dialog.open(PhonetCreditComponent, dconf);
  }

  getCurrencySymbol(): string {
    return this.session.locDefaults.currencyCode;
  }
}
