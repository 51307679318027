import { Inject, Injectable } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { UserEditProtectedService } from './user-edit-protected.service';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  FormHelperService,
  InstallationConfigService,
} from '@isp-sc/shared/data-access';
import {
  SC_CONFIGURATION,
  ScConfiguration,
  UserEditFormType,
} from '@isp-sc/shared/common';
import { MangoFormControl } from '@isp-sc/shared/segments/mango-processes/common';
import { UserType } from '@isp-sc/shared/segments/user/common';
import {
  moduloValidator,
  personalIdValidator,
} from '@isp-sc/shared/segments/selfcare/common';

@Injectable({
  providedIn: 'root',
})
export class UserEditFormService {
  constructor(
    public session: SessionService,
    public formHelper: FormHelperService,
    private installationConfigService: InstallationConfigService,
    private userEditProtectedService: UserEditProtectedService,
    @Inject(SC_CONFIGURATION) private scConfiguration: ScConfiguration
  ) {
    this.forms = new Map<UserEditFormType, FormGroup>();
  }

  forms: Map<UserEditFormType, FormGroup>;
  private userTypeChange: BehaviorSubject<null | boolean> = new BehaviorSubject<
    null | boolean
  >(null);

  private static preparePersonal(): FormGroup {
    return new FormGroup({
      type: new MangoFormControl('', [Validators.required]),
      degree: new MangoFormControl(''),
      firstName: new MangoFormControl(''),
      lastName: new MangoFormControl(''),
      degreeBehind: new MangoFormControl(''),
      companyName: new MangoFormControl(''),
      companyId: new MangoFormControl(''),
      companyFid: new MangoFormControl(''),
      companyVatId: new MangoFormControl(''),
      vatRegistered: new MangoFormControl(''),
      vatRegisteredFrom: new MangoFormControl('', [Validators.required]),
      statutary: new MangoFormControl(''),
      personalNumber: new MangoFormControl('', [personalIdValidator()]),
      dayOfBirth: new MangoFormControl(''),
      documentNumber: new MangoFormControl('', [
        Validators.pattern('^((SL)?\\d{6,10})|(\\d{6,12})$'),
      ]),
    });
  }

  private static prepareAddress(): FormGroup {
    return new FormGroup({
      addressSearch: new MangoFormControl(null),
      street: new MangoFormControl(''),
      houseId: new MangoFormControl(''),
      zip: new MangoFormControl(''),
      city: new MangoFormControl(''),
      mailingAddress: new MangoFormControl(''),
      mailingAddressSearch: new MangoFormControl(null),
      mailingAddressRecipient: new MangoFormControl(''),
      mailingAddressStreet: new MangoFormControl(''),
      mailingAddressHouseId: new MangoFormControl(''),
      mailingAddressZip: new MangoFormControl(''),
      mailingAddressCity: new MangoFormControl(''),
      billingAddress: new MangoFormControl(''),
      billingAddressSearch: new MangoFormControl(null),
      billingAddressRecipient: new MangoFormControl(''),
      billingAddressStreet: new MangoFormControl(''),
      billingAddressHouseId: new MangoFormControl(''),
      billingAddressZip: new MangoFormControl(''),
      billingAddressCity: new MangoFormControl(''),
    });
  }

  private static prepareParams(): FormGroup {
    return new FormGroup({});
  }

  private static preparePayments(): FormGroup {
    return new FormGroup({
      accountPreNumber: new MangoFormControl('', [
        Validators.min(10),
        Validators.max(999999),
        moduloValidator(11),
      ]),
      accountNumber: new MangoFormControl(''),
      accountBank: new MangoFormControl(''),
      accountIban: new MangoFormControl(''),
      accountSpecSym: new MangoFormControl('', [
        Validators.pattern('^\\d{2,10}$'),
      ]),
      sipo: new MangoFormControl('', [Validators.pattern('^\\d{2,10}$')]),
      agreementName: new MangoFormControl(''),
      accountVarSym: new MangoFormControl(''),
    });
  }

  private static prepareContacts(): FormGroup {
    return new FormGroup({
      emails: new MangoFormControl(''),
      phones: new MangoFormControl(''),
      fax: new MangoFormControl(''),
      infoMarketing: new MangoFormControl(''),
      infoEmail: new MangoFormControl(''),
      infoPost: new MangoFormControl(''),
      infoSms: new MangoFormControl(''),
      premises: new MangoFormControl(''),
    });
  }

  private static prepareCredentials(): FormGroup {
    return new FormGroup({
      password: new MangoFormControl(''),
      passwordCheck: new MangoFormControl(''),
    });
  }

  private static prepareProtected(): FormGroup {
    return new FormGroup({});
  }

  getForm(type: UserEditFormType): FormGroup {
    if (!this.forms.has(type)) {
      return this.prepareForm(type);
    } else {
      return (
        this.forms.get(type) ?? new FormGroup<any>({}, { updateOn: 'change' })
      );
    }
  }

  getAllForms(): FormGroup[] {
    return [
      this.getForm(UserEditFormType.personal),
      this.getForm(UserEditFormType.addresses),
      this.getForm(UserEditFormType.contacts),
      this.getForm(UserEditFormType.credentials),
      this.getForm(UserEditFormType.payments),
      this.getForm(UserEditFormType.params),
      this.getForm(UserEditFormType.protected),
    ];
  }

  private prepareForm(type: UserEditFormType): FormGroup {
    switch (type) {
      case UserEditFormType.personal:
        this.forms.set(type, UserEditFormService.preparePersonal());
        break;
      case UserEditFormType.addresses:
        this.forms.set(type, UserEditFormService.prepareAddress());
        break;
      case UserEditFormType.contacts:
        this.forms.set(type, UserEditFormService.prepareContacts());
        break;
      case UserEditFormType.credentials:
        this.forms.set(type, UserEditFormService.prepareCredentials());
        break;
      case UserEditFormType.payments:
        this.forms.set(type, UserEditFormService.preparePayments());
        break;
      case UserEditFormType.params:
        this.forms.set(type, UserEditFormService.prepareParams());
        break;
      case UserEditFormType.protected:
        this.forms.set(type, UserEditFormService.prepareProtected());
        break;
    }
    return (
      this.forms.get(type) ?? new FormGroup<any>({}, { updateOn: 'change' })
    );
  }

  getFormData(type: string, userType: UserType): Observable<{} | false> {
    // Volání markdirty zde odstraní validační chyby nastavené tím, že jsem neudělil souhlas.
    const typ = type as UserEditFormType;
    this.formHelper.markDirty(this.forms.get(typ)!);
    this.forms.get(typ)?.updateValueAndValidity();
    // Pozor, v rámci registrace se může stát, že některý z formulářů má všechny položky skryté.
    // V takovou chvíli není formulář valid. Proto je v následující podmínce negace invalidního stavu.
    if (!this.forms.get(typ)?.invalid) {
      // Kontrolu na chráněná data je potřeba dělat pouze nad validním formulářem, protože maže
      // chyby u itemů. Pokud by se to mělo dělat najednou, je nutné přepsat metodu checkProtectedDataValidConsent
      // aby zachovávala ostatní validační chyby.
      return this.checkProtectedDataValidConsent(typ, userType).pipe(
        map((valid) => {
          if (valid) {
            return this.forms.get(typ)?.getRawValue();
          } else {
            this.session.message(
              $localize`:@@UserEditFormService.save.message.invalid.protectedData:Nelze uložit data. Je vyžadován souhlas s nakládáním s chráněnými údaji.`
            );
            return false;
          }
        })
      );
    } else {
      this.formHelper.markDirty(this.forms.get(typ)!);
      console.log('Neprošla validace formuláře: ', typ);
      console.log('Ten formulář: ', this.forms.get(typ));
      console.log('Chyby v něm: ', this.forms.get(typ)?.errors);
      console.log('Surové hodnoty: ', this.forms.get(typ)?.getRawValue());
      // console.log('XXXXXXXXXXXXXXXXXX errors: ', this.forms.get(type).errors);
      // console.log('XXXXXXXXXXXXXXXXXX errors: ', this.forms.get(type).getRawValue());
      this.session.message(
        $localize`:@@UserEditFormService.save.message.invalid.protectedData:Některé položky nejsou vyplněné nebo nemají správnou hodnotu.`
      );
      return of(false);
    }
  }

  getAllFormsData(): Observable<any | false> {
    let result: any = {};
    let valid = true;

    const dataSubscriptions: any[] = [];
    const userType = this.getForm(UserEditFormType.personal).get('type')?.value;
    Object.keys(UserEditFormType).forEach((type: string) => {
      dataSubscriptions.push(this.getFormData(type, userType));
    });

    return forkJoin(dataSubscriptions).pipe(
      map((results) => {
        for (const data of results) {
          if (data) {
            result = { ...result, ...(data as {}) };
          } else {
            valid = false;
          }
        }

        if (valid) {
          result.ct = this.scConfiguration.ct;
          result.login = result.emails.split(',')[0];
          return result;
        } else {
          return false;
        }
      })
    );
  }

  /**
   *  Zkontroluje všechny itemy fomuláře, vrátí jestli je pro každý znich udělený souhlas a nastaví
   *  validační chybu těm, které souhlas nemají.
   */
  private checkProtectedDataValidConsent(
    type: UserEditFormType,
    userType: UserType
  ): Observable<boolean> {
    const form = this.forms.get(type);
    const checkItemsSubscriptions: any[] = [];
    Object.keys(form!.controls).forEach((key) => {
      const control = form?.get(key);
      if (
        control?.value !== '' &&
        control?.value !== null &&
        control?.value !== undefined
      ) {
        checkItemsSubscriptions.push(
          this.userEditProtectedService
            .validConsentForItem(key, [this.forms.get(type)!], userType)
            .pipe(
              tap((itemValid) => {
                // Chráněná dat kontroluji až když projde validace, takže z principu by nemělo vadit, že vymažu všechny
                // chyby, protože celý formulář by měl být validní.
                control.setErrors(itemValid ? null : { noConsent: true });
                control.markAsTouched();
              })
            )
        );
      }
    });
    if (checkItemsSubscriptions.length > 0) {
      return forkJoin(checkItemsSubscriptions).pipe(
        map((results) => results.every((valid) => valid === true))
      );
    } else {
      return of(true);
    }
  }

  /**
   * Metoda provede inicializaci konfigurace pro modul můj profil.
   */
  public profileDefaultConfig(
    form: FormGroup,
    formName: UserEditFormType
  ): void {
    // V případě, že prohlížím konfiguraci SC, vidím vše
    if (
      this.session.user &&
      this.session.getOption(SessionService.RIGHT_BASE + '.config.user') ===
        this.session.user.id.toString()
    ) {
      return;
    }
    // Aplikuji nastavení z configu (defaultní hodnoty, disablovatelnost, viditelnost, povinnost)
    // Zrovna v uživatelském profilu máme, kvůli konvenci v pojmenování
    // práv, kterou chce support (aby první úroveň odpovídala routě) a
    // zároveň zobrazovací e editační části, nestandardně zanořené options
    // a musíme metodě defaultConfig v nepovinném čtvrtém parametru podsunout
    // připravenou konfiguraci pro formulář.
    let preparedConf;
    if (this.session.user) {
      preparedConf =
        this.session.options.SELFCARE &&
        this.session.options.SELFCARE['user-profile'] &&
        this.session.options.SELFCARE['user-profile'].edit
          ? this.session.options.SELFCARE['user-profile'].edit
          : {};
      this.formHelper.defaultConfig(
        form,
        formName,
        {},
        preparedConf[formName] || {}
      );
    } else {
      this.installationConfigService
        .getRegistrationFormConfig()
        .subscribe((options) => {
          preparedConf = options;
          this.formHelper.defaultConfig(
            form,
            formName,
            {},
            preparedConf[formName] || {}
          );
        });
    }
  }

  /**
   * Slouží k zachycení události, kdy se změní typ uživatele. Toto je potřeba dát vědět komponentě
   * pro chráněná data, protože ta musí aktualizovat zobrazené kategorie chráněných dat,
   * které na typu uživatele závisí.
   */
  public userTypeChanged(): Observable<boolean | null> {
    return this.userTypeChange.asObservable();
  }

  /**
   * Zajišťuje vyvolání události při změně typu uživatele.
   */
  public userTypeUpdate(): void {
    this.userTypeChange.next(true);
  }
}
