<!-- Toto by měl být funkční formulář na parametry - vytváří už hotové inputy. Pokud bude potřeba je jen zobrazit, -->
<form [formGroup]="form">
  <app-param-form
    *ngIf="session?.user?.id as objectId"
    [paramsService]="userParamsService"
    [form]="form"
    [paramFormName]="'userParamsForm'"
    [objectId]="{ id: objectId }"
    [dependencyService]="userParamsDependencyService"
  ></app-param-form>
</form>
