<mat-card appearance="outlined">
  <mat-card-header> Integrační test pro platební bránu ČSOB </mat-card-header>
  <mat-card-content>
    Test slouží pro ověření funkčnosti implementace platební brány v dané lokaci
    proti integračnímu prostředí ČSOB. Testovací scénář vychází z dokumentu
    <a
      href="https://github.com/csob/paymentgateway/wiki/P%C5%99echod-do-produk%C4%8Dn%C3%ADho-prost%C5%99ed%C3%AD"
      >Testovací scénář</a
    >.
    <h4>Test komunikace - echo (GET)</h4>
    <div>Provedeno: {{ results.echoGet.done ? 'ANO' : 'NE' }}</div>
    Pro provedení pouze stiskněte tlačítko.
    <div>
      <button
        mat-raised-button
        color="primary"
        [disabled]="actualTest !== 'echoGet'"
        (click)="doEchoGet()"
      >
        Spustit
      </button>
    </div>
    <ng-container *ngIf="results.echoGet.done">
      <div>
        Výsledek:
        <span [ngStyle]="{ color: results.echoGet.result ? 'green' : 'red' }">
          {{ results.echoGet.result ? 'SUCCESS' : 'FAIL' }}
        </span>
      </div>
      <div>Zpráva: {{ results.echoGet.message }}</div>
    </ng-container>

    <h4>Test komunikace - echo (POST)</h4>
    <div>Provedeno: {{ results.echoPost.done ? 'ANO' : 'NE' }}</div>
    Pro provedení pouze stiskněte tlačítko.
    <div>
      <button
        mat-raised-button
        color="primary"
        [disabled]="actualTest !== 'echoPost'"
        (click)="doEchoPost()"
      >
        Spustit
      </button>
    </div>
    <ng-container *ngIf="results.echoPost.done">
      <div>
        Výsledek:
        <span [ngStyle]="{ color: results.echoPost.result ? 'green' : 'red' }">
          {{ results.echoPost.result ? 'SUCCESS' : 'FAIL' }}
        </span>
      </div>
      <div>Zpráva: {{ results.echoPost.message }}</div>
    </ng-container>

    <h4>Úspěšně autorizovaná platba</h4>
    <div>Provedeno: {{ results.successfulPayment.done ? 'ANO' : 'NE' }}</div>
    Pro provedení pouze stiskněte tlačítko, budete přesměrování na platební
    bránu. Na platební bráně zadat testovací kartu 4154610001000209, expiraci
    11/20 a CVC 100.
    <div>
      <button
        mat-raised-button
        color="primary"
        [disabled]="actualTest !== 'successfulPayment'"
        (click)="doPayment()"
      >
        Spustit
      </button>
    </div>
    <ng-container *ngIf="results.successfulPayment.done">
      <div>
        Výsledek:
        <span
          [ngStyle]="{
            color: results.successfulPayment.result ? 'green' : 'red'
          }"
        >
          {{ results.successfulPayment.result ? 'SUCCESS' : 'FAIL' }}
        </span>
      </div>
      <div>Zpráva: {{ results.successfulPayment.message }}</div>
    </ng-container>

    <h4>Uživatelem zrušená platba</h4>
    <div>Provedeno: {{ results.canceledPayment.done ? 'ANO' : 'NE' }}</div>
    Pro provedení pouze stiskněte tlačítko, budete přesměrování na platební
    bránu. Na platební bráně zrušit platbu kliknutím na odkaz "Zrušit platbu a
    návrat zpět do e-shopu".
    <div>
      <button
        mat-raised-button
        color="primary"
        [disabled]="actualTest !== 'canceledPayment'"
        (click)="doPayment()"
      >
        Spustit
      </button>
    </div>
    <ng-container *ngIf="results.canceledPayment.done">
      <div>
        Výsledek:
        <span
          [ngStyle]="{
            color: results.canceledPayment.result ? 'green' : 'red'
          }"
        >
          {{ results.canceledPayment.result ? 'SUCCESS' : 'FAIL' }}
        </span>
      </div>
      <div>Zpráva: {{ results.canceledPayment.message }}</div>
    </ng-container>

    <h4>Expirovaná (zrušená) platba</h4>
    <div>Provedeno: {{ results.expiredPayment.done ? 'ANO' : 'NE' }}</div>
    Pro provedení pouze stiskněte tlačítko, budete přesměrování na platební
    bránu. Nezadávat údaje pro autorizaci, po 30 minutách se provede
    přesměrování zpět do e-shopu obchodníka.
    <div>
      <button
        mat-raised-button
        color="primary"
        [disabled]="actualTest !== 'expiredPayment'"
        (click)="doPayment()"
      >
        Spustit
      </button>
    </div>
    <ng-container *ngIf="results.expiredPayment.done">
      <div>
        Výsledek:
        <span
          [ngStyle]="{ color: results.expiredPayment.result ? 'green' : 'red' }"
        >
          {{ results.expiredPayment.result ? 'SUCCESS' : 'FAIL' }}
        </span>
      </div>
      <div>Zpráva: {{ results.echoGet.message }}</div>
    </ng-container>

    <h4>Reverzovaná platba</h4>
    <div>Provedeno: {{ results.reversedPayment.done ? 'ANO' : 'NE' }}</div>
    Pro provedení pouze stiskněte tlačítko, budete přesměrování na platební
    bránu. Na platební bráně zadat testovací kartu 5542860001000224, expiraci
    06/23 a CVC 100.
    <div>
      <button
        mat-raised-button
        color="primary"
        [disabled]="actualTest !== 'reversedPayment'"
        (click)="doPayment()"
      >
        Spustit
      </button>
    </div>
    <ng-container *ngIf="results.reversedPayment.done">
      <div>
        Výsledek:
        <span
          [ngStyle]="{
            color: results.reversedPayment.result ? 'green' : 'red'
          }"
        >
          {{ results.reversedPayment.result ? 'SUCCESS' : 'FAIL' }}
        </span>
      </div>
      <div>Zpráva: {{ results.reversedPayment.message }}</div>
    </ng-container>
  </mat-card-content>
</mat-card>
