import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { hasRequiredField } from './has-required-fields';

// FIXME: Do knihovny!!!

@Pipe({
  name: 'hasRequiredField',
  pure: false,
})
export class HasRequiredFieldPipe implements PipeTransform {
  transform(control: AbstractControl | null): boolean {
    return hasRequiredField(control);
  }
}
