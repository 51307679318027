import { Inject, Injectable } from '@angular/core';
import { DataBase, DataBaseServiceConfig } from '@og_soft/data-base';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScConfiguration, SC_CONFIGURATION } from '@isp-sc/shared/common';
import { HttpClient } from '@angular/common/http';
import { CachingInterceptor } from '@og_soft/cache';

@Injectable({
  providedIn: 'root',
})
export class CheckDbService extends DataBase<boolean> {
  constructor(
    @Inject(SC_CONFIGURATION) private scConfiguration: ScConfiguration,
    http: HttpClient,
    configService: DataBaseServiceConfig,
    cache: CachingInterceptor
  ) {
    super(http, configService, cache);
  }
  protected getAllUrl(): string {
    return 'db-ready' as const;
  }

  isDbReady(): Observable<boolean> {
    const params: any = {};
    if (
      this.scConfiguration.dbVersion === 'devel' ||
      this.scConfiguration.dbVersion === 'test'
    ) {
      params.notStable = 1;
    }
    return this.getOneBy(params).pipe(map((result) => result));
  }
}
