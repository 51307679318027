import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { DataUserServicesService } from './data-user-services.service';
import {
  DataBase,
  DataBaseServiceConfig,
  FilterInfo,
  GetallResult,
} from '@og_soft/data-base';
import { CachingInterceptor } from '@og_soft/cache';
import {
  RecUserPayments,
  RecUserPaymentsMeta,
} from '@isp-sc/shared/segments/data/common';

@Injectable({
  providedIn: 'root',
})
export class DataUsersPaymentsService extends DataBase<RecUserPayments> {
  protected getAllUrl(): string {
    return 'user-payments' as const;
  }

  constructor(
    public override http: HttpClient,
    public serviceConfig: DataBaseServiceConfig,
    public userServices: DataUserServicesService,
    private cachingInterceptor: CachingInterceptor
  ) {
    super(http, serviceConfig, cachingInterceptor);
  }

  protected override dataPostprocess(
    data: GetallResult<RecUserPayments>
  ): GetallResult<RecUserPayments> {
    // FIXME optimalizovat, aby se to dělalo jedním getAll s parametry - viz. např. user-nodes.service.ts
    for (let i = 0; i < data.data.length; i++) {
      for (let iSa = 0; iSa < data.data[i].service.length; iSa++) {
        this.userServices
          .getone(data.data[i].service[iSa].id)
          .subscribe((d) => {
            data.data[i].service[iSa].name = d.saName;
            data.data[i].service[iSa].paramIdent =
              DataUserServicesService.saIdentGet(d);
            this.onDataCollected.emit();
          });
      }
    }
    return super.dataPostprocess(data);
  }

  override metaFetchFilterInfo(meta: RecUserPaymentsMeta): FilterInfo[] {
    if (meta) {
      const info: FilterInfo[] = [];
      if (meta.dateFrom || meta.dateTo) {
        const dp = new DatePipe('cs-CZ');
        const dates = [];
        dates.push(
          meta.dateFrom ? dp.transform(meta.dateFrom, 'd. M. yyyy') : ''
        );
        dates.push(meta.dateTo ? dp.transform(meta.dateTo, 'd. M. yyyy') : '');
        info.push({
          label: $localize`:@@DataUserPayments.filterInfo.date.label:Období`,
          filterNames: ['dateFrom', 'dateTo'],
          value: dates.join(' - '),
        });
      }

      return info;
    }
    return super.metaFetchFilterInfo(meta);
  }
}
