export enum ParamType {
  SELECT = 'SELECT',
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  BOOL = 'BOOL',
  NUM = 'NUM',
  FLOAT = 'FLOAT',
  TEXT = 'TEXT',
  TIME = 'TIME',
  STRING = 'STRING',
  PRICE = 'PRICE',
  FILE = 'FILE',
  PREMISEIDBOX = 'PREMISEIDBOX',
  PREMISEIDMEGA = 'PREMISEIDMEGA',
  UNKNOWN = '',
}
