<form [formGroup]="form">
  <ng-container *ngIf="form.get('accountPreNumber') as accountPreNumberControl">
    <mat-form-field
      *ngIf="formHelper.isVisible(accountPreNumberControl)"
      class="mango-form-field"
      [appearance]="formHelper.appearance()"
    >
      <mat-label i18n="@@UserProfile.accountPreNumber.label"
        >Předčíslí</mat-label
      >
      <input
        matInput
        formControlName="accountPreNumber"
        id="accountPreNumber"
        name="accountPreNumber"
        i18n-title="@@UserProfile.accountPreNumber.title"
        title="Předčíslí"
        [required]="accountPreNumberControl | hasRequiredField"
      />
      <app-config-setting
        input="accountPreNumber"
        [formName]="formName"
      ></app-config-setting>
      <mat-error
        *ngIf="
          accountPreNumberControl.invalid &&
          (accountPreNumberControl.dirty || !accountPreNumberControl.untouched)
        "
      >
        {{ formHelper.getErrorMessage(accountPreNumberControl) }}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="form.get('accountNumber') as accountNumberControl">
    <mat-form-field
      *ngIf="formHelper.isVisible(accountNumberControl)"
      class="mango-form-field"
      [appearance]="formHelper.appearance()"
    >
      <mat-label i18n="@@UserProfile.accountNumber.label">Číslo účtu</mat-label>
      <input
        matInput
        formControlName="accountNumber"
        id="accountNumber"
        name="accountNumber"
        i18n-title="@@UserProfile.accountNumber.title"
        title="Číslo účtu"
        [required]="accountNumberControl | hasRequiredField"
      />
      <app-config-setting
        input="accountNumber"
        [formName]="formName"
      ></app-config-setting>
      <mat-error
        *ngIf="
          accountNumberControl.invalid &&
          (accountNumberControl.dirty || !accountNumberControl.untouched)
        "
      >
        {{ formHelper.getErrorMessage(accountNumberControl) }}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="form.get('accountBank') as accountBankControl">
    <app-popup-list
      *ngIf="formHelper.isVisible(accountBankControl)"
      formFieldClass="mango-form-field"
      id="accountBank"
      name="accountBank"
      label="Banka"
      i18n-label="@@UserProfile.accountBank.title"
      formControlName="accountBank"
      [formGroup]="form"
      [dataSource]="bankCodesService"
      [filterDb]="false"
      [defaultFilters]="popupCondition"
      [required]="accountBankControl | hasRequiredField"
      [appearance]="formHelper.appearance()"
      [fixOptionsToInputWidth]="true"
      [customError]="formHelper.getErrorMessage(accountBankControl)"
    >
      <app-config-setting
        input="accountBank"
        [formName]="formName"
      ></app-config-setting>
    </app-popup-list>
  </ng-container>

  <ng-container *ngIf="form.get('accountIban') as accountIbanControl">
    <mat-form-field
      *ngIf="formHelper.isVisible(accountIbanControl)"
      class="mango-form-field"
      [appearance]="formHelper.appearance()"
    >
      <mat-label i18n="@@UserProfile.accountIban.label"
        >Číslo účtu ve formátu IBAN</mat-label
      >
      <input
        matInput
        formControlName="accountIban"
        id="accountIban"
        name="accountIban"
        i18n-title="@@UserProfile.accountIban.title"
        title="Číslo účtu ve formátu IBAN"
        [required]="accountIbanControl | hasRequiredField"
      />
      <app-config-setting
        input="accountIban"
        [formName]="formName"
      ></app-config-setting>
      <mat-error
        *ngIf="
          accountIbanControl.invalid &&
          (accountIbanControl.dirty || !accountIbanControl.untouched)
        "
      >
        {{ formHelper.getErrorMessage(accountIbanControl) }}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="form.get('accountSpecSym') as accountSpecSymControl">
    <mat-form-field
      *ngIf="formHelper.isVisible(accountSpecSymControl)"
      class="mango-form-field"
      [appearance]="formHelper.appearance()"
    >
      <mat-label i18n="@@UserProfile.accountSpecSym.label"
        >Specifický symbol</mat-label
      >
      <input
        matInput
        formControlName="accountSpecSym"
        id="accountSpecSym"
        name="accountSpecSym"
        i18n-title="@@UserProfile.accountSpecSym.title"
        title="Specifický symbol"
        [required]="accountSpecSymControl | hasRequiredField"
      />
      <app-config-setting
        input="accountSpecSym"
        [formName]="formName"
      ></app-config-setting>
      <mat-error
        *ngIf="
          accountSpecSymControl.invalid &&
          (accountSpecSymControl.dirty || !accountSpecSymControl.untouched)
        "
      >
        {{ formHelper.getErrorMessage(accountSpecSymControl) }}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="form.get('sipo') as sipoControl">
    <mat-form-field
      *ngIf="formHelper.isVisible(sipoControl)"
      class="mango-form-field"
      [appearance]="formHelper.appearance()"
    >
      <mat-label i18n="@@UserProfile.sipo.label">SIPO</mat-label>
      <input
        matInput
        formControlName="sipo"
        id="sipo"
        name="sipo"
        i18n-title="@@UserProfile.sipo.title"
        title="SIPO"
        [required]="sipoControl | hasRequiredField"
      />
      <app-config-setting
        input="sipo"
        [formName]="formName"
      ></app-config-setting>
      <mat-error
        *ngIf="
          sipoControl.invalid && (sipoControl.dirty || !sipoControl.untouched)
        "
      >
        {{ formHelper.getErrorMessage(sipoControl) }}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="form.get('agreementName') as agreementNameControl">
    <mat-form-field
      *ngIf="formHelper.isVisible(agreementNameControl)"
      class="mango-form-field"
      [appearance]="formHelper.appearance()"
    >
      <mat-label i18n="@@UserProfile.agreementName.label"
        >Číslo smlouvy</mat-label
      >
      <input
        matInput
        formControlName="agreementName"
        id="agreementName"
        name="agreementName"
        i18n-title="@@UserProfile.agreementName.title"
        title="Číslo smlouvy"
        [required]="agreementNameControl | hasRequiredField"
      />
      <app-config-setting
        input="agreementName"
        [formName]="formName"
      ></app-config-setting>
      <mat-error
        *ngIf="
          agreementNameControl.invalid &&
          (agreementNameControl.dirty || !agreementNameControl.untouched)
        "
      >
        {{ formHelper.getErrorMessage(agreementNameControl) }}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="form.get('accountVarSym') as accountVarSymControl">
    <mat-form-field
      *ngIf="formHelper.isVisible(accountVarSymControl)"
      class="mango-form-field"
      [appearance]="formHelper.appearance()"
    >
      <mat-label i18n="@@UserProfile.accountVarSym.label"
        >Variabilní symbol</mat-label
      >
      <input
        matInput
        formControlName="accountVarSym"
        id="accountVarSym"
        name="accountVarSym"
        i18n-title="@@UserProfile.accountVarSym.title"
        title="Variabilní symbol"
        [required]="accountVarSymControl | hasRequiredField"
      />
      <app-config-setting
        input="accountVarSym"
        [formName]="formName"
      ></app-config-setting>
      <mat-error
        *ngIf="
          accountVarSymControl.invalid &&
          (accountVarSymControl.dirty || !accountVarSymControl.untouched)
        "
      >
        {{ formHelper.getErrorMessage(accountVarSymControl) }}
      </mat-error>
    </mat-form-field>
  </ng-container>
</form>
