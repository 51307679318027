import { Injectable } from '@angular/core';
import { PopupOption } from '@isp-sc/shared/ui';
import { DataBase } from '@og_soft/data-base';
import { ProcessUnits } from '@isp-sc/shared/segments/process/common';

@Injectable({
  providedIn: 'root',
})
export class ProcessUnitsService extends DataBase<ProcessUnits> {
  protected getAllUrl(): string {
    return 'process-units' as const;
  }

  override tranformOptions(row: ProcessUnits): PopupOption {
    return { id: String(row.nodeId), name: row.nodeCacheName };
  }
}
