import { Injectable } from '@angular/core';
import { DataBase, DataBaseServiceConfig } from '@og_soft/data-base';
import { ProcessStatesHistory } from '@isp-sc/shared/segments/mango-processes/common';

@Injectable({
  providedIn: 'root',
})
export class DataProcessStatesHistoryService extends DataBase<ProcessStatesHistory> {
  // getallUrl = "process-states-history";
  protected getAllUrl(): string {
    return 'process-states-history' as const;
  }

  override configureUrl(config: DataBaseServiceConfig) {
    this.baseurl = config.mangoUrl ?? '';
  }
}
