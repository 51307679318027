import { Injectable } from '@angular/core';
import { DataBase } from '@og_soft/data-base';

@Injectable({
  providedIn: 'root',
})
export class NodeParamUniqueValidatorService extends DataBase<boolean> {
  protected getAllUrl(): string {
    return 'node-params-validation' as const;
  }
}
