<dialog-title>
  <h3 i18n="@@CardPayment.message.text">Platba kartou</h3>
</dialog-title>

<dialog-content>
  <form [formGroup]="form">
    <ng-container *ngIf="form.get('amount') as amountControl">
      <mat-form-field
        *ngIf="formHelper.isVisible(amountControl)"
        class="mango-form-field"
        [appearance]="formHelper.appearance()"
      >
        <mat-label i18n="@@CardPayment.amount.label">Částka</mat-label>
        <input
          matInput
          formControlName="amount"
          id="amount"
          name="amount"
          type="number"
        />
        <span matSuffix>{{ session.user?.locDefaults.currencySymbol }}</span>
        <mat-error
          *ngIf="
            amountControl.invalid &&
            (amountControl.dirty || !amountControl.untouched)
          "
        >
          {{ formHelper.getErrorMessage(amountControl) }}
        </mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="form.get('varSym') as varSymControl">
      <mat-form-field
        *ngIf="formHelper.isVisible(varSymControl)"
        class="mango-form-field"
        [appearance]="formHelper.appearance()"
      >
        <mat-label i18n="@@CardPayment.varSym.label"
          >Variabilní symbol</mat-label
        >
        <input matInput formControlName="varSym" id="varSym" name="varSym" />
        <mat-error
          *ngIf="
            varSymControl.invalid &&
            (varSymControl.dirty || !varSymControl.untouched)
          "
        >
          {{ formHelper.getErrorMessage(varSymControl) }}
        </mat-error>
      </mat-form-field>
    </ng-container>

    <div *ngIf="isTest" class="mango-text-secondary">
      <div style="font-style: italic">
        Následující instrukce se zobrazují pouze na testovací verzi
      </div>
      <div>
        Použij kartu <b>4000007000010006</b> nebo něco z
        <a
          href="https://github.com/csob/paymentgateway/wiki/Test-cards-and-credentials"
          >https://github.com/csob/paymentgateway/wiki/Test-cards-and-credentials</a
        >
      </div>
    </div>
  </form>
</dialog-content>

<dialog-actions>
  <button
    mat-flat-button
    dialog-close
    i18n="
      Zavřít|Tlačítko na zavření dialogu v dialogu při uzavření
      ticketu@@ProcessFinish.button.Close"
  >
    Zavřít
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="save()"
    i18n="
      Odeslat|Tlačítko na uložení dialogu v dialogu při uzavření
      ticketu@@ProcessFinish.button.Send"
  >
    Uložit
  </button>
</dialog-actions>
<app-session-processing></app-session-processing>
