import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogConfig, DialogService } from '@og_soft/dialog';
import {
  ProcessPremisesService,
  ProcessServicesService,
  ProcessUnitsService,
} from '@isp-sc/shared/segments/process/data-access';
import { ProcessParamDef } from '@isp-sc/shared/segments/process/common';
import { PopupOption } from '@isp-sc/shared/ui';
import { FormGroup } from '@angular/forms';
import { ForgetTableComponent } from '@isp-sc/shared/ui';
import {
  dateRangeValidate,
  DatetimeControlPickerType,
} from '@og_soft/datetime-control';
import { ProcessMessageDialogComponent } from '@isp-sc/isp-sc/segments/selfcare/features';
import { CrossFieldErrorMatcher } from '@isp-sc/shared/segments/selfcare/common';
import {
  ProcessParamsDefService,
  ProcessRedirectService,
} from '@isp-sc/shared/segments/process/data-access';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { FormHelperService } from '@isp-sc/shared/data-access';
import {
  DataCodeListService,
  DataProcessService,
  DataValueDomainService,
  DataValueDomainPatternService,
  ValueDomain,
} from '@isp-sc/shared/segments/params/data-access';
import { Process } from '@isp-sc/shared/segments/params/common';
import {
  DataProcessNotesService,
  DataProcessTypesService,
} from '@isp-sc/shared/segments/mango-processes/data-access';
import {
  MangoFormControl,
  ProcessNote,
  ProcessType,
} from '@isp-sc/shared/segments/mango-processes/common';

@Component({
  selector: 'app-process-list',
  templateUrl: './process-list.component.html',
  styleUrls: ['./process-list.component.scss'],
  providers: [DialogService],
})
export class ProcessListComponent implements OnInit {
  protected readonly DatetimeControlPickerType = DatetimeControlPickerType;
  processTypesNew: ProcessType[] = [];
  displayedParams: ProcessParamDef[] = [];
  displayedParamsOptions: Map<number, PopupOption[]>;
  activeProcesses: Process[] = [];

  processNotes: ProcessNote[] = [];

  filters: FormGroup;
  filterValues: any = { activeOnly: true };
  @ViewChild(ForgetTableComponent, { static: false })
  public tab?: ForgetTableComponent;

  // just specific error type, otherwise it will highlight on any error within the group
  errorMatcher = new CrossFieldErrorMatcher(['dateRangeInvalid']);

  procesTypesFilterOptions: PopupOption[] = [];
  procesUnitsFilterOptions: PopupOption[] = [];

  constructor(
    public dataService: DataProcessService,
    public dataTypesService: DataProcessTypesService,
    private paramsDefService: ProcessParamsDefService,
    private historyService: DataProcessNotesService,
    private session: SessionService,
    private dialog: DialogService,
    private dataValuePatterns: DataValueDomainPatternService,
    private dataCodeList: DataCodeListService,
    private dataValueDomain: DataValueDomainService,
    private dataProcessPremises: ProcessPremisesService,
    private dataProcessUnits: ProcessUnitsService,
    private dataProcessServices: ProcessServicesService,
    private processRedirectService: ProcessRedirectService,
    private processUnits: ProcessUnitsService,
    public formHelper: FormHelperService
  ) {
    this.displayedParamsOptions = new Map<number, PopupOption[]>();
    this.filters = new FormGroup(
      {
        typeId: new MangoFormControl(''),
        fulltext: new MangoFormControl(''),
        dateFrom: new MangoFormControl(''),
        dateTo: new MangoFormControl(''),
        //    state: new MangoFormControl(''),
        unit: new MangoFormControl(''),
        activeOnly: new MangoFormControl(''),
      },
      { validators: dateRangeValidate('dateFrom', 'dateTo') }
    );
  }

  ngOnInit(): void {
    this.filters.patchValue(this.filterValues);

    this.dataTypesService.getTypesForInsert().subscribe((next) => {
      console.log('Typy procesů pro založení nového: ', next);
      this.processTypesNew = next.data;
    });

    this.paramsDefService
      .getall({ displayedSc: true }, 3600)
      .subscribe((next) => {
        console.log('Parametry k zobrazení: ', next);
        this.displayedParams = next.data;
        this.prepareParamOptions();
      });

    this.dataService.getall({ hasJob: '1' }).subscribe((next) => {
      this.activeProcesses = next.data;
      this.activeProcesses.forEach((process) => {
        this.historyService
          .getall({ id: process.id, 'types[]': [13] })
          .subscribe((notes) => {
            process.notes = notes.data;
          });
      });
    });

    this.dataTypesService.optionsGet().subscribe((types) => {
      this.procesTypesFilterOptions = types;
    });

    this.processUnits.optionsGet().subscribe((units) => {
      this.procesUnitsFilterOptions = units;
    });
  }

  addComment(process: Process): void {
    const dialogConfig = new DialogConfig();
    dialogConfig.data = {};
    const dialogRef = this.dialog.open(
      ProcessMessageDialogComponent,
      dialogConfig
    );

    dialogRef.afterClosed.subscribe((result) => {
      if (result) {
        console.log(
          'XXXXXXXXXXXXXXXXXXXXXX data z editačního formuláře ',
          result
        );
        this.session.processingSet(true);
        this.sendNote(process, result.note);
      }
    });
  }

  sendNote(process: Process, noteText: string): void {
    this.historyService.sendNote(process.id, noteText).subscribe(
      (next) => {
        console.log('Zpráva odeslána');
        this.session.processingSet(false);
        this.getNotes(process);
        // toto dělám na pozadí, nepotřebuju to nijak hlásit
      },
      (err) => {
        console.log('Chyba při ukládání zprávy ' + err);
      }
    );
  }

  /**
   * Vrátí poslední zprávu od technika nebo prázdný řetězec
   */
  getLastNote(process: Process): string {
    if (process.notes && process.notes.length > 0) {
      // eslint-disable-next-line for-direction
      for (let i = process.notes.length - 1; i <= 0; i--) {
        if (!process.notes[i].user) {
          return process.notes[i].note;
        }
      }
      return '';
    } else {
      return '';
    }
  }

  transformParamValue(value: string, param: ProcessParamDef): string {
    if (param.type === 'SELECT') {
      const options = this.displayedParamsOptions.get(param.defId);
      if (options?.length) {
        const found = options.find((option) => option.id === value.toString());
        return found ? found.name : value;
      } else {
        return value;
      }
    } else {
      return value;
    }
  }

  private prepareParamOptions(): void {
    this.displayedParams.forEach((param) => {
      if (param.type === 'SELECT') {
        const domain = new ValueDomain(param.domain, {
          dataValuePatterns: this.dataValuePatterns,
          dataCodeList: this.dataCodeList,
          dataValueDomain: this.dataValueDomain,
          dataProcessPremises: this.dataProcessPremises,
          dataProcessServices: this.dataProcessServices,
          dataProcessUnits: this.dataProcessUnits,
        });
        domain.optionsGet({}).subscribe((next) => {
          this.displayedParamsOptions.set(param.defId, next);
        });
      }
    });
  }

  openProcess(id: number, typeId: number | null): void {
    this.processRedirectService.openProcess(id, typeId);
  }

  settingVisible(): boolean {
    const opt = this.session.getOption('SELFCARE.process-list.note.button');
    return !(opt && opt.includes('hidden'));
  }

  settingEditableVisible(): boolean {
    const opt = this.session.getOption('SELFCARE.process-list.edit.button');
    return !(opt && opt.includes('hidden'));
  }

  priorityNameVisible(): boolean {
    const opt = this.session.getOption('SELFCARE.process-list.priorityName');
    return !(opt && opt.includes('hidden'));
  }

  priorityNameTitleVisible(): boolean {
    const opt = this.session.getOption(
      'SELFCARE.process-list.priorityNameTitle'
    );
    return !(opt && opt.includes('hidden'));
  }

  getNotes(process: Process, expanded = true): void {
    // zajistění stánutí poznámek jen při rozkliknutí procesu
    if (expanded) {
      this.historyService.fetchProcessNotes(process.id).subscribe((next) => {
        process.notes = next.data
          .sort((a, b) => (a.time < b.time ? 1 : -1))
          .map((note) => {
            note.userName =
              note.mangoUserId == this.session.user?.backendLoginId
                ? this.session.user?.fullname
                : $localize`:@@ProcessList.notes.mangoUser:Operátor`;
            return note;
          });
      });
    }
  }
}
