import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  viewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import {
  DatetimeControlComponent,
  DatetimeControlPickerType,
} from '@og_soft/datetime-control';

import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { PopupOption } from '@isp-sc/shared/ui';
import { ParamControlType } from '@isp-sc/shared/segments/params/common';
import {
  DataCodeListService,
  DataValueDomainService,
  DataValueDomainPatternService,
  MangoParamFormControl,
  ParamBase,
} from '@isp-sc/shared/segments/params/data-access';
import { DependencyService } from '@isp-sc/shared/segments/dependency/data-access';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  ProcessPremisesService,
  ProcessServicesService,
  ProcessUnitsService,
} from '@isp-sc/shared/segments/process/data-access';

@Component({
  selector: 'app-param',
  templateUrl: './param.component.html',
  styleUrls: ['./param.component.scss'],
  providers: [
    DataValueDomainPatternService,
    DataCodeListService,
    DataValueDomainService,
    ProcessPremisesService,
    ProcessServicesService,
    ProcessUnitsService,
  ],
})
export class ParamComponent implements OnInit, DoCheck {
  @Input() param?: ParamBase;
  @Input() form?: FormGroup;
  // Formulář, ze kterého získávám veškerá data pro vyhodnocení závislostí
  // typicky to bude formulář nadřazený tomuto.
  @Input() dependencyDataForm?: FormGroup;
  @Input() dependencyService?: DependencyService;
  // Těmito eventy posílá parametr nadřazenému frame informace o tom, že bylo stisknuto tlačítko na přidání resp. odebrání.
  @Output() addIndexed: EventEmitter<ParamBase> = new EventEmitter<ParamBase>();
  @Output() deleteIndexed: EventEmitter<ParamBase> =
    new EventEmitter<ParamBase>();
  @ViewChild('df1') public dtc?: DatetimeControlComponent;
  private oldValue: any;
  readonly PARAM_CONTROL_TYPE = ParamControlType;
  public options: PopupOption[] = [];
  public required?: boolean;

  public timeOptions = [
    '00:00',
    '00:30',
    '01:00',
    '01:30',
    '02:00',
    '02:30',
    '03:00',
    '03:30',
    '04:00',
    '04:30',
    '05:00',
    '05:30',
    '06:00',
    '06:30',
    '07:00',
    '07:30',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30',
    '22:00',
    '22:30',
    '23:00',
    '23:30',
  ];

  get isValid(): boolean {
    if (this.param?.name) {
      return this.form?.controls[this.param.name].valid ?? false;
    } else {
      return false;
    }
  }

  get isDirty(): boolean {
    if (this.param?.name) {
      return this.form?.controls[this.param.name].dirty ?? false;
    } else {
      return false;
    }
  }

  constructor(private session: SessionService) {}

  ngOnInit(): void {
    this.param?.getOptions().subscribe((next) => {
      this.options = next;
      this.updatePopupValue();
    });

    this.param?.getRequired().subscribe((next) => {
      this.required = next;
      this.updatePopupValue();
    });

    this.myDependencyResolve();
    const additionalData = {
      USER_ID: this.session.user?.id,
    };

    this.param?.loadOptions(
      'null',
      this.dependencyDataForm?.getRawValue(),
      additionalData
    );

    if (
      this.param?.name &&
      this.param?.controlType !== ParamControlType.check // vyjímka pro checkbox viz. koment u onCheckChange()
    ) {
      this.form?.controls[this.param.name].valueChanges.subscribe((value) => {
        if (value !== this.oldValue) {
          // console.log('Vyhodnocuji závislosti: změnil se parametr ' + this.param.name +
          // ' Původně: ' + this.oldValue + ' Aktuálně: '+ value);
          this.oldValue = value;
          this.allDependeciesResolve();
        }
      });
    }
    this.allDependeciesResolve();
  }

  ngDoCheck(): void {
    if (this.param?.name) {
      this.oldValue = this.form?.controls[this.param.name].value;
    }
  }

  isVisible(): boolean {
    if (this.param?.noOptions) {
      // Týká se jen atributů - pokud by neměli žádný obor hodnot, nemá cenu je zobrazovat
      return false;
    } else if (this.param?.name) {
      return (this.form?.controls[this.param.name] as MangoParamFormControl)
        .visible;
    } else {
      return true;
    }
  }

  isDisabled(): boolean {
    if (this.param?.name) {
      return (this.form?.controls[this.param.name] as MangoParamFormControl)
        .disabled;
    } else {
      return false;
    }
  }

  myDependencyResolve(): void {
    if (this.param?.name && this.param.indexName) {
      const control = this.form?.controls[
        this.param.name
      ] as MangoParamFormControl;

      this.dependencyService?.controlDependencyResolve(
        control,
        this.dependencyDataForm?.getRawValue(),
        this.param.indexName
      );
    }
  }

  allDependeciesResolve(): void {
    (Object as any).values(this.form?.controls).forEach((control: any) => {
      control = control as MangoParamFormControl;
      if (control.param.name === this.param?.name) {
        return; // svůj stav neaktualizuju. return se uvnitř forEach chová jako continue, takže ostatní hodnoty projdu
      }

      if (this.param?.indexName !== undefined) {
        this.dependencyService?.controlDependencyResolve(
          control,
          this.dependencyDataForm?.getRawValue(),
          this.param.indexName
        );
      }
    });
  }

  getErrorMessage(): string {
    // FIXME: Použít formHelper!!!
    let component: MangoParamFormControl | undefined = undefined;
    if (this.param?.name) {
      component = this.form?.controls[this.param.name] as MangoParamFormControl;
    }

    if (component?.valid) {
      return '';
    } else {
      if (component?.hasError('required')) {
        return $localize`:@@ParamComponent.required.error.message:Položka musí být vyplněna`;
      }
      if (component?.hasError('valueDomain')) {
        return $localize`:@@ParamComponent.valueDomain.error.message:Hodnota neodpovídá definovanému oboru hodnot.`;
      }
      if (component?.hasError('pattern')) {
        return $localize`:@@ParamComponent.pattern.error.message:Hodnota musí být číslo.`;
      }
      if (component?.hasError('notUnique')) {
        return $localize`:@@FormHelper.notUnique.error.message:Tato hodnota je již v systému obsazena, prosím vyberte jinou.`;
      }
      return $localize`:@@ParamComponent.other.error.message:Nevalidní vstup`;
    }
  }

  /**
   * Zajišťuje, aby parametr (nebo atribut), pokud je
   * povinný, je to popup a má jen jednu hodnotu, tak aby byl vždy předvyplněný touto jendou hodnotou.
   */
  updatePopupValue(): void {
    if (
      this.param?.name &&
      this.options &&
      this.options.length === 1 &&
      this.required
    ) {
      this.form?.controls[this.param.name].setValue([this.options[0].id]);
    }
  }

  // Toto je workaround chyby, která se děje s použitím checkboxu
  // - při kliknutí nedojte k vyvolání valueChange, resp. reakce je až na následující kliknutí.
  // Bude to způsobené asi tím, že checkbox nemůže být uvnitř mat-form-fieldu
  // což se řeší zde: https://github.com/angular/components/issues/7891
  // Doporučují tam si vytvořit vlastní FormFieldControl, což by bylo asi nejsprávnější.
  // Protože se mi to teď nechce dělat, řeším to takto.
  onCheckChange($event: MatCheckboxChange): void {
    if (this.param?.name) {
      this.form?.controls[this.param.name].patchValue($event.checked, {
        emitEvent: false,
      });
    }
    this.allDependeciesResolve();
  }

  deleteParam(event: MouseEvent): void {
    event.stopPropagation(); // Po použití tlačítka už nechci aby se mi vyvolal focus na item
    this.deleteIndexed.emit(this.param);
  }

  addParam(event: MouseEvent): void {
    event.stopPropagation(); // Po použití tlačítka už nechci aby se mi vyvolal focus na item
    this.addIndexed.emit(this.param);
  }

  getDateTimeControlType(
    type: ParamControlType | null
  ): DatetimeControlPickerType | null {
    switch (type) {
      case ParamControlType.date:
        return DatetimeControlPickerType.calendar;
      case ParamControlType.dateTime:
        return DatetimeControlPickerType.both;
      case ParamControlType.time:
        return DatetimeControlPickerType.timer;
      default:
        return null;
    }
  }

  isDateTimeControlType(type: ParamControlType): boolean {
    return this.getDateTimeControlType(type) !== null;
  }
}
