import { Injectable } from '@angular/core';
import {
  DataBase,
  DataBaseServiceConfig,
  GetallResult,
} from '@og_soft/data-base';
import { Observable } from 'rxjs';
import { ProcessType } from '@isp-sc/shared/segments/mango-processes/common';

@Injectable({
  providedIn: 'root',
})
export class DataProcessTypesService extends DataBase<ProcessType> {
  // getallUrl = 'process-types';
  protected getAllUrl(): string {
    return 'process-types' as const;
  }

  override configureUrl(config: DataBaseServiceConfig): void {
    this.baseurl = config.mangoUrl ?? '';
  }

  getTypesForInsert(): Observable<GetallResult<ProcessType>> {
    return this.getall({ insertOnly: true }, 3600);
  }

  override tranformOptions(row: ProcessType): {
    id: string;
    name: string;
    disabled?: boolean;
  } {
    return { id: row.id.toString(), name: row.name };
  }
}
