<sc-main-toolbar>
  <!--  <h1 i18n="@@Nodes.header.caption">Seznam zařízení</h1>-->
  <!--  -->
  <!--  <div actions>-->
  <!--    <button mat-icon-button (click)="tableFilters.toggleFiltering(filterValues); $event.stopPropagation()"><mat-icon>filter_list</mat-icon></button>-->
  <!--  </div>-->
</sc-main-toolbar>

<div class="mango-table-page-contents">
  <h1 i18n="@@Nodes.header.caption">Seznam zařízení</h1>
  <sc-page-caption-block></sc-page-caption-block>

  <mgt-header [filterValues]="filterValues" [tab]="tbl">
    <mgt-filters
      #tableFilters
      [formGroup]="filters"
      (applyFilters)="filterValues = $event"
    >
      <app-popup-list
        formFieldClass="mango-form-field"
        formControlName="saId"
        title="Služba"
        i18n-title="@@Nodes.filters.saId.title"
        placeholder="Služba"
        i18n-placeholder="@@Nodes.filters.saId.placeholder"
        [formGroup]="filters"
        [dataSource]="dataUserServices"
        [filterDb]="false"
        [defaultFilters]="{ _nodesInfo: true }"
        [appearance]="'fill'"
        [fixOptionsToInputWidth]="true"
      >
      </app-popup-list>
    </mgt-filters>
  </mgt-header>

  <mgt-table #tbl [service]="dataService" [filters]="filterValues">
    <ng-container *ngFor="let r of tbl.scrollItems">
      <mgt-row [replacing-detail]="true" [record]="r">
        <mgt-ico>
          <img src="{{ urlImg + '/images/' + r.icon }}" alt="icon" />
        </mgt-ico>

        <mgt-cell-group master>
          <mgt-cell role="title">
            {{ r.name
            }}<ng-container *ngIf="r.parIdent">, {{ r.parIdent }}</ng-container>
          </mgt-cell>
          <mgt-cell *ngIf="r.uname">
            {{ r.uname }}
          </mgt-cell>
          <mgt-cell>
            <ng-container *ngFor="let s of r.sa; let i = index">{{
              (i ? ', ' : '') + s.saName
            }}</ng-container>
          </mgt-cell>
        </mgt-cell-group>

        <div detail>
          <!--          <h3 class="mat-title">-->
          <h3>
            <mgt-ico>
              <img src="{{ urlImg + '/images/' + r.icon }}" alt="icon" />
            </mgt-ico>
            {{ r.name
            }}<ng-container *ngIf="r.parIdent">, {{ r.parIdent }}</ng-container>
          </h3>
          <div class="detail-field" *ngIf="r.uname">
            <div class="mat-caption" i18n="@@Nodes.record.uname.title">
              Název
            </div>
            <div class="value">{{ r.uname }}</div>
          </div>
          <div class="detail-field" *ngIf="r.sa && r.sa.length > 0">
            <div class="mat-caption" i18n="@@Nodes.record.sa.title">Služby</div>
            <div class="value" *ngFor="let s of r.sa">{{ s.saName }}</div>
          </div>
          <div class="detail-field" *ngIf="r.address">
            <div class="mat-caption" i18n="@@Nodes.record.address.title">
              Umístění
            </div>
            <div class="value">{{ r.address }}</div>
          </div>
          <div class="detail-field" *ngIf="r.params && r.params.length > 0">
            <div class="mat-caption" i18n="@@Nodes.record.params.title">
              Parametry
            </div>
            <table class="value params-table">
              <tr *ngFor="let par of r.params">
                <div *ngIf="par.visible === 1">
                  <td class="label">{{ par.title }}:</td>
                  <td class="value">
                    <ng-container *ngIf="par.value">
                      {{ par.value }}
                    </ng-container>
                    <ng-container *ngIf="!par.value">
                      <span
                        class="empty-param-value"
                        i18n="@@Nodes.record.params.value.empty"
                        >Nezadáno</span
                      >
                    </ng-container>
                  </td>
                </div>
              </tr>
            </table>
          </div>
        </div>

        <mgt-actions>
          <button mat-button [routerLink]="['/user-services', r.id]">
            <mat-icon>shopping_cart</mat-icon>
            <span i18n="@@Nodes.services.button">Služby</span>
          </button>
          <button *ngIf="r.editable" mat-button (click)="nodeEdit(r.id, r)">
            <mat-icon>settings</mat-icon
            ><span i18n="@@User.settings.button">Nastavení</span>
          </button>
        </mgt-actions>
      </mgt-row>
    </ng-container>

    <div no-data-alternate-content>
      <page-empty
        i18n-message="@@Nodes.PageEmpty.message"
        message="Není co zobrazit."
      ></page-empty>
    </div>
  </mgt-table>
</div>
