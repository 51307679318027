import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { DialogConfig, DialogRef } from '@og_soft/dialog';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { FormHelperService } from '@isp-sc/shared/data-access';
import { MangoFormControl } from '@isp-sc/shared/segments/mango-processes/common';

@Component({
  selector: 'sc-process-message-dialog',
  templateUrl: './process-message-dialog.component.html',
  styleUrls: ['./process-message-dialog.component.scss'],
})
export class ProcessMessageDialogComponent {
  form: FormGroup;
  edit?: boolean;

  constructor(
    public session: SessionService,
    public formHelper: FormHelperService,
    public config: DialogConfig,
    private dlgRef: DialogRef
  ) {
    this.form = new FormGroup({
      note: new MangoFormControl('', { validators: [Validators.required] }),
    });
  }

  save() {
    if (this.form.valid) {
      const data = this.form.getRawValue();
      this.dlgRef.close(data);
    } else {
      this.formHelper.markDirty(this.form);
    }
  }
}
