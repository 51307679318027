import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DataBase } from '@og_soft/data-base';

@Injectable({
  providedIn: 'root',
})
/**
 * Třída využívá Mangovou php třídu ValueDomain ke zpracování oboru hodnot a vrácení patřičných výsledků.
 *
 */
export class DataValueDomainService extends DataBase<any> {
  // getallUrl = 'value-domain-resolve';

  protected getAllUrl(): string {
    return 'value-domain-resolve' as const;
  }

  /**
   * Zjistí ze serveru jestli daný obor hodnot obsahuje hodnotu
   */
  containValue(
    value: string,
    definition: string
  ): Observable<boolean> | undefined {
    if (this.getAllUrl === undefined) {
      console.error(
        `Chyba: třída ${this.constructor.name} metodu getall neumí - nemá definovanou vlastnost getallUrl`
      );
      return undefined;
    }

    const params: any = { method: 'contain', value, definition };

    const opts = { params: { ...params } };
    return this.getall(opts, 3600).pipe(map((d: any) => d.data));
  }

  /**
   * Zjistí ze serveru maximální a minimální hodnotu daného oboru hodnot.
   * Obor hodnot by měl být uložený v uPars, o jeho nastavení se stará servisa, která tuto servisu vytváří.
   * Netestované - možná nebude vůbec potřeba
   */
  minMax(definition: string):
    | Observable<{
        min: string;
        max: string;
      }>
    | undefined {
    if (this.getAllUrl === undefined) {
      console.error(
        `Chyba: třída ${this.constructor.name} metodu getall neumí - nemá definovanou vlastnost getallUrl`
      );
      return undefined;
    }

    const params: any = [{ method: 'minMax', definition }];

    const opts = { params: { ...params } };
    return this.getall(opts, 3600).pipe(map((d: any) => d.data));
  }
}
