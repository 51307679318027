import { Injectable } from '@angular/core';
import { DataBase } from '@og_soft/data-base';
import { RecUserServicesState } from '@isp-sc/shared/segments/data/common';

@Injectable({
  providedIn: 'root',
})
export class UserServicesStateService extends DataBase<RecUserServicesState> {
  // getallUrl = 'user-services-state';

  protected getAllUrl(): string {
    return 'user-services-state' as const;
  }
}
