import { NgModule } from '@angular/core';
import { ScPageCaptionBlockComponent } from './sc-page-caption-block/sc-page-caption-block.component';
import { EditorBlockModule } from '../editor-block/editor-block.module';
import { NgIf } from '@angular/common';

@NgModule({
  declarations: [ScPageCaptionBlockComponent],
  imports: [NgIf, EditorBlockModule],
  exports: [ScPageCaptionBlockComponent],
})
export class PageCaptionBlockModule {}
