<sc-main-toolbar>
  <!--  <h1 i18n="@@menu.data">Přehled datových přenosů</h1>-->
  <!--  -->
  <!--  <div actions>-->
  <!--  </div>-->
</sc-main-toolbar>

<div class="mango-tab-page-contents">
  <h1 i18n="@@menu.data">Přehled datových přenosů</h1>
  <sc-page-caption-block></sc-page-caption-block>
  <div [ngSwitch]="servicesLength">
    <mat-tab-group
      *ngSwitchDefault
      [ngClass]="services?.length === 1 ? 'single-tab' : ''"
      [animationDuration]="services?.length === 1 ? '0ms' : '200ms'"
    >
      <mat-tab *ngFor="let service of services ?? []; let i = index">
        <ng-template mat-tab-label>
          <div>{{ service.saName }}</div>
          <div class="sub-label">{{ labelUnitGet(i) }}</div>
        </ng-template>
        <ng-template matTabContent>
          <!-- umožňuje lazy loading -->
          <div (swiperight)="goPrevious()" (swipeleft)="goNext()">
            <mat-card appearance="outlined">
              <mat-card-title class="title"
                >{{ service.saName }}
                <ng-container *ngIf="service.saStateId !== '1'"
                  >({{ service.saEuStateName }})</ng-container
                ></mat-card-title
              ><!-- TODO Localize "zablokovaná" -->
              <mat-card-content>
                <div
                  *ngIf="serviceParams && serviceParams.length > 0"
                  class="mat-paragraph"
                >
                  <div class="mat-caption">
                    <span i18n="@@DataView.record.serviceParams.caption"
                      >Parametry služby</span
                    >
                  </div>
                  <table class="params">
                    <tr class="value" *ngFor="let param of serviceParams">
                      <td class="title">{{ param.title }}</td>
                      <td class="value">{{ param.value }} {{ param.units }}</td>
                    </tr>
                  </table>
                </div>
                <div
                  *ngIf="units !== null && units.length > 0"
                  class="mat-paragraph"
                >
                  <div class="mat-caption">
                    <span i18n="@@DataView.record.units.caption">Umístění</span>
                  </div>
                  <div class="value" *ngFor="let unit of units">
                    {{ [unit.address, unit.socket] | filter | join : ' - ' }}
                  </div>
                </div>
              </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined">
              <mat-card-title
                ><span i18n="@@DataView.record.transferredData.title"
                  >Přenesená data</span
                ></mat-card-title
              >
              <mat-card-content>
                <div [formGroup]="form">
                  <p *ngIf="inetData.inetIps().length > 1; else singleIp">
                    <app-popup-list
                      formFieldClass="mango-form-field"
                      title="IP adresy zařízení zákazníka"
                      i18n-title="@@DataView.ips.title"
                      placeholder="IP Adresy"
                      i18n-placeholder="@@DataView.ips.placeholder"
                      formControlName="ips"
                      id="ips"
                      name="ips"
                      [formGroup]="form"
                      [options]="inetIps() ?? []"
                      [appearance]="'fill'"
                      [fixOptionsToInputWidth]="true"
                    ></app-popup-list>
                  </p>
                  <ng-template #singleIp>
                    <div
                      *ngIf="
                        inetData.inetIps() && inetData.inetIps().length > 0
                      "
                      class="mat-paragraph"
                    >
                      <div class="mat-caption">
                        <span i18n="@@DataView.record.inetIps.caption"
                          >IP Adresa</span
                        >
                      </div>
                      <div class="value" *ngFor="let ip of inetData.inetIps()">
                        {{ ip }}
                      </div>
                    </div>
                  </ng-template>
                </div>

                <div class="inet-sum-range">
                  <div>
                    <div>
                      <span i18n="@@DataView.InetSumRange.day.title">Den:</span>
                    </div>
                    <div class="inet-data-in">
                      <mat-icon>arrow_downward</mat-icon>
                      {{
                        inetDataSumGet(
                          service.saBaseId,
                          'day',
                          'in',
                          form.controls.ips.value
                        ) | bytesPipe : 0
                      }}
                    </div>
                    <div class="inet-data-out">
                      <mat-icon>arrow_upward</mat-icon>
                      {{
                        inetDataSumGet(
                          service.saBaseId,
                          'day',
                          'out',
                          form.controls.ips.value
                        ) | bytesPipe : 0
                      }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span i18n="@@DataView.InetSumRange.week.title"
                        >Týden:</span
                      >
                    </div>
                    <div class="inet-data-in">
                      <mat-icon>arrow_downward</mat-icon>
                      {{
                        inetDataSumGet(
                          service.saBaseId,
                          'week',
                          'in',
                          form.controls.ips.value
                        ) | bytesPipe : 0
                      }}
                    </div>
                    <div class="inet-data-out">
                      <mat-icon>arrow_upward</mat-icon>
                      {{
                        inetDataSumGet(
                          service.saBaseId,
                          'week',
                          'out',
                          form.controls.ips.value
                        ) | bytesPipe : 0
                      }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span i18n="@@DataView.InetSumRange.month.title"
                        >Měsíc:</span
                      >
                    </div>
                    <div class="inet-data-in">
                      <mat-icon>arrow_downward</mat-icon>
                      {{
                        inetDataSumGet(
                          service.saBaseId,
                          'month',
                          'in',
                          form.controls.ips.value
                        ) | bytesPipe : 0
                      }}
                    </div>
                    <div class="inet-data-out">
                      <mat-icon>arrow_upward</mat-icon>
                      {{
                        inetDataSumGet(
                          service.saBaseId,
                          'month',
                          'out',
                          form.controls.ips.value
                        ) | bytesPipe : 0
                      }}
                    </div>
                  </div>
                </div>

                <div class="inet-data-graph">
                  <app-chart
                    *ngIf="
                      inetDataGet(service.saBaseId) &&
                      monthGraph2Data[tabSaIdToIndex(service.saBaseId)].labels
                        .length
                    "
                    [width]="600"
                    [height]="400"
                    [type]="'line'"
                    [data]="monthGraph2Data[tabSaIdToIndex(service.saBaseId)]"
                    [options]="monthGraph2OptionsGet(service.saBaseId)"
                  >
                  </app-chart>
                </div>
              </mat-card-content>
            </mat-card>

            <mat-card
              appearance="outlined"
              *ngIf="
                fupGet(service.saBaseId) &&
                fupGet(service.saBaseId)?.enabled > 0 &&
                fupGet(service.saBaseId)?.show
              "
              class="inet-fup"
            >
              <mat-card-title>FUP</mat-card-title>
              <mat-card-content>
                <div
                  *ngIf="
                    fupGet(service.saBaseId)?.data >
                    fupGet(service.saBaseId)?.limit
                  "
                  class="inet-data-fup-applied"
                >
                  <span i18n="@@DataView.FupApplied.text"
                    >Momentálně je aplikován FUP!</span
                  >
                </div>
                <div class="fup-param">
                  <span class="label"
                    ><span i18n="@@DataView.FupParam.text1"
                      >Započítáno:</span
                    ></span
                  >
                  <span class="value">{{
                    fupGet(service.saBaseId)?.data ?? 0 | bytesPipe : 0
                  }}</span>
                </div>
                <div class="fup-param">
                  <span class="label"
                    ><span i18n="@@DataView.FupParam.text2">Limit:</span></span
                  >
                  <span class="value">{{
                    fupGet(service.saBaseId)?.limit ?? 0 | bytesPipe : 0
                  }}</span>
                </div>
                <div class="fup-param">
                  <span class="label"
                    ><span i18n="@@DataView.FupParam.text3"
                      >Perioda:</span
                    ></span
                  >
                  <span class="value">{{
                    fupGet(service.saBaseId)?.period
                  }}</span>
                </div>
                <div class="fup-graph">
                  <app-chart
                    [width]="200"
                    [height]="200"
                    [type]="'doughnut'"
                    [data]="fupGraphDataGet(service.saBaseId)"
                  >
                  </app-chart>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
    <page-empty
      *ngSwitchCase="0"
      i18n-message="@@DataView.PageEmpty.message"
      message="Bohužel nemáte žádnou datovou službu."
    ></page-empty>
    <page-loading *ngSwitchCase="null"></page-loading>
  </div>
</div>
