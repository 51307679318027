import { Component, Inject, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { ScConfiguration, SC_CONFIGURATION } from '@isp-sc/shared/common';

@Component({
  selector: 'sc-editor-block',
  templateUrl: './editor-block.component.html',
  styleUrls: ['./editor-block.component.scss'],
})
export class EditorBlockComponent implements OnInit {
  @Input()
  public get id() {
    return this._id ? this._id : 0;
  }
  public set id(id: number) {
    this._id = id;
    this.iframeUrlBuild();
  }
  private _id: number | null = null;

  public iframeUrl: any;

  /**
   * Potřebujeme generovat unikátní identifikátory redakčních bloků. Jednoduchý autoinkrementační čítač.
   */
  private static _counter = 0;
  private _instNumber: number;
  public get instanceIdent(): string {
    return 'editorblockiframe' + this._instNumber.toString();
  }

  constructor(
    @Inject(SC_CONFIGURATION) private scConfiguration: ScConfiguration,
    private sanitizer: DomSanitizer,
    private session: SessionService
  ) {
    // S každou novou instancí povýšíme čítač, ten slouží k unikátnímu rozlišení jednotlivých instancí v DOM stromu.
    this._instNumber = ++EditorBlockComponent._counter;

    // Obsluha message z iframe pro nastavení dynamické výšky. Nepoužíváme @HostListerer, aby se nastavil jen jeden
    // globální handler a ne jeden s každou instancí.
    if (EditorBlockComponent._counter == 1) {
      window.addEventListener('message', (event) => {
        let values: { name: string; object: string; height: string } | null =
          null;
        try {
          values = JSON.parse(event.data);
        } catch (e) {
          // Pochytáme všechny výjimky. Zajímá nás jen iframeResize.
        }
        if (values !== null && values.name == 'iframeResize') {
          const element = document.getElementById(values.object);
          if (element) {
            const iframe = element.getElementsByTagName('iframe')[0];
            if (iframe) {
              iframe.height = values.height;
            }
          }
        }
      });
    }
  }

  ngOnInit() {
    this.iframeUrlBuild();
  }

  private iframeUrlBuild() {
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.scConfiguration.baseUrl +
        '/editor-block-content/' +
        this.id +
        '?elemId=' +
        this.instanceIdent +
        '&atb=' +
        this.session.user?.atb
    );
  }
}
