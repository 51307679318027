import { Injectable } from '@angular/core';
import { PopupOption } from '@isp-sc/shared/ui';
import { DataBase } from '@og_soft/data-base';
import { ProcessServices } from '@isp-sc/shared/segments/process/common';

@Injectable({
  providedIn: 'root',
})
export class ProcessServicesService extends DataBase<ProcessServices> {
  protected getAllUrl(): string {
    return 'process-services' as const;
  }

  override tranformOptions(row: ProcessServices): PopupOption {
    return { id: String(row.saId), name: row.saName };
  }
}
