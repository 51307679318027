import { Injectable } from '@angular/core';
import { DataBase } from '@og_soft/data-base';
import { RecPhonetCreditLoadData } from '@isp-sc/shared/segments/phonet/common';

@Injectable({
  providedIn: 'root',
})
export class PhonetCreditLoadService extends DataBase<RecPhonetCreditLoadData> {
  protected getAllUrl(): string {
    return 'phonet-credit-load' as const;
  }
}
