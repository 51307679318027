import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AsyncValidator, ValidationErrors } from '@angular/forms';
import { MangoParamFormControl } from './mango-param-form-control';

@Injectable({ providedIn: 'root' })
export class ValueDomainValidator implements AsyncValidator {
  validate(
    ctrl: MangoParamFormControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    ctrl = ctrl as MangoParamFormControl;

    // console.log('XXXXXXXXXXXXXXXXXXXXXX ctrl ',ctrl);

    if (!ctrl.param || !ctrl.param.valueDomain) {
      return of(null);
    }

    const obs = ctrl.param.valueDomain.containValue(ctrl.value);

    if (obs) {
      return obs.pipe(
        map((contain) => (contain ? null : { valueDomain: true })),
        catchError(() => of(null))
      );
    }

    return of(null);
  }
}
