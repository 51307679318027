import { FormGroup, ValidationErrors } from '@angular/forms';

export const emailCommunicationValidator: (
  control: FormGroup
) => ValidationErrors | null = (
  control: FormGroup
): ValidationErrors | null => {
  const infoEmail = control.get('infoEmail');
  const emails = control.get('emails');
  return infoEmail?.value && !emails?.value
    ? { emailCommunication: true }
    : null;
};
