<sc-main-toolbar>
  <!--  <h1 i18n="@@TelephonyList.header.caption">Výpis hovorů</h1>-->
  <!--  <div actions>-->
  <!--    <button mat-icon-button (click)="tableFilters.toggleFiltering(filterValues); $event.stopPropagation()">-->
  <!--      <mat-icon>filter_list</mat-icon>-->
  <!--    </button>-->
  <!--  </div>-->
</sc-main-toolbar>

<div class="mango-table-page-contents">
  <h1 i18n="@@TelephonyList.header.caption">Výpis hovorů</h1>
  <sc-page-caption-block></sc-page-caption-block>

  <mgt-header [filterValues]="filterValues" [tab]="tab">
    <mgt-filters
      #tableFilters
      [formGroup]="filters"
      (applyFilters)="updateFilter($event)"
    >
      <div class="filter-row">
        <mat-form-field class="mango-form-field">
          <datetime-control
            [pickerType]="DatetimeControlPickerType.calendar"
            formControlName="dateFrom"
            name="dateFrom"
            #df1
            i18n-placeholder="@@TelephonyList.filters.dateFrom.placeholder"
            placeholder="Od"
            [max]="filters.get('dateTo').value"
          ></datetime-control>
          <datetime-control-trigger
            matSuffix
            [for]="df1"
          ></datetime-control-trigger>
          <mat-error *ngIf="filters.controls['dateFrom'].errors">{{
            formHelper.getErrorMessage(filters.get('dateFrom'))
          }}</mat-error>
        </mat-form-field>

        <mat-form-field class="mango-form-field">
          <datetime-control
            [pickerType]="DatetimeControlPickerType.calendar"
            formControlName="dateTo"
            name="dateTo"
            #df2
            i18n-placeholder="@@TelephonyList.filters.dateTo.placeholder"
            placeholder="Do"
            [min]="filters.get('dateFrom').value"
            [errorStateMatcher]="errorMatcher"
          ></datetime-control>
          <datetime-control-trigger
            matSuffix
            [for]="df2"
          ></datetime-control-trigger>
          <mat-error *ngIf="filters.controls.dateTo.errors">{{
            formHelper.getErrorMessage(filters.get('dateTo'))
          }}</mat-error>
          <mat-error *ngIf="filters.errors">{{
            formHelper.getErrorMessage(filters.get('dateTo'), filters)
          }}</mat-error>
        </mat-form-field>
      </div>
      <app-popup-list
        formFieldClass="mango-form-field"
        title="Služba"
        i18n-title="@@TelephonyList.filters.saId.title"
        placeholder="Služba"
        i18n-placeholder="@@TelephonyList.filters.saId.placeholder"
        formControlName="saId"
        [formGroup]="filters"
        [dataSource]="dataUserServices"
        [filterDb]="false"
        [defaultFilters]="{ scFlags: 'SCF_VOIP,SCF_GSM,SCF_SMS' }"
        [appearance]="'fill'"
        [fixOptionsToInputWidth]="true"
      ></app-popup-list>
    </mgt-filters>

    <!-- Akce -->
    <!--
    <button class="mgt-act-menu" mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item disabled>
        <mat-icon>voicemail</mat-icon>
        <span>Hlasová pošta</span>
      </button>
      <button mat-menu-item>
        <mat-icon>warning</mat-icon>
        <span>Nastavení limitu hovorného</span>
      </button>
      <button mat-menu-item>
        <mat-icon>error</mat-icon>
        <span>Blokování čísel</span>
      </button>
      <button mat-menu-item>
        <mat-icon>label</mat-icon>
        <span>Automaticky přiřazovat štítky</span>
      </button>
    </mat-menu>
    -->
  </mgt-header>

  <mgt-table #tbl [service]="dataService" [filters]="filterValues">
    <ng-container *ngFor="let r of tbl.scrollItems; let i = index">
      <div class="grouped-row-container">
        <!-- Musí se to obalit extra elementem, aby se správně počítala výška, pokud to má používat ten header. Toto by se mělo asi upravit, pokud ten header budeme více používat. -->
        <mgt-sec-head *ngIf="secHeaderShow(i, tbl.scrollItems)">
          {{ r.tm | date : 'd. M. yyyy' }}
        </mgt-sec-head>

        <mgt-row
          [replacing-detail]="true"
          [groupFirst]="secFirst(i, tbl.scrollItems)"
          [groupLast]="secLast(i, tbl.scrollItems)"
          [record]="r"
        >
          <mgt-ico>
            <mat-icon class="type-main">{{
              r.type === 'CALL' ? 'phone' : r.type === 'MSG' ? 'message' : ''
            }}</mat-icon>
            <mat-icon class="type-dir">{{
              r.dir === 'I' ? 'call_received' : r.dir === 'O' ? 'call_made' : ''
            }}</mat-icon>
          </mgt-ico>

          <mgt-cell-group master>
            <mgt-cell
              i18n-title="@@TelephonyList.record.theirNum.title"
              title="Cizí číslo"
              role="title"
            >
              {{ '+' + r.theirNum | phoneNumber }}
            </mgt-cell>
            <mgt-cell
              i18n-title="@@TelephonyList.record.myNum.title"
              title="Moje číslo"
            >
              {{ '+' + r.myNum | phoneNumber }}
            </mgt-cell>
          </mgt-cell-group>

          <mgt-cell-group>
            <mgt-cell
              i18n-title="@@TelephonyList.record.tm.title"
              title="Kdy"
              class="cell-right"
              >{{ r.tm | date : 'HH:mm' }}</mgt-cell
            >
          </mgt-cell-group>

          <div detail>
            <h3 class="mat-headline-6">
              <mat-icon class="type-main">{{
                r.type === 'CALL' ? 'phone' : r.type === 'MSG' ? 'message' : ''
              }}</mat-icon>
              <mat-icon class="type-dir">{{
                r.dir === 'I'
                  ? 'call_received'
                  : r.dir === 'O'
                  ? 'call_made'
                  : ''
              }}</mat-icon>
              {{ '+' + r.theirNum | phoneNumber }}
            </h3>
            <div class="detail-field" *ngIf="r.saData">
              <div
                class="mat-caption"
                i18n="@@TelephonyList.record.saName.title"
              >
                Služba
              </div>
              <div class="value">{{ r.saData.saName }}</div>
            </div>
            <div class="detail-field">
              <div
                class="mat-caption"
                i18n="@@TelephonyList.record.myNum.title"
              >
                Moje číslo
              </div>
              <div class="value">{{ '+' + r.myNum | phoneNumber }}</div>
            </div>
            <div class="detail-field">
              <div class="mat-caption" i18n="@@TelephonyList.record.tm.title">
                Datum a čas
              </div>
              <div class="value">{{ r.tm | date : 'd. M. yyyy HH:mm:ss' }}</div>
            </div>
            <div class="detail-field" *ngIf="r.duration !== null">
              <div
                class="mat-caption"
                i18n="@@TelephonyList.record.duration.title"
              >
                Délka hovoru
              </div>
              <div class="value">
                {{ r.duration | duration }}
              </div>
            </div>
            <div class="detail-field" *ngIf="r.price !== null">
              <div
                class="mat-caption"
                i18n="@@TelephonyList.record.price.title"
              >
                Cena
              </div>
              <div class="value">
                {{ r.price | currency : getCurrencySymbol() }}
              </div>
            </div>
          </div>
        </mgt-row>
      </div>
    </ng-container>

    <div no-data-alternate-content>
      <page-empty
        i18n-message="@@TelephonyList.PageEmpty.message"
        message="Není co zobrazit."
      ></page-empty>
    </div>
  </mgt-table>
</div>
