import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
})
export class JoinPipe implements PipeTransform {
  transform(array: any[], glue?: string): string {
    if (!Array.isArray(array)) {
      return array;
    }
    return array.join(glue);
  }
}
