import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Tento validátor odpovídá validátoru TModuloValidator z frontendu.
 */
export function moduloValidator(modulo: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value) {
      const value = control.value;
      const length = control.value.length;
      let base = 1;
      let total = 0;
      for (let i = length - 1; i >= 0; i--) {
        total += parseInt(value[i], 10) * base;
        base *= 2;
      }
      const valid = !Number.isNaN(total) && !(total % modulo);
      // console.log('Jak je to dlouheeeeeeeeeeeeeeeee', total, ", valid=", valid, ", total neni NaNa=", !Number.isNaN(total));
      if (!valid) {
        return { modulo: { value: control.value } };
      }
    }

    return null;
  };
}
