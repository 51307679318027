import { Injectable } from '@angular/core';
import { DataBaseServiceConfig } from '@og_soft/data-base';
import { DataFiles, UploadProgress } from '@og_soft/data-file';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { CachingInterceptor } from '@og_soft/cache';
import * as mime from 'mime/lite';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { FileService } from '@isp-sc/shared/data-access';

@Injectable({
  providedIn: 'root',
})
export class DataProcessAttachmentService extends DataFiles<UploadProgress> {
  // getallUrl = 'process-attachment';
  protected getAllUrl(): string {
    return 'process-attachment' as const;
  }

  constructor(
    public override http: HttpClient,
    public serviceConfig: DataBaseServiceConfig,
    public cachingInterceptor: CachingInterceptor,
    public session: SessionService,
    public fileService: FileService
  ) {
    super(http, serviceConfig, cachingInterceptor);
  }

  public downloadFileCustom($event: Event, id: number, fileName: string): void {
    $event.preventDefault();
    const options = {
      observe: 'events',
      reportProgress: true,
      responseType: 'blob' as 'json',
    };

    this.session.processingSet(true, '0 B');
    this.getone(id, {}, 60, options).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.DownloadProgress) {
          this.session.processingSet(
            true,
            FileService.bytesToSize(event.loaded)
          );
        }
        if (event.type === HttpEventType.Response) {
          this.session.processingSet(false);
          const fileType = mime.getType(fileName);
          const blob = new Blob([event.body], { type: fileType ?? undefined });
          const url = window.URL.createObjectURL(blob);

          // Toto se za mě chová nejlíp: typy které prohlížeč rozezná otevře v novém okně,
          // a ty které neumí otevřít dá stahovat.
          // window.open(url);

          // Alternativa: stažení souboru.
          const downloadLink = document.createElement('a');
          downloadLink.href = url;
          downloadLink.target = '_blank';
          // if browser does not support mime download
          if (!this.fileService.mimeCheck(fileType)) {
            downloadLink.download = fileName;
          }
          if (fileType != null) {
            downloadLink.type = fileType;
          }

          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      },
      (error) => {
        this.session.processingSet(false);
      }
    );
  }
}
