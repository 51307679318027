import { Injectable } from '@angular/core';
import { DataBase } from '@og_soft/data-base';
import { RecUserDebt } from '@isp-sc/shared/segments/data/common';

@Injectable({
  providedIn: 'root',
})
export class UserDebtService extends DataBase<RecUserDebt> {
  // getallUrl = 'user-debt';

  protected getAllUrl(): string {
    return 'user-debt' as const;
  }
}
