import { Component } from '@angular/core';
import { DialogConfig, DialogRef } from '@og_soft/dialog';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';

@Component({
  selector: 'sc-protected-data-recall-erase-dialog',
  templateUrl: './protected-data-recall-erase-dialog.component.html',
  styleUrls: ['./protected-data-recall-erase-dialog.component.scss'],
})
export class ProtectedDataRecallEraseDialogComponent {
  public dataconf;

  constructor(
    public config: DialogConfig,
    private session: SessionService,
    private dlgRef: DialogRef
  ) {
    this.dataconf = config.data;
  }

  save() {
    const catId = this.dataconf.data.catId;
    if (catId) {
      this.dlgRef.close(this.dataconf);
    }
  }
}
