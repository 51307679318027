import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { UserEditFormType } from '@isp-sc/shared/common';

@Component({
  selector: 'app-display-edit-pane',
  templateUrl: './display-edit-pane.component.html',
  styles: [
    `
      /*
    */
      .display-type-card .pane-edit {
        position: absolute;
        top: 12px;
        right: 6px;
      }

      .pane-flex {
        display: flex;
        flex-direction: row;
      }
      @media screen and (max-width: 600px) {
        .pane-flex {
          flex-direction: column;
        }
      }

      .display-type-pane {
        & ::ng-deep .mat-caption {
          opacity: 0.6;
        }
      }

      /* nefunguje :-( */
      :host ::ng-deep .mat-expansion-panel-header mat-content {
        justify-content: space-between;
      }
    `,
  ],
})
export class DisplayEditPaneComponent {
  displayType = 'card'; // card nebo pane

  /**
   * Name of this pane (frame in the Mangospeak)
   *
   * Used for right names and when emiting the editButtonClicked event.
   */
  @Input() public name?: UserEditFormType;

  /**
   * Name of the form (form on module in the Mangospeak)
   *
   * Used for right names.
   */
  @Input() public formName?: string;

  /**
   * Heading text
   */
  @Input() title?: string;

  /**
   * Subheading text - about a sentence long text about what this pane does.
   */
  @Input() subtitle?: string;

  /**
   * Specifies, whether this pane has an edit button
   */
  // tslint:disable-next-line:no-input-rename
  @Input('can-edit') canEdit = false;

  /**
   * Optional edit button text instead of default 'Upravit'
   */
  // tslint:disable-next-line:no-input-rename
  @Input('edit-button-text') editBtnText?: string;

  /**
   * Optional edit button icon name
   */
  @Input('edit-button-icon') editBtnIcon?: string;

  /**
   * Event na kliknutí na editační tlačítko.
   */
  @Output() doEdit: EventEmitter<UserEditFormType> = new EventEmitter();

  constructor(public session: SessionService) {}

  editButtonClicked($event: Event) {
    this.doEdit.emit(this.name);
  }

  editUserProfile() {
    return $localize`:@@DisplayEditPane.edit.caption:Upravit`;
  }
}
