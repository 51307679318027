import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScNavComponent} from "../../navigation/navigation/sc-nav.component";
import { Subscription } from 'rxjs';

@Component({
  selector: 'sc-page-caption-block',
  template: `
    <ng-container *ngIf="this.ttId">
      <sc-editor-block [id]="ttId"></sc-editor-block>
    </ng-container>
  `,
})
export class ScPageCaptionBlockComponent implements OnInit, OnDestroy {
  constructor(private route: ActivatedRoute, private scnav: ScNavComponent) {}

  private routeSubscription: Subscription | null = null;

  private urlseg: string | null = null;

  public get ttId() {
    const eb = this.scnav.getEditorBlockByPlacement(this.urlseg);
    return eb ? eb.ttId : 0;
  }

  ngOnInit() {
    this.routeSubscription = this.route.url.subscribe((url) => {
      this.urlseg = url[0].path;
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
  }
}
