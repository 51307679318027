<sc-main-toolbar>
  <!-- <h1 i18n="@@DocumentsAgreementsComponent.header.caption">Smluvní dokumenty</h1> -->
  <!--    <h1 i18n="@@menu.documents-agreements">Smluvní dokumenty</h1>-->
</sc-main-toolbar>

<div class="mango-table-page-contents">
  <h1 i18n="@@menu.documents-agreements">Smluvní dokumenty</h1>
  <sc-page-caption-block></sc-page-caption-block>

  <mgt-header [filterValues]="filterValues" [tab]="tab!">
    <mgt-filters [formGroup]="filters" (applyFilters)="filterValues = $event">
      <mat-form-field class="mango-form-field">
        <mat-select
          formControlName="valid"
          placeholder="Stav"
          i18n-placeholder="@@DocumentsAgreements.filter.valid.placeholder"
          title="Stav"
          i18n-title="@@DocumentsAgreements.filter.valid.title"
        >
          <mat-option>--------</mat-option>
          <mat-option
            [value]="true"
            i18n="@@DocumentsAgreements.filter.valid.option.valid"
            >Platný</mat-option
          >
          <mat-option
            [value]="false"
            i18n="@@DocumentsAgreements.filter.valid.option.invalid"
            >Neplatný</mat-option
          >
        </mat-select>
      </mat-form-field>
    </mgt-filters>
  </mgt-header>
  <mgt-table #tbl [service]="dataService" [filters]="filterValues">
    <mgt-row
      [replacing-detail]="false"
      *ngFor="let r of tbl.scrollItems"
      [record]="r"
    >
      <ng-template #documentNameTpl
        >{{ r.atTypeName }} {{ r.aNumber }}</ng-template
      >

      <mgt-cell-group master>
        <mgt-cell
          i18n-title="@@DocumentsAgreements.record.typeName.title"
          title="Typ dokumentu"
          role="title"
        >
          <ng-container *ngTemplateOutlet="documentNameTpl"></ng-container>
        </mgt-cell>
        <mgt-cell
          i18n-title="@@DocumentsAgreements.record.datePayment.title"
          title="Expedováno"
          class="mango-no-wrap"
          role="result"
        >
          <span
            ><span i18n="@@DocumentsAgreements.record.datePayment.text"
              >Expedováno</span
            >
            {{ r.cdType }} {{ r.cdSysTime | date : 'd. M. yyyy' }}</span
          >
        </mgt-cell>
      </mgt-cell-group>

      <mgt-cell-group>
        <mgt-cell
          role="result"
          [ngClass]="{
            'cell-monetary': true,
            'mango-text-warn': r.atConfirm === 1 && r.dateConfirmed === '',
            'mango-text-success': r.atConfirm === 1 && r.dateConfirmed !== ''
          }"
        >
          <span *ngIf="r.atConfirm === 1 && r.dateConfirmed !== ''">
            <span i18n="@@DocumentsAgreements.confirm.text">Potvrzena</span>
          </span>
          <span *ngIf="r.atConfirm === 1 && r.dateConfirmed === ''">
            <span i18n="@@DocumentsAgreements.unconfirm.text">Nepotvrzena</span>
          </span>
        </mgt-cell>
      </mgt-cell-group>

      <div detail>
        <div *ngIf="r.sa.length > 0" class="mat-caption">
          <span i18n="@@DocumentsAgreements.sa.caption">Služby</span>
        </div>
        <mgt-row *ngFor="let sa of r.sa" [expandable]="false" [record]="sa">
          <mgt-cell-group master>
            <!--<mgt-cell role="title">{{sa.name}}  {{sa.paramIdent ? "(" + sa.paramIdent + ")" : ""}} </mgt-cell>-->
            <mgt-cell role="title"
              >{{ sa.name }}
              {{ sa.paramIdent ? '(' + sa.paramIdent + ')' : '' }}
              <span *ngIf="sa.dateFrom">
                od {{ sa.dateFrom | date : 'd. M. yyyy' }}
              </span>
              <span *ngIf="sa.dateTo">
                do {{ sa.dateTo | date : 'd. M. yyyy' }}
              </span>
            </mgt-cell>
          </mgt-cell-group>
        </mgt-row>

        <!--
                 <pre>{{r|json}}</pre>
                -->
      </div>

      <mgt-actions>
        <a
          mat-button
          *ngIf="
            r.atConfirm === 1 && r.dateConfirmed === '';
            else downloadButtonTpl
          "
          color="primary"
          (click)="confirmAgreement(r.cdId, documentNameTpl)"
          ><mat-icon>check</mat-icon>&nbsp;<span
            i18n="@@DocumentsAgreements.confirm.button"
            >Potvrdit</span
          ></a
        >

        <ng-template #downloadButtonTpl>
          <a
            mat-button
            *ngIf="r.cdId"
            [href]="printUtil.docLinkUrl('cd', r.cdId)"
            target="_blank"
            ><mat-icon>print</mat-icon> &nbsp;
            <span i18n="@@DocumentsAgreements.print.button">Smlouva</span>
          </a>
        </ng-template>
      </mgt-actions>
    </mgt-row>

    <div no-data-alternate-content>
      <page-empty
        i18n-message="@@DocumentsAgreements.PageEmpty.message"
        message="Není co zobrazit."
      ></page-empty>
    </div>
  </mgt-table>
</div>
