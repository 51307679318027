<sc-main-toolbar>
  <!-- <h1 i18n="@@DocumentsOtherComponent.header.caption">Ostatní dokumenty</h1> -->
  <!--  <h1 i18n="@@menu.documents-other">Ostatní dokumenty</h1>-->

  <!--  <div actions>-->
  <!--    <button mat-icon-button (click)="tableFilters.toggleFiltering(filterValues); $event.stopPropagation()"><mat-icon>filter_list</mat-icon></button>-->
  <!--  </div>-->
</sc-main-toolbar>

<div class="mango-table-page-contents">
  <h1 i18n="@@menu.billing.other">Ostatní doklady</h1>
  <sc-page-caption-block></sc-page-caption-block>

  <mgt-header [filterValues]="filterValues" [tab]="tab">
    <mgt-filters
      #tableFilters
      [formGroup]="filters"
      (applyFilters)="filterValues = $event"
    >
      <app-popup-list
        formFieldClass="mango-form-field"
        title="Typ dokumentu"
        i18n-title="@@DocumentsOther.filters.cdDocType.title"
        placeholder="Typ dokumentu"
        i18n-placeholder="@@DocumentsOther.filters.cdDocType.placeholder"
        formControlName="docType"
        [formGroup]="filters"
        [dataSource]="dataDocumentsOtherTypes"
        [filterDb]="false"
        [appearance]="'fill'"
        [fixOptionsToInputWidth]="true"
      ></app-popup-list>
    </mgt-filters>
  </mgt-header>

  <mgt-table #tbl [service]="dataService" [filters]="filterValues">
    <mgt-row
      [replacing-detail]="false"
      *ngFor="let r of tbl.scrollItems"
      [record]="r"
    >
      <mgt-cell-group master>
        <mgt-cell
          i18n-title="@@DocumentsOther.record.cdDocType.title"
          title="Typ dokumentu"
          role="title"
        >
          {{ r.docTitle }} {{ r.docData.docOtherVarSymbol }}
        </mgt-cell>
        <mgt-cell
          i18n-title="@@DocumentsOther.record.cdSysTime.title"
          title="Expedováno"
          class="mango-no-wrap"
          role="result"
        >
          <span>
            <span i18n="@@DocumentsOther.cdSysTime.text">Expedováno</span>
            {{ r.cdType }} {{ r.cdSysTime | date : 'd. M. yyyy' }}</span
          >
        </mgt-cell>
      </mgt-cell-group>
      <mgt-cell-group>
        <mgt-cell
          role="result"
          [ngClass]="{
            'cell-monetary': true,
            'mango-text-info':
              r.docData.amountPay > 0 || r.docData.amountPay < 0,
            'mango-text-success': r.docData.amountPay == 0
          }"
        >
          <span *ngIf="r.docData.amountPay == 0 && r.cdDocType === 'proforma'">
            <span i18n="@@DocumentsOther.record.amountPay.paid.text"
              >Uhrazeno</span
            >
          </span>
          <span *ngIf="r.docData.amountPay < 0 && r.cdDocType === 'proforma'">
            <span i18n="@@DocumentsOther.record.amountPay.overpayment.text"
              >Přeplatek</span
            >
            {{ r.docData.amountPay * -1 | currency : getCurrencySymbol() }}
          </span>
          <span *ngIf="r.docData.amountPay > 0 && r.cdDocType === 'proforma'">
            {{ r.docData.amountPay | currency : getCurrencySymbol() }}
          </span>
        </mgt-cell>
      </mgt-cell-group>

      <div detail>
        <div *ngIf="r.docData.docOtherStorno === 1">
          <span i18n="@@DocumentsOther.docOtherStorno.text"
            >Dokument stornován</span
          >
        </div>
        <!--
                 <pre>{{r|json}}</pre>
                -->
      </div>

      <mgt-actions>
        <a
          mat-button
          *ngIf="
            debt(r.docData.amountPay) &&
            r.cdDocType === 'proforma' &&
            this.session.getOption('SELFCARE.documents-other.creditCard.button')
          "
          (click)="payment(r)"
          target="_blank"
        >
          <mat-icon>credit_card</mat-icon>
          <span i18n="@@DocumentsOther.creditCard.button">Zaplatit</span>
        </a>
        <a
          mat-button
          *ngIf="r.cdId"
          [href]="printUtil.docLinkUrl('cd', r.cdId)"
          target="_blank"
          ><mat-icon>print</mat-icon>
          <span i18n="@@DocumentsOther.print.button">Dokument</span>
        </a>
      </mgt-actions>
    </mgt-row>

    <div no-data-alternate-content>
      <page-empty
        i18n-message="@@DocumentsOther.PageEmpty.message"
        message="Není co zobrazit."
      ></page-empty>
    </div>
  </mgt-table>
</div>
