import { Injectable } from '@angular/core';
import { DataBase } from '@og_soft/data-base';
import { RecAccountIban } from '@isp-sc/shared/segments/data/common';

@Injectable({
  providedIn: 'root',
})
export class AccountIbanService extends DataBase<RecAccountIban> {
  // getallUrl = 'account-iban';

  protected getAllUrl(): string {
    return 'account-iban' as const;
  }
}
