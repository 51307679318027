import { PopupOption } from '@isp-sc/shared/ui';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  Options,
  ParamObjectType,
} from '@isp-sc/shared/segments/params/common';
import { ParamType } from '@isp-sc/shared/segments/params/common';
import { ParamControlType } from '@isp-sc/shared/segments/params/common';
import { ParamServices } from './param-services';
import { ValueDomain } from './value.domain';

export class ParamBase {
  caption: string;
  id?: number;
  defId?: number;
  objId: number; // id objektu, ke kterému je parametr nadefinován
  objectType?: ParamObjectType;
  value: any;
  defaultValue?: string;
  hrValue?: string;
  name?: string; // Toto jméno by mělo být vždy unikátní (u indexovaných parametrů přidávám index)
  indexName?: string; // Jméno z db (u indexovaných parametrů bez indexu)
  private readonly required: BehaviorSubject<boolean>;
  description?: string;
  order: number;
  type: ParamType;
  controlType: ParamControlType;
  visible: boolean;
  disabled: boolean;
  indexed: boolean;
  index: number;
  domain: string;
  visibilityDependency: string | null | undefined;
  editDependency: string | null | undefined;
  requireDependency: string | null | undefined;
  // Určuje globální editovatelnost - např. u procesu, na který chybí editační právo,
  // budou všechny parametry disablované - v takovém případě nemá cenu vyhodnocovat závislosti
  editable: boolean;
  noOptions = false; // Zvláštní proměnná, která řeší, aby se nezobrazovali atributy, které nemá obor hodnot.
  group: {
    id: number | null;
    position: number | null;
    type: string | null;
    caption: string | null;
  } | null;

  attribute = false;
  private readonly options: BehaviorSubject<PopupOption[]>;
  // Proměnná do které si zaznamenám, jestli už došlo k načtení options
  // nechci se ptát na samotné options, protože můžou být i po načtení prázdné, tak ať se nestahují furt znova
  private optionsLoaded = false;
  private readonly _valueDomain: ValueDomain | null = null;

  constructor(options: Options, services: ParamServices) {
    this.type = (options.type || '') as ParamType;
    this.caption = options.caption || '';
    this.value = options.value;
    this.id = options.id;
    this.defId = options.defId;
    this.objId = options.objId ?? 0;
    this.objectType = options.objectType;
    this.defaultValue = options.defaultValue;
    this.hrValue = options.hrValue;
    this.name = options.name || '';
    this.name = options.name;
    this.indexName = this.resolveIndexName(
      options.name,
      options.index,
      options.type,
      options.indexed,
      options.unique
    );
    this.description = options.description;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = this.resolveType(options.indexed, options.unique);
    this.visible = !!options.visible;
    this.disabled = !!options.disabled;
    this.indexed = !!options.indexed;
    this.index = options.index || 0;
    this.domain = options.domain || '';
    this.visibilityDependency = options.visibilityDependency;
    this.editDependency = options.editDependency;
    this.requireDependency = options.requireDependency;
    this.editable = options.editable ?? true; // TODO zjistit jestli je fallback na true správný
    this.group = options.group || null;
    this.attribute = !!options.attribute;
    this.noOptions = this.hasOptions() && this.attribute;
    this.options = new BehaviorSubject<PopupOption[]>([]);
    this.required = new BehaviorSubject<boolean>(false);

    if (this.domain !== '') {
      this._valueDomain = new ValueDomain(this.domain, services);
    }
  }

  /**
   * Zde proběhne transformace všech možných typů parametrů na jednoznačný typ komponenty, který se využije v šabloně
   */
  resolveType(indexed?: boolean, unique?: boolean): ParamControlType {
    switch (this.type) {
      case ParamType.SELECT:
        if (indexed && unique) {
          return ParamControlType.multiSelect;
        } else {
          return ParamControlType.popupList;
        }
      case ParamType.DATE:
        return ParamControlType.date;
      case ParamType.DATETIME:
        return ParamControlType.dateTime;
      case ParamType.BOOL:
        return ParamControlType.check;
      case ParamType.NUM:
      case ParamType.FLOAT:
        return ParamControlType.number;
      case ParamType.TEXT:
        return ParamControlType.textBox;
      case ParamType.TIME:
        return ParamControlType.time;
      // case 'MULTISELECT':
      //  return ParamControlType.multiSelect;
      case ParamType.STRING:
      case ParamType.PRICE: // TODO: Dodělat
      case ParamType.FILE: // TODO: Dodělat
      case ParamType.PREMISEIDBOX: // TODO:??
      case ParamType.PREMISEIDMEGA: // TODO:??
      default:
        return ParamControlType.string;
    }
  }

  get valueDomain(): ValueDomain | null {
    return this._valueDomain;
  }

  /**
   * Vrací true, pokud daný parametr používá pri zobrazení seznam optionů
   */
  public hasOptions(): boolean {
    return (
      this.controlType === ParamControlType.popupList ||
      this.controlType === ParamControlType.multiSelect
    );
  }

  public getOptions(): Observable<PopupOption[]> {
    return this.options;
  }

  loadOptions(paramChanged: string, form: {}, additionalData: {}): void {
    if (this.hasOptions()) {
      this._valueDomain?.getRequiredParams().subscribe((requiredParams) => {
        // Nejprve si zjistím, jestli pro získání options potřebuju nějaké parametry
        if (requiredParams && requiredParams.length > 0) {
          // Pokud ano, a options dosud nejsou nahrány, nebo se změnil parametr ovlivňující options.
          // Tak si vyčtu options znovu.
          if (!this.optionsLoaded || requiredParams.includes(paramChanged)) {
            this.prepareOptions(
              this.fetchRequiredParams(requiredParams, form, additionalData)
            );
          }
        } else {
          // Pokud žádné parametry nepotřebuju a options jsme dosud nenahrál, tak si je nahraju
          if (!this.optionsLoaded) {
            this.prepareOptions(null);
          }
        }
      });
    }
  }

  private prepareOptions(params: {} | null): void {
    this._valueDomain?.optionsGet(params).subscribe((next) => {
      this.optionsLoaded = true;
      this.options.next(next);
      this.noOptions = this.attribute && (!next || next.length < 1);
    });
  }

  public getRequired(): Observable<boolean> {
    return this.required;
  }

  public setRequired(required: boolean): void {
    this.required.next(required);
  }

  /**
   * Vrací buď kompletní seznam povinných parametrů vytažených z formuláře,
   * nebo null v případě že jakýkoli z nich chybí.
   */
  private fetchRequiredParams(
    requiredParams: string[],
    form: {},
    additionalData: any
  ): {} {
    let formData: any = form;
    if (Object.prototype.hasOwnProperty.call(formData, 'processCoreForm')) {
      formData = formData.processCoreForm;
    }

    // Doplním další data, která nejsou v parametrech:
    formData.ML_ID = this.objId;
    if (
      !Object.prototype.hasOwnProperty.call(formData, 'ML_USER_ID') &&
      Object.prototype.hasOwnProperty.call(additionalData, 'USER_ID')
    ) {
      formData.ML_USER_ID = additionalData.USER_ID;
    }

    const params: { [key: string]: any } = {};
    requiredParams.forEach((param) => {
      if (Object.prototype.hasOwnProperty.call(formData, param)) {
        params[param] = formData[param];
      }
      if (
        param.startsWith('PARAM_') &&
        Object.prototype.hasOwnProperty.call(
          formData,
          param.replace('PARAM_', '')
        )
      ) {
        params[param] = formData[param.replace('PARAM_', '')];
      }
      // Spojení indexovaných parametrů do jednoho parametru, na to nejsem vůbec hrdý.
      let index: number | null = 0;
      const indexValues = [];
      while (index !== null) {
        if (
          param.startsWith('PARAM_') &&
          Object.prototype.hasOwnProperty.call(
            param.replace('PARAM_', '') + '[' + index + ']',
            formData
          )
        ) {
          // Indexovaný parametr bud defaultně prázdný
          params[param] = '';
          if (formData[param.replace('PARAM_', '') + '[' + index + ']']) {
            // Hodnoty doplním jen ty které existují. Pořadí v poli nebude odpovídat indexům,
            // ale to by snad nemuselo vadit.
            indexValues.push(
              formData[param.replace('PARAM_', '') + '[' + index + ']']
            );
          }
          // Pozice nicméně existuje, tak pokračuju v hledání.
          index++;
        } else {
          index = null;
        }
      }
      // A zde doplním hodnotu jen v případě, že jde o indexovaný parametr, a našel jsme nějaké existující hodnoty.
      if (indexValues.length > 0) {
        params[param] = indexValues.join(',');
      }
    });

    // console.log('Vyhodnocuju jestli mám všechny parametry k vytažení options pro parametr '+ this.name);
    // console.log('Potřebuju: ', requiredParams);
    // console.log('Mám k dispozici: ', formData);
    // console.log('Vyplnil jsem ', params);
    return params;
  }

  isMultiSelectParam(first: boolean): boolean {
    return (
      this.controlType === ParamControlType.multiSelect &&
      this.indexed &&
      ((first && this.index === 0) || (!first && this.index > 0))
    );
  }

  private resolveIndexName(
    name?: string,
    index?: number,
    type?: string,
    indexed?: boolean,
    unique?: boolean
  ): string | undefined {
    if (indexed) {
      if (type === 'SELECT' && unique) {
        return name;
      } else {
        return name + '[' + index + ']';
      }
    }
    return name;
  }
}
