import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'scloud-popup-color-badge',
  template: '',
  styles: [
    `
      :host {
        min-width: 1rem;
        min-height: 1rem;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        border: 1px solid #cbd3d8;
      }
    `,
  ],
})
export class PopupColorBadgeComponent {
  @HostBinding('style.background-color') @Input() color = '#cbd3d8';
}
