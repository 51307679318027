<dialog-title>
  <h3>{{ heading }}</h3>
</dialog-title>

<dialog-content>
  <ng-container [ngSwitch]="formType">
    <ng-container *ngSwitchCase="USER_EDIT_FORM_TYPE.personal">
      <app-user-edit-personal></app-user-edit-personal>
    </ng-container>

    <ng-container *ngSwitchCase="USER_EDIT_FORM_TYPE.addresses">
      <app-user-edit-addresses></app-user-edit-addresses>
    </ng-container>

    <ng-container *ngSwitchCase="USER_EDIT_FORM_TYPE.contacts">
      <app-user-edit-contacts></app-user-edit-contacts>
    </ng-container>

    <ng-container *ngSwitchCase="USER_EDIT_FORM_TYPE.payments">
      <app-user-edit-payments></app-user-edit-payments>
    </ng-container>

    <ng-container *ngSwitchCase="USER_EDIT_FORM_TYPE.credentials">
      <app-password-change></app-password-change>
    </ng-container>

    <ng-container *ngSwitchCase="USER_EDIT_FORM_TYPE.params">
      <app-user-edit-params></app-user-edit-params>
    </ng-container>
  </ng-container>
  <app-user-edit-protected [formType]="formType"></app-user-edit-protected>
</dialog-content>

<dialog-actions>
  <button
    mat-flat-button
    dialog-close
    i18n="
      Zavřít|Tlačítko na zavření dialogu v dialogu pro editaci údajů v Múj
      profil@@UserProfileDialog.Close"
  >
    Zavřít
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="saveProfile()"
    i18n="
      Uložit|Tlačítko na uložení dialogu v dialogu pro editaci údajů v Múj
      profil@@UserProfileDialog.Send"
  >
    Uložit
  </button>
</dialog-actions>
<app-session-processing></app-session-processing>
