import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ProcessEditComponent} from "@isp-sc/isp-sc/segments/selfcare/pages";
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProcessEditCanDeactivate
  implements CanDeactivate<ProcessEditComponent>
{
  canDeactivate(component: ProcessEditComponent): Observable<boolean> {
    return of(component.leavingWithBackButton).pipe(
      tap((canLeave) => {
        if (!canLeave) {
          component.leaveProcess();
        }
      })
    );
  }
}
