import { AbstractControl, ValidatorFn } from '@angular/forms';

export function dateRangeValidator(min: Date, max?: Date): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const date = new Date(control.value);
    let fail = 0;

    if (min && date < min) {
      fail = 1;
    }

    if (max && date > max) {
      fail = 1;
    }

    return fail ? { dateRange: { value: control.value, min, max } } : null;
  };
}
