import { Injectable } from '@angular/core';
import { DataBase } from '@og_soft/data-base';
import { PayOrder } from '@isp-sc/isp-sc/segments/selfcare/common';

@Injectable({
  providedIn: 'root',
})
export class PayOrderService extends DataBase<PayOrder> {
  protected getAllUrl(): string {
    return 'pay-order' as const;
  }
}
