import { Component, Input } from '@angular/core';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { ScNavComponent } from '@isp-sc/isp-sc/segments/selfcare/features';

/**
 * Komponenta na jednu položku menu.
 *
 * Máme ji, abychom hloupě neopakovali pořád stejné věci (včetně zavření
 * šuplíku s vysunutým menu na mobilu při kliknutí na již aktivní routu).
 */
@Component({
  selector: 'sc-menu-item',
  template: `
    <a
      mat-list-item
      *ngIf="session.canView(ident ?? '')"
      [routerLink]="['/' + ident]"
      routerLinkActive="active"
      (click)="scnav.closeDrawer()"
    >
      <ng-content></ng-content>
    </a>
  `,
})
export class ScNavMenuItemComponent {
  @Input() ident?: string;

  constructor(public session: SessionService, public scnav: ScNavComponent) {}
}
