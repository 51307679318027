import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  QueryList,
} from '@angular/core';
import { DashboardWidgetComponent } from '../dashboard-widget/dashboard-widget.component';

@Component({
  selector: 'sc-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements AfterContentInit {
  @Input() protected hgap = 20;

  @Input() protected vgap = 20;

  @Input() protected breakpoints: number[] = [540, 900, 1200];

  // public flick = false;

  // Konečně jsem pochopil rozdíl mezi ViewChildren a ContentChildren.
  // ViewChildren jsou v mé vlastní šabloně, ContentChildren v transcludovaném
  // obsahu třeba. Takže tady potřebujeme ContentChildren.
  @ContentChildren(DashboardWidgetComponent)
  widgets!: QueryList<DashboardWidgetComponent>;

  constructor(protected element: ElementRef) {}

  ngAfterContentInit() {
    setTimeout(() => {
      this.doRelayout();
    });
  }

  onResize(event: Event) {
    this.doRelayout();
  }

  doRelayout() {
    const myWidth = this.element.nativeElement.firstElementChild.clientWidth;

    let ncols = this.breakpoints.length + 1;
    for (let i = 0; i < this.breakpoints.length; i++) {
      //console.log("Zvažuju šířku", this.breakpoints[i]);
      if (myWidth < this.breakpoints[i]) {
        //console.log(" ... to už je moc.");
        ncols = i + 1;
        break;
      }
    }

    const colWidth = (myWidth - (ncols - 1) * this.hgap) / ncols;

    //console.log("Jsem široký " + myWidth + " pixelů, budu mít " + ncols + " sloupečky, široké " + colWidth);

    // Připravíme si, kam budeme počítat délku jednotlivých sloupečků
    const collens: number[] = [];
    for (let i = 0; i < ncols; i++) collens[i] = 0;

    // Widgety na dashboardu setříděné sestupně podle priority
    const sw: DashboardWidgetComponent[] = this.widgets
      .toArray()
      .sort((a, b) => {
        return b.priority - a.priority;
      });

    sw.forEach((widget) => {
      //console.log("Widget: ", widget, "s prioritou", widget.priority, "vysoký", widget.getHeight());

      widget.resetPlacement();
      widget.element.nativeElement.style.width = colWidth + 'px';

      let candidate = 0;
      let candidateLength = 9999;
      for (let i = 0; i < ncols; i++) {
        if (collens[i] < candidateLength) {
          candidate = i;
          candidateLength = collens[candidate];
        }
      }
      const widgetLeft = candidate * (colWidth + this.hgap);
      const widgetTop = collens[candidate];
      //console.log("  - dám ho do sloupečku " + candidate + ", " + widgetLeft + " doleva, " + widgetTop + " odvrchu.");
      widget.element.nativeElement.style.left = widgetLeft + 'px';
      widget.element.nativeElement.style.top = widgetTop + 'px';
      collens[candidate] += widget.getHeight() + this.vgap;
      //console.log("  - výšky sloupečků teď jsou ", collens);
    });

    // Protože všechno v nás je pozicované absolutně, musíme si sami vymyslet svou výšku
    let myHeight = 0;
    for (let i = 0; i < ncols; i++)
      if (collens[i] > myHeight) myHeight = collens[i];
    this.element.nativeElement.firstElementChild.style.height = myHeight + 'px';

    //this.flick = true;
    //setTimeout(_ => { this.flick = false }, 1000);
  }
}
