import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import {
  UserDataEditService,
  UserEditFormService,
} from '@isp-sc/shared/segments/user/data-access';
import { MatChipInputEvent } from '@angular/material/chips';
import { UserData } from '@isp-sc/shared/segments/user/common';
import {
  communicationChannelValidator,
  CrossFieldErrorMatcher,
  emailCommunicationValidator,
  phoneCommunicationValidator,
} from '@isp-sc/shared/segments/selfcare/common';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { FormHelperService } from '@isp-sc/shared/data-access';
import { UserEditFormType } from '@isp-sc/shared/common';
import { hasRequiredField } from '@isp-sc/shared/ui';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

@Component({
  selector: 'app-user-edit-contacts',
  templateUrl: './user-edit-contacts.component.html',
  styleUrls: ['./user-edit-contacts.component.scss'],
})
export class UserEditContactsComponent implements OnInit {
  protected readonly hasRequiredField = hasRequiredField;
  public form: FormGroup;
  private userData?: UserData;
  public formName: string;
  // Tento formulář se při registraci chová jinak. To jestli jsme v registraci určuju podle toho
  // jestli v session existuje user. Dělám to v konstruktoru a uložím si to sem, kdyby se to v budoucnu mělo
  // dělat nějak jinak;
  public registration: boolean;

  readonly separatorKeysCodesEmail: number[] = [ENTER, COMMA, SPACE];
  readonly separatorKeysCodesPhones: number[] = [ENTER, COMMA];
  emails: string[] = [];
  phones: string[] = [];

  errorMatcher = new CrossFieldErrorMatcher();

  @ViewChild('phoneList', { static: false }) phoneList: any;
  @ViewChild('emailList', { static: false }) emailList: any;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  constructor(
    public session: SessionService,
    public formHelper: FormHelperService,
    private userEditService: UserDataEditService,
    private userEditFormService: UserEditFormService
  ) {
    this.formName = UserEditFormType.personal;
    this.registration = !this.session.user;
    this.form = this.userEditFormService.getForm(UserEditFormType.contacts);
    if (this.registration) {
      this.form.get('emails')?.setValidators(Validators.required);
    }
    this.form.setValidators([
      emailCommunicationValidator as ValidatorFn,
      phoneCommunicationValidator as ValidatorFn,
      communicationChannelValidator as ValidatorFn,
    ]);

    this.userEditFormService.profileDefaultConfig(
      this.form,
      UserEditFormType.contacts
    );
  }

  ngOnInit(): void {
    this.form.get('emails')?.valueChanges.subscribe((value) => {
      this.checkEmails();
    });

    this.form.get('phones')?.valueChanges.subscribe((value) => {
      this.checkPhones();
    });

    if (this.session.user) {
      this.userEditService.getSingleton({}, 1800).subscribe(
        (userData) => {
          this.userData = userData;
          this.form.patchValue(this.userData);
          this.initConfiguration();
        },
        (err) => {
          console.log('Chyba při získání dat uživatelského profilu ' + err);
        }
      );
    }
  }

  private initConfiguration(): void {
    this.phones = this.userData?.phones
      ? this.userData.phones.split(/, */)
      : [];
    this.emails = this.userData?.emails
      ? this.userData.emails.split(/, */)
      : [];
  }

  // **************************************** Zadávání emailů a telefonních čísel *********************************
  // Tyto funkce jsou tu aby fungovali itemy na telefonní číslo a email (mat-chips)
  // Pokud by bylo potřeba tento item použít jinde,
  // tak je potřeba na něj vytvořit vlastní komponentu, neopisovat toto řešení !!!!
  // (dělal jsme to bez komponenty protože kdybychom to nepotřebovali jinde tak to bude zbytečná práce)

  addEmail(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.emails.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    const emails = this.emails.join(',');
    this.solveEmailAutocheck(emails);
    this.form.get('emails')?.setValue(emails);
    this.form.get('emails')?.markAsTouched();
    this.form.get('emails')?.markAsDirty();
  }

  removeEmail(email: string): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
    }
    const emails = this.emails.join(',');
    this.solveEmailAutocheck(emails);
    this.form.get('emails')?.setValue(emails);
    this.form.get('emails')?.markAsTouched();
    this.form.get('emails')?.markAsDirty();
  }

  addPhone(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      let telNumber;

      const phoneUtil = PhoneNumberUtil.getInstance();
      try {
        const telNumberObj = phoneUtil.parse(value);
        telNumber = phoneUtil.format(telNumberObj, PhoneNumberFormat.E164);
      } catch (err) {
        telNumber = value;
        this.phoneList.errorState = true;
      }

      this.phones.push(telNumber);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    const phones = this.phones.join(',');
    this.solveSmsAutocheck(phones);
    this.form.get('phones')?.setValue(phones);
    this.form.get('phones')?.markAsTouched();
    this.form.get('phones')?.markAsDirty();
  }

  removePhone(phone: string): void {
    const index = this.phones.indexOf(phone);

    if (index >= 0) {
      this.phones.splice(index, 1);
    }
    const phones = this.phones.join(',');
    this.solveSmsAutocheck(phones);
    this.form.get('phones')?.setValue(phones);
    this.form.get('phones')?.markAsTouched();
    this.form.get('phones')?.markAsDirty();
  }

  /**
   * Protože telefonní čísla nejsou klasický formControl, ale chip list,
   * tak tam validátory nefungují úplně klasicky. Při každé změně tedy musím přepočítat a ručně nastavit
   * tomu chip listu jestli je validní.
   */
  checkPhones(): void {
    if (!this.phoneList) {
      // Pokud volám ještě před získáním ViewChild
      return;
    }
    const phoneUtil = PhoneNumberUtil.getInstance();

    let errorState = false;

    this.phones.forEach((value) => {
      try {
        const telNumberObj = phoneUtil.parse(value, 'CZ');
        if (!phoneUtil.isValidNumber(telNumberObj)) {
          errorState = true;
        }
      } catch (err) {
        errorState = true;
      }
    });

    if (this.form.errors?.['phoneCommunication']) {
      // Item je také nevalidní, pokud neprojde validátor phoneCommunication
      // (je zaškrtnutá komunikace sms, ale není žádný telefon vyplněný)
      errorState = true;
    }

    if (errorState) {
      this.form.get('phones')?.setErrors({ formatError: true });
    }
    this.phoneList.errorState = errorState;
  }

  /**
   * Protože emaily nejsou klasický formControl, ale chip list,
   * tak tam validátory nefungují úplně klasicky. Při každé změně tedy musím přepočítat a ručně nastavit
   * tomu chip listu jestli je validní.
   */
  checkEmails(): void {
    if (!this.emailList) {
      // Pokud volám ještě před získáním ViewChild
      return;
    }
    const EMAIL_REGEXP =
      /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    let errorState = false;

    if (this.registration && this.emails.length === 0) {
      // Při registraci je email povinný, pokud není nastaven, je nevalidní
      errorState = true;
    }

    // Zároveň je item nevalidní, když jakýkoli z emailů nemá požadovaný formát.
    this.emails.forEach((value) => {
      try {
        if (!EMAIL_REGEXP.test(value)) {
          errorState = true;
        }
      } catch (err) {
        errorState = true;
      }
    });

    if (this.form.errors?.['emailCommunication']) {
      // Item je také nevalidní, pokud neprojde validátor emailCommunication
      // (je zaškrtnutá komunikace emailem, ale není žádný email vyplněný)
      errorState = true;
    }

    if (errorState) {
      this.form.get('emails')?.setErrors({ formatError: true });
    }
    this.emailList.errorState = errorState;
  }

  private solveEmailAutocheck(value: any): void {
    const infoEmail = this.form.get('infoEmail');
    const emails = this.form.get('emails');
    if (this.session.getOption('USER_AUTOCHECK_SEND_EMAIL')) {
      infoEmail?.setValue(!!value);
    }
  }

  private solveSmsAutocheck(value: any): void {
    const infoEmail = this.form.get('infoSms');
    if (this.session.getOption('USER_AUTOCHECK_SEND_SMS')) {
      infoEmail?.setValue(!!value);
    }
  }
}
