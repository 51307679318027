import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataUserServicesService } from './data-user-services.service';
import {
  DataBase,
  DataBaseServiceConfig,
  FilterInfo,
  GetallResult,
} from '@og_soft/data-base';
import { CachingInterceptor } from '@og_soft/cache';
import {
  RecDocumentsAgreements,
  RecDocumentsAgreementsMeta,
} from '@isp-sc/shared/segments/data/common';

@Injectable({
  providedIn: 'root',
})
export class DataDocumentsAgreementsService extends DataBase<RecDocumentsAgreements> {
  protected getAllUrl(): string {
    return 'documents-agreements' as const;
  }

  constructor(
    public override http: HttpClient,
    public serviceConfig: DataBaseServiceConfig,
    public userServices: DataUserServicesService,
    private cachingInterceptor: CachingInterceptor
  ) {
    super(http, serviceConfig, cachingInterceptor);
  }

  protected override dataPostprocess(
    data: GetallResult<RecDocumentsAgreements>
  ): GetallResult<RecDocumentsAgreements> {
    // FIXME optimalizovat, aby se to dělalo jedním getAll s parametry - viz. např. user-nodes.service.ts
    for (let i = 0; i < data.data.length; i++) {
      for (let iSa = 0; iSa < data.data[i].sa.length; iSa++) {
        this.userServices.getone(data.data[i].sa[iSa].saId).subscribe((d) => {
          data.data[i].sa[iSa].name = d.saName;
          data.data[i].sa[iSa].paramIdent = DataUserServicesService.saIdentGet(d);
          this.onDataCollected.emit();
        });
      }
    }
    return super.dataPostprocess(data);
  }

  override metaFetchFilterInfo(meta: RecDocumentsAgreementsMeta): FilterInfo[] {
    if (meta) {
      const info: FilterInfo[] = [];
      if (meta.valid && (meta.valid === 'true' || meta.valid === 'false')) {
        info.push({
          label: $localize`:@@DataDocumentsAgreementsServices.filterInfo.valid.label:Stav`,
          filterNames: ['valid'],
          value:
            meta.valid === 'true'
              ? $localize`:@@DataDocumentsAgreementsServices.filterInfo.valid.valid:Platný`
              : $localize`:@@DataDocumentsAgreementsServices.filterInfo.valid.invalid:Naplatný`,
        });
      }
      return info;
    }

    return super.metaFetchFilterInfo(meta);
  }
}
