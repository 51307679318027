import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  dateRangeValidate,
  DatetimeControlPickerType,
} from '@og_soft/datetime-control';
import { ForgetTableComponent } from '@isp-sc/shared/ui';
import { CrossFieldErrorMatcher } from '@isp-sc/shared/segments/selfcare/common';
import { DataUsersPaymentsService } from '@isp-sc/shared/segments/data/data-access';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import {
  FormHelperService,
  UtilPrintService,
} from '@isp-sc/shared/data-access';
import { MangoFormControl } from '@isp-sc/shared/segments/mango-processes/common';
import { castToAny } from '@isp-sc/shared/common';

@Component({
  selector: 'app-user-payments',
  templateUrl: './user-payments.component.html',
  styleUrls: ['./user-payments.component.scss'],
})
export class UserPaymentsComponent implements OnInit {
  protected readonly castToAny = castToAny;
  protected readonly DatetimeControlPickerType = DatetimeControlPickerType;
  filters: FormGroup;
  filterValues: any = {};

  @ViewChild(ForgetTableComponent, { static: false })
  public tab?: ForgetTableComponent;

  // just specific error type, otherwise it will highlight on any error within the group
  errorMatcher = new CrossFieldErrorMatcher(['dateRangeInvalid']);

  constructor(
    public dataService: DataUsersPaymentsService,
    public session: SessionService,
    public printUtil: UtilPrintService,
    public formHelper: FormHelperService
  ) {
    this.filters = new FormGroup(
      {
        dateFrom: new MangoFormControl(''),
        dateTo: new MangoFormControl(''),
      },
      { validators: dateRangeValidate('dateFrom', 'dateTo') }
    );
  }

  ngOnInit() {
    this.filters.patchValue(this.filterValues);
  }

  getCurrencySymbol() {
    return this.session.locDefaults.currencyCode;
  }

  notePaymentVisible(): boolean {
    const opt = this.session.getOption('SELFCARE.user-payments.notePayment');
    return !(opt && opt.includes('hidden'));
  }
}
