import { NgModule } from '@angular/core';
import { SessionProcessingComponent } from './session-processing/session-processing.component';
import { DialogModule } from '@og_soft/dialog';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { AsyncPipe, NgIf } from '@angular/common';
import { UnavailablePageComponent } from './unavailable-page/unavailable-page.component';
import { MatButton } from '@angular/material/button';
@NgModule({
  declarations: [UnavailablePageComponent, SessionProcessingComponent],
  imports: [DialogModule, MatProgressSpinner, NgIf, MatButton, AsyncPipe],
  exports: [UnavailablePageComponent, SessionProcessingComponent],
})
export class CoreModule {}
