import { Component, Input } from '@angular/core';
import { DialogConfig } from '@og_soft/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  @Input() heading = 'Confirm';
  @Input() message = 'Do you really want to continue the action?';
  @Input() messageDetail = '';

  constructor(public config: DialogConfig) {
    this.heading = config.data.heading ? config.data.heading : this.heading;
    this.message = config.data.message ? config.data.message : this.message;
    this.messageDetail = config.data.messageDetail
      ? config.data.messageDetail
      : this.messageDetail;
  }
}
