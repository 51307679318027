<ng-container *ngIf="unframed">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>
<mat-card appearance="outlined" *ngIf="!unframed">
  <mat-card-header>
    <mat-card-title>{{ title }}</mat-card-title>
    <mat-card-subtitle>{{ subtitle }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </mat-card-content>
</mat-card>

<ng-template #content>
  <div #here><ng-content></ng-content></div>
</ng-template>
