import { Injectable } from '@angular/core';
import { DataBase } from '@og_soft/data-base';
import { RecEditorBlock } from '@isp-sc/shared/common';

@Injectable({
  providedIn: 'root',
})
export class EditorBlockService extends DataBase<RecEditorBlock> {
  protected getAllUrl(): string {
    return 'editor-block' as const;
  }
}
