<div class="container">
  <ng-container [ngSwitch]="state">
    <ng-container *ngSwitchCase="'ok'">
      <div>
        <span i18n="@@VerifyRegistration.ok.text"
          >Vše v pořádku, za okamžik budete přesměrováni.</span
        >
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'failed'">
      <div>
        <span i18n="@@VerifyRegistration.failed.text"
          >Platnost odkazu již vypršela</span
        >
      </div>
      <div>
        <button mat-flat-button [routerLink]="['/login']">
          <span i18n="@@VerifyRegistration.failed.button">Zpět</span>
        </button>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'check'">
      <div>
        <span i18n="@@VerifyRegistration.check.text"
          >Probíhá ověřování Vašeho emailu, okamžik strpení.</span
        >
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'wait'">
      <div>
        <span i18n="@@VerifyRegistration.wait.text"
          >Na Váš email byla odeslána zpráva s&nbsp;aktivačním odkazem. Prosím,
          zkontrolujte svůj email a&nbsp;pokračujte podle pokynů ve
          zprávě.</span
        >
      </div>
      <div>
        <button mat-flat-button [routerLink]="['/login']">
          <span i18n="@@VerifyRegistration.failed.button"
            >Zpět na přihlášení</span
          >
        </button>
      </div>
    </ng-container>
  </ng-container>
</div>
