import { Injectable } from '@angular/core';
import { PopupOption } from '@isp-sc/shared/ui';
import { DataBase } from '@og_soft/data-base';
import { ProcessPremises } from '@isp-sc/shared/segments/process/common';

@Injectable({
  providedIn: 'root',
})
export class ProcessPremisesService extends DataBase<ProcessPremises> {
  protected getAllUrl(): string {
    return 'process-premises' as const;
  }

  override tranformOptions(row: ProcessPremises): PopupOption {
    return { id: row.premiseId, name: row.premiseName };
  }
}
