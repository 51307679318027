import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ForgetTableComponent } from '@isp-sc/shared/ui';
import {
  DataDocumentsOtherService,
  DataDocumentsOtherTypesService,
} from '@isp-sc/shared/segments/data/data-access';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { ActivatedRoute } from '@angular/router';
import { DialogConfig, DialogService } from '@og_soft/dialog';
import { MangoFormControl } from '@isp-sc/shared/segments/mango-processes/common';
import { CardPaymentComponent } from '@isp-sc/isp-sc/segments/selfcare/features';
import { UtilPrintService } from '@isp-sc/shared/data-access';

@Component({
  selector: 'app-documents-other',
  templateUrl: './documents-other.component.html',
  styleUrls: ['./documents-other.component.scss'],
})
export class DocumentsOtherComponent implements OnInit {
  filters: FormGroup;
  filterValues: any = {};

  @ViewChild(ForgetTableComponent, { static: false })
  public tab!: ForgetTableComponent;

  private subParams: any;

  constructor(
    public dataService: DataDocumentsOtherService,
    public session: SessionService,
    private route: ActivatedRoute,
    public dataDocumentsOtherTypes: DataDocumentsOtherTypesService,
    public dialog: DialogService,
    public printUtil: UtilPrintService
  ) {
    this.filters = new FormGroup({
      docType: new MangoFormControl(''),
    });
  }

  ngOnInit() {
    this.subParams = this.route.params.subscribe((params) => {
      this.filters.setValue({ docType: params['id'] ? +params['id'] : null });
    });
  }

  getCurrencySymbol(): string {
    return this.session.locDefaults.currencyCode;
  }

  debt(amountPay: number): number {
    if (amountPay > 0) {
      return 1;
    }
    return 0;
  }

  payment(data: any): void {
    const dconfp = new DialogConfig();
    let attachType = null;
    if (data.docData.payVarSymbol == data.docData.docOtherVarSymbol) {
      attachType = 17;
    }
    dconfp.data = {
      amount: data.docData.amountPay,
      varsym: data.docData.payVarSymbol,
      typeAttach: attachType,
      saId: 0,
    };
    //    this.formCardPay.itemDisabled("amount");
    //    this.formCardPay.itemDisabled("varsym");

    const dref = this.dialog.open(CardPaymentComponent, dconfp);
    dref.afterClosed.subscribe((result) => {
      // if (result)
      // console.error("Tady mam data ", result)
    });
  }
}
