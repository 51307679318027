import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Payment3dsecureResponseService } from '@isp-sc/isp-sc/segments/selfcare/data-access';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';

@Component({
  selector: 'app-card-response',
  templateUrl: './card-response.component.html',
  styleUrls: ['./card-response.component.scss'],
})
export class CardResponseComponent {
  constructor(
    public session: SessionService,
    private route: ActivatedRoute,
    private router: Router,
    private payment3dSecureResponse: Payment3dsecureResponseService
  ) {
    this.route.params.subscribe((params) => {
      this.session.processingSet(
        true,
        $localize`:@@CardResponse.pay3DSec.message.processing:Zpracováváme Vaší platbu, strpení prosím.`
      );
      this.route.queryParams.subscribe(
        (qp) => {
          this.payment3dSecureResponse.post(qp).subscribe(
            (result) => {
              this.session.processingSet(false);
              if (result.result) {
                this.session.message(result.message);
              } else {
                this.session.message(
                  $localize`:@@CardResponse.pay3DSec.next.error.message:Chyba!` +
                    ' ' +
                    result.message
                );
              }
              this.router.navigate([params['red']]);
            },
            (error) => {
              this.paymentError(params['red']);
            }
          );
        },
        (error) => {
          this.paymentError(params['red']);
        }
      );
    });
  }

  private paymentError(redirect: string): void {
    this.session.processingSet(false);
    this.session.message(
      $localize`:@@CardResponse.pay3DSec.error.message:Problém s platbou kartou`
    );
    this.router.navigate([redirect]);
  }
}
