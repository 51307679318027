import { Injectable } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ValueDomainValidator } from './value-domain.validator';
import { ParamBase } from './param-base';
import {
  ParamObjectType,
  ParamType,
} from '@isp-sc/shared/segments/params/common';
import { MangoParamFormControl } from './mango-param-form-control';
import { NodeParamUniqueValidator } from './node-param-unique.validator';

@Injectable({
  providedIn: 'root',
})
export class ParamControlService {
  constructor(
    private valueDomainValidator: ValueDomainValidator,
    private nodeParamUniqueValidator: NodeParamUniqueValidator
  ) {}

  toFormGroup(params: ParamBase[]): FormGroup {
    const group: any = {};

    params.forEach((param) => {
      if (param.name) {
        group[param.name] = this.makeControlFromParam(param);
      }
    });
    return new FormGroup(group);
  }

  makeControlFromParam(param: ParamBase): MangoParamFormControl {
    let value: any;
    let control: MangoParamFormControl;

    if (param.type === ParamType.BOOL) {
      if (param.value === '1' || param.value === 'true') {
        value = true;
      } else if (param.value === '0' || param.value === 'false') {
        value = false;
      } else if (param.defaultValue === '1' || param.defaultValue === 'true') {
        value = true;
      } else if (param.defaultValue === '0' || param.defaultValue === 'false') {
        value = false;
      } else {
        // V parametrech je hodnota null analogická hodnotě false.
        value = false;
      }
      param.value = value;
    } else {
      value = param.value || param.defaultValue || '';
    }

    const validators = this.getDomainValidators(false, param);

    // console.log('XXXXXXXXXXXXXXXXXXXXXX vytvářím parametr: '+param.name+' s hodnotou: '+param.value+' a indexem: '+param.index);

    if (param.type === ParamType.SELECT) {
      // U popuplistů se s použitím asynchronních validátorů rozbije schopnost držet hodnotu
      // pokud zadáme: updateOn: "blur". Nicméně myslím, že asynchronní validátory jsou u popuplistů
      // z principu zbytečné (resp. u populistu má smyslu pouze validátor na povinnost), takže to tady
      // pořeším výhybkou.
      control = new MangoParamFormControl(value, {
        validators: validators.validators,
      });
    } else {
      control = new MangoParamFormControl(value, {
        validators: validators.validators,
        asyncValidators: validators.asyncValidators,
        updateOn: 'blur',
      });
    }

    if (!param.editable) {
      control.disable();
    }

    control.param = param;

    return control;
  }

  getDomainValidators(required: boolean, param?: ParamBase): any {
    const validators = [];
    const asyncValidators = [];

    if (required) {
      validators.push(Validators.required);
    }

    if (param?.type === ParamType.FLOAT) {
      validators.push(Validators.pattern('^[-+]?[0-9]*,?[0-9]+$'));
    }

    if (param?.type === ParamType.NUM) {
      validators.push(Validators.pattern('^[-+]?[0-9]+$'));
    }

    if (param?.domain && param.type !== ParamType.SELECT) {
      asyncValidators.push(
        this.valueDomainValidator.validate.bind(this.valueDomainValidator)
      );
    }

    if (param?.objectType === ParamObjectType.Node) {
      asyncValidators.push(
        this.nodeParamUniqueValidator.validate.bind(
          this.nodeParamUniqueValidator
        )
      );
    }
    return { validators, asyncValidators };
  }
}
