import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let secs = parseInt(value, 10);
    if (isNaN(secs)) {
      secs = 0;
    }
    const components = [];
    if (secs >= 3600) {
      components.push(Math.floor(secs / 3600));
    }
    components.push(Math.floor(secs / 60) % 60);
    components.push(secs % 60);
    return components
      .map((v, i) => v.toString(10).padStart(i ? 2 : 1, '0'))
      .join(':');
  }
}
