import { Observable, of } from 'rxjs';
import { DataCodeListService } from './data-code-list.service';
import { DataValueDomainPatternService } from './data-value-domain-pattern.service';
import { DataValueDomainService } from './data-value-domain.service';
import { PopupOption } from '@isp-sc/shared/ui';
import { map, mergeMap } from 'rxjs/operators';
import {
  ProcessPremisesService,
  ProcessServicesService,
  ProcessUnitsService,
} from '@isp-sc/shared/segments/process/data-access';
import { DomainType } from '@isp-sc/shared/segments/params/common';
import { ParamServices } from './param-services';

/**
 * Třída, která slouží k zjištění oboru hodnot.
 * Využívá další servisy, které se jí starají o vytažení samotných dat z backendu.
 */
export class ValueDomain {
  type?: DomainType;
  // Parametry požadované pro vyhodnocení oboru hodnoty
  // Týká se především případů, kdy je obor hodnot dotazem z tabulky, do kterého je třeba doplnit různé
  // parametry procesu.
  requiredParams: string[] | null = null;
  definition: string;
  dataValuePatterns: DataValueDomainPatternService;
  dataCodeList: DataCodeListService;
  dataValueDomain: DataValueDomainService;
  dataProcessPremises: ProcessPremisesService;
  dataProcessServices: ProcessServicesService;
  dataProcessUnits: ProcessUnitsService;

  constructor(definition: string, services: ParamServices) {
    this.definition = definition;
    this.dataValuePatterns = services.dataValuePatterns;
    this.dataCodeList = services.dataCodeList;
    this.dataValueDomain = services.dataValueDomain;
    this.dataProcessPremises = services.dataProcessPremises;
    this.dataProcessServices = services.dataProcessServices;
    this.dataProcessUnits = services.dataProcessUnits;

    if (this.definition === null) {
      throw new Error(
        'ValueDomainService musí mít vždy nadefinovaný obor hodnot.'
      );
    }
  }

  optionsGet(params: any): Observable<PopupOption[]> {
    // Před dotazem na seznam options si zjistím jestli k jejich vytažení
    // nepotřebuji znát nějaké parametry (typicky kvůli value_domain_pattern).
    // Pokud tyto parametry nemám k dispozici, tak request zbytečně neposílám.
    return this.getRequiredParams().pipe(
      mergeMap((requiredParams) => {
        let check = true;
        if (requiredParams && requiredParams.length > 0) {
          requiredParams.forEach((param) => {
            if (!Object.prototype.hasOwnProperty.call(params, param)) {
              check = false;
            }
          });
        }
        if (!check) {
          return of(null); // This will be transformed later
        } else {
          return this.optionsPrepare(params);
        }
      }),
      map((options) => (options === null ? [] : options))
    );
  }

  optionsPrepare(params: any): Observable<PopupOption[]> {
    this.parseDomain();
    let requestParams: any;
    switch (this.type) {
      case DomainType.dom_any:
      case DomainType.dom_unknown:
        return of([]);
      case DomainType.dom_code_list:
        return this.dataCodeList.optionsGet({
          category: this.definition.split('::')[1],
        });
      case DomainType.dom_table:
        return this.dataValuePatterns.optionsGet({
          ...{ tableId: this.definition.split('::')[1] },
          ...params,
        });
      case DomainType.dom_attribute:
        requestParams = {};
        switch (this.definition.split('::')[1]) {
          case 'PREMISE':
            return this.dataProcessPremises.optionsGet();
          case 'UNIT':
            if (Object.prototype.hasOwnProperty.call(params, 'ML_ID')) {
              requestParams.mlId = params.ML_ID;
            }
            return this.dataProcessUnits.optionsGet(requestParams);
          case 'SERVICE':
            if (Object.prototype.hasOwnProperty.call(params, 'ML_UNIT_ID')) {
              requestParams.unitId = params.ML_UNIT_ID;
            }
            if (Object.prototype.hasOwnProperty.call(params, 'ML_ID')) {
              requestParams.mlId = params.ML_ID;
            }
            return this.dataProcessServices.optionsGet(requestParams);
          default:
            return of([]);
        }
      case DomainType.dom_range:
      case DomainType.dom_subdefs:
      case DomainType.dom_preg:
      case DomainType.dom_complicated:
        return this.dataValueDomain.optionsGet({
          ...{ definition: this.definition },
          ...params,
        });
      case DomainType.dom_value:
        return of(
          this.definition.split(',').map((value) => {
            return { id: value, name: value };
          })
        );
      default:
        return of([]);
    }
  }

  getRequiredParams(): Observable<string[]> {
    this.parseDomain();
    switch (this.type) {
      case DomainType.dom_table:
      case DomainType.dom_subdefs:
      case DomainType.dom_complicated:
        if (this.requiredParams) {
          return of(this.requiredParams);
        }
        return this.dataValueDomain
          .getall({ definition: this.definition, method: 'requiredParams' })
          .pipe(
            map((data) => {
              this.requiredParams = data.data;
              return this.requiredParams;
            })
          );
      case DomainType.dom_attribute:
        // Atribut služby závisí na vybrané jednotce.
        // Jak služba tak jednotka závisí na id procesu (případ, kdy je u procesu ukončená služba
        // potom by měla být v popupu
        switch (this.definition.split('::')[1]) {
          case 'SERVICE':
            return of(['ML_UNIT_ID', 'ML_ID']);
          case 'UNIT':
            return of(['ML_ID']);
          default:
            return of([]);
        }
      default:
        return of([]);
    }
  }

  containValue(value: string): Observable<boolean> | undefined {
    this.parseDomain();
    switch (this.type) {
      case DomainType.dom_any:
      case DomainType.dom_unknown:
        return of(true);
      case DomainType.dom_value:
        if (this.definition === 'PIN_QUADRUPLE') {
          return of(this.pinQuadrupleCheck(value));
        }
        return of(this.definition.split(',').includes(value));
      case DomainType.dom_code_list:
      case DomainType.dom_table:
      case DomainType.dom_attribute:
        // Zde nemá asi smysl validovat, populist si musí sám vyřešit, aby neměl nastavenou hodnotu, které není v jeho optionech.
        return of(true);
      case DomainType.dom_range:
      case DomainType.dom_subdefs:
      case DomainType.dom_preg:
      case DomainType.dom_complicated:
        return this.dataValueDomain.containValue(value, this.definition);
      default:
        return of(false);
    }
  }

  private parseDomain(): void {
    if (!this.definition) {
      this.type = DomainType.dom_any;

      // Jednoduchá tabulka z code_listu
    } else if (/^code_list::[a-zA-Z0-9_]*$/.test(this.definition)) {
      this.type = DomainType.dom_code_list;

      // Jednoduchá tabulka z value_domain_patters
    } else if (/^table::[0-9]*$/.test(this.definition)) {
      this.type = DomainType.dom_table;
      //    this.type = DomainType.dom_complicated;
    } else if (/^attribute::[a-zA-Z0-9_]*$/.test(this.definition)) {
      this.type = DomainType.dom_attribute;

      // Pokud nejde ani o jeden případ výše, potřebuji rozhodnout, jestli jde o jednoduchou
      // definici, nebo něco složitější. Jednoduchá je pro mě v tuto chvíli jen čárkou oddělený seznam,
      // cokoli složitější posílám do backendu na zpracování do ValueDomain.
      // Složitost určuji existencí speciálních znaků - toto je možné případně upravit.
    } else if (/::|\^|\?|~|-|\.\./.test(this.definition)) {
      this.type = DomainType.dom_complicated;

      // Sem by měl spadnou už jen čárkou oddělený seznam.
    } else if (/,/.test(this.definition)) {
      this.type = DomainType.dom_value;

      // Případně jediná samostatná hodnota
    } else {
      this.type = DomainType.dom_value;
    }
  }

  // přepsáno z ParamFrameRecord->GetEditItemByType
  private pinQuadrupleCheck(value: string): boolean {
    const regexpNoMatch = [
      '(?!0{4,6})', // Nesmi to byt 4-6 stejnych po sobe jdoucich cislic
      '(?!1{4,6})',
      '(?!2{4,6})',
      '(?!3{4,6})',
      '(?!4{4,6})',
      '(?!5{4,6})',
      '(?!6{4,6})',
      '(?!7{4,6})',
      '(?!8{4,6})',
      '(?!9{4,6})',
      '(?!^0?1234(56?)?$)', // Nesmi byt 1234, 0123, 123456 apod
      '(?!^(6?5)?43210?$)', // Nesmi byt 654321, 4321 apod.
    ];

    const reqexpMatch = ['(?=^(\\d{4,6})$)']; // Musí to být 4-6-ti místné číslo
    // Více info o kombinaci reg. výrazů OR a AND na http://stackoverflow.com/questions/869809/combine-regexp
    const regexp = '^' + reqexpMatch.join('') + regexpNoMatch.join('') + '.*';

    return new RegExp(regexp).test(value);
  }
}
