<dialog-title>
  <h3
    i18n="
      Návrh na uzavření ticketu|Nadpis dialogu uzavření
      ticketu@@ProcessFinish.ResolveTicket"
  >
    Napište zprávu
  </h3>
</dialog-title>

<dialog-content>
  <form [formGroup]="form">
    <div>
      <mat-form-field style="width: calc(100% - 40px)">
        <mat-label i18n="@@ProcessEdit.newNote.label">Zpráva</mat-label>
        <textarea
          matInput
          formControlName="note"
          id="note"
          name="note"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</dialog-content>

<dialog-actions>
  <button
    mat-flat-button
    dialog-close
    i18n="
      Zavřít|Tlačítko na zavření dialogu v dialogu při uzavření
      ticketu@@ProcessFinish.button.Close"
  >
    Zavřít
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="save()"
    i18n="
      Odeslat|Tlačítko na uložení dialogu v dialogu při uzavření
      ticketu@@ProcessFinish.button.Send"
  >
    Odeslat
  </button>
</dialog-actions>
