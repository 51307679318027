import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DependencyService } from '@isp-sc/shared/segments/dependency/data-access';

/**
 * Servisa, která se stará o vyhodnocení závislostí parametrů služby
 * V tuto chvíli není vyhodnocování závislostí nijak implementované, parametry jsou viditelné vždy
 *
 */
@Injectable({
  providedIn: 'root',
})
export class DependencyServiceService extends DependencyService {
  private data: {} = {}; //Moje představa je, že si služba bude držet veškerá data na vyhodnocení závislostí, a že tyto data budou

  public resolveDependency(
    dependencyStr: string,
    objectId: number,
    actualData: any
  ): Observable<boolean> {
    // console.log('Vyhodnocuju závislost: ',dependencyStr);

    if (dependencyStr === '') {
      return of(false);
    }

    if (dependencyStr === 'ALL') {
      return of(true);
    }

    const paramDep = dependencyStr.split(';');
    for (const key in actualData.params) {
      if (Object.prototype.hasOwnProperty.call(actualData.params, key)) {
        if (key == paramDep[0]) {
          const value = actualData.params[key];
          const paramDepValue = paramDep[1].split(',');
          for (const key2 in paramDepValue) {
            if (paramDepValue[key2] == value) {
              return of(true);
            }
          }
        }
      }
    }

    return of(false);
  }
}
