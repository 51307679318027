import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ParamBase } from './param-base';
import { DataProcessGeneralService } from '@isp-sc/shared/segments/params/data-access';

@Injectable({
  providedIn: 'root',
})
export class DataNewProcessService extends DataProcessGeneralService {
  // getallUrl = 'process-new';

  protected getAllUrl(): string {
    return 'process-new' as const;
  }

  getAttributesAsParams(id: number, defId: number): Observable<ParamBase[]> {
    return this.getOneBy({ defId }).pipe(
      map((param) => {
        return this.transformDataToParams(param, this.getServices());
      })
    );
  }
}
