import { Injectable } from '@angular/core';
import { DataBase } from '@og_soft/data-base';
import { RecInetData } from '@isp-sc/shared/common';

@Injectable({
  providedIn: 'root',
})
export class InetDataService extends DataBase<RecInetData, {}> {
  protected getAllUrl(): string {
    return 'inet-data' as const;
  }

  private _data: any[] = [];

  /**
   * Načte data v závislosti na parametrech. Implementuje jednoduchou cache.
   */
  public fetchData(saId?: number): any {
    const hash = saId;
    if (hash !== undefined && !this._data[hash]) {
      this.getone(saId).subscribe(
        (p) => {
          // console.log("InetDataService fetched data");
          this._data[hash] = p;
        },
        (error) => {
          console.log('InetDataService fetching error', error);
          this._data[hash] = undefined;
        }
      );
    }
    return hash !== undefined ? this._data[hash] : undefined;
  }

  /**
   * Vrací sumu načtených dat podle zadaných kritérií.
   *
   * @param saId ID služby, pro kterou zjišťujeme data.
   * @param range Rozsah dat (month/week/day)
   * @param inOut Směr přenosu (in/out)
   * @param ip Ip adresa pro kterou chceme data, all pro sumu všech.
   */
  public inetSum(saId: number, range: string, inOut: string, ip = 'all') {
    let sum = undefined;
    const data = this.fetchData(saId);
    if (data !== undefined && data !== null) {
      sum = 0;
      for (const anIp in data[range]) {
        if (ip == anIp) {
          for (const time in data[range][anIp][inOut]) {
            sum += data[range][anIp][inOut][time];
          }
        }
      }
    }
    return sum;
  }

  /**
   * Vrací kumulovaná data podle zadaných kritérií. Kumulovaná se např. použijí v grafu.
   *
   * @param range Rozsah dat (month/week/day)
   * @param inOut Směr přenosu (in/out)
   * @param ip Ip adresa pro kterou chceme data, all pro data za celou službu bez ohledu na jednotlivé IP.
   */
  public inetDataAccumulated(
    saId: number,
    range: string,
    inOut: string,
    ip: string = 'all'
  ): number[] {
    let accData: number[] = [];
    const data = this.fetchData(saId);
    if (data != undefined || data != null) {
      accData = [];
      let acc = 0;
      for (const time in data[range][ip][inOut]) {
        if (data[range][ip][inOut][time]) {
          acc += data[range][ip][inOut][time];
          accData.push(acc);
        } else {
          accData.push();
        }
      }
    }
    return accData;
  }

  /**
   * Vrací labely pro Xovou osu grafu.
   *
   * @param range Rozsah dat (month/week/day)
   */
  public inetDataLabels(saId: number, range: string): string[] {
    let labels: string[] = [];
    const data = this.fetchData(saId);
    if (data != undefined || data != null) {
      labels = [];
      const date = new Date();
      for (const time in data[range]['all']['in']) {
        switch (range) {
          case 'month':
            labels.push(
              Math.floor(+time / 100) + '. ' + (date.getMonth() + 1) + '.'
            );
            break;
          default:
            labels.push(time);
            break;
        }
      }
    }
    return labels;
  }

  public inetDataXMin(saId: number, range: string): number | undefined {
    const data = this.fetchData(saId);
    if (data != undefined || data != null) {
      return +Object.keys(data[range]['all']['in']).reduce((k, v) => k);
    }
    return undefined;
  }

  public inetDataXMax(saId: number, range: string): number | undefined {
    const data = this.fetchData(saId);
    if (data != undefined || data != null) {
      return +Object.keys(data[range]['all']['in']).reduce((k, v) => v);
    }
    return undefined;
  }

  /**
   * Vrací seznam IP adres v datech.
   */
  public inetIps(saId?: number): string[] {
    const ips = [];
    const data = this.fetchData(saId);
    if (data != undefined || data != null) {
      for (const ip in data['month']) {
        if (ip !== 'all') {
          ips.push(ip);
        }
      }
    }
    return ips;
  }
}
