import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';

/**
 * Třída, která zajišťuje, aby v případě validace napříč více itemy (validace FormGroup) se mohli tyto itemy zobrazovat
 * jako nevalidní. V opačném případě nezafunguje mat-error
 */
export class CrossFieldErrorMatcher implements ErrorStateMatcher {
  /**
   * @param errorsFilter List of errors to match with the assigned field, otherwise will get invalid on any error at form group level
   */
  constructor(protected errorsFilter: string[] = []) {}

  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    // console.log('Control:', control, 'Form:', form, 'Form invalid:', form.invalid, 'control touched:', control.touched, 'control invalid:', control.invalid)
    return !!(
      (control?.dirty &&
        form?.invalid &&
        (this.errorsFilter.length === 0 ||
          this.errorsFilter.some((error) => {
            return form.hasError(error);
          }))) ||
      ((control?.dirty || control?.touched) && control.invalid)
    );
  }
}
