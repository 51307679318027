import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormGroup,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { PopupOption } from '../popup-option';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, filter, Observable, of, tap } from 'rxjs';

@Component({
  selector: 'app-popup-list',
  templateUrl: './popup-list.component.html',
  styleUrl: './popup-list.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PopupListComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class PopupListComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  // ---------- ControlValueAccessor implementation START ----------
  public writeValue(): void {
    // no use
  }

  registerOnChange(): void {
    // no use
  }

  public registerOnTouched(): void {
    // no use
  }
  // ---------- ControlValueAccessor implementation END ----------

  @Input() formGroup!: FormGroup;
  @Input() formControlName?: string;
  @Input() formControl?: UntypedFormControl;
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() label?: string;
  @Input() title?: string;
  @Input() optionsWidth = '18rem'; // fixní šířka roztažených optionů
  @Input() fixOptionsToInputWidth = false; // nastaví šířku optionů podle inputu
  @Input() emptyOption?: PopupOption = {
    id: '',
    name: '--------',
    disabled: false,
  };
  @Input() formFieldClass?: string;
  @Input() subscriptSizing: 'fixed' | 'dynamic' = 'fixed';
  @Input() showClear = false;
  @Input() customError?: string;
  @Input() defaultOption?: PopupOption;
  @Input() placeholder = '';
  @Input() defaultLimit = 999;
  //eslint-disable-next-line
  @Input() dataSource: any;
  @Input() exactOnly = false; // Pro testování vyhledávače adres
  @Input() defaultFilters: object = {};
  @Input() filterDb = false; // nastavení, které určuje, jestli se filtruje dotazy do databáze, nebo se načtou všechny hodnoty a filtruje se mezi nimi.

  private _options: PopupOption[] = [];
  @Input() set options(value: PopupOption[]) {
    const newOptions = value.sort((a, b) => (b.sorter ?? 0) - (a.sorter ?? 0));
    if (JSON.stringify(this._options) !== JSON.stringify(newOptions)) {
      this._options = newOptions;
      if (this.popupControl) {
        const val = this.popupControl.value;
        const disp = this.displayFn(val);
        const search = this.rawSearchString;
        // We need to reset the control value when the options list changes and
        // the actual value is not in the options list (displayFn returns
        // empty string when can't find the option) or displayed value doesn't
        // correspond to the new display value (label) of the item in the list.
        if (val && (!disp || (search && disp !== search))) {
          this.popupControl.setValue(null);
        } else {
          this.popupControl.setValue(val); // Options can be init later than default value, so we need to set it again
        }
      }
    }
  }
  public get options(): PopupOption[] {
    return this._options;
  }

  @Input()
  get noValue(): string | false {
    return this.emptyOption ? this.emptyOption.name : false;
  }
  set noValue(value: string | false) {
    if (value === false) {
      this.emptyOption = undefined;
    } else {
      this.emptyOption = { id: '', name: value, disabled: false };
    }
  }

  public get popupControl(): AbstractControl | undefined {
    if (this.formControlName) {
      return this.formGroup.controls[this.formControlName];
    } else {
      return this.formControl;
    }
  }

  public get controlErrorCondition(): boolean {
    return (
      !!this.popupControl &&
      this.popupControl.invalid &&
      (this.popupControl.dirty || this.popupControl.touched)
    );
  }

  public get selectedOption(): PopupOption | undefined {
    return this.options.find((opt) => this.popupControl?.value === opt.id);
  }

  public filteredOptions: PopupOption[] = [];
  public applyFilter = new EventEmitter<void>();
  public shouldClosePopup = new EventEmitter<boolean>();

  @ViewChild('searchInput') searchInput?: ElementRef;
  public get rawSearchString(): string {
    return this.searchInput?.nativeElement?.value ?? '';
  }
  public get searchString(): string {
    return this.rawSearchString.toLowerCase();
  }

  @ViewChild(MatAutocompleteTrigger)
  autocompleteTrigger?: MatAutocompleteTrigger;

  ngOnInit(): void {
    if (this.dataSource) {
      this.getOptions('', this.defaultLimit);
    }
    this.filter();
    this.observeFilterChange();
    this.initDefaultValue();
    this.observeShouldClosePopup();
  }

  ngOnChanges(): void {
    this.filter();
  }

  public optionSelected(): void {
    // je potřeba přidat timeout, protože po eventu optionSelected se zavolá ještě focus a po focusu je kurzor na konci a není vidět začátek názvu optionu
    setTimeout(() => {
      this.searchInput?.nativeElement.blur();
    }, 0);
  }

  public nullControlValue(): void {
    this.popupControl?.setValue(null);
  }

  public focus(): void {
    this.filteredOptions = this.options; // reset optionů, aby se při otevření popupu zobrazily všechny
    this.shouldClosePopup.emit(false); // Zajistí, že panel optionů zostane otevřený.
  }

  public focusOut(): void {
    if (this.popupControl) {
      const val = this.popupControl.value;
      // pokud label optionu není roven obsah inputu
      if (this.displayFn(val) !== this.rawSearchString) {
        this.popupControl.setValue(val); // znovu propíšu hodnotu, aby se zobrazila v inputu
      }
    }
    this.shouldClosePopup.emit(true);
  }

  private filter(): void {
    if (this.dataSource) {
      if (this.filterDb || !this.options || this.options.length < 1) {
        const searchString = this.filterDb ? this.searchString : '';
        this.getOptions(searchString, this.defaultLimit).subscribe(
          (options) => {
            this.options = options;
            this.filterOptions();
          }
        );
      } else {
        this.filterOptions();
      }
    } else {
      this.filterOptions();
    }
  }

  private filterOptions(): void {
    this.filteredOptions = this.options.filter((opt) =>
      opt.name.toLowerCase().includes(this.searchString)
    );
  }

  private observeFilterChange(): void {
    this.applyFilter
      .pipe(
        debounceTime(this.filterDb ? 600 : 0),
        tap(() => this.filter()),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private observeShouldClosePopup(): void {
    this.shouldClosePopup
      .pipe(
        debounceTime(200),
        filter((close) => close),
        filter(() => !!this.autocompleteTrigger?.panelOpen),
        tap(() => this.autocompleteTrigger?.closePanel()),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private initDefaultValue(): void {
    // pokud je defaultOption deklarován a nachází se v poli optionů, nastavím jeho hodnotu
    if (
      this.defaultOption &&
      this.options.some((o) => o.id === this.defaultOption?.id)
    ) {
      this.popupControl?.setValue(this.defaultOption.id);
    }
  }

  public displayFn(id?: string | null): string {
    return this.options?.find((o) => o.id === id)?.name ?? '';
  }

  private getOptions(
    searchString: string,
    limit: number
  ): Observable<PopupOption[]> {
    if (this.dataSource) {
      return this.dataSource.optionsGet({
        limit,
        searchText: searchString || '',
        exactOnly: this.exactOnly ? 1 : 0,
        ...this.defaultFilters,
      });
    } else {
      return of([]);
    }
  }
}
