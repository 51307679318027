import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { DataProcessStatesHistoryService } from '@isp-sc/shared/segments/mango-processes/data-access';
import {
  DataParamsPremiseService,
  DataParamsUserService,
  ParamControlService,
} from '@isp-sc/shared/segments/params/data-access';
import { DependencyService } from './dependency.service';

/**
 * Servisa, která se stará o vyhodnocení závislostí (především parametrů)
 * Tato třída je úzce svázána s parametry procesů. Případná abstrakce je možná, nicméně
 * zůstane potřeba řešit specifika (názvy parametrů a atributů) a dělal bych ji až bude potřeba.
 */
@Injectable({
  providedIn: 'root',
})
export class DependencyProcessService extends DependencyService {
  private data: { [key: string]: any } = {}; //Moje představa je, že si služba bude držet veškerá data na vyhodnocení závislostí, a že tyto data budou

  // Data bude potřeba držet ke správnému procesu, proto si musím držet jeho id. Předpokládám, že najednou budu mít vždy
  // jen jeden proces (pokud by jich bylo víc, bude potřeba, aby tato služba nebyla injectovatelná
  private id?: number;
  private statesHistoryData = false; // zde si poznamenám, jestli mám vytažená data z historie, abych se na ně neptal pořád dokola

  constructor(
    private statesHistory: DataProcessStatesHistoryService,
    private userDataService: DataParamsUserService,
    private premiseDataService: DataParamsPremiseService,
    private paramControl1: ParamControlService,
    private session1: SessionService
  ) {
    super(paramControl1, session1);
  }

  public resolveDependency(
    dependencyStr: string,
    objectId: number,
    actualData: any
  ): Observable<boolean> {
    let clearCache = false;

    if (dependencyStr === '') {
      return of(false);
    }

    if (dependencyStr === 'ALL') {
      return of(true);
    }

    // Pokud se změnil proces, vyčistím všechno co jsem mohl mít v cachi.
    if (this.id !== objectId) {
      this.data = {};
      clearCache = true;
      this.id = objectId;
    }

    // Dynamická data:
    for (const key in actualData.processCoreForm) {
      if (
        Object.prototype.hasOwnProperty.call(actualData.processCoreForm, key)
      ) {
        if (key.startsWith('ML_')) {
          this.data['PROCESS.CORE.' + key] = DependencyProcessService.transform(
            actualData.processCoreForm[key]
          );
        } else {
          this.data['PROCESS.PARAM.' + key] =
            DependencyProcessService.transform(actualData.processCoreForm[key]);
        }
      }
    }

    actualData.state = Number(actualData.state);
    actualData.transition = Number(actualData.transition);

    let userData: Observable<any>;
    let premiseData: Observable<any>;
    if (clearCache) {
      if (dependencyStr.search('USER.PARAM') >= 0) {
        userData = this.userDataService.getSimpleParams({ all: true });
      } else {
        userData = of(null);
      }

      if (
        dependencyStr.search('PREMISE.PARAM') >= 0 &&
        actualData.processCoreForm &&
        actualData.processCoreForm.ML_P_ID > 0
      ) {
        premiseData = this.premiseDataService.getSimpleParams({
          id: actualData.processCoreForm.ML_P_ID,
          all: true,
        });
      } else {
        premiseData = of(null);
      }
    } else {
      userData = of(null);
      premiseData = of(null);
    }

    let stateHistoryData: Observable<any>;
    let statesChanged = false;

    // Pokud již nějaká data ke stavu mám, budu je znovu vytahovat jen v případě
    // 1) Změnil se aktuální stav
    // 2) Mám někde v závislosti nadefinované operátory,
    //    kterými řeším předchozí stavy.

    // Nejdřív si vyřeším jestli se mi změnil stav (zahrnuje i to že není vůbec definovaný.
    if (this.data['STATE']) {
      statesChanged =
        this.data['STATE'][this.data['STATE'].length - 1] !== actualData.state;
    } else {
      statesChanged = true;
    }

    // Zde si ještě pořeším,jestli ty staré stavy vůbec potřebuji (kvůli operátorům).
    // Vytahuji si stavy i přechody najednou - takže prostě pokud
    // daný operátor je někde v textu, tak si t data vytáhnu.
    // Je to poměrně složité, ale má to zabránit zbytečným dotazům na historii stavů
    if (statesChanged) {
      this.statesHistoryData = false;
      if (/->|->=|!->|!->=/.test(dependencyStr) && objectId > 0) {
        // Změnil se stav, a budu vyhodnocovat historii - vytáhnu si data z historie
        stateHistoryData = this.statesHistory.getone(objectId);
      } else {
        // Změnil stav, ale nepotřebuju data z historie
        stateHistoryData = of([]);
      }
    } else {
      if (
        !this.statesHistoryData &&
        /->|->=|!->|!->=/.test(dependencyStr) &&
        objectId > 0
      ) {
        //Stav se sice nezměnil, ale potřebuji data k vyhodnocení a nemám je, takže si pro ně musím šáhnout
        stateHistoryData = this.statesHistory.getone(objectId);
      } else {
        // Stav se nezměnil, pracuji tedy s daty, která mám
        stateHistoryData = of(null);
      }
    }

    // spojím si všechny observable, které mi shánějí data do jedné
    return forkJoin([stateHistoryData, userData, premiseData]).pipe(
      map((results) => {
        // stateHistoryData
        if (results[0] !== null) {
          if (results[0].states) {
            this.statesHistoryData = true;
            this.data['STATE'] = results[0].states.concat([actualData.state]);
          } else {
            this.data['STATE'] = [actualData.state];
          }

          if (results[0].transitions) {
            this.statesHistoryData = true;
            this.data['TRANSITION'] = results[0].transitions.concat([
              actualData.transition,
            ]);
          } else {
            this.data['TRANSITION'] = [actualData.transition];
          }
        }

        // userData
        if (results[1]) {
          for (const key in results[1]) {
            if (Object.hasOwnProperty.call(key, results[1])) {
              this.data['USER.PARAM.' + key] =
                DependencyProcessService.transform(results[1][key]);
            }
          }
        }

        // premiseData
        if (results[2]) {
          for (const key in results[2]) {
            if (Object.hasOwnProperty.call(key, results[2])) {
              this.data['PREMISE.PARAM.' + key] =
                DependencyProcessService.transform(results[2][key]);
            }
          }
        }

        const orParts = dependencyStr.split('#OR#');

        let allCheck = false;
        orParts.forEach((part) => {
          let check = true;
          const andParts = part.split('#AND#');
          andParts.forEach((part) => {
            if (!this.resolvePartDependency(part)) {
              check = false;
            }
          });
          if (check) {
            allCheck = true; // Pokud prošla jakákoli z OR částí.
          }
        });
        return allCheck;
      })
    );
  }

  private resolvePartDependency(depPartString: string) {
    const dependency = depPartString.split('::');

    if (dependency[0] === 'STATE' || dependency[0] === 'TRANSITION') {
      dependency[2] = String(dependency[2]);
    }
    switch (dependency[1]) {
      case '=':
        if (dependency[0] === 'STATE' || dependency[0] === 'TRANSITION') {
          dependency[2] = String(dependency[2]);
          const actId =
            this.data[dependency[0]][this.data[dependency[0].length - 1]]; // V datech mám pole, aktuální stav je ten poslední
          return actId !== dependency[2];
        } else {
          return this.data[dependency[0]] === dependency[2];
        }
      case '!=':
        if (dependency[0] === 'STATE' || dependency[0] === 'TRANSITION') {
          dependency[2] = String(dependency[2]);
          const actId =
            this.data[dependency[0]][this.data[dependency[0].length - 1]]; // V datech mám pole, aktuální stav je ten poslední
          return actId !== dependency[2];
        } else {
          return this.data[dependency[0]] !== dependency[2];
        }
      case '->':
        return this.data[dependency[0]]
          .slice(0, -1)
          .includes(Number(dependency[2]));
      case '->=':
        return this.data[dependency[0]].includes(Number(dependency[2]));
      case '!->':
        return !this.data[dependency[0]]
          .slice(0, -1)
          .includes(Number(dependency[2]));
      case '!->=':
        return !this.data[dependency[0]].includes(Number(dependency[2]));
      case '>':
        return this.data[dependency[0]] > dependency[2];
      case '<':
        return this.data[dependency[0]] < dependency[2];
      case '>=':
        return this.data[dependency[0]] >= dependency[2];
      case '<=':
        return this.data[dependency[0]] <= dependency[2];
      case '%':
        return this.data[dependency[0]].search(dependency[2]) !== -1;
      case '!%':
        return this.data[dependency[0]].search(dependency[2]) === -1;
    }
  }

  private static transform(value: any) {
    switch (value) {
      case true:
        return '1';
      case false:
        return '0';
      default:
        return value;
    }
  }
}
