import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  DataBase,
  DataBaseServiceConfig,
  GetallResult,
} from '@og_soft/data-base';
import { HttpClient } from '@angular/common/http';
import { CachingInterceptor } from '@og_soft/cache';
import { DataValueDomainPatternService } from './data-value-domain-pattern.service';
import { DataCodeListService } from './data-code-list.service';
import { DataValueDomainService } from './data-value-domain.service';
import {
  ProcessServicesService,
  ProcessUnitsService,
} from '@isp-sc/shared/segments/process/data-access';
import { ProcessPremisesService } from '@isp-sc/shared/segments/process/data-access';
import { ParamBase } from './param-base';
import { ParamServices } from './param-services';

@Injectable({
  providedIn: 'root',
})
export abstract class DataParamsService extends DataBase<any> {
  constructor(
    public override http: HttpClient,
    private serviceConfig: DataBaseServiceConfig,
    private cachingInterceptor: CachingInterceptor,
    protected dataValuePatterns: DataValueDomainPatternService,
    protected dataCodeList: DataCodeListService,
    protected dataValueDomain: DataValueDomainService,
    protected dataProcessPremises: ProcessPremisesService,
    protected dataProcessServices: ProcessServicesService,
    protected dataProcessUnits: ProcessUnitsService
  ) {
    super(http, serviceConfig, cachingInterceptor);
  }

  /**
   * Vytvoří a vrátí pole objektů potomků třídy ParamBase.
   * Využívá transformaci, kterou je potřeba přetížit ve potomkovských třídách.
   */
  public getParams(
    params?: any,
    cacheTimeout?: number
  ): Observable<ParamBase[]> {
    return this.getall(params, cacheTimeout).pipe(
      map((d: GetallResult<any>) => {
        const start = Date.now();
        const a = this.createParams(d.data);
        //console.log('Doba vytvoření parametrů: ' + (Date.now() - start) + ' ms');
        return a;
      })
    );
  }

  /**
   * Metoda která vrátí jednoduchá pole parametrů, kde klíčem je název parametru a hodnotu jeho hodnota.
   * @param params - parametry dotazu
   * @param humanReadable - pokud je true, jsou hodnoty zobrazené v lidsky čitelném formátu (týká se především SELECTů)
   */
  public getSimpleParams(
    queryParams?: any,
    humanReadable?: boolean,
    cacheTimeout?: number
  ): Observable<{}> {
    return this.getall(queryParams, cacheTimeout).pipe(
      map((d: GetallResult<any>) => {
        const params: { [key: string]: any } = {};
        d.data.forEach((param) => {
          // Indexované parametry převedu na pole
          if (param.indexed && Number.isInteger(param.index)) {
            if (!Array.isArray(params[param.name])) {
              params[param.name] = [];
            }
            params[param.name][Number(param.index)] = humanReadable
              ? param.hrValue || param.value
              : param.value;
          } else {
            params[param.name] = humanReadable
              ? param.hrValue(param.hrValue || param.value)
              : param.value;
          }
        });
        return params;
      })
    );
  }

  getServices(): ParamServices {
    return {
      dataValuePatterns: this.dataValuePatterns,
      dataCodeList: this.dataCodeList,
      dataValueDomain: this.dataValueDomain,
      dataProcessPremises: this.dataProcessPremises,
      dataProcessServices: this.dataProcessServices,
      dataProcessUnits: this.dataProcessUnits,
    };
  }

  public abstract transformParams(
    paramData: any,
    services: {
      dataValuePatterns: DataValueDomainPatternService;
      dataCodeList: DataCodeListService;
      dataValueDomain: DataValueDomainService;
      dataProcessPremises: ProcessPremisesService;
      dataProcessServices: ProcessServicesService;
      dataProcessUnits: ProcessUnitsService;
    }
  ): ParamBase;

  private createParams(data: any[]): ParamBase[] {
    let params: ParamBase[] = [];
    data.forEach((paramsData) => {
      params.push(this.transformParams(paramsData, this.getServices()));
    });
    params = this.joinMultiSelectParams(params);
    return params;
  }

  private joinMultiSelectParams(params: ParamBase[]): ParamBase[] {
    const multiSelectParams = params.filter((param) =>
      param.isMultiSelectParam(true)
    );
    // Přidám si hodnoty vyšších indexů do hlavního parametru.
    multiSelectParams.forEach((multiSelectParam) => {
      multiSelectParam.value = [multiSelectParam.value];
      for (const param of params) {
        if (param.indexName === multiSelectParam.indexName && param.index > 0) {
          multiSelectParam.value.push(param.value);
        }
      }
    });
    // nakonec odstraním všechny indexy větší než 0
    return params.filter((param) => !param.isMultiSelectParam(false));
  }
}
