<mat-form-field
  [class]="formFieldClass"
  [formGroup]="formGroup"
  [appearance]="appearance"
  [subscriptSizing]="subscriptSizing"
  [style.width.%]="100"
  (focusout)="focusOut()"
>
  <mat-label *ngIf="label">{{ label }}</mat-label>

  <div class="input-wrapper">
    <scloud-popup-color-badge
      *ngIf="
        selectedOption &&
        selectedOption.name === rawSearchString &&
        selectedOption.color
      "
      [color]="selectedOption.color"
    ></scloud-popup-color-badge>

    <input
      *ngIf="formControl; else noControl"
      #searchInput
      matInput
      [formControl]="formControl"
      [matAutocomplete]="auto"
      [title]="title"
      [placeholder]="placeholder"
      (input)="applyFilter.emit()"
      (focus)="focus()"
    />

    <ng-template #noControl>
      <input
        *ngIf="formControlName"
        #searchInput
        matInput
        [formControlName]="formControlName"
        [matAutocomplete]="auto"
        [title]="title"
        [placeholder]="placeholder"
        (input)="applyFilter.emit()"
        (focus)="focus()"
      />
    </ng-template>

    <div class="suffix">
      <svg-icon
        *ngIf="showClear"
        [ngClass]="{ 'clear-icon': true, visible: searchString.length }"
        src="assets/images/icon/ico-clear.svg"
        matRipple
        (click)="$event.stopPropagation(); nullControlValue()"
      ></svg-icon>
      <mat-icon class="arrow-icon">arrow_drop_down</mat-icon>
    </div>
  </div>

  <mat-autocomplete
    #auto="matAutocomplete"
    [requireSelection]="true"
    [displayWith]="displayFn.bind(this)"
    [panelWidth]="fixOptionsToInputWidth ? '' : optionsWidth"
    (optionSelected)="optionSelected()"
  >
    <mat-option
      *ngIf="emptyOption"
      [value]="emptyOption.id"
      [disabled]="emptyOption.disabled"
    >
      <scloud-popup-option-label>{{
        emptyOption?.name
      }}</scloud-popup-option-label>
    </mat-option>

    <mat-option
      *ngFor="let option of filteredOptions"
      [value]="option.id"
      [disabled]="option.disabled"
    >
      <div
        [ngStyle]="{
          display: 'flex',
          alignItems: 'center',
          gap: '0.25rem',
        }"
      >
        <scloud-popup-color-badge
          *ngIf="option.color as color"
          [color]="color"
        ></scloud-popup-color-badge>
        <scloud-popup-option-label>{{ option.name }}</scloud-popup-option-label>
        <mat-icon *ngIf="option.icon as icon">{{ icon }}</mat-icon>
      </div>
    </mat-option>
  </mat-autocomplete>

  <mat-error *ngIf="customError && controlErrorCondition">
    {{ customError }}
  </mat-error>
</mat-form-field>
