import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first, tap } from 'rxjs/operators';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';

@Component({
  selector: 'app-verify-registration',
  templateUrl: './verify-registration.component.html',
  styleUrls: ['./verify-registration.component.scss'],
})
export class VerifyRegistrationComponent {
  private code?: string;
  public state: 'wait' | 'check' | 'failed' | 'ok' = 'check';

  constructor(
    private session: SessionService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.queryParams.subscribe((params) => {
      this.code = params['code'];
      if (this.code) {
        this.session
          .autoLogin(this.code)
          .pipe(
            first(),
            tap({
              next: (data) => {
                if (data) {
                  this.state = 'ok';
                  this.router.navigateByUrl(SessionService.MAIN_PAGE);
                } else {
                  this.state = 'failed';
                }
              },
              error: (error) => {
                this.state = 'failed';
              },
            })
          )
          .subscribe();
      } else {
        this.state = 'wait';
      }
    });
  }
}
