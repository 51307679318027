import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DialogConfig, DialogRef } from '@og_soft/dialog';
import { Subject } from 'rxjs';
import { filter, startWith, switchMap, take, tap } from 'rxjs/operators';
import { ParamControlService } from '@isp-sc/shared/segments/params/data-access';
import { ParamFormComponent } from '@isp-sc/shared/segments/params/features';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { DependencyNodesService } from '@isp-sc/shared/segments/dependency/data-access';
import { FormHelperService } from '@isp-sc/shared/data-access';
import { DataNodesService } from '@isp-sc/shared/segments/data/data-access';
import { DataNodeParamsService } from '@isp-sc/shared/segments/csob/data-access';

@Component({
  selector: 'sc-node-edit',
  templateUrl: './node-edit.component.html',
  styleUrls: ['./node-edit.component.scss'],
  providers: [ParamControlService],
})
export class NodeEditComponent implements OnInit {
  private _id: number | null = null;
  public get id(): number | null {
    return this._id;
  }
  public set id(value: number) {
    if (this._id !== value) {
      this._id = value;
    }
  }

  private _caption: string | null = null;
  public get caption(): string | null {
    return this._caption;
  }
  public set caption(value: string) {
    if (this._caption !== value) {
      this._caption = value;
    }
  }

  @ViewChild(ParamFormComponent, { static: false })
  paramForm?: ParamFormComponent;

  public form: FormGroup;

  public formSubmitSubject$ = new Subject();

  constructor(
    public dialogConfig: DialogConfig,
    private dialogRef: DialogRef,
    private session: SessionService,
    private formHelper: FormHelperService,
    public dataNodesService: DataNodesService,
    public dataNodeParamsService: DataNodeParamsService,
    public dependencyNodesService: DependencyNodesService
  ) {
    this.id = dialogConfig.data.id;
    this.caption =
      dialogConfig.data.nodeData.name +
      (dialogConfig.data.nodeData.parIdent
        ? ', ' + dialogConfig.data.nodeData.parIdent
        : '');
    this.form = new FormGroup({});
  }

  ngOnInit(): void {
    this.session.processingSet(true);

    /**
     * Handles pending async validation on submit
     * https://stackoverflow.com/questions/49516084/reactive-angular-form-to-wait-for-async-validator-complete-on-submit
     */
    this.formSubmitSubject$
      .pipe(
        tap(() => () => this.form.markAsDirty()),
        switchMap(() =>
          this.form.statusChanges.pipe(
            startWith(this.form.status),
            filter((status) => status !== 'PENDING'),
            take(1)
          )
        ),
        filter((status) => status === 'VALID')
      )
      .subscribe(() => this.save());
  }

  protected save(): void {
    const formData = this.form.value;
    formData.id = this.id;
    console.log('Ukládaná data ', formData);
    if (this.form.valid) {
      this.dataNodesService.post(formData).subscribe(
        () => {
          this.dataNodeParamsService.clearCache();
          this.session.message(
            $localize`:@@NodeEdit.form.save.ok:OK. Všechno máme uloženo.`
          );
          this.dialogRef.close(formData);
        },
        (error) => {
          this.session.message(
            $localize`:@@NodeEdit.form.save.error:Tak to tak úplně nevyšlo.`
          );
          console.error('Tak to tak úplně nevyšlo.', error);
          this.dialogRef.close(null);
        }
      );
    } else {
      this.session.message(
        $localize`:@@NodeEdit.form.validate.message:Některé položky nejsou vyplněné nebo nemají správnou hodnotu.`
      );
      this.formHelper.markDirty(this.form);
    }
  }

  onParamsReady(): void {
    this.session.processingSet(false);
  }
}
