import { Component } from '@angular/core';
import { DialogConfig } from '@og_soft/dialog';

@Component({
  selector: 'app-protected-data-level3-dialog-component',
  template: `
    <dialog-content>
      <div class="info-content" [innerHTML]="infoText"></div>
    </dialog-content>
    <dialog-actions>
      <button
        mat-flat-button
        dialog-close
        i18n="@@ProtectedData.level3Dialog.button.close"
      >
        Zavřít
      </button>
    </dialog-actions>
  `,
})
export class ProtectedDataLevel3DialogComponent {
  infoText: string;

  constructor(public config: DialogConfig) {
    this.infoText = config.data.infoText;
  }
}
