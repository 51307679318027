<dialog-title>
  <h3 i18n="@@NodeEdit">Změna nastavení</h3>
</dialog-title>

<dialog-content>
  <p>
    <b>{{ caption }}</b>
  </p>

  <app-param-form
    [paramsService]="dataNodeParamsService"
    [form]="form"
    [paramFormName]="'params'"
    [objectId]="{ id: id }"
    [dependencyService]="dependencyNodesService"
    (formFinished)="onParamsReady()"
  ></app-param-form>
</dialog-content>

<dialog-actions>
  <button
    mat-flat-button
    dialog-close
    i18n="
      Zavřít|Tlačítko na zavření dialogu v dialogu editace
      zařízení@@NodeEdit.button.Close"
  >
    Zavřít
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="formSubmitSubject$.next(undefined)"
    i18n="
      Odeslat|Tlačítko na uložení dialogu v dialogu editace
      zařízení@@NodeEdit.button.Send"
  >
    Uložit
  </button>
</dialog-actions>
<app-session-processing></app-session-processing>
