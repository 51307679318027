import { Injectable } from '@angular/core';
import { PopupOption } from '@isp-sc/shared/ui';
import { DataBase } from '@og_soft/data-base';
import { CodeList } from '@isp-sc/shared/segments/params/common';

@Injectable({
  providedIn: 'root',
})
export class DataCodeListService extends DataBase<CodeList> {
  protected getAllUrl(): string {
    return 'code-list' as const;
  }

  override tranformOptions(row: CodeList): PopupOption {
    return { id: row.key, name: row.value };
  }
}
