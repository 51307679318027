import { Component, OnInit } from '@angular/core';
import { DialogConfig, DialogRef } from '@og_soft/dialog';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserEditFormType } from '@isp-sc/shared/common';
import {
  UserDataEditService,
  UserEditFormService,
} from '@isp-sc/shared/segments/user/data-access';
import { DataParamsUserService } from '@isp-sc/shared/segments/params/data-access';
import { UserType } from '@isp-sc/shared/segments/user/common';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile-dialog.component.html',
  styleUrls: ['./user-profile-dialog.component.scss'],
})
export class UserProfileDialogComponent implements OnInit {
  public formType: UserEditFormType;
  public heading = 'Confirm';

  public USER_EDIT_FORM_TYPE = UserEditFormType;

  private userEditConfig = {
    personal: {
      title: $localize`:@@UserProfile.personal.dialog.title:Změna osobních údajů`,
    },
    addresses: {
      title: $localize`:@@UserProfile.addresses.dialog.title:Změna adresy`,
    },
    contacts: {
      title: $localize`:@@UserProfile.contacts.dialog.title:Změna kontaktních údajů`,
    },
    credentials: {
      title: $localize`:@@UserProfile.credentials.dialog.title:Změna přihlašovacích údajů`,
    },
    payments: {
      title: $localize`:@@UserProfile.payments.dialog.title:Změna platebních údajů`,
    },
    params: {
      title: $localize`:@@UserProfile.params.dialog.title:Další nastavení`,
    },
  };

  constructor(
    public config: DialogConfig,
    private dlgRef: DialogRef,
    private session: SessionService,
    private userEditService: UserDataEditService,
    private userEditFormService: UserEditFormService,
    public userParamsService: DataParamsUserService
  ) {
    this.formType = this.config.data.editMode;
  }

  ngOnInit(): void {
    if (this.formType !== undefined) {
      switch (this.formType) {
        case UserEditFormType.personal:
          this.heading = this.userEditConfig.personal.title;
          break;
        case UserEditFormType.addresses:
          this.heading = this.userEditConfig.addresses.title;
          break;
        case UserEditFormType.contacts:
          this.heading = this.userEditConfig.contacts.title;
          break;
        case UserEditFormType.credentials:
          this.heading = this.userEditConfig.credentials.title;
          break;
        case UserEditFormType.payments:
          this.heading = this.userEditConfig.payments.title;
          break;
        case UserEditFormType.params:
          this.heading = this.userEditConfig.params.title;
          break;
      }
    }
  }

  saveProfile(): void {
    this.session.processingSet(
      true,
      $localize`:@@UserProfileDialog.processing.save.message:Ukládám...`
    );
    this.getUserType().subscribe((userType) => {
      this.userEditFormService.getFormData(this.formType, userType).subscribe(
        (data) => {
          if (data) {
            console.log('Data k uložení: ', data);
            this.userEditService.post(data).subscribe(
              (userData) => {
                this.session.processingSet(false);
                this.session.message(
                  $localize`:@@UserProfile.editUser.message:Změny v profilu byly úspěšně uloženy.`
                );
                this.userParamsService.clearCache();
                this.dlgRef.close();
              },
              (err) => {
                this.session.processingSet(false);
                console.error('Jejda, chyba při ukládání:', err);
                return false;
              }
            );
          } else {
            this.session.processingSet(false);
          }
        },
        (error) => {
          this.session.processingSet(false);
        }
      );
    });
  }

  private getUserType(): Observable<UserType> {
    if (this.formType === UserEditFormType.personal) {
      // Pokud edituji osobní údaje nebo jsme  v registrace, aktuální typ mám ve formuláři.
      return of(
        this.userEditFormService.getForm(UserEditFormType.personal).get('type')
          ?.value
      );
    } else {
      // Jinak si ho získám z db
      return this.userEditService.getSingleton().pipe(map((user) => user.type));
    }
  }
}
