// Pozor, v komentarich v tomto souboru NEPOUZIVAT DIAKRITIKU. Utilita "ng"
// se ji zmate a tento soubor pocuni (pri pridavani importu v nem zacne delat
// neukoncene retezce.

import localeCs from '@angular/common/locales/cs';
import localeSk from '@angular/common/locales/sk';
import {
  ErrorHandler,
  NgModule,
  TRANSLATIONS_FORMAT,
  Inject,
} from '@angular/core';
import { AppComponent } from './app.component';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe, registerLocaleData } from '@angular/common';
import { environment } from '../environments/environment';
import { CachingInterceptor } from '@og_soft/cache';
import { PhoneNumberModule, PhoneNumberPipe } from '@og_soft/phone-number';
import { OgDataBaseModule } from '@og_soft/data-base';
import { DialogModule } from '@og_soft/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import * as Sentry from '@sentry/angular';
import { DatetimeControlModule } from '@og_soft/datetime-control';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { BaseChartDirective } from 'ng2-charts';
import {
  MainToolbarModule,
  NavigationModule,
  PageCaptionBlockModule,
} from '@isp-sc/isp-sc/segments/selfcare/features';
import { ScConfiguration, SC_CONFIGURATION } from '@isp-sc/shared/common';
import {
  ErrorInterceptor,
  SentryErrorHandler,
  SessionInterceptor,
} from '@isp-sc/shared/data-access';
import {
  appSetupName,
  appVersion,
  buildDate,
  vcsLastCommit,
} from '../environments/version';
import { MangoHammerConfig } from '@isp-sc/shared/segments/mango-processes/common';
import { PageEmptyModule, PopupListModule } from '@isp-sc/shared/ui';
import { CoreModule } from '@isp-sc/shared/segments/session/features';
import { ServiceWorkerModule } from '@angular/service-worker';

if (environment.sentryDns) {
  Sentry.init({
    dsn: environment.sentryDns,
    release: environment.version,
  });
}

// Potřebujeme říci ng2-pdf-vieweru, aby si knihovnu pdf.worker.js bral
// někde z našeho buildu a mít ji jako vlastní součást. Důvodem je použití
// blokovanými zákazníky (hlásil ASS...), kteří si, pokud je knihovna
// někde v CDN, například svoji smlouvu nepřečtou...
(window as any).pdfWorkerSrc = 'pdf.worker.min.js';

// Takhle bychom si staticky natahli preklady pro pouziti nize v "providers".
// Realne pouzivame factory, ktera je zavede podle LOCALE_ID.
// export const translations = require(`raw-loader!../locale/messages.en.xlf`);

export function createScConfiguration(): ScConfiguration {
  return {
    production: environment.production,
    baseUrl: environment.baseUrl,
    sentryDsn: environment.sentryDns,
    dbVersion: environment.dbVersion,
    addressSearchUrl: environment.addressSearchUrl,
    ct: environment.ct,
    theme: environment.theme,
    formAppearance: environment.formAppearance,
    reCaptchaSiteKey: environment.reCaptchaSiteKey,
    version: appVersion,
    buildDate: buildDate,
    appSetupName: appSetupName,
    vcsLastCommit: vcsLastCommit,
  };
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    OgDataBaseModule.forRoot({
      appUrl: environment.baseUrl,
      mangoUrl: environment.baseUrl,
    }),
    ReactiveFormsModule,
    // ** Knihovny vlastni
    DialogModule,
    PhoneNumberModule,
    DatetimeControlModule,
    // ** Material design
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatListModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatSelectModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatTableModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatChipsModule,
    MatTooltipModule,
    MatBadgeModule,
    ScrollingModule,
    // ** Other libraries
    NgxMatSelectSearchModule,
    PdfViewerModule,
    // ** Veci ktere musi byt kdyz se knihovny tahaji naprimo
    RecaptchaV3Module,
    VirtualScrollerModule,
    BaseChartDirective,
    PageCaptionBlockModule,
    MainToolbarModule,
    PageEmptyModule,
    CoreModule,
    NavigationModule,
    PopupListModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    DatePipe,
    CachingInterceptor,
    PhoneNumberPipe,
    {
      provide: SC_CONFIGURATION,
      useFactory: (): ScConfiguration => createScConfiguration(),
    },
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MangoHammerConfig },
    // Format pouzitych prekladu:
    { provide: TRANSLATIONS_FORMAT, useValue: 'xlf' },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptchaSiteKey },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor(
    @Inject(SC_CONFIGURATION) private scConfiguration: ScConfiguration
  ) {
    console.log(
      'App version: ' +
        scConfiguration.appSetupName +
        ' ' +
        scConfiguration.version +
        ', built at ' +
        scConfiguration.buildDate +
        ', last revision: ' +
        scConfiguration.vcsLastCommit
    );
  }
}

registerLocaleData(localeCs, 'cs');
registerLocaleData(localeSk, 'sk');
