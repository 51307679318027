import { Injectable } from '@angular/core';
import { DataParamsProcessGeneralService } from './data-params-process-general-service';

// Data pro existující a nové procesy získávám z jiných endpointů.
// Mají odpovídající formát, jen definiční endpoint neobsahuje některé atributy - to je ošetřené
// ve funkci transformParams výše.
@Injectable({
  providedIn: 'root',
})
export class DataParamsProcessService extends DataParamsProcessGeneralService {
  protected getAllUrl(): string {
    return 'process-params' as const;
  }
}
