import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { DataUserContactsService } from '@isp-sc/shared/segments/data/data-access';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { FormHelperService } from '@isp-sc/shared/data-access';
import { MangoFormControl } from '@isp-sc/shared/segments/mango-processes/common';
import { Contact } from '@isp-sc/shared/segments/user/common';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent {
  form: FormGroup;

  constructor(
    public dataService: DataUserContactsService,
    public session: SessionService,
    public formHelper: FormHelperService
  ) {
    this.form = new FormGroup({
      firstName: new MangoFormControl('', [Validators.required]),
      lastName: new MangoFormControl('', [Validators.required]),
      mail: new MangoFormControl('', [Validators.required]),
      phone: new FormGroup({
        gsm: new MangoFormControl('', []),
        landline: new MangoFormControl('', []),
        landline2: new MangoFormControl('', []),
        fax: new MangoFormControl('', []),
      }),
      cred: new FormGroup({
        login: new MangoFormControl('', []),
        password: new MangoFormControl('', []),
        passwordc: new MangoFormControl('', []),
      }),
    });
  }

  joinNonempty(a: any[]) {
    return a.filter((el) => !!el).join(', ');
  }

  public newContact = false;
  public editContact: Contact | null = null;
  public deleteContact: Contact | null = null;

  protected goAddContact() {
    this.newContact = true;
    this.form.reset();
  }

  protected goEditContact(c: Contact) {
    this.editContact = c;
    this.form.patchValue(c);
  }

  protected goDeleteContact(c: Contact) {
    this.deleteContact = c;
  }

  protected goCancelAnything() {
    this.newContact = false;
    this.editContact = null;
    this.deleteContact = null;
    this.form.reset();
  }

  protected confirmCreateContact() {
    const data = this.form.getRawValue();
    console.log('XXX confirmCreateContact value:', data);
    this.dataService.post(data).subscribe(
      (d) => {
        console.log(
          'XXX Přidáno, vrátilo se tohle:',
          d,
          'Ještě vymyslet, jak to refreshnout.'
        );
        this.session.message(
          $localize`:@@Contacts.contactCreate.message:Kontakt byl vytvořen.`
        );
        this.goCancelAnything();
      },
      (err) => {
        console.error('Se to nějak nepovedlo:', err);
        this.session.message(
          $localize`:@@Contacts.contactCreate.message:Operace se nezdařila.`
        ); // TODO: appbus error message
      }
    );
  }

  protected confirmEditContact() {
    const data = this.form.getRawValue();
    console.log('XXX confirmEditContact value:', data);
    this.dataService.put(this.editContact!.id, data).subscribe(
      (d) => {
        console.log(
          'XXX Uloženo, vrátilo se tohle:',
          d,
          'Ještě vymyslet, jak to refreshnout.'
        );
        this.session.message(
          $localize`:@@Contacts.contactEdit.message:Kontakt byl uložen.`
        );
        this.goCancelAnything();
      },
      (err) => {
        console.error('Se to nějak nepovedlo:', err);
        this.session.message(
          $localize`:@@Contacts.contactEdit.message:Operace se nezdařila.`
        ); // TODO: appbus error message
      }
    );
  }

  protected confirmDeleteContact() {
    this.dataService.delete(this.deleteContact?.id);
    //.pipe(catchError());
  }
}
