<form [formGroup]="form">
  <p class="title" i18n="@@PasswordChange.password.title">
    Heslo buď musí být alespoň 12 znaků dlouhé, anebo musí být nejméně 8 znaků
    dlouhé a obsahovat malá i velká písmena a číslice a žádný znak se nesmí
    opakovat více než dvakrát za sebou.
  </p>
  <ng-container *ngIf="form.get('password') as passwordControl">
    <div *ngIf="formHelper.isVisible(passwordControl)">
      <mat-form-field
        class="mango-form-field"
        [appearance]="formHelper.appearance()"
      >
        <mat-label i18n="@@PasswordChange.password.label">Heslo</mat-label>
        <input
          matInput
          type="password"
          formControlName="password"
          id="password"
          name="password"
          i18n-title="@@PasswordChange.password.title"
          title="Heslo buď musí být alespoň 12 znaků dlouhé, anebo musí být nejméně 8 znaků dlouhé a obsahovat malá i velká písmena a číslice a žádný znak se nesmí opakovat více než dvakrát za sebou."
          [required]="passwordControl | hasRequiredField"
        />
        <app-config-setting
          input="password"
          [formName]="formName"
        ></app-config-setting>

        <mat-error
          *ngIf="
            passwordControl.invalid &&
            (passwordControl.dirty || !passwordControl.untouched)
          "
        >
          {{ getErrorMessage(passwordControl) }}
        </mat-error>
      </mat-form-field>
    </div>
  </ng-container>

  <ng-container *ngIf="form.get('passwordCheck') as passwordCheckControl">
    <div *ngIf="formHelper.isVisible(passwordCheckControl)">
      <mat-form-field
        class="mango-form-field"
        [appearance]="formHelper.appearance()"
      >
        <mat-label i18n="@@PasswordChange.passwordCheck.label"
          >Heslo ještě jednou</mat-label
        >
        <input
          matInput
          type="password"
          formControlName="passwordCheck"
          id="passwordCheck"
          name="passwordCheck"
          i18n-title="@@PasswordChange.passwordCheck.title"
          title=""
          [errorStateMatcher]="errorMatcher"
          [required]="passwordCheckControl | hasRequiredField"
        />
        <app-config-setting
          input="passwordCheck"
          [formName]="formName"
        ></app-config-setting>
        <mat-error
          *ngIf="
            passwordCheckControl.invalid &&
            (passwordCheckControl.dirty || !passwordCheckControl.untouched)
          "
        >
          {{ getErrorMessage(passwordCheckControl) }}
        </mat-error>
        <mat-error
          *ngIf="
            form.errors?.passwordCheck &&
            (passwordCheckControl.dirty || !passwordCheckControl.untouched)
          "
        >
          <span i18n="@@PasswordChange.error.notIdentical"
            >Heslo musí být zadáno dvakrát stejné.</span
          >
        </mat-error>
      </mat-form-field>
    </div>
  </ng-container>
</form>
